import React from 'react';
import { RouteProps } from 'react-router-dom';
import { appRoutes } from '../../config/routes';
import { useSelector } from 'react-redux';
import { roleSelector } from '../../redux/auth/selectors';
import { ProtectedRoute } from './protected-route';
import { UserRoleType } from '../../redux/user/types';
import userRoleHierarchy from '../../config/userRoleHierarchy';

/**
 * Private route. Makes sure the user is authenticated when trying to access this route.
 * Tries to get the current authenticated user from
 *
 * @param props Properties for the route.
 * @constructor
 */

export interface RoleProtectedRouteProps extends RouteProps {
  roleHierarchy: UserRoleType;
}

export const RoleProtectedRoute: React.FC<RoleProtectedRouteProps> = ({
                                                                        roleHierarchy,
                                                                        ...props
                                                                      }) => {

  const role = useSelector(roleSelector);
  const hasAccess = role && userRoleHierarchy[roleHierarchy].includes(role);

  // If user is not in a support role, then redirect to not found
  const protectedProps: RouteProps = {
    ...props,
    component: hasAccess ? props.component : appRoutes.notfound.component
  };

  // Actually display the route
  return <ProtectedRoute {...protectedProps} />;
};