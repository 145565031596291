import { ApplicationState } from '../index';
import {
	Account,
	AccountReport,
	BinType,
	Event,
	EventType,
	Subscriber,
} from './types';
import { Optional } from '../types';

export const accountSelector = (state: ApplicationState): Optional<Account> =>
	state.account.data;
export const accountAPICalledSelector = (state: ApplicationState): boolean =>
	state.account.apiCalled;
export const accountAPILoadedSelector = (state: ApplicationState): boolean =>
	state.account.apiLoaded;

export const customerIdSelector = (state: ApplicationState): Optional<string> =>
	state.account.data?.customer_id || null;
export const timezoneSelector = (state: ApplicationState): string =>
	state.account.data?.locale?.timezone || 'UTC';
export const languageSelector = (state: ApplicationState): string =>
	state.account.data?.locale?.language || 'en';
export const eventSelectorOfType =
	(type: EventType) =>
	(state: ApplicationState): Event | null =>
		state.account.data?.events?.find((e) => e.type === type) || null;
export const thresholdSelector = (state: ApplicationState): number =>
	eventSelectorOfType('Notifications_BinFull')(state)?.condition?.value ||
	Infinity;
export const binTypesSelector = (state: ApplicationState): Array<BinType> =>
	state.account.data?.bin_types || [];
export const reportsSelector = (
	state: ApplicationState,
): Array<AccountReport> => state.account.data?.reports || [];
export const subscribersSelectorOfEventType =
	(type: EventType) =>
	(state: ApplicationState): Array<Subscriber> =>
		eventSelectorOfType(type)(state)?.notifications?.subscribers || [];
export const mapEnabledSelector = (state: ApplicationState): boolean =>
	state.account.data?.options?.map_enabled || false;
