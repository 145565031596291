import React from 'react';
import style from './filter-controls.module.scss';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { SensorListOrderDirection } from '../../../redux/sensor-overview/types';
import { ReactComponent as ArrowIcon } from '../../../assets/img/icons/icon.arrow-up-solid.svg';

/**
 * Generic interface for a sort option
 */
export interface FilterOption {
    key: string;
    i18nKey?: string;
    label: string;
}

/**
 * Props for the sort controls component
 */
export interface FilterOptionProps {
    /** The available sort options to display */
    filterOptions: Array<FilterOption>;
    /** The active sort option */
    activeFilterOptionKey: string;
    /** Optional click handler for a sort option */
    activeDirectionOption?: SensorListOrderDirection;
    onFilterOptionClicked?: (filterOption: FilterOption, event: React.MouseEvent<HTMLLIElement>) => void;
}

/**
 * Displays sort controls.
 *
 * @param props
 */
export const FilterControls: React.FC<FilterOptionProps> = (props) => {
    return (
        <div className={style.FilterControls}>
            <ul>
                {props.filterOptions.map((filterOption) => {
                    const isActive = props.activeFilterOptionKey === filterOption.key;

                    return (
                        <li
                            key={filterOption.key}
                            onClick={(event) => props.onFilterOptionClicked && props.onFilterOptionClicked(filterOption, event)}
                            className={classNames({ [style.active]: isActive })}
                            title={filterOption.label}
                        >
                            {filterOption.i18nKey ? <Trans i18nKey={filterOption.i18nKey}/> : filterOption.label}
                            <em>.</em>{ props.activeDirectionOption && <ArrowIcon className={classNames(style.arrow, style[props.activeDirectionOption])}/>}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
