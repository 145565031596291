import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAccountReport, updateAccountReport } from '../../../redux/account/actions';
import { AccountAsyncDispatch, AccountReport, AccountReportTypeEnum, SensorScheduleDay, Subscriber } from '../../../redux/account/types';
import { Modal } from '../../controls/modal/modal';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { ReportsSettingsGeneralModal } from './reports-settings-general-modal';
import { ReportsSettingsStaffModal } from './reports-settings-staff-modal';
import { MuiStepper } from '../../mui/MuiStepper';
import { ScheduleSelector } from '../../controls/schedule-selector/schedule-selector';
import { Box, Typography } from '@material-ui/core';

const CONTENT_HEIGHT = 440

export interface AccountReportSettingsModalProps {
  accountReport: AccountReport | null;
  onClose: () => void;
}

export const ReportsSettingsModal: React.FC<AccountReportSettingsModalProps> = (props) => {
  
  const { accountReport, onClose } = props;
  
  const [incRC, decRC] = useLoadingBarDispatch();
  
  const title = accountReport ? 'Update an account report' : 'Add new report';
  
  const [name, setName] = useState<string>(accountReport?.name || '');
  const [nameError, setNameError] = useState<string>('');
  
  const [type, setType] = useState<AccountReportTypeEnum | undefined>(accountReport?.type || AccountReportTypeEnum.BINS_READY_FOR_PICKUP);
  const [allBins, setAllBins] = useState<boolean>(accountReport?.allBins || false);
  const [binsReadyForPickupThreshold, setBinsReadyForPickupThreshold] = useState<number>(accountReport?.binsReadyForPickupThreshold || 0);
  
  const [sensorScheduleWeek, setSensorScheduleWeek] = useState<Array<SensorScheduleDay>>(accountReport?.schedule?.week || []);
  
  const [subscribers, setSubscribers] = useState<Array<Subscriber>>(accountReport?.subscribers || []);
  
  const accountAsyncDispatch = useDispatch<AccountAsyncDispatch>();
  
  const onSubmitHandler = (onComplete: () => void): void => {
    if (!type) {
      return;
    }
    
    if (accountReport) {
      const newAccountReport: AccountReport = {
        id: accountReport.id,
        name: name,
        binsReadyForPickupThreshold: binsReadyForPickupThreshold,
        allBins: allBins,
        enabled: true,
        type: type,
        subscribers: subscribers,
        schedule: {
          type: 'WEEKLY',
          week: sensorScheduleWeek,
          rate: 0
        }
      };
      
      incRC();
      accountAsyncDispatch(updateAccountReport(newAccountReport))
        .then(() => {
          decRC();
          onComplete()
        })
        .catch(() => {
          decRC();
        });
    } else {
      const ar: Omit<AccountReport, 'id'> = {
        name: name,
        binsReadyForPickupThreshold: binsReadyForPickupThreshold,
        allBins: allBins,
        enabled: true,
        type: type,
        subscribers: subscribers,
        schedule: {
          type: 'WEEKLY',
          week: sensorScheduleWeek,
          rate: 0
        }
      };
      
      incRC();
      accountAsyncDispatch(createAccountReport(ar))
        .then(() => {
          decRC();
          onComplete()
        })
        .catch(() => decRC());
    }
  };
  
  useEffect(() => {
    setNameError('');
  }, [name]);
  
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      maxWidth="md"
      title={title}
      content={(
        <MuiStepper
          contentHeight={CONTENT_HEIGHT}
          steps={[
            {
              label: 'general',
              content: <ReportsSettingsGeneralModal
                key='general'
                setName={setName}
                type={type}
                name={name}
                nameError={nameError}
                allBins={allBins}
                setAllBins={setAllBins}
                setType={setType}
                binsReadyForPickupThreshold={binsReadyForPickupThreshold}
                setBinsReadyForPickupThreshold={setBinsReadyForPickupThreshold}
              />,
              onComplete: (handler) => {
                let validationFailed = false;
                
                if (!name) {
                  setNameError('Name required');
                  validationFailed = true;
                }
                
                handler(!validationFailed)
              }
            },
            {
              label: 'schedule',
              content: (
                <Box>
                  {/*<Box mb={4}>*/}
                  {/*  <Typography variant="h4">*/}
                  {/*    Set when the report goes out*/}
                  {/*  </Typography>*/}
                  {/*</Box>*/}
                  <ScheduleSelector
                    cellHeight={50}
                    height={CONTENT_HEIGHT}
                    week={sensorScheduleWeek}
                    onChange={(week) => setSensorScheduleWeek(week)}
                    readonly={false}
                  />
                </Box>
              )
            },
            {
              label: 'staff',
              content: <ReportsSettingsStaffModal
                key='staff'
                subscribers={subscribers}
                setSubscribers={setSubscribers}
              />,
              onComplete: (handler) => {
                onSubmitHandler(() => {
                  onClose()
                })
              }
            },
          ]}
        />
      )}
    />
  );
};
