import React, { useEffect, useState } from 'react';
import style from './interface-header.module.scss';
// import { NotificationIndicator } from '../../controls/notification-indicator/notification-indicator';
import { UserProfile } from '../../controls/user-profile/user-profile';
import { ReactComponent as LidbotLogo } from '../../../assets/img/smartbinIo-small-logo.svg';
import { Link } from 'react-router-dom';
import { Customer } from '../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { clientIdSelector, userSelector } from '../../../redux/auth/selectors';
import { API, Auth } from 'aws-amplify';
import { loadUser } from '../../../redux/auth/actions';
import { AuthAsyncDispatch } from '../../../redux/auth/types';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';
import { Access } from '../../controls/access/access';
import { useAccess } from '../../../hooks/access';
import config from '../../../config/config';
import { Backdrop, Box, CircularProgress, createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyledAutocomplete } from '../../mui/StyledAutocomplete';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

export const InterfaceHeader: React.FC = () => {
    const user = useSelector(userSelector);
    const access = useAccess('LIDBOT_SUPPORT');
    const clientId = useSelector(clientIdSelector);
    
    const [switchingCustomer, setSwitchingCustomer] = useState<boolean>(false)
    const [selectedClientOption, setSelectedClientOption] = useState<Customer | null>(null);
    const [clientOptions, setClientOptions] = useState<Array<Customer>>([]);
    
    useEffect(() => {
        access &&
        API.get(config.API.name, '/customers', {})
            .then((response) => {
                const customers: Array<Customer> = response.data;
                setClientOptions(customers);
                
                const currentCustomer = customers.find((option) => option.customer_id === clientId);
                setSelectedClientOption(currentCustomer || null);
            })
            .catch((e) => {
                console.error(e);
            });
    }, [access]);
    
    const authAsyncDispatch = useDispatch<AuthAsyncDispatch>();
    
    const onChangeClientIdHandler = (customer: Customer | null): void => {
        if (customer) {
            setSwitchingCustomer(true)
            setSelectedClientOption(customer)
            const toastId = notifySuccess('Switching account...', 'This may take a few seconds.', {
                autoClose: 10000
            })
            Auth.updateUserAttributes(user, { 'custom:client_id': customer.customer_id })
                .then(() => {
                    authAsyncDispatch(loadUser(true))
                        .then(() => {
                            toast.dismiss(toastId)
                            notifySuccess('Account Switched');
                            setSwitchingCustomer(false)
                        })
                        .catch((e) => {
                            notifyError('An error occurred while switching account', notifyErrorMessage(e));
                            setSwitchingCustomer(false)
                            console.error(e);
                        });
                })
                .catch((e) => {
                    notifyError('Customer not changed', notifyErrorMessage(e));
                    setSwitchingCustomer(false)
                    console.error(e);
                });
        }
    };
    
    const classes = useStyles()
    
    return (
        <Box display='flex' justifyContent='space-between' className={style.DashboardHeader}>
            <Backdrop className={classes.backdrop} open={switchingCustomer}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box flexBasis='130px'>
                <Link className={style.logo} to={'/'}>
                    <LidbotLogo />
                </Link>
            </Box>
            <Box display='flex' alignItems='center'>
                <Box marginRight={2}>
                    <Access roleHierarchy='LIDBOT_SUPPORT'>
                        {
                            <StyledAutocomplete
                                options={clientOptions}
                                value={selectedClientOption}
                                getOptionLabel={(option: Customer) => option.account_name}
                                onChange={(e, option) => onChangeClientIdHandler(option)}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label='Current Account' type='text' size='medium' variant='filled' margin='normal' />}
                            />
                        }
                    </Access>
                </Box>
                <Box>
                    <UserProfile />
                </Box>
            </Box>
        </Box>
    );
};
