import React, { PropsWithChildren } from 'react';
import style from './form-label.module.scss';
import classNames from 'classnames';

export interface FormLabelAttributes {
    capitalize?: boolean;
    required?: boolean;
    for?: string;
}

export type FormLabelProps = PropsWithChildren<FormLabelAttributes>

export const FormLabel: React.FC<FormLabelProps> = (props: FormLabelProps) => {
    const { required = false, capitalize = true } = props;
    return (
        <label className={classNames(style.FormLabel, {[style.required]: required, [style.capitalize]: capitalize})}>
            { props.children }
        </label>
    );
}