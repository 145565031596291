import React, { useEffect, useState } from 'react';
import style from './loading-bar.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { loadingBarReferenceCountSelector } from '../../../redux/loading-bar/selectors';

export const LoadingBar: React.FC = () => {

    const referenceCount = useSelector(loadingBarReferenceCountSelector);
    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);

    useEffect(() => {
        referenceCount > 0 && !isLoading && setIsLoading(true);
        referenceCount === 0 && isLoading && !isDone && setIsDone(true);
        //eslint-disable-next-line
    }, [referenceCount]);

    useEffect(() => {
        if (isLoading && isDone) {
            setTimeout(() => {
                setIsLoading(false);
                setIsDone(false);
            }, 700);
        }
    }, [isLoading, isDone]);

    return (
        <div className={classNames(style.LoadingBar, {[style.loading]: isLoading, [style.done]: isDone})}>
            <div className={style.bar}/>
        </div>
    );
}