import React from 'react';
import style from './dashboard-header.module.scss';
import { DashboardStats } from '../../controls/dashboard-stats/dashboard-stats';
import { Box, Typography } from '@material-ui/core';

export const DashboardHeader: React.FC = () => {
    return (
        <div className={style.DashboardHeader}>
            <Box marginBottom={2}>
                <Typography variant="h1">Dashboard</Typography>
            </Box>
            <DashboardStats avgFill={72} awaitingPickup={7} totalBins={113} />
        </div>
    );
};
