import React from 'react';
import style from './calendar.module.scss';
import classNames from 'classnames';
import { Optional } from '../../../redux/types';
import { ReactComponent as TriangleIcon } from '../../../assets/img/icons/icon.triangle-left.svg';
import moment, { Moment } from 'moment';

export type CalendarDayState = 'out-of-range' | 'range-start' | 'range-end' | 'out-of-month'
  | 'selected' | 'selected-start' | 'selected-end'
  | 'adjusted' | 'adjusted-start' | 'adjusted-end' | 'today' | 'none';

export interface CalendarDay {
  value: number;
  label: string | number;
  states: Array<CalendarDayState>
}

export interface CalendarProps {
  displayedMonth: number;
  selectedStartTimestamp?: Optional<number>;
  adjustedStartTimestamp?: Optional<number>;
  selectedEndTimestamp?: Optional<number>;
  adjustedEndTimestamp?: Optional<number>;
  minTimestamp?: Optional<number>;
  maxTimestamp?: Optional<number>;
  highlightedHeader?: boolean;
  onClickPreviousMonth?: () => void;
  onClickNextMonth?: () => void;
  onMouseEnterDate?: (date: CalendarDay) => void;
  onMouseLeaveGrid?: () => void;
  onClickDate?: (date: CalendarDay) => void;
}

export const Calendar: React.FC<CalendarProps> = ({
                                                    displayedMonth,
                                                    selectedStartTimestamp = null,
                                                    adjustedStartTimestamp = null,
                                                    selectedEndTimestamp = null,
                                                    adjustedEndTimestamp = null,
                                                    minTimestamp = null,
                                                    maxTimestamp = null,
                                                    onClickPreviousMonth,
                                                    onClickNextMonth,
                                                    onMouseEnterDate,
                                                    onMouseLeaveGrid,
                                                    onClickDate,
                                                    highlightedHeader = false
                                                  }) => {
  /** Calendar Header Labels */
  const monthLabel = moment().month(displayedMonth).format('MMMM');
  const yearLabel = moment().month(displayedMonth).format('YYYY');

  /** Calendar Weekdays Header */
  const daysOfWeek = Array.from(Array(7), (_, dow) => moment().weekday(dow).format('dd'));

  /** Calendar Dates Grid */
  const mMonth = moment().month(displayedMonth);
  const mStart: Optional<Moment> = selectedStartTimestamp ? moment.unix(selectedStartTimestamp) : null;
  const mAdjStart: Optional<Moment> = adjustedStartTimestamp ? moment.unix(adjustedStartTimestamp) : null;
  const mMinStart: Optional<Moment> = [mStart, mAdjStart].reduce((acc, cur) => {
    if (acc && cur) return moment.min(acc, cur);
    else if (acc) return acc;
    else return cur;
  }, null);
  const mEnd: Optional<Moment> = selectedEndTimestamp ? moment.unix(selectedEndTimestamp) : null;
  const mAdjEnd: Optional<Moment> = adjustedEndTimestamp ? moment.unix(adjustedEndTimestamp) : null;
  const mMaxEnd: Optional<Moment> = [mEnd, mAdjEnd].reduce((acc, cur) => {
    if (acc && cur) return moment.max(acc, cur);
    else if (acc) return acc;
    else return cur;
  }, null);
  const mMin: Optional<Moment> = minTimestamp ? moment.unix(minTimestamp) : null;
  const mMax: Optional<Moment> = maxTimestamp ? moment.unix(maxTimestamp) : null;

  // Get the integer representation of the weekday
  const firstOfMonthWeekday: number = mMonth.clone().startOf('month').weekday();
  const lastOfMonthWeekday: number = mMonth.clone().endOf('month').weekday();

  // Get the first and last day that will appear on the calendar
  const firstDayOfGrid = mMonth.clone().startOf('month').subtract(firstOfMonthWeekday, 'days');
  const lastDayOfGrid = mMonth.clone().endOf('month').subtract(lastOfMonthWeekday, 'days').add(7, 'days');

  // Create an array of all the days in the month
  const numberOfDays = lastDayOfGrid.diff(firstDayOfGrid, 'days');
  const days: Array<CalendarDay> = Array.from(Array(numberOfDays), (x, i) => {
    const mDay = moment(firstDayOfGrid).add(i, 'days');
    const states: Array<CalendarDayState> = [];

    if (mAdjStart && mAdjEnd && mDay.isSameOrAfter(mAdjStart, 'day') && mDay.isSameOrBefore(mAdjEnd, 'day')) states.push('adjusted');
    if (mStart && mEnd && mDay.isSameOrAfter(mStart, 'day') && mDay.isSameOrBefore(mEnd, 'day')) states.push('selected');

    if (mAdjStart && mDay.isSame(mAdjStart, 'day')) states.push('adjusted-start');
    if (mStart && mDay.isSame(mStart, 'day')) states.push('selected-start');

    if (mAdjEnd && mDay.isSame(mAdjEnd, 'day')) states.push('adjusted-end');
    if (mEnd && mDay.isSame(mEnd, 'day')) states.push('selected-end');

    if (mMinStart && mDay.isSame(mMinStart, 'day')) states.push('range-start');
    if (mMaxEnd && mDay.isSame(mMaxEnd, 'day')) states.push('range-end');
    if (mDay.isSame(moment(), 'day')) states.push('today');

    if (mMax && mDay.isAfter(mMax, 'day')) states.push('out-of-range');
    else if (mMin && mDay.isBefore(mMin, 'day')) states.push('out-of-range');

    if (!mDay.isSame(mMonth, 'month')) states.push('out-of-month');

    return {
      value: mDay.unix(),
      label: mDay.format('DD'),
      states
    };
  });

  /** Disabling Next/Previous Month Buttons */
  const hidePreviousMonthButton = mMin && mMonth.isSameOrBefore(mMin, 'month');
  const hideNextMonthButton = mMax && mMonth.isSameOrAfter(mMax, 'month');


  return (
    <div className={style.Calendar}>
      <div className={classNames(style.header, { [style.highlighted]: highlightedHeader })}>
        {onClickPreviousMonth && (
          <div className={classNames(style.highlighted, style.previous, { [style.disable]: hidePreviousMonthButton })} onClick={onClickPreviousMonth}>
            <TriangleIcon />
          </div>
        )}
        {highlightedHeader && <div className={style.month}>{monthLabel}</div>}
        {highlightedHeader && <div className={style.year}>{yearLabel}</div>}
        {!highlightedHeader && <div className={style.date}>{monthLabel}, {yearLabel}</div>}
        {onClickNextMonth && (
          <div className={classNames(style.highlighted, style.next, { [style.disable]: hideNextMonthButton })} onClick={onClickNextMonth}>
            <TriangleIcon />
          </div>
        )}
      </div>
      <div className={style.grid} onMouseLeave={() => onMouseLeaveGrid && onMouseLeaveGrid()}>
        {daysOfWeek.map(weekday => (
          <div key={weekday} className={style.weekday}>{weekday}<em>.</em></div>
        ))}
        {days.map((date, index) => {
          return (
            <div
              key={index}
              className={classNames(style.date, ...date.states.map(s => style[s]))}
              onMouseEnter={() => onMouseEnterDate && onMouseEnterDate(date)}
              onClick={() => onClickDate && onClickDate(date)}
            >
              <div className={classNames(style.dateInnerLayer, style.selectedBackground)}>
                <div className={style.left} />
                <div className={style.right} />
              </div>
              <div className={classNames(style.dateInnerLayer, style.flexCenter)}>
                <div className={style.selectedDot} />
              </div>
              <div className={classNames(style.dateInnerLayer, style.adjustedBackground)}>
                <div className={style.left} />
                <div className={style.right} />
              </div>
              <div className={classNames(style.dateInnerLayer, style.flexCenter)}>
                <div className={style.adjustedDot} />
              </div>
              <div className={classNames(style.dateInnerLayer, style.flexCenter, style.dateContainer)}>
                {date.label}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};