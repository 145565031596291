import { API } from "aws-amplify";
import {
  Account,
  AccountAction,
  AccountActionType,
  AccountAPIPost,
  AccountAsyncAction,
  AccountAsyncDispatch,
  AccountReport,
  AddAccountReport,
  AddBinType,
  AddEvent,
  BinType,
  DeleteAccountReport,
  DeleteBinType,
  Event,
  EventType,
  SetAccount,
  SetEvent,
  UpdateAccountReport,
  UpdateBinType,
} from "./types";
import { DeepPartial, RequestOptions } from "../types";
import { pickBy } from "ramda";
import {
  notifyError,
  notifyErrorMessage,
  notifySuccess,
} from "../../utils/notify";
import { configMomentJS } from "../../utils/momentjs";
import config from "../../config/config";

/** ACTION CREATORS */
export const setAccount = (account: Account): SetAccount => ({
  type: AccountActionType.SET_ACCOUNT,
  payload: account,
});
export const setEvent = (payload: Event): SetEvent => ({
  type: AccountActionType.SET_EVENT,
  payload,
});
export const addEventAction = (payload: Event): AddEvent => ({
  type: AccountActionType.ADD_EVENT,
  payload,
});

export const addBinTypeAction = (binType: BinType): AddBinType => ({
  type: AccountActionType.ADD_BIN_TYPE,
  payload: binType,
});
export const addAccountReportAction = (
  accountReport: AccountReport
): AddAccountReport => ({
  type: AccountActionType.ADD_ACCOUNT_REPORT,
  payload: accountReport,
});
export const updateBinTypeAction = (binType: BinType): UpdateBinType => ({
  type: AccountActionType.UPDATE_BIN_TYPE,
  payload: binType,
});
export const updateAccountReportAction = (
  accountReport: AccountReport
): UpdateAccountReport => ({
  type: AccountActionType.UPDATE_ACCOUNT_REPORT,
  payload: accountReport,
});
export const deleteBinTypeAction = (binType: BinType): DeleteBinType => ({
  type: AccountActionType.DELETE_BIN_TYPE,
  payload: binType,
});
export const deleteAccountReportAction = (
  accountReport: AccountReport
): DeleteAccountReport => ({
  type: AccountActionType.DELETE_ACCOUNT_REPORT,
  payload: accountReport,
});

/** ASYNC ACTIONS */
export const loadAccount =
  (): AccountAsyncAction =>
  (dispatch: AccountAsyncDispatch): Promise<void | Account> => {
    return API.get(config.API.name, "/account", {})
      .then((account: Account) => {
        const timezone = account.locale?.timezone;
        const language = account.locale?.language;
        configMomentJS(timezone, language);
        dispatch(setAccount(account));
        return account;
      })
      .catch((e) => {
        notifyError("Account info not loaded", notifyErrorMessage(e));
        console.error(e);
      });
  };

export const updateAccount =
  (body: AccountAPIPost): AccountAsyncAction =>
  (dispatch: AccountAsyncDispatch): Promise<void | AccountAction> => {
    const options: RequestOptions = { body };
    return API.post(config.API.name, "/account", options)
      .then((account: Account) => {
        const timezone = account.locale?.timezone;
        const language = account.locale?.language;
        configMomentJS(timezone, language);
        dispatch(setAccount(account));
      })
      .catch((e) => {
        console.error(e);
      });
  };

export const createAccountReport =
  (accountReport: Omit<AccountReport, "id">): AccountAsyncAction =>
  (dispatch: AccountAsyncDispatch): Promise<void | AccountAction> => {
    const options: RequestOptions = {
      body: accountReport,
    };
    return API.post(config.API.name, "/account/reports", options)
      .then((ar: AccountReport) => {
        dispatch(addAccountReportAction(ar));
        notifySuccess("Report created");
      })
      .catch((e) => {
        notifyError("Report not created", notifyErrorMessage(e));
        console.error(e);
      });
  };

export const createBinType =
  (binType: Omit<BinType, "bin_type_id">): AccountAsyncAction =>
  (dispatch: AccountAsyncDispatch): Promise<void | AccountAction> => {
    const options: RequestOptions = {
      body: binType,
    };
    return API.post(config.API.name, "/account/bin-types", options)
      .then((bt: BinType) => {
        dispatch(addBinTypeAction(bt));
        notifySuccess("Bin model created");
      })
      .catch((e) => {
        notifyError("Bin model not created", notifyErrorMessage(e));
        console.error(e);
      });
  };

export const updateAccountReport =
  (accountReport: AccountReport): AccountAsyncAction =>
  (dispatch: AccountAsyncDispatch): Promise<void | AccountAction> => {
    const options: RequestOptions = {
      body: pickBy((value, key) => key !== "id", accountReport),
    };

    return API.post(
      config.API.name,
      `/account/reports/${accountReport.id}`,
      options
    )
      .then((accountReport: AccountReport) => {
        dispatch(updateAccountReportAction(accountReport));
        notifySuccess("Account report updated");
      })
      .catch((e) => {
        notifyError("Account report not updated", notifyErrorMessage(e));
        console.error(e);
      });
  };

export const updateBinType =
  (binType: BinType): AccountAsyncAction =>
  (dispatch: AccountAsyncDispatch): Promise<void | AccountAction> => {
    const options: RequestOptions = {
      body: pickBy((value, key) => key !== "bin_type_id", binType),
    };

    return API.post(
      config.API.name,
      `/account/bin-types/${binType.bin_type_id}`,
      options
    )
      .then((binType: BinType) => {
        dispatch(updateBinTypeAction(binType));
        notifySuccess("Bin model updated");
      })
      .catch((e) => {
        notifyError("Bin model not updated", notifyErrorMessage(e));
        console.error(e);
      });
  };

export const deleteBinType =
  (binType: BinType): AccountAsyncAction =>
  (dispatch: AccountAsyncDispatch): Promise<void | AccountAction> => {
    return API.del(
      config.API.name,
      `/account/bin-types/${binType.bin_type_id}`,
      {}
    )
      .then((binType: BinType) => {
        dispatch(deleteBinTypeAction(binType));
        notifySuccess("Bin model deleted");
      })
      .catch((e) => {
        notifyError("Bin model not deleted", notifyErrorMessage(e));
        console.error(e);
      });
  };

export const deleteAccountReport =
  (accountReport: AccountReport): AccountAsyncAction =>
  (dispatch: AccountAsyncDispatch): Promise<void | AccountAction> => {
    return API.del(config.API.name, `/account/reports/${accountReport.id}`, {})
      .then((accountReport: AccountReport) => {
        dispatch(deleteAccountReportAction(accountReport));
        notifySuccess("Report deleted");
      })
      .catch((e) => {
        notifyError("Report not deleted", notifyErrorMessage(e));
        console.error(e);
      });
  };

export const updateEvent =
  (eventType: EventType, body: DeepPartial<Event>): AccountAsyncAction =>
  (dispatch: AccountAsyncDispatch): Promise<void> => {
    const eventName = eventType.replace(/.*_/, "").replace(/([A-Z])/g, " $1");
    return API.post(config.API.name, `/account/events/${eventType}`, {
      body,
    })
      .then((event: Event) => {
        dispatch(setEvent(event));
        notifySuccess("Saved");
      })
      .catch((e) => {
        notifyError(eventName + " event not updated", notifyErrorMessage(e));
        console.error(e);
      });
  };

export const addEvent =
  (eventType: EventType, body: Event): AccountAsyncAction =>
  (dispatch: AccountAsyncDispatch): Promise<void> => {
    const eventName = eventType.replace(/.*_/, "").replace(/([A-Z])/g, " $1");
    return API.post(config.API.name, "/account/events", { body })
      .then((event: Event) => {
        dispatch(addEventAction(event));
        notifySuccess(eventName + " event created");
      })
      .catch((e) => {
        notifyError(eventName + " event not created", notifyErrorMessage(e));
        console.error(e);
      });
  };
