import { ApplicationState } from './../index';
import { SensorReport } from '../../api/sensors-reports';
import { HistogramQuery } from '../../api/histogram';
import { DatePickerOption } from '../../components/controls/date-picker-control/utils';
import { Optional } from '../types';

export const filterOptionSelector = (
	state: ApplicationState,
): DatePickerOption => state.sensorDetail.filterOption;
export const filterSearchQuerySelector = (
	state: ApplicationState,
): Optional<Partial<HistogramQuery>> => state.sensorDetail.filterSearchQuery;
export const reportDataSelector = (
	state: ApplicationState,
): Array<SensorReport> => state.sensorDetail.reportData;
