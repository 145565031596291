import { sortBy } from 'lodash/';
import moment from 'moment';
import 'moment-timezone';
import { Option } from '../redux/types';

export interface TimezoneOption extends Option {
	time: string;
}

export const getTimezones = (): Array<TimezoneOption> => {
	const timezones = moment.tz.names();
	const offsetTmz = [];

	for (const i in timezones) {
		const tz = moment
			.tz(timezones[i])
			.format('Z')
			.replace(':00', '')
			.replace(':30', '.5');
		const x = tz === '0' ? 0 : parseInt(tz).toFixed(2);

		const timeZone: TimezoneOption = {
			label: `(GMT${moment.tz(timezones[i]).format('Z')}) ${
				timezones[i]
			}`,
			value: `${timezones[i]}`,
			time: `${x}`,
		};
		offsetTmz.push(timeZone);
	}

	return sortBy(offsetTmz, [
		function (el) {
			return -el.time;
		},
	]);
};
