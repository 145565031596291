import { AvgFillChartReport, HistogramReport } from '../redux/dashboard/types';
import { Bucket, SearchResponse } from '../api/histogram';

const secondsInDay = 60 * 60 * 24;

export const extractReportsHistogramData = (
	response: SearchResponse,
): Array<AvgFillChartReport> =>
	response.aggregations?.data?.buckets?.map((datum: Bucket) => ({
		created_on: Math.floor(datum.key / 1000),
		value: datum.stats.avg,
	})) || [];

export const createBlankReportsHistogramData = (
	initialUnix: number,
	days: number,
): Array<AvgFillChartReport> =>
	Array(days)
		.fill(0)
		.map((_, index) => ({
			created_on: initialUnix + index * secondsInDay,
			value: null,
		}));

/*
 * TODO: Adjust fill function after timezone architecture is implemented
 * The fill functions have a weakness in that if the timezone settings of the client and the search api are too far
 * out of sync, all the data in the report will be shifted over a day.
 */
export const fillReportsHistogramData = (
	initialUnix: number,
	days: number,
	histogram: Array<AvgFillChartReport>,
): Array<AvgFillChartReport> => {
	const blankData = createBlankReportsHistogramData(initialUnix, days);
	histogram.forEach((datum) => {
		const index = Math.abs(
			Math.floor((datum.created_on - initialUnix) / secondsInDay),
		);
		if (blankData[index]) blankData[index] = datum;
	});
	return blankData;
};

export const createBlankEventsHistogramReport = (
	initialUnix: number,
	days: number,
): Array<HistogramReport> =>
	Array(days)
		.fill(0)
		.map((_, index) => ({
			datetime: initialUnix + index * secondsInDay,
			value: 0,
		}));

export const extractEventsHistogramData = (
	response: SearchResponse,
): Array<HistogramReport> =>
	response.aggregations?.data?.buckets?.map((datum: Bucket) => ({
		datetime: Math.floor(datum.key / 1000),
		value: datum.doc_count,
	})) || [];

export const fillEventsHistogramData = (
	initialUnix: number,
	days: number,
	data: Array<HistogramReport>,
): Array<HistogramReport> => {
	const blankHistogramData = createBlankEventsHistogramReport(
		initialUnix,
		days,
	);
	data.forEach((datum) => {
		const index = Math.abs(
			Math.floor((datum.datetime - initialUnix) / secondsInDay),
		);
		blankHistogramData[index] = datum;
	});
	return blankHistogramData;
};
