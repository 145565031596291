import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../../redux/auth/selectors';
import { Storage } from 'aws-amplify';
import { getFullName, getInitials } from '../../../utils/string';
import { NotificationCenter } from './notification-center';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import { Avatar, Box, createStyles, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Paper, Theme, Typography } from '@material-ui/core';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { logoutUser } from '../../../redux/auth/actions';
import { appRoutes } from '../../../config/routes';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';
import { AuthAsyncDispatch } from '../../../redux/auth/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      rounded: {
        borderRadius: 20
      },
      elevation1: {
        boxShadow: 'none',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0 4px 8px rgb(51 51 51 / 20%)'
        }
      }
    }));


export const UserProfile: React.FC<{}> = () => {
  const authDispatch = useDispatch<AuthAsyncDispatch>();
  const history = useHistory();

  const classes = useStyles();

  const user = useSelector(userSelector);
  const [avatar, setAvatar] = useState("");
  const { setIdentity } = useTrackingCode();
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [notificationsAnchor, setNotificationsAnchor] = React.useState<null | HTMLElement>(null);

  if (user && user.attributes) {
    setIdentity(user.attributes.email, {
      givenName: user.attributes.given_name,
      familyName: user.attributes.family_name,
    });

    let initials = "";
    let fullName = "";

    if (user.attributes.given_name || user.attributes.family_name) {
      fullName = getFullName(
        user.attributes.given_name,
        user.attributes.family_name
      );
      initials = getInitials(
        user.attributes.given_name,
        user.attributes.family_name
      );
    }

    if (user.attributes.picture) {
      Storage.get(user.attributes.picture).then((url) => {
        setAvatar(url.toString());
      });
    }

    const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setNotificationsAnchor(event.currentTarget);
    };

    const handleNotificationClose = () => {
      setNotificationsAnchor(null)
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
      setMenuAnchor(null);
    };
  
    const goToProfile = () => {
      history.push(appRoutes.user.path as string)
    };

    const logoutButtonClickHandler = (): void => {
      authDispatch(logoutUser())
          .then(() => {
            history.push(appRoutes.login.path as string);
            notifySuccess("You have been signed out");
          })
          .catch((e) => {
            notifyError("Log out failed", notifyErrorMessage(e));
            console.error(e);
          });
    };

    return (
      <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Paper classes={{
              rounded: classes.rounded,
              elevation1: classes.elevation1
            }}>
              <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={56} height={56}
                  onClick={handleNotificationClick}>
                <NotificationsOutlinedIcon />
              </Box>
            </Paper>
            <Menu
                getContentAnchorEl={null}
                anchorEl={notificationsAnchor}
                keepMounted
                open={Boolean(notificationsAnchor)}
                onClose={handleNotificationClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
            >
              <NotificationCenter />
            </Menu>
          </Box>

        <Box>
          <Paper classes={{
            rounded: classes.rounded,
            elevation1: classes.elevation1
          }}>
            <Box display='flex' alignItems='center' padding={1} onClick={handleMenuClick}>
              <Avatar src={avatar}>
                {initials}
              </Avatar>
              <ExpandMoreOutlinedIcon/>
            </Box>
          </Paper>

          <Menu
              getContentAnchorEl={null}
              anchorEl={menuAnchor}
              keepMounted
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
          >
            <Box
                 paddingTop="24px"
                 paddingLeft="20px"
                 paddingRight="20px"
                 paddingBottom="20px"
                 borderColor="secondary.light"
                 style={{
                   borderImageSource: 'linear-gradient(90deg ,transparent,hsla(0,0%,84.7%,.2) 24px,#d8d8d8 50%,hsla(0,0%,84.7%,.2) calc(100% - 24px),transparent)',
                   borderImageSlice: 1,
                   borderBottom: '1px solid #000'
                 }}
            >
              <Box>
                <Typography variant="h5">
                  {fullName}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption" color="secondary" style={{color: 'rgba(0, 0, 0, 0.25)'}}>
                 {user.attributes.email}
                </Typography>
              </Box>
            </Box>
            <MenuItem onClick={goToProfile}>
              <ListItemText>
                <Typography variant="body2">Edit my profile</Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={logoutButtonClickHandler}>
              <ListItemText>
                <Typography variant="body2">Sign out</Typography>
              </ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    );
  }

  return null;
};
