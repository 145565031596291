import React, { PropsWithChildren, ReactNodeArray } from 'react';
import style from './fader.module.scss';
import classNames from 'classnames';
import { Optional } from '../../../redux/types';

export interface FaderAttributes {
  className?: Optional<string>;
  selectedIndex?: number;
}

export type FaderProps = PropsWithChildren<FaderAttributes>;

export const Fader: React.FC<FaderProps> = ({
  className = null,
  selectedIndex = 0,
  children = [],
}: FaderProps) => {
  const childNodes: ReactNodeArray = children
    ? (children as ReactNodeArray).map
      ? (children as ReactNodeArray)
      : [children]
    : [];
  return (
    <div className={classNames(className, style.Fader)}>
      {childNodes.map((node, i) => (
        <div
          key={i}
          className={classNames(style.child, {
            [style.visible]: i === selectedIndex,
          })}
        >
          {node}
        </div>
      ))}
    </div>
  );
};
