import { UserAction, UserActionType, UsersDataState } from './types';

const initialUsersDataState: UsersDataState = {
	users: null,
	apiCalled: false,
	apiLoaded: false,
};

export const usersDataReducer = (
	state: UsersDataState = initialUsersDataState,
	action: UserAction,
): UsersDataState => {
	switch (action.type) {
		case UserActionType.SET_USERS: {
			return {
				...state,
				users: action.payload,
			};
		}
		case UserActionType.SET_API_CALLED: {
			return {
				...state,
				apiCalled: action.payload,
			};
		}
		case UserActionType.SET_API_LOADED: {
			return {
				...state,
				apiLoaded: action.payload,
			};
		}
		case UserActionType.ADD_USER: {
			return {
				...state,
				users: state.users
					? [...state.users, action.payload]
					: [action.payload],
			};
		}
		case UserActionType.SET_USER: {
			return {
				...state,
				users: state.users
					? state.users.map((user) =>
							user.email === action.payload.email
								? action.payload
								: user,
					  )
					: null,
			};
		}
		case UserActionType.REMOVE_USER: {
			return {
				...state,
				users: state.users
					? state.users.filter(
							(user) => user.email !== action.payload.email,
					  )
					: null,
			};
		}
		default: {
			return state;
		}
	}
};
