import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { UserRoleType } from '../../../redux/user/types';
import { useAccess } from '../../../hooks/access';

export interface AccessProps {
  roleHierarchy: UserRoleType;
  className?: string;
  onClick?: (e: MouseEvent) => void;
}

export const Access: React.FC<AccessProps> = ({
                                                className = null,
                                                roleHierarchy,
                                                onClick,
                                                children
                                              }) => {
  const access = useAccess(roleHierarchy);
  return access ? (
    <div className={classNames(className)} onClick={onClick}>{children}</div>
  ) : null;
};
