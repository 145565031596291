import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../i18n/en';
import de from '../i18n/de';

const resources = {
	en,
	de,
};

i18n.use(initReactI18next).init({
	resources,
	lng: 'en',
	fallbackLng: 'en',
	debug: process.env.NODE_ENV !== 'production',
	interpolation: {
		escapeValue: false,
	},
	whitelist: ['de', 'en'],
});
