import React, { FC } from 'react';
import style from './content-container.module.scss';
import classNames from 'classnames';

export interface ContentContainerProps {
    className?: string;
    type?: 'main' | 'settings';
}

export const ContentContainer: FC<ContentContainerProps> = ({ className, type = 'main', children }) => (
    <div className={classNames(style.ContentContainer, style[type], className)}>{ children }</div>
);