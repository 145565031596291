import { useState } from 'react';
import { WasteType } from '../../../redux/account/types';

export type WasteColor = 'wc-green' | 'wc-aqua' | 'wc-red' | 'wc-yellow' | 'wc-blue' | 'wc-gray' | 'wc-none' | 'wc-violet' | 'wc-organic'

export type WasteColorPalette = { [wasteType in WasteType]: WasteColor; }

export const useWasteColorPalette = (): WasteColorPalette => {
    const [colorPalette] = useState(defaultWasteColorPalette);
    return colorPalette;
}

const defaultWasteColorPalette: WasteColorPalette = {
    [WasteType.PLASTIC]: 'wc-yellow',
    [WasteType.RECYCLABLE]: 'wc-aqua',
    [WasteType.GLASS]: 'wc-green',
    [WasteType.METALS]: 'wc-red',
    [WasteType.PAPER]: 'wc-blue',
    [WasteType.MIXED]: 'wc-gray',
    [WasteType.ORGANIC]: 'wc-organic',
    [WasteType.CLOTHING]: 'wc-violet',
}