import React from 'react';
import style from './view-switcher.module.scss';
import { LightTooltip } from '../light-tooltip/light-tooltip';

/**
 * Describes a display option, which the user can toggle.
 */
export interface DisplayOption {
    /** The label for the display option */
    label: string;
    /** The icon for the display option */
    icon: React.ReactNode;
    /** The key of the display option */
    key: string;
}

/**
 * View switcher component props
 */
export interface ViewSwitcherProps {
    /** The available display options */
    displayOptions: Array<DisplayOption>,
    /** The active display option */
    activeDisplayOptionKey: string,
    /** Optional click handler for a display option */
    onDisplayOptionClicked?: (displayOption: DisplayOption, event: React.MouseEvent<HTMLButtonElement>) => void,
}

export const ViewSwitcher: React.FC<ViewSwitcherProps> = (props) => {
    return (
        <div className={style.ViewSwitcher}>
            {props.displayOptions.map((displayOption) => {
                const isActive = props.activeDisplayOptionKey === displayOption.key;

                return !isActive ? (
                    <LightTooltip
                        title={
                            <>
                                <p>{`Switch to ${displayOption.label} view`}</p>
                            </>
                        }
                        enterDelay={1000}
                        enterNextDelay={300}
                        key={displayOption.key}
                    >
                        <button
                            type='button'
                            key={displayOption.key}
                            onClick={(event) => props.onDisplayOptionClicked && props.onDisplayOptionClicked(displayOption, event)}
                        >
                            {displayOption.icon}
                        </button>
                    </LightTooltip>
                ) :
                    (<button
                        type='button'
                        key={displayOption.key}
                        onClick={(event) => props.onDisplayOptionClicked && props.onDisplayOptionClicked(displayOption, event)}
                        className={style.activeDisplayOption}
                    >
                        {displayOption.icon}
                    </button>);
            })}
        </div>
    );
};
