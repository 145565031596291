import React from 'react';
import style from './full-bins-panel.module.scss';
import { StatsPanel } from '../../controls/stats-panel/stats-panel';
import { FullBinsChart } from '../../controls/full-bins-chart/full-bins-chart';
import { TopFullBinsChart } from '../../controls/top-full-bins-chart/top-full-bins-chart';
import { useSelector } from 'react-redux';
import { awaitingPickupSelector, binsTotalSelector } from '../../../redux/dashboard/selectors';

export const FullBinsPanel: React.FC<{}> = () => {
  const panelTitle = (
    <span>
      Bins full now
    </span>
  );

  const totalBins = useSelector(binsTotalSelector);
  const awaitingPickup = useSelector(awaitingPickupSelector);

  return (
    <StatsPanel title={panelTitle} to="/sensors">
      <div className={style.FullBinsPanel}>
        <FullBinsChart totalBins={totalBins} awaitingPickup={awaitingPickup} />
        <TopFullBinsChart />
      </div>
    </StatsPanel>
  );
};
