import { secondaryMainColor } from '../../mui/themes';

export const barChartMockupData = [
    {
        "name": "",
        "stats": {
            "count": 0,
            "min": 169,
            "max": 180,
            "avg": 174.125,
            "sum": 1393
        },
        "color": secondaryMainColor,
        "colorSecondary": secondaryMainColor
    },
    {
        "name": "",
        "stats": {
            "count": 0,
            "min": 279,
            "max": 300,
            "avg": 286.5,
            "sum": 2865
        },
        "color": secondaryMainColor,
        "colorSecondary": secondaryMainColor
    },
    {
        "name": "",
        "stats": {
            "count": 0,
            "min": 97,
            "max": 98,
            "avg": 97.33333333333333,
            "sum": 292
        },
        "color": secondaryMainColor,
        "colorSecondary": secondaryMainColor
    },
    {
        "name": "",
        "stats": {
            "count": 0,
            "min": 192,
            "max": 198,
            "avg": 194.5,
            "sum": 778
        },
        "color": secondaryMainColor,
        "colorSecondary": secondaryMainColor
    },
    {
        "name": "",
        "stats": {
            "count": 0,
            "min": 112,
            "max": 118,
            "avg": 116,
            "sum": 348
        },
        "color": secondaryMainColor,
        "colorSecondary": secondaryMainColor
    },
    {
        "name": "",
        "stats": {
            "count": 0,
            "min": 92,
            "max": 99,
            "avg": 96.84,
            "sum": 2421
        },
        "color": secondaryMainColor,
        "colorSecondary": secondaryMainColor
    }
]

export const wasteMockupData = {
    "histogram": {
        "buckets": [
            {
                "key_as_string": "2021-05-18",
                "key": 1621267200000,
                "doc_count": 3,
                "CLOTHING": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "GLASS": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "MIXED": {
                    "doc_count": 0,
                    "stats": {
                        "count": 1,
                        "min": 279,
                        "max": 279,
                        "avg": 279,
                        "sum": 279
                    }
                },
                "METALS": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "stats": {
                    "count": 0,
                    "min": 98,
                    "max": 279,
                    "avg": 158.33333333333334,
                    "sum": 475
                },
                "PAPER": {
                    "doc_count": 2,
                    "stats": {
                        "count": 0,
                        "min": 98,
                        "max": 98,
                        "avg": 98,
                        "sum": 196
                    }
                },
                "PLASTIC": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "RECYCLABLE": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                }
            },
            {
                "key_as_string": "2021-05-19",
                "key": 1621353600000,
                "doc_count": 2,
                "CLOTHING": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "GLASS": {
                    "doc_count": 1,
                    "stats": {
                        "count": 1,
                        "min": 192,
                        "max": 192,
                        "avg": 192,
                        "sum": 192
                    }
                },
                "MIXED": {
                    "doc_count": 1,
                    "stats": {
                        "count": 1,
                        "min": 294,
                        "max": 294,
                        "avg": 294,
                        "sum": 294
                    }
                },
                "METALS": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "stats": {
                    "count": 2,
                    "min": 192,
                    "max": 294,
                    "avg": 243,
                    "sum": 486
                },
                "PAPER": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "PLASTIC": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "RECYCLABLE": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                }
            },
            {
                "key_as_string": "2021-05-20",
                "key": 1621440000000,
                "doc_count": 12,
                "CLOTHING": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "GLASS": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "MIXED": {
                    "doc_count": 3,
                    "stats": {
                        "count": 3,
                        "min": 285,
                        "max": 300,
                        "avg": 290,
                        "sum": 870
                    }
                },
                "METALS": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "stats": {
                    "count": 12,
                    "min": 92,
                    "max": 300,
                    "avg": 171.08333333333334,
                    "sum": 2053
                },
                "PAPER": {
                    "doc_count": 5,
                    "stats": {
                        "count": 5,
                        "min": 92,
                        "max": 99,
                        "avg": 96,
                        "sum": 480
                    }
                },
                "PLASTIC": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "RECYCLABLE": {
                    "doc_count": 0,
                    "stats": {
                        "count": 4,
                        "min": 169,
                        "max": 180,
                        "avg": 175.75,
                        "sum": 703
                    }
                }
            },
            {
                "key_as_string": "2021-05-21",
                "key": 1621526400000,
                "doc_count": 14,
                "CLOTHING": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "GLASS": {
                    "doc_count": 1,
                    "stats": {
                        "count": 1,
                        "min": 198,
                        "max": 198,
                        "avg": 198,
                        "sum": 198
                    }
                },
                "MIXED": {
                    "doc_count": 2,
                    "stats": {
                        "count": 2,
                        "min": 279,
                        "max": 279,
                        "avg": 279,
                        "sum": 558
                    }
                },
                "METALS": {
                    "doc_count": 2,
                    "stats": {
                        "count": 2,
                        "min": 118,
                        "max": 118,
                        "avg": 118,
                        "sum": 236
                    }
                },
                "stats": {
                    "count": 14,
                    "min": 92,
                    "max": 279,
                    "avg": 143.92857142857142,
                    "sum": 2015
                },
                "PAPER": {
                    "doc_count": 6,
                    "stats": {
                        "count": 6,
                        "min": 92,
                        "max": 99,
                        "avg": 96.33333333333333,
                        "sum": 578
                    }
                },
                "PLASTIC": {
                    "doc_count": 1,
                    "stats": {
                        "count": 1,
                        "min": 98,
                        "max": 98,
                        "avg": 98,
                        "sum": 98
                    }
                },
                "RECYCLABLE": {
                    "doc_count": 0,
                    "stats": {
                        "count": 2,
                        "min": 169,
                        "max": 178,
                        "avg": 173.5,
                        "sum": 347
                    }
                }
            },
            {
                "key_as_string": "2021-05-22",
                "key": 1621612800000,
                "doc_count": 5,
                "CLOTHING": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "GLASS": {
                    "doc_count": 1,
                    "stats": {
                        "count": 1,
                        "min": 194,
                        "max": 194,
                        "avg": 194,
                        "sum": 194
                    }
                },
                "MIXED": {
                    "doc_count": 1,
                    "stats": {
                        "count": 1,
                        "min": 291,
                        "max": 291,
                        "avg": 291,
                        "sum": 291
                    }
                },
                "METALS": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "stats": {
                    "count": 5,
                    "min": 97,
                    "max": 291,
                    "avg": 155.4,
                    "sum": 777
                },
                "PAPER": {
                    "doc_count": 1,
                    "stats": {
                        "count": 1,
                        "min": 98,
                        "max": 98,
                        "avg": 98,
                        "sum": 98
                    }
                },
                "PLASTIC": {
                    "doc_count": 2,
                    "stats": {
                        "count": 2,
                        "min": 97,
                        "max": 97,
                        "avg": 97,
                        "sum": 194
                    }
                },
                "RECYCLABLE": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                }
            },
            {
                "key_as_string": "2021-05-23",
                "key": 1621699200000,
                "doc_count": 5,
                "CLOTHING": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "GLASS": {
                    "doc_count": 1,
                    "stats": {
                        "count": 1,
                        "min": 194,
                        "max": 194,
                        "avg": 194,
                        "sum": 194
                    }
                },
                "MIXED": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "METALS": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "stats": {
                    "count": 5,
                    "min": 93,
                    "max": 194,
                    "avg": 144.6,
                    "sum": 723
                },
                "PAPER": {
                    "doc_count": 2,
                    "stats": {
                        "count": 2,
                        "min": 93,
                        "max": 93,
                        "avg": 93,
                        "sum": 186
                    }
                },
                "PLASTIC": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "RECYCLABLE": {
                    "doc_count": 0,
                    "stats": {
                        "count": 2,
                        "min": 169,
                        "max": 174,
                        "avg": 171.5,
                        "sum": 343
                    }
                }
            },
            {
                "key_as_string": "2021-05-24",
                "key": 1621785600000,
                "doc_count": 12,
                "CLOTHING": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "GLASS": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "MIXED": {
                    "doc_count": 2,
                    "stats": {
                        "count": 2,
                        "min": 285,
                        "max": 288,
                        "avg": 286.5,
                        "sum": 573
                    }
                },
                "METALS": {
                    "doc_count": 1,
                    "stats": {
                        "count": 1,
                        "min": 112,
                        "max": 112,
                        "avg": 112,
                        "sum": 112
                    }
                },
                "stats": {
                    "count": 12,
                    "min": 96,
                    "max": 288,
                    "avg": 130.66666666666666,
                    "sum": 1568
                },
                "PAPER": {
                    "doc_count": 9,
                    "stats": {
                        "count": 9,
                        "min": 96,
                        "max": 99,
                        "avg": 98.11111111111111,
                        "sum": 883
                    }
                },
                "PLASTIC": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                },
                "RECYCLABLE": {
                    "doc_count": 0,
                    "stats": {
                        "count": 0,
                        "min": null,
                        "max": null,
                        "avg": null,
                        "sum": 0
                    }
                }
            }
        ]
    },
}