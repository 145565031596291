import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { SensorReport } from "../../api/sensors-reports";
import { ActionWithPayload, Optional } from "../types";
import { HistogramQuery } from "../../api/histogram";
import { DatePickerOption } from "../../components/controls/date-picker-control/utils";

export interface ReportElementRaw {
  battery: number;
  bin_level: number;
  signal: number;
}

export interface ReportElement {
  b: number;
  created_on: number;
  fill_percentage: number;
  l: number;
  missed_pickup: number;
  s: string;
  raw: ReportElementRaw;
}

export interface SensorDetailState {
  filterOption: DatePickerOption;
  filterSearchQuery: Optional<Partial<HistogramQuery>>;
  reportData: Array<SensorReport>;
}

export enum SensorDetailTypes {
  SET_FILTER_OPTION = "@@sensor_detail/SET_FILTER_OPTION",
  SET_FILTER_SEARCH_QUERY = "@@sensor_detail/SET_FILTER_SEARCH_QUERY",
  SET_REPORT_DATA = "@@sensor_detail/SET_REPORT_DATA",
}

export interface SetFilterOption {
  type: SensorDetailTypes.SET_FILTER_OPTION;
  payload: DatePickerOption;
}

export type SetFilterSearchQuery = ActionWithPayload<
  SensorDetailTypes.SET_FILTER_SEARCH_QUERY,
  Optional<Partial<HistogramQuery>>
>;

export interface SetReportData {
  type: SensorDetailTypes.SET_REPORT_DATA;
  payload: Array<SensorReport>;
}

export type SensorDetailActions =
  | SetFilterOption
  | SetFilterSearchQuery
  | SetReportData;
export type SensorDetailAsyncActions = ThunkAction<
  Promise<void | SensorDetailActions>,
  unknown,
  unknown,
  Action
>;

export type SensorDetailAsyncDispatch = ThunkDispatch<{}, {}, Action>;
