import { API } from 'aws-amplify';
import {
	AvgFillChartReport,
	AwaitingPickupChartReport,
	DashboardAction,
	DashboardAsyncAction,
	DashboardAsyncDispatch,
	DashboardStats,
	DashboardTypes,
	HistogramReport,
	SetAvgFillChartData,
	SetAvgFillChartFilter,
	SetAvgFillChartSearchQuery,
	SetAvgPickupFillChartData,
	SetAwaitingPickupChartData,
	SetBagsChartData,
	SetDashboardStats,
	SetMissedPickupChartData,
	SetTodayMissedPickupCount,
	SetTotalBagsCount,
	SetWasteCollectionData,
} from './types';
import moment from 'moment';
import { Optional, RequestOptions } from '../types';
import {
	extractEventsHistogramData,
	extractReportsHistogramData,
	fillEventsHistogramData,
} from '../../funcs/histogram';
import { DatePickerOption } from '../../components/controls/date-picker-control/utils';
import {
	HistogramQuery,
	SearchResponse,
	WasteCollectionResponse,
} from '../../api/histogram';
import config from '../../config/config';

export const setDashboardStats = (stats: DashboardStats): SetDashboardStats => {
	return {
		type: DashboardTypes.SET_DASHBOARD_STATS,
		payload: stats,
	};
};

export const setAvgFillChartFilter = (
	filter: DatePickerOption,
): SetAvgFillChartFilter => ({
	type: DashboardTypes.SET_AVG_FILL_CHART_FILTER,
	payload: filter,
});

export const setAvgFillChartSearchQuery = (
	query: Optional<Partial<HistogramQuery>>,
): SetAvgFillChartSearchQuery => ({
	type: DashboardTypes.SET_AVG_FILL_CHART_SEARCH_QUERY,
	payload: query,
});

export const setAverageFillChartData = (
	avgFillChartData: Array<AvgFillChartReport>,
): SetAvgFillChartData => {
	return {
		type: DashboardTypes.SET_AVG_FILL_CHART_DATA,
		payload: avgFillChartData,
	};
};

export const setAwaitingPickupChartData = (
	data: Array<AwaitingPickupChartReport>,
): SetAwaitingPickupChartData => {
	return {
		type: DashboardTypes.SET_AWAITING_PICKUP_CHART_DATA,
		payload: data,
	};
};

export const loadSensorStatistics =
	(): DashboardAsyncAction =>
	(dispatch: DashboardAsyncDispatch): Promise<void | DashboardAction> => {
		const options: RequestOptions = {
			queryStringParameters: {
				sort_field: 'fill_percentage',
				sort_order: 'desc',
				limit: 10000,
			},
		};
		return API.get(config.API.name, '/search/sensors/stats', options).then(
			(response) => {
				const dashboardStats: DashboardStats = {
					avgFill: response.aggregations.stats.avg,
					awaitingPickup:
						response.aggregations.ranges.buckets[1].stats.count,
					binsTotal: response.hits.length,
				};
				dispatch(setDashboardStats(dashboardStats));

				const awaitingPickupChartData: Array<AwaitingPickupChartReport> =
					response.hits.slice(0, 3);
				dispatch(setAwaitingPickupChartData(awaitingPickupChartData));
			},
		);
	};

export const loadReportHistogram =
	(searchQuery: Partial<HistogramQuery>): DashboardAsyncAction =>
	(dispatch: DashboardAsyncDispatch): Promise<void | DashboardAction> => {
		if (!searchQuery || Object.keys(searchQuery).length === 0)
			return Promise.reject('Empty search query');

		const options: RequestOptions = {
			queryStringParameters: {
				calendar_interval: 'day',
				limit: 1,
				sort_field: 'created_on',
				sort_order: 'asc',
				stats_field: 'fill_percentage',
				...searchQuery,
			},
		};

		return API.get(
			config.API.name,
			'/search/reports/date-histogram',
			options,
		).then((response) => {
			const avgFillData: Array<AvgFillChartReport> =
				extractReportsHistogramData(response);

			if (avgFillData.length === 0) {
				dispatch(setAverageFillChartData([]));
				const endTime = searchQuery['created_on.lt'] || moment().unix();
				const startTime =
					searchQuery['created_on.lt'] ||
					moment.unix(endTime).subtract(7, 'days').unix();
				dispatch(
					setAvgFillChartSearchQuery({
						'created_on.lt': endTime,
						'created_on.gte': startTime,
						exclude_muted: true,
					}),
				);
				return;
			}

			// Handle 'all' preset where there is no start point for the search query
			const updatedSearchQuery = {
				...searchQuery,
				'created_on.gte':
					searchQuery['created_on.gte'] ||
					moment
						.unix(avgFillData[0].created_on)
						.startOf('day')
						.unix(),
			};
			dispatch(setAvgFillChartSearchQuery(updatedSearchQuery));

			dispatch(setAverageFillChartData(avgFillData));
		});
	};

export const setAvgPickupFillChartData = (
	data: SearchResponse,
): SetAvgPickupFillChartData => ({
	type: DashboardTypes.SET_AVG_PICKUP_FILL_CHART_DATA,
	payload: data,
});

export const setWasteCollectionData = (
	data: WasteCollectionResponse,
): SetWasteCollectionData => ({
	type: DashboardTypes.SET_WASTE_COLLECTION_DATA,
	payload: data,
});

export const setMissedPickupChartData = (
	data: Array<HistogramReport>,
): SetMissedPickupChartData => ({
	type: DashboardTypes.SET_MISSED_PICKUP_CHART_DATA,
	payload: data,
});

export const setTodayMissedPickupCount = (
	count: number,
): SetTodayMissedPickupCount => ({
	type: DashboardTypes.SET_TODAY_MISSED_PICKUP_COUNT,
	payload: count,
});

export const loadTodayMissedPickupCount =
	(): DashboardAsyncAction =>
	(dispatch: DashboardAsyncDispatch): Promise<void | DashboardAction> => {
		const options: RequestOptions = {
			queryStringParameters: {
				event_type: 'Notifications_MissedPickup',
				'created_on.gte': 'now/d',
				'created_on.lte': 'now',
			},
		};

		return API.get(
			config.API.name,
			'/search/events/date-histogram',
			options,
		)
			.then((response) => {
				const count = response.aggregations.stats.count;
				dispatch(setTodayMissedPickupCount(count));
			})
			.catch((e) => console.error(e));
	};

export const loadMissedPickupChartData =
	(): DashboardAsyncAction =>
	(dispatch: DashboardAsyncDispatch): Promise<void | DashboardAction> => {
		const options: RequestOptions = {
			queryStringParameters: {
				event_type: 'Notifications_MissedPickup',
				'created_on.gte': 'now-7d/d',
			},
		};

		return API.get(
			config.API.name,
			'/search/events/date-histogram',
			options,
		)
			.then((response) => {
				const initialUnix = moment()
					.subtract(7, 'days')
					.startOf('day')
					.unix();
				const missedPickupData: Array<HistogramReport> =
					extractEventsHistogramData(response);
				const missedPickupChartData = fillEventsHistogramData(
					initialUnix,
					7,
					missedPickupData,
				);
				dispatch(setMissedPickupChartData(missedPickupChartData));
			})
			.catch((e) => console.error(e));
	};

export const setTotalBagsCount = (count: number): SetTotalBagsCount => ({
	type: DashboardTypes.SET_TOTAL_BAGS_COUNT,
	payload: count,
});

export const setBagsChartData = (
	data: Array<HistogramReport>,
): SetBagsChartData => ({
	type: DashboardTypes.SET_BAGS_CHART_DATA,
	payload: data,
});

export const loadBagsChartData =
	(): DashboardAsyncAction =>
	(dispatch: DashboardAsyncDispatch): Promise<void | DashboardAction> => {
		const options: RequestOptions = {
			queryStringParameters: {
				event_type: 'Notifications_BinChanged',
				'created_on.gte': 'now-7d/d',
				'created_on.lt': 'now/d',
			},
		};

		return API.get(
			config.API.name,
			'/search/events/date-histogram',
			options,
		)
			.then((response) => {
				const initialUnix = moment()
					.subtract(7, 'days')
					.startOf('day')
					.unix();
				const bagsData: Array<HistogramReport> =
					extractEventsHistogramData(response);
				const bagsChartData = fillEventsHistogramData(
					initialUnix,
					7,
					bagsData,
				);
				dispatch(setBagsChartData(bagsChartData));

				const totalBagsCount = response.aggregations.stats.count;
				dispatch(setTotalBagsCount(totalBagsCount));
			})
			.catch((e) => console.error(e));
	};

export const loadAvgPickupFillChartData =
	(): DashboardAsyncAction =>
	(dispatch: DashboardAsyncDispatch): Promise<void | DashboardAction> => {
		const options: RequestOptions = {
			queryStringParameters: {
				event_type: 'Notifications_BinChanged',
				calendar_interval: 'month',
				stats_field: 'metadata.fill_percentage_at_change',
				'created_on.gte': 'now-5M/M',
			},
		};

		return API.get(
			config.API.name,
			'/search/events/date-histogram',
			options,
		)
			.then((response) => {
				dispatch(setAvgPickupFillChartData(response));
			})
			.catch((e) => console.error(e));
	};

export const loadWasteCollectionData =
	(
		calendarInterval: string,
		fromGTE: string,
		toLT: string,
	): DashboardAsyncAction =>
	(dispatch: DashboardAsyncDispatch): Promise<void | DashboardAction> => {
		const options: RequestOptions = {
			queryStringParameters: {
				calendar_interval: calendarInterval,
				'created_on.gte': fromGTE,
				'created_on.lt': toLT,
			},
		};

		return API.get(
			config.API.name,
			'/search/events/waste-collection-histogram',
			options,
		)
			.then((response) => {
				dispatch(setWasteCollectionData(response));
			})
			.catch((e) => console.error(e));
	};
