import React from 'react';
import { Box, Button, CircularProgress, createStyles, makeStyles, PropTypes, Theme } from '@material-ui/core';


export interface MuiButtonProps {
  onClick: () => void;
  label?: string;
  inProgress?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  color?: PropTypes.Color;
  variant?: 'text' | 'outlined' | 'contained';
  autoFocus?: boolean;
  disabled?: boolean;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.light,
      '&:hover': {
        backgroundColor: theme.palette.error.main
      }
    }
  }));


export const MuiButton: React.FC<MuiButtonProps> = (props: MuiButtonProps) => {
  const classes = useStyles();
  
  const {
    label = 'Save',
    inProgress = false,
    fullWidth = false,
    error,
    color = 'primary',
    variant = 'contained',
    autoFocus,
    disabled
    
  } = props;
  
  const onClick = () => {
    if(!inProgress) {
      props.onClick()
    }
  }
  
  return (
    <Button
      classes={{
        root: error ? classes.error : '',
      }}
      onClick={onClick}
      disableRipple={inProgress}
      disableTouchRipple={inProgress}
      disableFocusRipple={inProgress}
      disableElevation={inProgress}
      size='large'
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      disabled={disabled}
      autoFocus={autoFocus}
    >
      <Box display='flex' alignItems='center'>
        {
          inProgress ? (
            <Box display='flex' alignItems='center'>
              <CircularProgress size={26} style={{
                color: '#fff'
              }} />
            </Box>
          ) : (
            <Box>
              {label}
            </Box>
          )
        }
      </Box>
    </Button>
  );
};