import React from 'react';
import style from './user-settings-sensors.module.scss';
import { useSensorOverviewData } from '../../../redux/sensor-overview/effects';
import { SensorList } from '../sensor-list/sensor-list';
import { Box, DialogActions } from '@material-ui/core';
import _ from 'lodash';
import { UserSensor } from '../../../api/contracts';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';

export interface UserSettingsSensorsProps {
  onClose: () => void;
  onSubmit: () => void;
  modalAction: string;
  selectedSensors: Array<UserSensor>;
  setSelectedSensors: (selectedSensors: Array<UserSensor>) => void;
}

export const UserSettingsSensors: React.FC<UserSettingsSensorsProps> = ({
  selectedSensors = [],
  ...props
}: UserSettingsSensorsProps) => {
  useSensorOverviewData(null, null);

  const onSensorSelectedHandler = (
    newlySelectedSensorId: string,
    selected: boolean
  ): void => {
    const foundSensorId = _.find(
      selectedSensors,
      (alreadySelectedUserSensor) =>
        alreadySelectedUserSensor.sensor_id === newlySelectedSensorId
    );

    if (foundSensorId === undefined && selected) {
      selectedSensors.push({
        sensor_id: newlySelectedSensorId,
      });
    }

    if (foundSensorId && !selected) {
      _.remove(
        selectedSensors,
        (alreadySelectedUserSensor) =>
          alreadySelectedUserSensor.sensor_id === newlySelectedSensorId
      );
    }

    props.setSelectedSensors(selectedSensors);
  };

  return (
    <Box className={style.UserSettingsSensors}>
      <Box className={style.sensorsSection}>
        <SensorList
          selectable={true}
          displayOption="mini_tiles"
          onSensorSelectedHandler={(sensorId, selected) =>
            onSensorSelectedHandler(sensorId, selected)
          }
          selectedSensors={selectedSensors}
        />
      </Box>
      <DialogActions>
        <LidbotButtonGroup onPrimaryClickHandler={props.onSubmit} onSecondaryClickHandler={props.onClose}/>
      </DialogActions>
    </Box>
  );
};
