import { DashboardAction, DashboardState, DashboardTypes } from './types';
import { datePickerOptions } from '../../components/controls/date-picker-control/utils';

const initialDashboardState: DashboardState = {
	dashboardStats: {
		avgFill: 0,
		awaitingPickup: 0,
		binsTotal: 0,
	},
	avgFillChartFilter: datePickerOptions[1],
	avgFillChartSearchQuery: null,
	avgFillChartData: [],
	awaitingPickupChartData: [],
	todayMissedPickupCount: 0,
	missedPickupChartData: [],
	wasteCollectionPanelData: null,
	totalBagsCount: 0,
	avgPickupFillChartData: null,
	bagsChartData: [],
};

export const dashboardReducer = (
	state: DashboardState = initialDashboardState,
	action: DashboardAction,
): DashboardState => {
	switch (action.type) {
		case DashboardTypes.SET_DASHBOARD_STATS: {
			return {
				...state,
				dashboardStats: action.payload,
			};
		}
		case DashboardTypes.SET_AVG_FILL_CHART_FILTER: {
			return {
				...state,
				avgFillChartFilter: action.payload,
			};
		}
		case DashboardTypes.SET_AVG_FILL_CHART_SEARCH_QUERY: {
			return {
				...state,
				avgFillChartSearchQuery: action.payload,
			};
		}
		case DashboardTypes.SET_AVG_FILL_CHART_DATA: {
			return {
				...state,
				avgFillChartData: action.payload,
			};
		}
		case DashboardTypes.SET_AWAITING_PICKUP_CHART_DATA: {
			return {
				...state,
				awaitingPickupChartData: action.payload,
			};
		}
		case DashboardTypes.SET_TODAY_MISSED_PICKUP_COUNT: {
			return {
				...state,
				todayMissedPickupCount: action.payload,
			};
		}
		case DashboardTypes.SET_MISSED_PICKUP_CHART_DATA: {
			return {
				...state,
				missedPickupChartData: action.payload,
			};
		}
		case DashboardTypes.SET_TOTAL_BAGS_COUNT: {
			return {
				...state,
				totalBagsCount: action.payload,
			};
		}
		case DashboardTypes.SET_AVG_PICKUP_FILL_CHART_DATA: {
			return {
				...state,
				avgPickupFillChartData: action.payload,
			};
		}
		case DashboardTypes.SET_WASTE_COLLECTION_DATA: {
			return {
				...state,
				wasteCollectionPanelData: action.payload,
			};
		}
		case DashboardTypes.SET_BAGS_CHART_DATA: {
			return {
				...state,
				bagsChartData: action.payload,
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
};
