import React, { useEffect } from 'react';
import { Radio, RadioProps } from '../../controls/radio/radio';
import { basicUserRoleOptions, UserRoleOption, UserRoleType } from '../../../redux/user/types';
import { Option } from '../../../redux/types';
import { useSelector } from 'react-redux';
import { roleSelector } from '../../../redux/auth/selectors';
import userRoleHierarchy from '../../../config/userRoleHierarchy';
import { Box, TextField } from '@material-ui/core';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';
import { validateEmail } from '../../../utils/string';

export interface UserSettingsDetailsProps {
  onClose: () => void;
  onSubmit: () => void;
  givenName: string;
  setGivenName: (name: string) => void;
  setGivenNameError: (givenNameError: string) => void;
  givenNameError?: string;
  familyName: string;
  setFamilyName: (familyName: string) => void;
  email: string;
  setEmail: (email: string) => void;
  setEmailError: (emailError: string) => void;
  emailError?: string;
  userRole: UserRoleType;
  setUserRole: (userRole: UserRoleType) => void;
  modalAction: string;
}

export const UserSettingsDetails: React.FC<UserSettingsDetailsProps> = (
  props: UserSettingsDetailsProps
) => {
  const role = useSelector(roleSelector);
  const userRoleOptions = role
    ? basicUserRoleOptions.filter(
        (o) => o.value === role || !userRoleHierarchy[role].includes(o.value)
      )
    : [];
  const onChangeRole = (option: Option): void =>
    props.setUserRole((option as UserRoleOption).value);

  const radioButtonsProps: RadioProps = {
    label: "Role",
    value: props.userRole,
    name: "user-role",
    options: userRoleOptions,
    onChange: onChangeRole,
    required: true,
  };
  
  const onSubmit = (): void => {
    let validationFailed = false
  
    if (!props.givenName) {
      props.setGivenNameError('Given name is required');
      validationFailed = true
    }
  
    if (!props.email) {
      props.setEmailError('Email is required');
      validationFailed = true
    }
    
    if(!validateEmail(props.email)) {
      props.setEmailError('Email address format incorrect.');
      validationFailed = true
    }
  
    if(validationFailed) {
      return
    }
    
    props.onSubmit()
  };
  
  useEffect(() => {
    if(props.givenName) {
      props.setGivenNameError('')
    }
    
    if(props.email) {
      props.setEmailError('')
    }
  }, [props.givenName, props.email])
  
  return (
    <Box>
        <Box>
          <Box marginBottom={4}>
            <TextField
              type="text"
              label="First name"
              value={props.givenName}
              required={true}
              variant='filled'
              error={!!props.givenNameError}
              helperText={props.givenNameError ? props.givenNameError : ''}
              fullWidth
              onChange={(e) => props.setGivenName(e.target.value)}
            />
          </Box>
  
          <Box marginBottom={4}>
            <TextField
              type="text"
              label="Last name"
              value={props.familyName}
              onChange={(e) => props.setFamilyName(e.target.value)}
              variant='filled'
              fullWidth
            />
          </Box>
  
          <Box marginBottom={4}>
            <TextField
              type="email"
              label="Email"
              value={props.email}
              onChange={(e) => props.setEmail(e.target.value)}
              required={true}
              variant='filled'
              error={!!props.emailError}
              helperText={props.emailError ? props.emailError : ''}
              fullWidth
            />
          </Box>
  
          <Box marginBottom={4}>
            <Radio helpContentType="roles" {...radioButtonsProps} />
          </Box>
        </Box>
  
      <Box marginBottom={2}>
          <LidbotButtonGroup
              onSecondaryClickHandler={props.onClose}
              onPrimaryClickHandler={onSubmit}
              primaryLabel="Next"
          />
        </Box>
    </Box>
  );
};
