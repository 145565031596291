import React, { FC, MouseEvent, PropsWithChildren, ReactNode, useRef } from 'react';
import classNames from 'classnames';
import style from './row.module.scss';
import { useDocumentEventListener } from '../../../hooks/document-event-listener';
import { RowCellLayoutClass } from './row-types';
import { UserRoleType } from '../../../redux/user/types';
import { Access } from '../access/access';

export interface RowProps {
  className?: string;
  rowClassName?: "header";
  selected?: boolean;
  onDeselect?: () => void;
  onClick?: () => void;
}

export interface RowCellAttributes {
  className?: string;
  rowCellClassName?: RowCellLayoutClass | null;
  onClick?: (e: MouseEvent) => void;
}

export interface HeaderRowCellProps extends Omit<RowCellAttributes, "onClick"> {
  hasBorder?: boolean;
}

export interface OptionsRowCellProps extends RowCellAttributes {
  flyover?: ReactNode;
  roleHierarchy: UserRoleType;
}

export type x = PropsWithChildren<RowProps>;

export const Row: FC<RowProps> = (props: x) => {
  const { className = null, selected, onDeselect, onClick, children } = props;

  const rowClassName = props.rowClassName ? style[props.rowClassName] : null;

  // Deselect bin type when click event from outside of component
  const rowRef = useRef<HTMLDivElement>(null);
  useDocumentEventListener(rowRef, "click", () => {
    selected && onDeselect && onDeselect();
  });

  return (
    <div
      className={classNames(style.Row, className, rowClassName, {
        [style.selected]: selected,
        [style.clickable]: onClick,
      })}
      ref={rowRef}
      onClick={() => onClick && onClick()}
    >
      {children}
    </div>
  );
};

export type RowCellProps = PropsWithChildren<RowCellAttributes>;

export const RowCell: FC<RowCellAttributes> = (props: RowCellProps) => {
  const { onClick, children } = props;
  const parentClassName = props.className || null;
  const rowCellClassName = props.rowCellClassName
    ? style[props.rowCellClassName]
    : null;
  const onClickHandler = (e: MouseEvent): void => {
    if (onClick) {
      e.stopPropagation();
      onClick(e);
    }
  };
  return (
    <div
      className={classNames(parentClassName, style.RowCell, rowCellClassName, {
        [style.clickable]: props.onClick,
      })}
      onClick={onClickHandler}
    >
      {children}
    </div>
  );
};

export const HeaderRowCell: FC<HeaderRowCellProps> = (props) => {
  const { children } = props;
  const parentClassName = props.className || null;
  const rowCellClassName = props.rowCellClassName
    ? style[props.rowCellClassName]
    : null;
  const borderClassName = props.hasBorder ? style.bordered : null;
  return (
    <div
      className={classNames(
        parentClassName,
        style.RowCell,
        style.header,
        borderClassName,
        rowCellClassName
      )}
    >
      {children}
    </div>
  );
};

export const IconRowCell: FC<RowCellAttributes> = (props) => {
  const rowCellProps: RowCellAttributes = {
    ...props,
    rowCellClassName: "icon",
  };
  return <RowCell {...rowCellProps} />;
};

export const FlexibleRowCell: FC<RowCellAttributes> = (props) => {
  const rowCellProps: RowCellAttributes = {
    ...props,
    rowCellClassName: "flexible",
  };
  return <RowCell {...rowCellProps} />;
};

export const StaticRowCell: FC<RowCellAttributes> = (props) => {
  const rowCellProps: RowCellAttributes = {
    ...props,
    rowCellClassName: "static",
  };
  return <RowCell {...rowCellProps} />;
};

export const HighlightedCellGroup: FC<RowCellAttributes> = (props) => {
  const rowCellProps: RowCellAttributes = {
    ...props,
    rowCellClassName: "highlighted",
  };
  return <RowCell {...rowCellProps} />;
};

export const CellGroup: FC<RowCellAttributes> = (props) => {
  const rowCellProps: RowCellAttributes = {
    ...props,
    rowCellClassName: "grouped",
  };
  return <RowCell {...rowCellProps} />;
};

export const OptionsRowCell: FC<OptionsRowCellProps> = ({
  onClick,
  ...props
}) => {
  const rowCellProps: RowCellAttributes = {
    ...props,
    rowCellClassName: "options",
  };

  const onClickHandler = (e: MouseEvent): void => {
    e.stopPropagation();
    onClick && onClick(e);
  };

  const children = props.children ? (
    <Access
      className={style.optionsIcon}
      roleHierarchy={props.roleHierarchy}
      onClick={onClickHandler}
    >
      {props.children}
      {props.flyover && (
        <div className={style.optionsFlyover}>{props.flyover}</div>
      )}
    </Access>
  ) : null;

  return <RowCell {...rowCellProps}>{children}</RowCell>;
};
