import {
	SensorOverviewActions,
	SensorOverviewState,
	SensorOverviewTypes,
} from './types';
import { sortOptions } from '../../components/building-blocks/sensor-overview-header/sensor-overview-header';

const initialSensorOverviewState: SensorOverviewState = {
	sortOption: sortOptions[0],
	displayOption: 'tiles',
	direction: 'desc',
};

export const sensorOverviewReducer = (
	state: SensorOverviewState = initialSensorOverviewState,
	action: SensorOverviewActions,
): SensorOverviewState => {
	switch (action.type) {
		case SensorOverviewTypes.SET_SORT_OPTION: {
			return {
				...state,
				sortOption: action.payload,
			};
		}
		case SensorOverviewTypes.SET_DISPLAY_OPTION: {
			return {
				...state,
				displayOption: action.payload,
			};
		}
		case SensorOverviewTypes.SET_DIRECTION: {
			return {
				...state,
				direction: action.payload,
			};
		}
		case SensorOverviewTypes.SET_SENSORS: {
			return {
				...state,
				sensors: action.payload,
			};
		}
		case SensorOverviewTypes.SET_SENSOR: {
			return {
				...state,
				sensors: state.sensors?.map((s) =>
					s.sensor_id === action.payload.sensor_id
						? action.payload
						: s,
				),
			};
		}
		case SensorOverviewTypes.DELETE_SENSOR: {
			return {
				...state,
				sensors: state.sensors?.filter(
					(s) => s.sensor_id !== action.payload.sensor_id,
				),
			};
		}
		default: {
			return state;
		}
	}
};
