import moment from 'moment';
import { Option, Optional } from '../../../redux/types';
import { HistogramQuery } from '../../../api/histogram';

/**
 * We will split up the date picker options into 2 separate pieces, a static json object with the different options available
 * and a function that generates the search queries for a couple reasons.
 * 1. The big reason is that momentjs will not get configured until after the account API is called. Keeping the search query
 * static would make it impossible to update.
 * 2. The second reason is that it gives us more flexibility in updating the start and end timestamps for the charts and
 * date picker.
 * */

export type DatePickerOptionValue =
	| '3d'
	| '1w'
	| '2w'
	| '4w'
	| 'all'
	| 'custom';

export interface DatePickerOption extends Option {
	value: DatePickerOptionValue;
}

export const datePickerOptions: Array<DatePickerOption> = [
	{
		value: '3d',
		label: '3 Days',
		i18nKey: 'sensorDetails.header.filterOptions.3d',
	},
	{
		value: '1w',
		label: '7 Days',
		i18nKey: 'sensorDetails.header.filterOptions.1w',
	},
	{
		value: '2w',
		label: '2 Weeks',
		i18nKey: 'sensorDetails.header.filterOptions.2w',
	},
	{
		value: '4w',
		label: '4 Weeks',
		i18nKey: 'sensorDetails.header.filterOptions.4w',
	},
	{
		value: 'all',
		label: 'All-Time',
		i18nKey: 'sensorDetails.header.filterOptions.all',
	},
	{
		value: 'custom',
		label: 'Custom',
	},
];

export const getSearchQueryForDatePickerOption = (
	value: DatePickerOptionValue,
): Optional<Partial<HistogramQuery>> => {
	switch (value) {
		case '3d': {
			return {
				'created_on.gte': moment()
					.subtract(3, 'days')
					.startOf('day')
					.unix(),
				'created_on.lt': moment().unix(),
			};
		}
		case '1w': {
			return {
				'created_on.gte': moment()
					.subtract(1, 'week')
					.startOf('day')
					.unix(),
				'created_on.lt': moment().unix(),
			};
		}
		case '2w': {
			return {
				'created_on.gte': moment()
					.subtract(2, 'weeks')
					.startOf('day')
					.unix(),
				'created_on.lt': moment().unix(),
			};
		}
		case '4w': {
			return {
				'created_on.gte': moment()
					.subtract(4, 'weeks')
					.startOf('day')
					.unix(),
				'created_on.lt': moment().unix(),
			};
		}
		case 'all': {
			return {
				'created_on.lt': moment().unix(),
			};
		}
		default: {
			return null;
		}
	}
};
