import React, { PropsWithChildren } from 'react';
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { Help } from '../controls/help/help';

// const StyledLabel = withStyles()

export interface CalendarIntervalAttributes {
    label: string,
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export type CalendarIntervalProps = PropsWithChildren<CalendarIntervalAttributes>

export const CalendarInterval: React.FC<CalendarIntervalProps> = (props) => {
    const { label, value, onChange } = props;

    return (
        <Box display='flex' alignItems='center'>
            <Box display='flex' alignItems='center' marginRight={1}>
                <FormLabel component='legend'>{label}</FormLabel>
            </Box>
            <Box marginRight={1}>
                <Help>
                    <h4>Calendar Interval</h4>
                    <Box marginBottom={1}>The bigger the interval the more higher level view over the data you have.</Box>
                    <Box>If you want to know which day of the month produces the most waste you will use day interval. If you want to know which month of the year produces the most waste, you want to use month interval</Box>
                </Help>
            </Box>
            <RadioGroup row aria-label='calendar interval' name='calendar-interval' value={value} onChange={onChange}>
                <FormControlLabel value='day' control={<Radio color='primary' />} label='Day' />
                <FormControlLabel value='week' control={<Radio color='primary' />} label='Week' />
                <FormControlLabel value='month' control={<Radio color='primary' />} label='Month' />
            </RadioGroup>
        </Box>
    );
};