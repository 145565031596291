import { ActionWithPayload } from '../types';

export interface LoadingBarState {
	referenceCount: number;
}

export enum LoadingBarActionType {
	INCREMENT_REFERENCE_COUNT = '@@loading-bar/INCREMENT_REFERENCE_COUNT',
	DECREMENT_REFERENCE_COUNT = '@@loading-bar/DECREMENT_REFERENCE_COUNT',
}

export type IncrementReferenceCount = ActionWithPayload<
	LoadingBarActionType.INCREMENT_REFERENCE_COUNT,
	null
>;
export type DecrementReferenceCount = ActionWithPayload<
	LoadingBarActionType.DECREMENT_REFERENCE_COUNT,
	null
>;

export type LoadingBarAction =
	| IncrementReferenceCount
	| DecrementReferenceCount;
