import React from 'react';
import style from './dashboard.module.scss';
import { InterfaceFrame } from '../../building-blocks/interface-frame/interface-frame';
import { DashboardHeader } from '../../building-blocks/dashboard-header/dashboard-header';
import { AverageFillChart } from '../../building-blocks/average-fill-chart/average-fill-chart';
import { FullBinsPanel } from '../../building-blocks/full-bins-panel/full-bins-panel';
import { AvgFillPanel } from '../../building-blocks/avg-fill-at-pickup-panel/avg-fill-at-pickup-panel';
import { TotalBagsPanel } from '../../building-blocks/total-bags-panel/total-bags-panel';
import { useDashboardDataLoader } from '../../../redux/dashboard/effects';
import { useClientRefreshCallback } from '../../../redux/auth/effects';
import { useDispatch, useSelector } from 'react-redux';
import { avgFillChartFilterSelector } from '../../../redux/dashboard/selectors';
import { setAvgFillChartSearchQuery } from '../../../redux/dashboard/actions';
import { WasteCollectionPanel } from '../../building-blocks/waste-collection-panel/waste-collection-panel';
import { WasteAnalysisWidePanel } from '../../building-blocks/waste-analysis-wide-panel/waste-analysis-wide-panel';
import { Box } from '@material-ui/core';

export const Dashboard: React.FC<{}> = () => {
  useDashboardDataLoader();

  const filterOption = useSelector(avgFillChartFilterSelector);
  const dispatch = useDispatch();
  useClientRefreshCallback(() => {
    filterOption.value !== "custom" &&
      dispatch(setAvgFillChartSearchQuery(null));
  });

  return (
    <InterfaceFrame>
      <div className={style.DashboardPage}>
        <Box marginBottom={6}>
          <DashboardHeader />
        </Box>
        <Box marginBottom={6}>
          <WasteAnalysisWidePanel />
        </Box>
        <Box display="flex" flexWrap="wrap" marginBottom={6} className={style.panels}>
          <FullBinsPanel />
          <TotalBagsPanel />
          <AvgFillPanel />
          <WasteCollectionPanel />
        </Box>
        <Box marginBottom={6}>
          <AverageFillChart />
        </Box>
      </div>
    </InterfaceFrame>
  );
};
