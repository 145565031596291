import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as LidbotLogo } from '../../../assets/img/smartbinIo-full-logo.svg';
import '../../../config/amplify';
import { appRoutes } from '../../../config/routes';
import { loadUser, loginUser } from '../../../redux/auth/actions';
import { AuthAsyncDispatch } from '../../../redux/auth/types';
import { PasswordResetForm } from '../../building-blocks/password-reset-form/password-reset-form';
import { ProgressIndicator, ProgressStep } from '../../building-blocks/progress-indicator/progress-indicator';
import { PublicPageFrame } from '../../building-blocks/public-page-frame/public-page-frame';
import { RequestPasswordResetForm } from '../../building-blocks/request-password-reset-form/request-password-reset-form';
import { FloatingPanel } from '../../controls/floating-panel/floating-panel';
import style from './password-reset.module.scss';

/**
 * The steps for the progress indicator.
 * Will be translated within the progress step component.
 */
const progressIndicatorSteps: Array<ProgressStep> = [
  {
    label: "passwordReset.steps.address",
    hint: "passwordReset.steps.addressHint",
  },
  {
    label: "passwordReset.steps.email",
    hint: "passwordReset.steps.emailHint",
  },
  {
    label: "passwordReset.steps.change",
    hint: "passwordReset.steps.changeHint",
  },
];

export const PasswordReset: React.FC<{}> = () => {
  const history = useHistory();
  const authDispatch = useDispatch<AuthAsyncDispatch>();
  const [displayResent, setDisplayResent] = useState(false);
  const { username, code } = useParams() as any;
  const displayPasswordForm = username && code;
  const [currentStep, setCurrentStep] = useState(displayPasswordForm ? 3 : 1);

  const requestPasswordResetFormSubmitHandler = (): void => {
    if (!displayResent) {
      setDisplayResent(true);
      setCurrentStep(currentStep + 1);
    }
  };

  const requestPasswordResetForm = (
    <RequestPasswordResetForm
      displayResent={displayResent}
      onSubmit={requestPasswordResetFormSubmitHandler}
    />
  );

  const passwordResetFormSubmitHandler = (password: string): void => {
    if (username && code) {
      authDispatch(loginUser(username, password, ""))
        .then(() => {
          history.push(appRoutes.dashboard.path as string);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const passwordResetForm = (
    <PasswordResetForm onSubmit={passwordResetFormSubmitHandler} />
  );

  useEffect(() => {
    authDispatch(loadUser()).then(() =>
      history.push(appRoutes.dashboard.path as string)
    );
  }, [authDispatch, history]);

  return (
    <PublicPageFrame>
      <FloatingPanel collapsed>
        <div className={style.PasswordReset}>
          <div className={style.panelMainContent}>
            {!displayPasswordForm && requestPasswordResetForm}
            {displayPasswordForm && passwordResetForm}
          </div>

          <div className={style.progressBar}>
            <LidbotLogo />
            <ProgressIndicator
              steps={progressIndicatorSteps}
              currentIndex={currentStep}
            />
          </div>
        </div>
      </FloatingPanel>
    </PublicPageFrame>
  );
};
