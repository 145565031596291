import { ReactComponent as BinIcon } from './../../../assets/img/icons/icon.bin.svg';
import React, { useState } from 'react';
import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

export interface SensorMiniTileProps {
    name?: string;
    sensorId: string;
    onClick?: (selected: boolean) => void;
    selectable?: boolean;
    isSelected: boolean;
}

export const SensorMiniTile: React.FC<SensorMiniTileProps> = ({
                                                                  selectable = false,
                                                                  ...props
                                                              }) => {
    const [selected, setSelected] = useState<boolean>(props.isSelected);
    
    const sensorClickHandler = (): void => {
        if (props.onClick) {
            props.onClick(!selected);
        }
        
        if (selectable) {
            setSelected(!selected);
        }
    };
    
    return (
        <Chip
            icon={<BinIcon />}
            label={props.name && props.name !== '' ? props.name : props.sensorId}
            clickable
            size='medium'
            variant='default'
            color='default'
            onClick={sensorClickHandler}
            onDelete={sensorClickHandler}
            deleteIcon={
                selected ? (
                    <DoneIcon />
                ) : (
                    <></>
                )
            }
        />
    );
};
