import React, { ReactNode } from 'react';
import style from './waste-avatar.module.scss';
import classNames from 'classnames';
import { WasteType } from '../../../redux/account/types';
import { useWasteColorPalette, WasteColor } from './waste-avatar-utils';
import { ReactComponent as RecycleIcon } from '../../../assets/img/icons/icon.recycle.svg';
import { ReactComponent as GlassIcon } from '../../../assets/img/icons/icon.glass.svg';
import { ReactComponent as PlasticIcon } from '../../../assets/img/icons/icon.plastic.svg';
import { ReactComponent as AluminiumIcon } from '../../../assets/img/icons/icon.aluminium.svg';
import { ReactComponent as PaperIcon } from '../../../assets/img/icons/icon.paper.svg';
import { ReactComponent as OrganicIcon } from '../../../assets/img/icons/icon.organic.svg';
import { ReactComponent as ClothingIcon } from '../../../assets/img/icons/icon.clothing.svg';
import { ReactComponent as MixedIcon } from '../../../assets/img/icons/icon.mixed.svg';
import { Optional } from '../../../redux/types';

export interface WasteAvatarProps {
    size: number;
    type?: Optional<WasteType>;
    placeholder?: string;
    selected?: boolean;
    darkMode?: boolean;
    displayIcon?: boolean;
}

export const WasteAvatar: React.FC<WasteAvatarProps> = props => {
    const { type, size, placeholder = '', selected = false, darkMode = true, displayIcon = true } = props;

    const colorPalette = useWasteColorPalette();
    const color: WasteColor = type ? colorPalette[type] : 'wc-none';

    const sizeClass = `size-${size}`;

    const getIconForWasteType = (wasteType: WasteType): ReactNode => {
        if(!displayIcon) {
            return <></>
        }
        
        switch (wasteType) {
            case WasteType.PLASTIC: {
                return displayIcon ? <PlasticIcon /> : null;
            }
            case WasteType.RECYCLABLE: {
                return <RecycleIcon />;
            }
            case WasteType.GLASS: {
                return displayIcon ? <GlassIcon /> : null;
            }
            case WasteType.METALS: {
                return <AluminiumIcon />
            }
            case WasteType.PAPER: {
                return <PaperIcon />
            }
            case WasteType.MIXED: {
                return <MixedIcon />
            }
            case WasteType.CLOTHING: {
                return <ClothingIcon />
            }
            case WasteType.ORGANIC:
                return <OrganicIcon />
            default: {
                return null
            }
        }
    }

    const icon: ReactNode = type ? getIconForWasteType(type) : null;

    const inlineStyle = { width: `${size}px`, height: `${size}px`};

    return(
        <div
            className={classNames(style.WasteAvatar, style[color], style[sizeClass], { [style.selected]: selected, [style.darkMode]: darkMode })}
            style={inlineStyle}>
            { icon ? icon : placeholder }
        </div>
    );
}