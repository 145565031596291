import React, { PropsWithChildren, ReactElement } from 'react';
import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';
import { robotoBold } from '../../mui/themes';


const StyledTooltip = withStyles(() => ({
  tooltip: {
    fontFamily: robotoBold,
    fontSize: 12,
    lineHeight: 1.4,
    fontWeight: 'bold',
    backgroundColor: '#ffffff',
    color: '#000000',
    boxShadow: '0 4px 8px rgb(51 51 51 / 20%)',
    borderRadius: '8px',
    padding: '24px 22px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    position: 'relative',
    '& .status': {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '6px',
      background: 'linear-gradient(180deg, #00C391 0%, rgba(23, 202, 155, 0.3) 100%)'
    },
    '& .warning': {
      background: 'linear-gradient(180deg, rgba(250, 182, 53, 1) 0%, rgba(250, 182, 53, 0.3) 100%)'
    },
    '& .error': {
      background: 'linear-gradient(180deg, #a74040 0%, rgba(218, 100, 100, 0.5) 100%)'
    },
    '& p': {
      color: '#000',
      fontWeight: 'bold',
      margin: 0,
      padding: 0
    },
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      '& b': {
        color: '#000'
      }
    },
    '& h1': {
      color: '#000',
      fontSize: '1.25em',
      margin: '0 0 1.25em 0'
    },
    '& h2': {
      color: '#000',
      fontSize: '1em',
      margin: '0 0 1em 0'
    },
    '& h3': {
      color: '#000',
      fontSize: '1em',
      margin: '0 0 .75em 0'
    },
    '& h4': {
      color: '#000',
      fontSize: '1em',
      margin: '0 0 .5em 0'
    },
    '& h5': {
      color: '#757575',
      fontSize: '1em',
      margin: '0 0 .25em 0'
    },
    '& ul': {
      padding: '0 0 20px 14px',
      margin: 0,
      color: '#757575',
      '& li': {
        fontSize: 12,
        padding: '0 0 10px 0',
        '&:last-child': {
          paddingBottom: 0
        }
      },
      '&:last-child': {
        paddingBottom: 0
      }
    }
  }
}))(Tooltip);


export type toolTipSeverityTypes = 'info' | 'warning' | 'error';
export type contentTypes = 'roles' | 'pin';


export interface LightTooltipAttributes extends TooltipProps {
  severity?: toolTipSeverityTypes;
  contentType?: contentTypes;
}


const getHelpByType = (contentType: contentTypes): ReactElement => {
  switch (contentType) {
    case 'roles':
      return (
        <>
          <h2>{'User\'s Role:'}</h2>
          <ul>
            <li>
              <h4>User</h4>
              <span>Read only access to the system. The user will also receive notifications and reports.</span>
            </li>
            <li>
              <h4>Admin</h4>
              <span>Can edit all users (except users with roles Developer and Owner).</span>
              <span>Can edit senors, sensor schedule, reports, etc...</span>
            </li>
            <li>
              <h4>Owner</h4>
              <span>Same privileges as Admin with the exception than an owner can edit other owners.</span>
            </li>
            <li>
              <h4>Developer</h4>
              <span>Same privileges as Owner, plus can manage API Keys. The purpose of this role is to enable 3rd party integration with the Smartbin.io API.</span>
            </li>
          </ul>
        </>
      );
    case 'pin':
      return (
        <>
          <h4>Pin / Unpin this sensor</h4>
          <span>Pinned sensors will stay on top of the list.</span>
        </>
      );
      break;
    default:
      return (
        <></>
      );
  }
};

export type LightTooltipProps = PropsWithChildren<LightTooltipAttributes>

export const LightTooltip: React.FC<LightTooltipProps> = (props: LightTooltipProps) => {
  const {
    severity = 'info',
    placement = 'bottom',
    enterDelay = 300,
    enterNextDelay = 100
  } = props;
  
  return (
    <StyledTooltip
      title={
        <>
          <div>
            <div className={`status ${severity}`} />
            {props.contentType ? getHelpByType(props.contentType) : props.title}
          </div>
        </>
      }
      placement={placement}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
    >
      {props.children}
    </StyledTooltip>
  );
};