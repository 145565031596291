import React, { Dispatch, SetStateAction } from 'react';
import style from './sensor-schedule.module.scss';
import { SensorSchedule, SensorScheduleType } from '../../../redux/account/types';
import { ScheduleSelector } from '../../controls/schedule-selector/schedule-selector';
import { numericInputOnChangeHandlerWithSetter, numericInputValueFilter } from '../../../utils/input';
import { useAccess } from '../../../hooks/access';
import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { Help } from '../../controls/help/help';

export interface SensorScheduleProps {
  schedule: SensorSchedule
  setSchedule: (schedule: SensorSchedule) => void
    height?: number | string
    scheduleError?: string
    onlyFixed?: boolean
    rate?: number
    setRate?: Dispatch<SetStateAction<number>>
}

export const Schedule: React.FC<SensorScheduleProps> = (props) => {
  const {
      schedule,
      setSchedule,
      height = '100%',
      scheduleError,
      onlyFixed = false,
      rate = 0,
      setRate
  } = props
  
  const access = useAccess("ADMIN");
  const readonly = !access;

  return (
    <Box display="flex" className={style.SensorSchedule}>
        {
            !onlyFixed &&
            <Box flexBasis="200px" mr={4} className={style.scheduleSelection}>
                <Box mb={3} className={style.scheduleTypeInfo}>
                    <Box mb={2}>
                        <Typography variant="h4">
                            Schedule Type
                        </Typography>
                    </Box>
                    <Typography variant="caption" color="secondary">
                        Select the type of schedule your sensors will follow.
                    </Typography>
                </Box>
                <Box>
                    <FormControl component="fieldset">
                        <Box display="flex" alignItems="center" mb={1}>
                            <Box mr={1}>
                                <FormLabel component="legend">Schedule type</FormLabel>
                            </Box>
                            <Help>
                                <Box mb={2}>
                                    <Typography variant="h5">
                                        Fixed:
                                    </Typography>
                                    <Typography variant="caption">
                                        Fixed schedule type means that the sensors will take measurements according at the times selected in the calendar.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h5">
                                        Interval:
                                    </Typography>
                                    <Typography variant="caption">
                                        Interval based schedule type means that the sensors will take measurements every x (interval) number of minutes.
                                    </Typography>
                                </Box>
                            </Help>
                        </Box>
                        <RadioGroup aria-label="schedule type" name="schedule-type" value={schedule.type}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setSchedule({
                                            ...schedule,
                                            type: (event.target as HTMLInputElement).value as SensorScheduleType
                                        })
                                    }}
                        >
                            <FormControlLabel value="WEEKLY" control={<Radio color="primary" />} label="Fixed" />
                            <FormControlLabel value="RATE" control={<Radio color="primary"/>} label="Interval" />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
        }
          <Box flexBasis="100%">
            {schedule.type === "RATE" ? (
                <Box className={style.rateSelectorWrapper}>
                  <Box marginBottom={4} className={style.rateSelectorTitle}>
                    <Typography variant="h4">
                        Measure fill-levels every:
                    </Typography>
                  </Box>
                  <Box className={style.rateSelector}>
                    <Box className={style.rateValue}>
                      <TextField
                          variant='filled'
                          label="Interval"
                          value={numericInputValueFilter(rate)}
                          onChange={setRate && numericInputOnChangeHandlerWithSetter(setRate)}
                          error={scheduleError ? true : false}
                          required
                          style={{width: 300}}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>
                              <Box display="flex" alignItems="center">
                                <Box marginRight={1}>
                                  {
                                    schedule.rate ? (
                                        <Typography>{`(${ Math.floor(rate / 60) } hours ${Math.floor(rate % 60)} min)`}</Typography>
                                    ) : (
                                        <Typography>{`( )`}</Typography>
                                    )
                                  }
                                </Box>
                                <Box>
                                  <Help>
                                      <Box mb={2}>
                                          <Typography variant="caption">
                                              Specify how often you want your sensors to wake up, take measurement of your your fill level, and send the data to the cloud.
                                          </Typography>
                                      </Box>
                                      <Box mb={2}>
                                          <Typography variant="caption">
                                      For example, if you enter 60, the sensors will wake up every hour.
                                      </Typography>
                                      </Box>
                                      <Box mb={2}>
                                          <Typography variant="caption">
                                      Please note that the interval must be between 5 minutes and 1 day.
                                      </Typography>
                                      </Box>
                                  </Help>
                                </Box>
                              </Box>
                            </InputAdornment>
                          }}
                          helperText={scheduleError ? scheduleError : 'Enter time duration in minutes (min) between sensor measurements.'}
                          disabled={readonly}
                      />
                    </Box>
                  </Box>
                </Box>
            ) : (
                <Box position="relative" className={style.scheduleSelector}>
                  <ScheduleSelector
                      height={height}
                      cellHeight={50}
                      week={schedule.week || []}
                      onChange={(week) => setSchedule({
                        ...schedule,
                        week: week
                      })}
                      error={!!scheduleError}
                      readonly={readonly}
                  />
                    {
                        scheduleError &&
                        <Box position="absolute" bottom={-20} right={10}>
                            <FormHelperText error={!!scheduleError}>
                                {scheduleError}
                            </FormHelperText>
                        </Box>
                    }

                </Box>
            )}
          </Box>
    </Box>
  );
};
