import React from 'react';
import style from './dashboard-stats.module.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { avgFillChartFilterSelector, avgFillChartSearchQuerySelector, avgFillSelector, awaitingPickupSelector, binsTotalSelector } from '../../../redux/dashboard/selectors';
import { Trans } from 'react-i18next';
import { DatePickerControl } from '../date-picker-control/date-picker-control';
import { DatePickerOption } from '../date-picker-control/utils';
import { setAvgFillChartFilter, setAvgFillChartSearchQuery } from '../../../redux/dashboard/actions';
import moment from 'moment';

export interface DashboardStatsProps {
    avgFill: number;
    awaitingPickup: number;
    totalBins: number;
}

export const DashboardStats: React.FC<DashboardStatsProps> = () => {
    const avgFill = useSelector(avgFillSelector);
    const avgFillLabel = `${Math.round(avgFill)}`;

    const awaitingPickup = useSelector(awaitingPickupSelector);
    const binsTotal = useSelector(binsTotalSelector);

    const dispatch = useDispatch();
    const avgFillChartFilter = useSelector(avgFillChartFilterSelector);
    const searchQuery = useSelector(avgFillChartSearchQuerySelector);
    const selectedStartTimestamp = searchQuery?.['created_on.gte'] || null;
    const selectedEndTimestamp = searchQuery?.['created_on.lt'] || null;
    const onFilterOptionClickedHandler = (option: DatePickerOption): void => {
        dispatch(setAvgFillChartFilter(option));
        option.value !== 'custom' && dispatch(setAvgFillChartSearchQuery(null));
    };
    const onDateRangeChangeHandler = (start: number, end: number): void => {
        dispatch(setAvgFillChartSearchQuery({
            'created_on.gte': moment.unix(start).startOf('day').unix(),
            'created_on.lt': moment.unix(end).endOf('day').unix()
        }));
    };
    return (
        <div className={style.DashboardStats}>
            <div className={classNames(style.statsColumn, style.green)}>
                <span className={style.statsValue}>{avgFillLabel}%</span>
                <span className={style.statsLabel}>
                    <Trans i18nKey='dashboard.header.stats.avgFill1'/>
                    <br/>
                    <Trans i18nKey='dashboard.header.stats.avgFill2'/>
                </span>
            </div>
            <div className={classNames(style.statsColumn, style.red)}>
                <span className={style.statsValue}>{awaitingPickup}</span>
                <span className={style.statsLabel}>
                    <Trans i18nKey='dashboard.header.stats.pickup1' />
                    <br/>
                    <Trans i18nKey='dashboard.header.stats.pickup2' />
                </span>
            </div>
            <div className={classNames(style.statsColumn, style.gray)}>
                <span className={style.statsValue}>{binsTotal}</span>
                <span className={style.statsLabel}>
                    <Trans i18nKey='dashboard.header.stats.bins1'/>
                    <br/>
                    <Trans i18nKey='dashboard.header.stats.bins2'/>
                </span>
            </div>
            <div className={style.datePicker}>
                <DatePickerControl
                    selectedDatePickerOption={avgFillChartFilter}
                    selectedStartTimestamp={selectedStartTimestamp}
                    selectedEndTimestamp={selectedEndTimestamp}
                    onDatePickerChange={onFilterOptionClickedHandler}
                    onDateRangeChange={onDateRangeChangeHandler}
                />
            </div>
        </div>
    );
};
