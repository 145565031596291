import {
	AccountAction,
	AccountActionType,
	AccountReport,
	AccountState,
	BinType,
	EventType,
	Subscriber,
} from './types';
import { assocPath, findIndex, lensPath, over, reject, update } from 'ramda';

const initialAccountState: AccountState = {
	data: null,
	apiCalled: false,
	apiLoaded: false,
};

export const accountReducer = (
	state: AccountState = initialAccountState,
	action: AccountAction,
): AccountState => {
	switch (action.type) {
		case AccountActionType.SET_ACCOUNT: {
			return {
				...state,
				data: action.payload,
			};
		}
		case AccountActionType.SET_API_CALLED: {
			return {
				...state,
				apiCalled: action.payload,
			};
		}
		case AccountActionType.SET_API_LOADED: {
			return {
				...state,
				apiLoaded: action.payload,
			};
		}
		case AccountActionType.ADD_EVENT:
		case AccountActionType.SET_EVENT: {
			const newEvent = action.payload;
			const filteredEvents =
				state.data?.events?.filter((e) => e.type !== newEvent.type) ||
				[];
			return {
				...state,
				data: state.data
					? {
							...state.data,
							events: [...filteredEvents, newEvent],
					  }
					: null,
			};
		}
		case AccountActionType.DELETE_ACCOUNT_REPORT: {
			const accountReport: AccountReport = action.payload;
			return deleteAccountReportReducer(accountReport, state);
		}
		case AccountActionType.DELETE_BIN_TYPE: {
			const binType: BinType = action.payload;
			return deleteBinTypeReducer(binType, state);
		}
		case AccountActionType.ADD_BIN_TYPE: {
			const binType: BinType = action.payload;
			return addBinTypeReducer(binType, state);
		}
		case AccountActionType.ADD_ACCOUNT_REPORT: {
			const accountReport: AccountReport = action.payload;
			return addAccountReportReducer(accountReport, state);
		}
		case AccountActionType.UPDATE_BIN_TYPE: {
			const binType: BinType = action.payload;
			return updateBinTypeReducer(binType, state);
		}
		case AccountActionType.UPDATE_ACCOUNT_REPORT: {
			const accountReport: AccountReport = action.payload;
			return updateAccountReportReducer(accountReport, state);
		}
		case AccountActionType.ADD_SUBSCRIBER: {
			const { eventType, payload: subscriber } = action;
			return addSubscriberForEventTypeReducer(
				subscriber,
				eventType,
				state,
			);
		}
		case AccountActionType.DELETE_SUBSCRIBER: {
			const { payload: subscriber, eventType } = action;
			return deleteSubscriberForEventTypeReducer(
				subscriber,
				eventType,
				state,
			);
		}
		case AccountActionType.SET_CONDITION_VALUE: {
			const { payload: value, eventType } = action;
			return setConditionValueForEventTypeReducer(
				value,
				eventType,
				state,
			);
		}

		default: {
			return state;
		}
	}
};

const addBinTypeReducer = (
	binType: BinType,
	state: AccountState,
): AccountState => {
	const transformer = (binTypes: Array<BinType>): Array<BinType> => {
		const filteredBTs = reject<BinType>(
			(bt) => bt.bin_type_id === binType.bin_type_id,
			binTypes,
		);
		return [...filteredBTs, binType];
	};
	return over(lensPath(['data', 'bin_types']), transformer, state);
};

const addAccountReportReducer = (
	accountReport: AccountReport,
	state: AccountState,
): AccountState => {
	const transformer = (
		accountReports: Array<AccountReport>,
	): Array<AccountReport> => {
		const filtered = reject<AccountReport>(
			(ar) => ar.id === accountReport.id,
			accountReports,
		);
		return [...filtered, accountReport];
	};
	return over(lensPath(['data', 'reports']), transformer, state);
};

const updateBinTypeReducer = (
	binType: BinType,
	state: AccountState,
): AccountState => {
	const transformer = (binTypes: Array<BinType>): Array<BinType> => {
		const index = findIndex<BinType>(
			(bt) => bt.bin_type_id === binType.bin_type_id,
			binTypes,
		);
		return index || index === 0
			? update(index, binType, binTypes)
			: binTypes;
	};
	return over(lensPath(['data', 'bin_types']), transformer, state);
};

const updateAccountReportReducer = (
	accountReport: AccountReport,
	state: AccountState,
): AccountState => {
	const transformer = (
		accountReports: Array<AccountReport>,
	): Array<AccountReport> => {
		const index = findIndex<AccountReport>(
			(ar) => ar.id === accountReport.id,
			accountReports,
		);
		return index || index === 0
			? update(index, accountReport, accountReports)
			: accountReports;
	};
	return over(lensPath(['data', 'reports']), transformer, state);
};

const deleteBinTypeReducer = (
	binType: BinType,
	state: AccountState,
): AccountState => {
	const transformer = (binTypes: Array<BinType>): Array<BinType> => {
		const filteredBTs = reject<BinType>(
			(bt) => bt.bin_type_id === binType.bin_type_id,
			binTypes,
		);
		return [...filteredBTs];
	};
	return over(lensPath(['data', 'bin_types']), transformer, state);
};

const deleteAccountReportReducer = (
	accountReport: AccountReport,
	state: AccountState,
): AccountState => {
	const transformer = (
		accountReports: Array<AccountReport>,
	): Array<AccountReport> => {
		const filtered = reject<AccountReport>(
			(ar) => ar.id === accountReport.id,
			accountReports,
		);
		return [...filtered];
	};
	return over(lensPath(['data', 'reports']), transformer, state);
};

const addSubscriberForEventTypeReducer = (
	subscriber: Subscriber,
	eventType: EventType,
	state: AccountState,
): AccountState => {
	const eventIndex = state.data?.events?.findIndex(
		(e) => e.type === eventType,
	);
	if (eventIndex || eventIndex === 0) {
		const subscribers =
			state.data?.events?.[eventIndex]?.notifications?.subscribers || [];
		const newSubs = [
			...reject<Subscriber>(
				(s) => s.email === subscriber.email,
				subscribers,
			),
			subscriber,
		];
		return assocPath<Array<Subscriber>, AccountState>(
			['data', 'events', eventIndex, 'notifications', 'subscribers'],
			newSubs,
			state,
		);
	}
	return state;
};

const deleteSubscriberForEventTypeReducer = (
	subscriber: Subscriber,
	eventType: EventType,
	state: AccountState,
): AccountState => {
	const eventIndex = state.data?.events?.findIndex(
		(e) => e.type === eventType,
	);
	if (eventIndex || eventIndex === 0) {
		const subscribers =
			state.data?.events?.[eventIndex]?.notifications?.subscribers || [];
		const newSubs = [
			...reject<Subscriber>(
				(s) => s.email === subscriber.email,
				subscribers,
			),
		];
		return assocPath<Array<Subscriber>, AccountState>(
			['data', 'events', eventIndex, 'notifications', 'subscribers'],
			newSubs,
			state,
		);
	}
	return state;
};

const setConditionValueForEventTypeReducer = (
	value: number,
	eventType: EventType,
	state: AccountState,
): AccountState => {
	const eventIndex = state.data?.events?.findIndex(
		(e) => e.type === eventType,
	);
	if (eventIndex || eventIndex === 0) {
		return assocPath<number, AccountState>(
			['data', 'events', eventIndex, 'condition', 'value'],
			value,
			state,
		);
	}
	return state;
};
