import React from 'react';
import style from './chart-tootltip.module.scss';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChartTooltip: React.FC<any> = (props) => {
    let sensorData = null;

    if (props.payload && props.payload.length > 0) {
        sensorData = props.payload[0];
    }

    const threshold = props.threshold || Infinity;

    const showBattery = !(props.hideBattery || false);

    if (props.active && sensorData) {
        const hasAlert = sensorData.payload.value >= threshold;
        return (
            <div className={classNames(style.ChartTooltip, { [style.hasAlert]: hasAlert })}>
                <div className={style.statusBar} />
                <div className={style.sensorTooltipContent}>
                    <span className={style.sensorFillStatus}>{Math.round(sensorData.payload.value)} %</span>
                    <div className={style.sensorDetails}>
                        <span className={style.sensorName}>
                            { moment.unix(sensorData.payload.created_on).format('MM/DD/YYYY, hh:mm A')}
                        </span>
                        { showBattery && sensorData?.payload?.b &&
                            <span className={style.sensorStatus}>
                                <Trans i18nKey='sensorDetails.sensorChart.chartTooltip.battery'/>: {sensorData.payload.b} %
                            </span>
                        }
                    </div>
                </div>
            </div>
        );
    }

    return null;
};
