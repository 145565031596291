import React, { ReactElement, RefObject } from 'react';
import style from './animated-list-container.module.scss';
import { ElementAnimationData, ElementAnimationDataHash } from './animated-list-container-types';

export const getAnimatedVisibleAndFadeOutElementsFunction = (
    visibleContainerChildren: Array<ReactElement>,
    visibleContainerRef: RefObject<HTMLDivElement>,
    hiddenContainerChildren: Array<ReactElement>,
    hiddenContainerRef: RefObject<HTMLDivElement>,
    fadeoutContainerRef: RefObject<HTMLDivElement>
) => (): [Array<ReactElement>, Array<ReactElement>] => {
    const fromContainerChildren = visibleContainerChildren;
    const fromContainerRef = visibleContainerRef;
    const toContainerChildren = hiddenContainerChildren;
    const toContainerRef = hiddenContainerRef;

    const tempAnimationDataHash = toContainerChildren.reduce<ElementAnimationDataHash>((acc, cur) => {
        const id = cur.props.id;
        const to = toContainerRef.current?.children.namedItem(id)?.getBoundingClientRect() || null;
        acc[id] = { element: cur, from: null, to }
        return acc;
    }, {});

    const animationDataHash = fromContainerChildren.reduce<ElementAnimationDataHash>((acc, cur) => {
        const id = cur.props.id;
        const from = fromContainerRef.current?.children.namedItem(id)?.getBoundingClientRect() || null;
        if (acc[id]) acc[id].from = from;
        else acc[id] = { element: cur, from, to: null };
        return acc;
    }, tempAnimationDataHash);

    const [newVisibleDataHash, newFadeOutDataHash] = Object.values(animationDataHash).reduce<[Array<ElementAnimationData>, Array<ElementAnimationData>]>((
        acc,
        cur
    ) => {
        const index = cur.to !== null ? 0 : 1;
        acc[index].push(cur);
        return acc;
    }, [[],[]]);

    const newVisibleContainerChildren = newVisibleDataHash.map(({ element, from, to }) => {
        const transform = from && to ? `translate(${from.x - to.x}px, ${from.y - to.y}px)` : undefined;
        const opacity = from === null ? 0 : undefined;
        const className = from && to ? style.translate : style.fadeIn;
        return (<div {...element.props} key={element.props.id} className={className}  style={{
            className,
            transform,
            opacity
        }}/>)
    });

    const { x: px, y: py } = fadeoutContainerRef.current?.getBoundingClientRect() || {x: undefined, y: undefined};
    const newFadeOutContainerChildren = newFadeOutDataHash.map(({element, from}) => {
        const x = from?.x;
        const y = from?.y;
        const left = x && px ? `${x - px}px` : undefined;
        const top = y && py ? `${y - py}px` : undefined;
        const width = from?.width ? `${from.width}px` : undefined;
        return (
            <div {...element.props} key={element.props.id} className={style.fadeOut} style={{
                top,
                left,
                width
            }}/>
        );
    })

    return [newVisibleContainerChildren, newFadeOutContainerChildren];
}