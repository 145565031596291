import React, { useEffect, useRef, useState } from 'react';
import style from './waste-analysis-wide-panel.module.scss';
import { useSelector } from 'react-redux';
import { clientIdSelector } from '../../../redux/auth/selectors';
import { Optional, RequestOptions } from '../../../redux/types';
import { API } from 'aws-amplify';
import config from '../../../config/config';
import { Bar, BarChart, CartesianGrid, Cell, Line, LineChart, Pie, PieChart, ResponsiveContainer, Sector, Tooltip, XAxis, YAxis } from 'recharts';
import _ from 'lodash';
import { ChartType, WasteType } from '../../../redux/account/types';
import { WasteLabel } from '../waste-label/waste-label';
import { convertHexToRGBA } from '../../../utils/string';
import { ActiveDot, ActiveDotProps } from '../../controls/recharts-dot/active-dot';
import { WasteChartTooltip } from '../../controls/waste-chart-tooltip/waste-chart-tooltip';
import { Box, capitalize } from '@material-ui/core';
import { avgFillChartSearchQuerySelector } from '../../../redux/dashboard/selectors';
import { Help } from '../../controls/help/help';
import { DateTime } from 'luxon';
import { BarChartOutlined as BarChartIcon, PieChartOutlined as PieChartIcon } from '@material-ui/icons';
import { CalendarInterval } from '../../mui/CalendarInterval';
import { barChartMockupData, wasteMockupData } from './data';

const TOOLTIP_OFFSET_Y = -15;

export const WasteAnalysisWidePanel: React.FC<{}> = () => {
  const clientId = useSelector(clientIdSelector);
  const [data, setData] = useState<any | null>(wasteMockupData);
  const [barChartData, setBarChartData] = useState<Array<any>>(barChartMockupData);
  const [selectedWasteType, setSelectedWasteType] = useState<WasteType>(WasteType.RECYCLABLE);
  const [selectedColor, setSelectedColor] = useState<string>("#46B490");
  const [chartType, setChartType] = useState<ChartType>(ChartType.BAR);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [calendarInterval, setCalendarInterval] = useState<string>("day");

  const tooltip = useRef<any>(null);
  const [tooltipX, setTooltipX] = useState<number>(0);
  const [tooltipY, setTooltipY] = useState<number>(0);

  const searchQuery = useSelector(avgFillChartSearchQuerySelector);
  const startTimestamp = searchQuery?.["created_on.gte"] || null;
  const endTimestamp = searchQuery?.["created_on.lt"] || null;

  const [domainStart, setDomainStart] =
    useState<Optional<number>>(startTimestamp);
  const [domainEnd, setDomainEnd] = useState<Optional<number>>(endTimestamp);

  useEffect(() => {
    if (startTimestamp && endTimestamp) {
      setDomainStart(startTimestamp);
      setDomainEnd(endTimestamp);
    }
  }, [startTimestamp, endTimestamp]);
  
  useEffect(() => {
    if (clientId && domainStart && domainEnd) {
      const options: RequestOptions = {
        queryStringParameters: {
          calendar_interval: calendarInterval,
          "created_on.gte": domainStart,
          "created_on.lt": domainEnd,
        },
      };

      API.get(config.API.name, "/search/all-waste-histogram", options)
        .then((data) => {
          setData(data);
          setBarChartData(
            _.map(
              _.remove(
                [
                  {
                    name: WasteType.RECYCLABLE,
                    stats: data.RECYCLABLE.stats,
                    color: "#46B490",
                    colorSecondary: "#E3F4EE",
                  },
                  {
                    name: WasteType.MIXED,
                    stats: data.MIXED.stats,
                    color: "#37383A",
                    colorSecondary: "#ebebeb",
                  },
                  {
                    name: WasteType.PLASTIC,
                    stats: data.PLASTIC.stats,
                    color: "#FAB635",
                    colorSecondary: "#fef4e1",
                  },
                  {
                    name: WasteType.GLASS,
                    stats: data.GLASS.stats,
                    color: "#4FAE49",
                    colorSecondary: "#e5f3e4",
                  },
                  {
                    name: WasteType.METALS,
                    stats: data.METALS.stats,
                    color: "#FC4939",
                    colorSecondary: "#ffe5e2",
                  },
                  {
                    name: WasteType.PAPER,
                    stats: data.PAPER.stats,
                    color: "#286DCC",
                    colorSecondary: "#d5e2f5",
                  },
                  {
                    name: WasteType.CLOTHING,
                    stats: data.CLOTHING.stats,
                    color: "#6628CC",
                    colorSecondary: "#E5DFF7",
                  },
                ],
                (o) => o.stats.sum > 0
              ),
              function (o: any) {
                return {
                  name: o.name.toLocaleLowerCase(),
                  stats: o.stats,
                  color: o.color,
                  colorSecondary: o.colorSecondary,
                };
              }
            )
          );
        })
        .catch((e) => console.error(e));
    }
  }, [clientId, domainStart, domainEnd, calendarInterval]);

  const barClickedHandler = (data: any, index: number) => {
    const bar = barChartData[index];
    setSelectedWasteType(bar.name.toUpperCase());
    setSelectedColor(bar.color);
    setActiveIndex(index);
    setSelectedIndex(index);
  };

  const barOnMouseOverHandler = (data: any, index: number) => {
    // setSelectedWasteType(data.payload.name.toUpperCase())
    // setSelectedColor(data.payload.color)
  };

  const showTooltip = (props: ActiveDotProps): void => {
    const { cx, cy } = props;

    // setTooltipWrapperStyle(activeTooltipWrapperStyle);
    if (tooltip && tooltip.current && tooltip.current.wrapperNode && cx && cy) {
      const tooltipRect = tooltip.current.wrapperNode.getBoundingClientRect();
      setTooltipX(cx - tooltipRect.width / 2);
      setTooltipY(cy - tooltipRect.height + TOOLTIP_OFFSET_Y);
    }
  };

  const XAxisTickLabel: React.FC<any> = (props) => {
    const { width, height, x, y, payload } = props;

    if (payload?.value && barChartData) {
      const o = _.find(barChartData, (o) => o.name === payload.value);
      const isCurrent = payload.value.toUpperCase() === selectedWasteType;

      return (
        <text
          fontWeight={isCurrent ? "bold" : "normal"}
          fontSize={12}
          width={width}
          height={height}
          x={x}
          y={y}
          dy={16}
          textAnchor="middle"
          fill={isCurrent ? o.color : "#A0A0A0"}
        >
          {capitalize(payload.value)}
        </text>
      );
    }

    return null;
  };

  const LineChartXAxisTickLabel: React.FC<any> = (props) => {
    const { width, height, x, y, payload } = props;

    if (payload?.value && Number.isInteger(payload.value) && barChartData) {
      return (
        <text
          fontWeight={"normal"}
          fontSize={12}
          width={width}
          height={height}
          x={x}
          y={y}
          dy={16}
          textAnchor="middle"
          fill="#A0A0A0"
        >
          {DateTime.fromMillis(payload.value).toFormat('LLLL dd')}
        </text>
      );
    }

    return null;
  };

  const BarValue: React.FC<any> = (props) => {
    const { width, height, x, y, value } = props;

    if (value) {
      return (
        <text
          cursor="pointer"
          onClick={() =>
            barClickedHandler({ payload: props.color }, props.index)
          }
          fontWeight="bold"
          fontSize={12}
          width={width}
          height={height}
          x={x + 30}
          y={y}
          dy={16}
          textAnchor="middle"
          fill="white"
        >
          {value}
        </text>
      );
    }
    return null;
  };

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {capitalize(payload.name)}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor}>
          <tspan fontSize={24} fontWeight="bold" fill={fill}>
            {value}
          </tspan>
          <tspan fontSize={14} fill="#858585">
            {" "}
            bag(s)
          </tspan>
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fontSize={10}
          fill="#858585"
        >
          <tspan fill="#858585" fontSize={10}>
            Rate:{" "}
          </tspan>
          <tspan fill="#000">{(percent * 100).toFixed(0)}</tspan>
          <tspan fill="#000">%</tspan>
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={36}
          textAnchor={textAnchor}
          fontSize={10}
          fill="#858585"
        >
          <tspan fill="#858585" fontSize={10}>
            Vol:{" "}
          </tspan>
          <tspan fill="#000"> {payload.stats.sum}</tspan>
          <tspan fill="#000"> ltr.</tspan>
        </text>
        {/*<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={52} textAnchor={textAnchor} fontSize={12} fill="#999">*/}
        {/*    {`(Avg ${payload.stats.avg.toFixed(0)} ltr.)`}*/}
        {/*</text>*/}
      </g>
    );
  };

  const onPieEnter = (data: any, index: number) => {
    setActiveIndex(index);
  };

  const onPieLeave = (data: any, index: number) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className={style.WasteCollectionPanel}>
      {barChartData && barChartData.length > 0 && (
        <div className={style.charts}>
          <Box className={style.chartTitle}>
            <Box display="flex" flexBasis="70%" alignItems="center">
              <span>Total volume per waste type (bags)</span>
              <Help>
                <h3>
                  Click on a bar to see detailed information about the selected
                  waste type.
                </h3>
                <p>Click on the icons on the right to change the chart type.</p>
              </Help>
            </Box>
            <Box textAlign="right" flexBasis="30%">
              <BarChartIcon
                cursor="pointer"
                color={chartType === ChartType.BAR ? 'primary' : 'secondary'}
                onClick={() => setChartType(ChartType.BAR)}
              />
              <PieChartIcon
                cursor="pointer"
                color={chartType === ChartType.PIE ? 'primary' : 'secondary'}
                onClick={() => setChartType(ChartType.PIE)}
              />
            </Box>
          </Box>
          {chartType === ChartType.PIE && (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={barChartData}
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  innerRadius={60}
                  outerRadius={100}
                  dataKey="stats.count"
                  onClick={barClickedHandler}
                  onMouseEnter={onPieEnter}
                  onMouseLeave={onPieLeave}
                >
                  {barChartData.map((entry: any, index: number) => (
                    <Cell
                      cursor="pointer"
                      key={`cell-${index}`}
                      fill={
                        entry.name.toUpperCase() === selectedWasteType
                          ? entry.color
                          : entry.colorSecondary
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
          {chartType === ChartType.BAR && (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={100}
                data={barChartData}
                margin={{
                  top: 25,
                  right: 0,
                  left: -15,
                  bottom: 60,
                }}
              >
                <XAxis
                  dataKey="name"
                  tickLine={false}
                  tick={<XAxisTickLabel />}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  width={70}
                  tickMargin={20}
                  allowDecimals={false}
                  tick={{ fontSize: 13, color: "#333333", fill: "#333333" }}
                />
                <CartesianGrid
                  stroke="#E7E7E7"
                  strokeWidth={1}
                  vertical={false}
                />
                <Bar
                  dataKey="stats.count"
                  onClick={barClickedHandler}
                  onMouseEnter={barOnMouseOverHandler}
                  barSize={60}
                  label={<BarValue />}
                >
                  {barChartData.map((entry: any, index: number) => (
                    <Cell
                      cursor="pointer"
                      key={`cell-${index}`}
                      fill={
                        entry.name.toUpperCase() === selectedWasteType
                          ? entry.color
                          : entry.colorSecondary
                      }
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}

          {data && (
            <div className={style.chart}>
              <div
                style={{
                  background: `linear-gradient(135deg, ${convertHexToRGBA(
                    selectedColor,
                    100
                  )} 0%, ${convertHexToRGBA(selectedColor, 30)} 100%)`,
                }}
                className={style.statusBar}
              />
              <Box className={style.content}>
                <Box>
                  <WasteLabel
                    className={style.wasteLabel}
                    type={selectedWasteType}
                  >
                    {selectedWasteType.toLocaleLowerCase()}
                  </WasteLabel>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <div
                        className={style.chartTitle}
                      >{`Collected volume histogram (${calendarInterval} interval)`}</div>
                    </Box>
                    <Box>
                      <CalendarInterval
                        label="Interval"
                        value={calendarInterval}
                        onChange={(e) => setCalendarInterval(e.target.value)}
                      />
                    </Box>
                  </Box>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      width={800}
                      height={400}
                      data={data.histogram.buckets}
                      margin={{
                        top: 25,
                        right: 0,
                        left: -15,
                        bottom: 2,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="waste-stroke-gradient"
                          x1="0"
                          y1="0"
                          x2="100%"
                          y2="0"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop
                            offset="0%"
                            stopColor={convertHexToRGBA(selectedColor, 30)}
                            stopOpacity={0.3}
                          />
                          <stop
                            offset="40%"
                            stopColor={convertHexToRGBA(selectedColor, 70)}
                            stopOpacity={1}
                          />
                          <stop
                            offset="100%"
                            stopColor={convertHexToRGBA(selectedColor, 100)}
                            stopOpacity={0.3}
                          />
                        </linearGradient>
                      </defs>
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        width={70}
                        tickMargin={20}
                        allowDecimals={false}
                        tick={{
                          fontSize: 13,
                          color: "#333333",
                          fill: "#333333",
                        }}
                      />
                      <XAxis
                          dataKey="key"
                          tickLine={false}
                          tick={<LineChartXAxisTickLabel />}
                      />
                      <CartesianGrid
                        stroke="#E7E7E7"
                        strokeWidth={1}
                        vertical={false}
                      />
                      <Line
                        type="monotoneX"
                        dataKey={`${selectedWasteType}.stats.count`}
                        stroke="url(#waste-stroke-gradient)"
                        strokeWidth={4}
                        fillOpacity={1}
                        activeDot={
                          <ActiveDot
                            onRender={showTooltip}
                            fill={selectedColor}
                          />
                        }
                        dot={false}
                      />
                      <Tooltip
                        cursor={false}
                        wrapperStyle={{
                          opacity: 1,
                          transition: "opacity 300ms ease-in-out",
                        }}
                        allowEscapeViewBox={{ x: true, y: true }}
                        position={{ x: tooltipX, y: tooltipY }}
                        isAnimationActive={false}
                        content={
                          <WasteChartTooltip
                            color={selectedColor}
                            wasteType={selectedWasteType}
                          />
                        }
                        ref={tooltip}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
