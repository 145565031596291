import { createMuiTheme } from '@material-ui/core';
import { transparent1X1 } from 'aws-amplify-react';

export const blackMainColor = '#000000'
export const blackLightColor = '#3A3A3A'

export const errorMainColor = '#a74040'
export const errorLightColor = '#DA6464'

export const primaryMainColor = "#00C391"
export const primaryLightColor = "#4DD5B2"

export const secondaryMainColor = '#ECECEC'
export const secondaryLightColor = '#F5F5F5'

export const whiteColor = '#ffffff'

export const boxShadow = '0 4px 8px rgb(51 51 51 / 20%)'

export const robotoRegular = '"Roboto-Regular","Roboto","Segoe UI",Arial,sans-serif'
export const robotoMedium = '"Roboto-Medium","Roboto","Segoe UI",Arial,sans-serif'
export const robotoBold = '"Roboto-Bold","Roboto","Segoe UI",Arial,sans-serif'
export const robotoBlack = '"Roboto-Black","Roboto","Segoe UI",Arial,sans-serif'

const theme = createMuiTheme();

export default createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  palette: {
    primary: {
      main: primaryMainColor,
      light: primaryLightColor
    },
    secondary: {
      main: '#aaa',
      light: '#aaa'
    },
    error: {
      light: errorLightColor,
      main: errorMainColor
    }
  },
  overrides: {
    MuiChip: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '40px',
        borderRadius: 20,
        border: 'none',
        backgroundColor: 'white',
        boxShadow: boxShadow,
        fontFamily: robotoRegular,
        fontStyle: 'normal',
        color: '#333',
        fontWeight: 'bold',
        fontSize: 12,
        lineHeight: '14px',
        '& .MuiChip-icon': {
          marginLeft: 16,
        },
        '& .MuiChip-deleteIcon': {
          marginRight: 16
        }
      },
      deleteIcon: {
        color: primaryMainColor
      },
      label: {
        flexGrow: 1
      },
      deletable: {
        transition: '300ms ease-in-out',
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          transform: 'scale(1.05)'
        }
      }
    },
    MuiStepper: {
      root: {
        padding: 0,
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginBottom: theme.spacing(4)
      }
    },
    MuiStepIcon: {
      root: {
        width: 30,
        height: 30,
        color: secondaryLightColor,
      },
      text: {
        fontFamily: robotoRegular,
        fontWeight: 'bold',
        fontSize: 12
      },
      active: {
        color: `${primaryLightColor} !important`
      },
      completed: {
        color: `${primaryLightColor} !important`
      }
    },
    MuiStepLabel: {
      label: {
        '&.MuiTypography-body2': {
          fontFamily: robotoRegular
        },
        '&.MuiStepLabel-active': {
          fontWeight: 900,
        }
      }
    },
    MuiDialog: {
      paperWidthXs: {
        width: 400
      },
      paperWidthSm: {
        width: 430
      },
      paperWidthMd: {
        width: 600
      }
    },
    MuiDialogTitle: {
      root: {
        padding: theme.spacing(3),
        paddingBottom: 0,
        '& .MuiTypography-h6': {
          fontSize: 23,
          lineHeight: 1
        }
      }
    },
    MuiDialogContent: {
      root: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(1),
        paddingBottom: 0,
      }
    },
    MuiDialogActions: {
      root: {
        display: 'block',
        padding: theme.spacing(3),
      }
    },
    MuiPaper: {
      root: {
        transitionDuration: '200ms',
        transitionProperty: 'all',
        transitionTimingFunction: 'ease-in-out'
      },
      rounded: {
        borderRadius: '15px'
      }
    },
    MuiMenu: {
      paper: {
        minWidth: 200,
        backgroundColor: '#FCFCFC',
        borderRadius: "8px",
        boxShadow: '0px 4px 8px rgba(51, 51, 51, 0.15)'
      }
    },
    MuiList: {
      padding: {
        paddingBottom: 0,
        paddingTop: 0
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 12,
        color: '#000',
        fontFamily: robotoMedium,
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 20,
        paddingLeft: 20,
        borderBottom: '1px solid',
        borderImageSource: 'linear-gradient(90deg ,transparent,hsla(0,0%,84.7%,.2) 24px,#d8d8d8 50%,hsla(0,0%,84.7%,.2) calc(100% - 24px),transparent)',
        borderImageSlice: 1,
        '&:last-child': {
          borderBottom: 'none',
        },
        '&.error': {
          '&:hover': {
            backgroundColor: errorLightColor,
            '& .MuiTypography-root': {
              color: `${whiteColor} !important`
            }
          }
        }
      }
    },
    MuiListItemIcon: {
      root: {
        color: blackMainColor,
        minWidth: 40
      }
    },
    MuiListItemText: {
      root: {
        color: blackMainColor
      }
    },
    MuiTypography: {
      root: {
        color: blackMainColor,
        fontStyle: 'normal',
      },
      body1: {
        fontSize: 12,
        lineHeight: '14px'
      },
      body2: {
        fontFamily: robotoMedium
      },
      h1: {
        fontFamily: robotoBlack,
        fontWeight: 900,
        fontSize: 26,
        lineHeight: 1
      },
      h2: {
        fontFamily: robotoBlack,
        fontWeight: 900,
        fontSize: 24,
      },
      h3: {
        fontSize: 20,
        fontWeight: 'bold',
      },
      h4: {
        fontSize: 16,
        fontWeight: 'bold',
      },
      h5: {
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 'bold',
      },
      h6: {
        fontSize: 12,
        fontWeight: 'bold',
      },
      caption: {
        fontSize: 12,
        lineHeight: '14px',
        color: 'rgba(0, 0, 0, 0.25)'
      },
      subtitle1: {
      
      },
      subtitle2: {
        fontSize: 10
      }
    },
    MuiTextField: {
      root: {
        "& .Mui-error": {
          color: errorMainColor
        },
        "& .MuiOutlinedInput-root.Mui-error": {
          "& fieldset": {
            borderColor: errorMainColor,
          },
        }
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 20,
        // backgroundColor: '#f5f5f5'
      },
      input: {
        // borderRadius: 20,
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: 10,
        lineHeight: 1.5,
        marginTop: 8,
        '&.Mui-error': {
          color: errorMainColor
        }
      },
      contained: {
        marginLeft: 24
      }
    },
    MuiInputAdornment: {
      root: {
        "& .MuiTypography-body1": {
          fontSize: 10
        }
      },
    },
    MuiInputLabel: {
      filled: {
        transform: 'translate(25px, 20px) scale(1)',
        color: blackMainColor,
        '&.MuiInputLabel-shrink': {
          transform: 'translate(25px, 8px) scale(0.75)',
          backgroundImage: 'linear-gradient(258.19deg,#00c391 -.78%,#24c6dc 99.6%)',
          backgroundClip: 'text',
          "-webkit-background-clip": 'text',
          "-webkit-text-fill-color": 'transparent',
          fontWeight: 800,
        }
      }
    },
    MuiInputBase: {
      input: {
        '&.Mui-disabled': {
          opacity: .5
        }
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '88px',
        borderTopLeftRadius: '88px',
        borderTopRightRadius: '88px',
        transitionDuration: '300ms',
        transitionProperty: 'all',
        transitionTimingFunction: 'ease-in-out',
        backgroundColor: secondaryLightColor,
        '&:hover': {
          backgroundColor: whiteColor,
          boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.07)'
        },
        '&.Mui-focused': {
          backgroundColor: whiteColor,
          boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.07)'
        },
        '&.Mui-disabled': {
          backgroundColor: secondaryLightColor,
          '&:hover': {
            boxShadow: 'none'
          }
        },
        '& .MuiInputBase-input': {
          color: blackMainColor,
          fontWeight: 600,
          fontSize: 16,
          lineHeight: '21px',
          letterSpacing: '0.004em',
        }
      },
      input: {
        padding: '29px 25px 8px'
      },
      underline: {
        '&:before, &:after, &.Mui-disabled:before': {
          borderBottom: 'none',
          borderBottomStyle: 'none'
        },
        '&:hover': {
          '&:before': {
            borderBottom: 'none'
          }
        }
      }
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "none",
        },
      },
      label: {
        fontFamily: robotoMedium,
        fontSize: 15,
        padding: 0,
        fontWeight: 'normal',
        letterSpacing: '.1em'
      },
      contained: {
        color: whiteColor,
        backgroundColor: blackMainColor,
        boxShadow: 'none',
        minWidth: 150,
        "&:hover": {
          boxShadow: 'none',
          backgroundColor: '#3A3A3A'
        }
      },
      containedPrimary: {
        backgroundColor: primaryMainColor,
        color: whiteColor,
        "&:hover": {
          backgroundColor: primaryLightColor
        }
      },
      containedSecondary: {
        backgroundColor: secondaryLightColor,
        color: '#333333',
        '&:hover': {
          backgroundColor: secondaryMainColor
        }
      }
    },
    MuiAvatar: {
      root: {
        fontSize: "14px",
        fontWeight: "bold",
        transition: "300ms ease-in-out",
        textTransform: 'uppercase'
      },
      colorDefault: {
        backgroundColor: "#D8D8D8",
        color: "white",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "14px",
        color: secondaryMainColor,
        lineHeight: "1.5",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
      label: {
        fontFamily: robotoBold,
        fontWeight: 900,
        fontSize: "14px",
        color: blackLightColor,
        lineHeight: "18px",
      },
    },
    MuiFab: {
      root: {
        boxShadow: 'none'
      },
      secondary: {
        backgroundColor: secondaryLightColor,
        '&:hover': {
          backgroundColor: '#c6c6c6',
        }
      }
    },
    MuiRadio: {
      root: {
        color: secondaryMainColor
      }
    }
  },
});
