import React, { PropsWithChildren } from 'react';
import style from './help.module.scss';
import { IconButton, withStyles } from '@material-ui/core';
import { ReactComponent as HelpIcon } from '../../../assets/img/icons/icon.help.svg';
import { contentTypes, LightTooltip } from '../light-tooltip/light-tooltip';

const StyledIconButton = withStyles(() => ({
  root: {
    boxShadow: "none",
    padding: "0px",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#00C391",
      boxShadow: "none",
    },
  },
}))(IconButton);

export interface HelpAttributes {
  children: NonNullable<React.ReactNode>;
  contentType?: contentTypes;
}

export type HelpProps = PropsWithChildren<HelpAttributes>;

export const Help: React.FC<HelpProps> = (props: HelpProps) => {
  return (
    <div className={style.Help}>
      <LightTooltip title={props.children} contentType={props.contentType}>
        <StyledIconButton aria-label="help">
          <HelpIcon />
        </StyledIconButton>
      </LightTooltip>
    </div>
  );
};
