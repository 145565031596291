import React, { MouseEvent, useState } from 'react';
import style from './sensor-tile.module.scss';
import classNames from 'classnames';
import { ReactComponent as PinIcon } from '../../../assets/img/icons/icon.pin.svg';
import { ReactComponent as BellCrossedIcon } from '../../../assets/img/icons/icon.bell-crossed.svg';
import { useAccess } from '../../../hooks/access';
import { BatteryLevelDisplay } from '../battery-level-display/battery-level-display';
import { SignalStrengthDisplay } from '../signal-strength-display/signal-strength-display';
import { DateTime } from 'luxon';
import { LightTooltip } from '../light-tooltip/light-tooltip';

const GOOD_SIGNAL_THRESHOLD = 20;

export interface SensorTileProps {
    name?: string;
    sensorId: string;
    binTypeName?: string;
    signalStatus?: string;
    batteryStatus?: number | string;
    fillStatus?: number;
    lastReport?: number;
    threshold?: number;
    isPinned?: boolean;
    battery?: number;
    signal?: number;
    muted?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onPinClick?: (isPinned: boolean) => void;
    selectable?: boolean;
}

export const SensorTile: React.FC<SensorTileProps> = ({
    fillStatus,
    lastReport,
    threshold = Infinity ,
    battery = 100,
    signal = 31,
    muted,
    isPinned = false,
    selectable = false,
    ...props
}) => {
    let isNotResponding = false
    const NOT_REPOSNDING_THRESHOLD = 24

    if(lastReport) {
        const date1 = DateTime.fromSeconds(lastReport)
        const date2 = DateTime.now()

        const diff = date1.diff(date2, ["hours"])

        if(diff.hours < -NOT_REPOSNDING_THRESHOLD) {
            isNotResponding = true
        }
    }

    const breaksThreshold = fillStatus && fillStatus >= threshold;

    const isConnected: boolean = lastReport !== undefined;

    const hasPinningAccess = useAccess('ADMIN');
    const pinOnClickHandler = (event: MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation();
        if (hasPinningAccess) {
            setPinState(!pinState);
            props.onPinClick && props.onPinClick(!pinState);
        }
    }

    const [pinState, setPinState] = useState<boolean>(isPinned);

    return (
        <div
            className={classNames(style.SensorTile,
                {
                    [style.hasAlert]: breaksThreshold,
                    [style.isPinned]: pinState,
                    [style.isConnected]: isConnected,
                    [style.notResponding]: isNotResponding,
                    [style.muted]: muted })}
            onClick={props.onClick && props.onClick}
        >
            <div className={style.statusBar} />
            <div className={style.sensorIdentity}>
                <div className={style.sensorIdentityData}>
                    <div className={style.sensorName}>
                        {props.name && props.name !== '' ? props.name : props.sensorId}
                        <em>.</em>
                    </div>
                    <div className={style.sensorType}>
                        <span className={style.sensorTypeName}>{props.binTypeName}</span>
                    </div>
                </div>
                {
                    !selectable &&
                    <div className={style.sensorPinButton} onClick={pinOnClickHandler}>
                        <LightTooltip
                            title={""}
                            contentType="pin"
                            enterDelay={1000}
                            enterNextDelay={200}
                        >
                            <PinIcon/>
                        </LightTooltip>
                    </div>
                }
            </div>
            <div className={style.sensorLocation}>
                <div className={style.sensorFillStatus}>
                    { fillStatus && <span>{fillStatus}<span className={style.sensorFillStatusUnit}>%</span></span> }
                </div>
                <div className={style.sensorAddress}>
                    {
                        (battery && battery <= 75) &&
                        <BatteryLevelDisplay percentage={battery} />
                    }

                    {
                        (signal && signal < GOOD_SIGNAL_THRESHOLD) &&
                        <SignalStrengthDisplay signal={signal}/>
                    }
                    {
                        muted &&
                        <LightTooltip
                            title={
                                <>
                                    <p>{"Sensor is muted"}</p>
                                </>
                            }
                            severity="error"
                        >
                            <BellCrossedIcon />
                        </LightTooltip>
                    }
                </div>
            </div>
        </div>
    );
};
