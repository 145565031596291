import { UserAsyncDispatch } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadUsersData, setUsers } from './actions';
import { clientIdSelector } from '../auth/selectors';
import { useLoadingBarDispatch } from '../loading-bar/effects';
import { RequestOptions } from '../types';

export const useUsersData = (): void => {
	const clientId = useSelector(clientIdSelector);
	const dispatch = useDispatch();
	const userAsyncDispatch = useDispatch<UserAsyncDispatch>();
	const [incRC, decRC] = useLoadingBarDispatch();
	useEffect(() => {
		if (clientId) {
			incRC();
			dispatch(setUsers(null));

			const query = {
				limit: 60,
			};

			const options: RequestOptions = {
				queryStringParameters: query,
			};

			userAsyncDispatch(loadUsersData(options))
				.then(() => decRC())
				.catch(() => decRC());
		}
		//eslint-disable-next-line
	}, [clientId]);
};
