import AddIcon from '@material-ui/icons/Add';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import style from './customers.module.scss';
import { API } from 'aws-amplify';
import { CellGroup, FlexibleRowCell, HeaderRowCell, HighlightedCellGroup, IconRowCell, OptionsRowCell, Row } from '../../controls/row/row';
import { ReactComponent as EllipsisIcon } from '../../../assets/img/icons/icon.ellipsis.svg';
import { Customer, Optional } from '../../../redux/types';
import { CustomerModal } from './customer-modal';
import { FlyOver, FlyOverOption } from '../../controls/fly-over/fly-over';
import { useClientRefreshCallback } from '../../../redux/auth/effects';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';
import config from '../../../config/config';
import { InterfaceFrame } from '../../building-blocks/interface-frame/interface-frame';
import { Box, Fab, Typography } from '@material-ui/core';
import { ListSkeleton } from '../../mui/skeletons/ListSkeleton';
import { LightTooltip } from '../../controls/light-tooltip/light-tooltip';
import { LidbotListHeader } from '../../mui/LidbotListHeader';
import { Modal } from '../../controls/modal/modal';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';
import { DeleteModal } from '../../controls/modal/deleteModal';
import { AddFab } from '../../mui/AddFab';

export const Customers: React.FC = () => {
    useClientRefreshCallback(() => {
        setSelectedCustomer(null);
        setDeleteModalCustomer(null);
    });
    
    const [selectedCustomer, setSelectedCustomer] =
        useState<Optional<Customer>>(null);
    const [customers, setCustomers] = useState<Array<Customer>>([]);
    
    useEffect(() => {
        API.get(config.API.name, '/customers', {}).then((res) => {
            setCustomers(res.data as Customer[]);
        });
    }, []);
    
    const selectCustomerOnClickHandler = (c: Customer) => () => {
        setTimeout(() => {
            setSelectedCustomer(c);
        }, 0);
    };
    
    const onCreateCustomerHandler = (customer: Customer): void => {
        const filteredCustomers = customers
            ? customers.filter((c) => c.customer_id !== customer.customer_id)
            : [];
        
        const newCustomers = [...filteredCustomers, customer];
        
        setCustomers(newCustomers);
        
        setDisplayedModal('none');
    };
    
    const onDeleteCustomerHandler = (): void => {
        deleteModalCustomer &&
        API.del(
            config.API.name,
            `/customers/${deleteModalCustomer?.customer_id}`,
            {}
        )
            .then(() => {
                const newCustomers = customers
                    ? customers.filter(
                        (c) => c.customer_id !== deleteModalCustomer.customer_id
                    )
                    : [];
                setCustomers(newCustomers);
                setDeleteModalCustomer(null);
                setDisplayedModal('none');
                notifySuccess('Customer deleted');
            })
            .catch((e) => {
                notifyError('Customer not deleted', notifyErrorMessage(e));
                console.error(e);
            });
    };
    
    type CustomersSettingsModal =
        | 'none'
        | 'customer-settings'
        | 'delete-customer';
    const [displayedModal, setDisplayedModal] =
        useState<CustomersSettingsModal>('none');
    
    const [settingsModalCustomer, setSettingsModalCustomer] = useState<Customer>();
    
    const displaySettingModalOnClickHandler = (c?: Customer) => () => {
        setSelectedCustomer(null);
        setSettingsModalCustomer(c);
        setDisplayedModal('customer-settings');
    };
    
    const [deleteModalCustomer, setDeleteModalCustomer] =
        useState<Optional<Customer>>(null);
    const displayDeleteModalOnClick = (c: Customer) => () => {
        setSelectedCustomer(null);
        setDeleteModalCustomer(c);
        setDisplayedModal('delete-customer');
    };
    
    const getRow = (customer: Customer): ReactElement => {
        const flyOverOptions: Array<FlyOverOption> = [
            {
                label: 'Edit',
                onClick: displaySettingModalOnClickHandler(customer)
            }
        ];
        
        const destructiveOption: FlyOverOption = {
            label: 'Delete',
            onClick: displayDeleteModalOnClick(customer)
        };
        
        return (
            <Row
                key={customer.customer_id}
                className={style.row}
                selected={selectedCustomer?.customer_id === customer.customer_id}
                onDeselect={() => setSelectedCustomer(null)}
            >
                <IconRowCell className={style.iconCell}>
                </IconRowCell>
                <HighlightedCellGroup>
                    <FlexibleRowCell className={style.nameCell}>
                        <Typography variant='h4'>{customer.account_name}</Typography>
                        <Typography color='secondary' variant='caption'>{customer.customer_id}</Typography>
                    </FlexibleRowCell>
                    <FlexibleRowCell className={style.timezoneCell}>
                        <Typography variant="h6">{customer.locale?.timezone || ''}</Typography>
                    </FlexibleRowCell>
                </HighlightedCellGroup>
                <OptionsRowCell
                    className={style.optionsCell}
                    onClick={selectCustomerOnClickHandler(customer)}
                    flyover={
                        <FlyOver
                            options={flyOverOptions}
                            destructiveOption={destructiveOption}
                        />
                    }
                    roleHierarchy='LIDBOT_SUPPORT'
                >
                    <EllipsisIcon />
                </OptionsRowCell>
            </Row>
        );
    };
    
    return (
        <InterfaceFrame>
            <Box
                display='flex'
                alignItems='center'
                marginBottom={4}
            >
                <Box marginRight={2}>
                    <Typography variant='h1'>Customers</Typography>
                </Box>
                <Box>
                    <AddFab onClick={displaySettingModalOnClickHandler()} title='Add customer'/>
                </Box>
            </Box>
            <Box className={style.CustomersSettings}>
                <Box className={style.content}>
                    {displayedModal === 'customer-settings' && (
                        <CustomerModal
                            customer={settingsModalCustomer}
                            onClose={() => setDisplayedModal('none')}
                            onCreate={(customer) => onCreateCustomerHandler(customer)}
                        />
                    )}
                    <DeleteModal
                        isOpen={displayedModal === 'delete-customer'}
                        onClose={() => setDisplayedModal('none')}
                        onDelete={onDeleteCustomerHandler}
                        objectClass="Customer"
                        objectName={deleteModalCustomer?.account_name}
                    />
                    <Row className={style.header} rowClassName='header'>
                        <HeaderRowCell className={style.iconCell} rowCellClassName='icon' />
                        <CellGroup>
                            <HeaderRowCell
                                className={style.nameCell}
                                rowCellClassName='flexible'
                                hasBorder={true}
                            >
                                Name
                            </HeaderRowCell>
                            <HeaderRowCell
                                className={style.timezoneCell}
                                rowCellClassName='flexible'
                                hasBorder={true}
                            >
                                Timezone
                            </HeaderRowCell>
                        </CellGroup>
                        <HeaderRowCell
                            className={style.optionsCell}
                            rowCellClassName='options'
                        />
                    </Row>
                    {
                        customers.length > 0 ? (
                            customers.map((customer) => getRow(customer))
                        ) : (
                            <ListSkeleton />
                        )
                    }
                </Box>
            </Box>
        </InterfaceFrame>
    );
};
