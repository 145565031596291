import React, { ReactElement } from 'react';
import style from './notification-center.module.scss';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsSelector } from '../../../redux/notifications/selectors';
import { NotificationRow } from './notification-row';
import { NotificationAsyncDispatch, NotificationResponse } from '../../../redux/notifications/types';
import { useNotificationData } from '../../../redux/notifications/effects';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { deleteNotification } from '../../../redux/notifications/actions';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { Box } from '@material-ui/core';

export const NotificationCenter: React.FC<{}> = () => {
  const history = useHistory();
  
  const [incRC, decRC] = useLoadingBarDispatch();
  useNotificationData();
  const notificationResponses = useSelector(notificationsSelector);

  const groupedNotifications = _.groupBy(
    notificationResponses,
    function (notification) {
      return DateTime.fromSeconds(notification.created_on).toISODate();
    }
  );

  const notificationsContent = (groupedNotifications: any): ReactElement => {
    const days: any = [];

    let hasYesterdaysNotifications = false;
    let hasAnyOldNotifications = false;

    _.forOwn(
      groupedNotifications,
      function (value: Array<NotificationResponse>, key: string) {
        let header;
        let isOld = false;

        if (DateTime.fromISO(key).hasSame(DateTime.local(), "day")) {
          header = "today";
        } else if (
          DateTime.fromISO(key).hasSame(
            DateTime.local().minus({ days: 1 }),
            "day"
          )
        ) {
          header = "yesterday";
          hasYesterdaysNotifications = true;
        } else {
          header = DateTime.fromISO(key).toLocaleString(DateTime.DATE_HUGE);
          hasAnyOldNotifications = true;
          isOld = true;
        }

        days.push({
          day: key,
          header: header,
          old: isOld,
          notifications: value,
        });
      }
    );

    const sortedDays = _.sortBy(days, function (day) {
      return day.day;
    }).reverse();

    return (
      <Box>
        {_.map(sortedDays, function (day) {
          if (
            ((day.header === "today" && !hasYesterdaysNotifications) ||
              day.header === "yesterday") &&
            hasAnyOldNotifications
          ) {
            return (
              <Box>
                {notificationSection(day)}
                <Box className={style.divider}>
                  <Box className={style.left} />
                  <h2>previous notifications</h2>
                  <Box className={style.right} />
                </Box>
              </Box>
            );
          } else {
            return notificationSection(day);
          }
        })}
      </Box>
    );
  };

  const notificationSection = (day: any): ReactElement => {
    return (
      <Box
        key={day.day}
        className={classNames(style.section, { [style.old]: day.old })}
      >
        <h2>{day.header}</h2>
        {day.notifications.map((notificationResponse: NotificationResponse) =>
          notificationRow(notificationResponse, day.old)
        )}
      </Box>
    );
  };

  const notificationRow = (
    notificationResponse: NotificationResponse,
    old: boolean
  ): ReactElement => {
    return (
      <NotificationRow
        key={notificationResponse.notification_id}
        notificationId={notificationResponse.notification_id}
        sensorId={notificationResponse.sensor_id}
        sensorFillPercentage={notificationResponse.sensor_fill_percentage}
        sensorNickname={notificationResponse.sensor_nickname}
        eventType={notificationResponse.event_type}
        read={notificationResponse.read}
        archived={notificationResponse.archived}
        createdOn={notificationResponse.created_on}
        old={old}
        onLinkClick={() =>
          notificationLinkClickHandler(notificationResponse.sensor_id)
        }
        onDeleteClick={() =>
          notificationDeleteClickHandler(notificationResponse)
        }
      />
    );
  };

  const notificationLinkClickHandler = (sensorId?: string): void => {
    history.push(`/sensors/${sensorId}`);
  };

  const notificationsAsyncDispatch = useDispatch<NotificationAsyncDispatch>();
  const notificationDeleteClickHandler = (
    notificationResponse: NotificationResponse
  ): void => {
    incRC();
    notificationsAsyncDispatch(deleteNotification(notificationResponse))
      .then(() => {
        decRC();
      })
      .catch(() => decRC());
  };

  return (
    <Box className={style.NotificationCenter}>
      <Box m={3} className={style.head}>
        <h1>News</h1>
      </Box>
      <Box pt={3} className={style.content}>
        {Object.keys(groupedNotifications).length > 0 &&
          notificationsContent(groupedNotifications)}
        {Object.keys(groupedNotifications).length === 0 && (
          <Box className={style.message}>
            {"You don't have any notifications"}
          </Box>
        )}
      </Box>
    </Box>
  );
};
