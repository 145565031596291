import React from 'react';
import style from './notification-row.module.scss';
import { ReactComponent as BinIcon } from './../../../assets/img/icons/icon.bin.full.svg';
import { ReactComponent as BinIconDisabled } from './../../../assets/img/icons/icon.bin.full.disabled.svg';
import { ReactComponent as DeleteIcon } from './../../../assets/img/icons/icon.delete-red.svg';
import { ReactComponent as LinkIcon } from './../../../assets/img/icons/icon.link.svg';
import { ReactComponent as BatteryLowIcon } from './../../../assets/img/icons/icon.battery.low.svg';
import { ReactComponent as BinChangedIcon } from './../../../assets/img/icons/icon.chaged-bin.svg';
import { ReactComponent as BinChangedIconDisabled } from './../../../assets/img/icons/icon.chaged-bin.disabled.svg';
import { ReactComponent as BatteryLowIconDisabled } from './../../../assets/img/icons/icon.battery.low.disabled.svg';
import { EventType } from '../../../redux/account/types';
import { DateTime } from 'luxon';
import classNames from 'classnames';

interface NotificationRowProps {
  notificationId: string;
  sensorId?: string;
  sensorFillPercentage?: number;
  sensorNickname?: string;
  eventType?: EventType;
  read: boolean;
  archived: boolean;
  createdOn: number;
  old: boolean;
  onLinkClick?: () => void;
  onDeleteClick?: () => void;
}

export const NotificationRow: React.FC<NotificationRowProps> = ({
  notificationId,
  sensorId,
  sensorFillPercentage,
  sensorNickname,
  eventType,
  read,
  archived,
  createdOn,
  old,
  onLinkClick,
  onDeleteClick,
}) => {
  const time = DateTime.fromSeconds(createdOn);
  let description;
  let icon;

  switch (eventType) {
    case "Notifications_BinFull":
      description = (
        <span>
          is {sensorFillPercentage}% full - ready for
          <b> pick-up!</b>
        </span>
      );

      icon = old ? <BinIconDisabled /> : <BinIcon />;
      break;
    case "Notifications_LowBatteryLevel":
      description = <span>running low on battery - please swap!</span>;
      icon = old ? <BatteryLowIconDisabled /> : <BatteryLowIcon />;
      break;
    case "Notifications_BinChanged":
      description = <span>emptied</span>;
      icon = old ? <BinChangedIconDisabled /> : <BinChangedIcon />;
  }

  return (
    <div className={classNames(style.NotificationRow, { [style.read]: read })}>
      <div className={style.icon}>{icon}</div>
      <div className={style.col1}>
        <h3>{sensorNickname ? sensorNickname : sensorId}</h3>
        <span>{description}</span>
      </div>
      <div className={style.time}>
        <div>{time.toLocaleString(DateTime.TIME_SIMPLE)}</div>
      </div>
      <div className={style.options}>
        {sensorId && <LinkIcon onClick={onLinkClick} />}
        <DeleteIcon onClick={onDeleteClick} />
      </div>
    </div>
  );
};
