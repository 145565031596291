import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';
import style from './password-reset-form.module.scss';
import { Box, Link, TextField, Typography } from '@material-ui/core';
import { MuiButton } from '../../mui/MuiButton';

interface PasswordResetFormProps {
    onSubmit?: (password: string) => void;
}

export const MIN_PASSWORD_LENGTH = 8

export const PasswordResetForm: React.FC<PasswordResetFormProps> = (props) => {
    const { username, code } = useParams() as any;
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<string>();
    
    const [inProgress, setInProgress] = useState<boolean>(false);
    
    useEffect(() => {
        if(password) {
            setPasswordError('')
        }
    }, [password])
    
    const formSubmitHandler = (event?: React.FormEvent<HTMLFormElement>): void => {
        event && event.preventDefault();
    
        let validationFailed = false
    
        if(!password) {
            setPasswordError('Password required')
            validationFailed = true
        }
        
        if(password && password.length < MIN_PASSWORD_LENGTH) {
            setPasswordError(`Please enter at least ${MIN_PASSWORD_LENGTH} characters.`)
            validationFailed = true
        }
    
        if(validationFailed) {
            return
        }
    
        setInProgress(true)
        
        if (username && code) {
            Auth.forgotPasswordSubmit(username, code, password)
                .then(() => {
                    setInProgress(false)
                    props.onSubmit && props.onSubmit(password);
                    notifySuccess(
                        'Password updated',
                        'Your password has been updated successfully.'
                    );
                })
                .catch((e) => {
                    setInProgress(false)
                    notifyError('Something went wrong', notifyErrorMessage(e));
                    console.error(e);
                });
        }
    };
    
    return (
        <div className={style.PasswordResetForm}>
            <div className={style.headline}>
                <Trans i18nKey='passwordReset.passwordForm.headline' />
            </div>
            
            <div className={style.subline}>
                {t('passwordReset.passwordForm.subline')}
            </div>
            
            <div className={style.text}>
                <Trans i18nKey='passwordReset.passwordForm.text' />
            </div>
            
            <form onSubmit={formSubmitHandler}>
                <Box marginBottom={2}>
                    <TextField
                        type='password'
                        label={t('passwordReset.formLabels.newPassword')}
                        value={password}
                        variant='filled'
                        fullWidth
                        error={!!passwordError}
                        helperText={passwordError ? passwordError : ' '}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Box>
                <Box marginBottom={2}>
                    <MuiButton onClick={formSubmitHandler} label='Save & Login' fullWidth inProgress={inProgress} />
                </Box>
                <Box textAlign="right">
                    <Typography variant="caption" color="secondary">
                        Go back to Smartbin
                        <Link style={{
                            marginLeft: 3
                        }} color="primary" href="/login">login</Link>
                    </Typography>
                </Box>
            </form>
        </div>
    );
};
