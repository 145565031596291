import React, { ReactElement, useState } from 'react';
import { Box, createStyles, DialogActions, Link, makeStyles, Step, StepLabel, Stepper, Theme, Typography } from '@material-ui/core';
import { MuiButton } from './MuiButton';
import _ from 'lodash'


export interface MuiStepperProps {
  steps: Step[]
  contentHeight?: number
}

export interface Step {
  label: string
  content: ReactElement
  onComplete?: (handler: (success: boolean) => void) => void
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginBottom: theme.spacing(8),
    }
  })
);

export const MuiStepper: React.FC<MuiStepperProps> = (props: MuiStepperProps) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [inProgress, setInProgress] = useState<boolean>(false);
  
  const {
    contentHeight,
    steps
  } = props;
  
  // const steps = _.remove(props.steps, step => !step.disabled)
  
  const isStepOptional = (step: number) => {
    return step === -1;
  };
  
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  
  const handleNext = () => {
    const onComplete = steps[activeStep].onComplete
    
    if(onComplete) {
      setInProgress(true)
      onComplete((success) => {
        if(success) {
          goToNextStep()
        }
        setInProgress(false)
      })
    } else {
      goToNextStep()
    }
  };
  
  const goToNextStep = () => {
    let newSkipped = skipped;
  
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
  
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error('You can\'t skip a step that isn\'t optional.');
    }
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  
  const handleReset = () => {
    setActiveStep(0);
  };
  
  return (
    <Box className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant='caption'>Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box>
        {activeStep === steps.length ? (
          <Box>
            <Box height={contentHeight} className={classes.instructions}>
              <Typography variant="h4">
                All steps completed - you&apos;re finished
              </Typography>
              <Typography variant="caption" color="secondary">
                If you want to review your changes click
                <Link href="#" style={{padding: '0 3px'}} onClick={handleReset}>here</Link>
                to go through the steps one more time
              </Typography>
            </Box>
            <DialogActions>
            
            </DialogActions>
          </Box>
        ) : (
          <Box>
            <Box height={contentHeight} className={classes.instructions}>{steps[activeStep].content}</Box>
            <Box mb={3} display="flex" alignItems="center" justifyContent="flex-end">
              <MuiButton variant="contained" color="secondary" disabled={activeStep === 0} onClick={handleBack} label="Back"/>
              {isStepOptional(activeStep) && (
                <MuiButton
                  color='primary'
                  onClick={handleSkip}
                  label="Skip"
                />
              )}
              <Box ml={2}>
                <MuiButton
                  variant='contained'
                  color='primary'
                  onClick={handleNext}
                  label={activeStep === steps.length - 1 ? 'Save' : 'Next'}
                  inProgress={inProgress}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};