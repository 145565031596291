import {
	SensorDetailActions,
	SensorDetailState,
	SensorDetailTypes,
} from './types';
import { datePickerOptions } from '../../components/controls/date-picker-control/utils';

const initialSensorDetailState: SensorDetailState = {
	filterOption: datePickerOptions[1],
	filterSearchQuery: null,
	reportData: [],
};

export const sensorDetailReducer = (
	state: SensorDetailState = initialSensorDetailState,
	action: SensorDetailActions,
): SensorDetailState => {
	switch (action.type) {
		case SensorDetailTypes.SET_FILTER_OPTION: {
			return {
				...state,
				filterOption: action.payload,
			};
		}
		case SensorDetailTypes.SET_FILTER_SEARCH_QUERY: {
			return {
				...state,
				filterSearchQuery: action.payload,
			};
		}
		case SensorDetailTypes.SET_REPORT_DATA: {
			return {
				...state,
				reportData: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};
