import React from 'react';
import style from './notification-toast.module.scss';
import classNames from 'classnames';
import './notification-toast-animations.css';

export enum NotificationType {
    success = 'success',
    warning = 'warning',
    error = 'error'
}

export interface NotificationToastProps {
    type: NotificationType;
    title: string;
    message?: string;
}

export const NotificationToast: React.FC<NotificationToastProps> = (props) => {
    const message = props.message && (
        <div className={style.notificationContent}>
            {props.message}
        </div>
    );

    return (
        <div className={classNames(style.NotificationToast, style[props.type])}>
            <div className={style.notificationHeader}>
                <span className={style.title}>
                    { props.title }
                </span>
                <span className={style.icon} />
            </div>
            { message }
        </div>
    );
}
