import { Auth } from "aws-amplify";
import {
  AuthActions,
  AuthActionTypes,
  AuthAsyncActions,
  AuthAsyncDispatch,
  LidbotUser,
  SetUser,
} from "./types";

export const setUser = (user: LidbotUser | null): SetUser => {
  return {
    type: AuthActionTypes.SET_USER,
    payload: user,
  };
};

/**
 * Log the user in with his credentials and store the user object in the redux store.
 * Clears the user object in the store, if the sign in fails.
 *
 * @param user
 * @param pass
 */
export const loginUser =
  (user: string, pass: string, newPassword: string): AuthAsyncActions =>
  (dispatch: AuthAsyncDispatch): Promise<AuthActions | void> => {
    return Auth.signIn(user, pass)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          return Auth.completeNewPassword(user, newPassword, {})
            .then(() => {
              /** This is a bit of a hack, but on first signIn, the custom user attributes are not on the user object.
               * Reloading the user will ensure that it exists. **/
              dispatch(loadUser());
            })
            .catch((e) => {
              dispatch(setUser(null));
              throw e;
            });
        } else {
          dispatch(setUser(user));
          return user;
        }
      })
      .catch((e) => {
        dispatch(setUser(null));
        throw e;
      });
  };

/**
 * Logs the user out and removes the dataset from the store.
 */
export const logoutUser =
  (): AuthAsyncActions =>
  (dispatch: AuthAsyncDispatch): Promise<AuthActions | void> => {
    return Auth.signOut().then(() => dispatch(setUser(null)));
  };

/**
 * Tries to load an authenticated user.
 * Loads the authenticated user into the redux store or resets the user, if it fails.
 */
export const loadUser =
  (bypassCache = false): AuthAsyncActions =>
  (dispatch: AuthAsyncDispatch): Promise<AuthActions | void> => {
    return Auth.currentAuthenticatedUser({ bypassCache })
      .then((user) => {
        dispatch(setUser(user));
        return user;
      })
      .catch((e) => {
        dispatch(setUser(null));
        throw e;
      });
  };
