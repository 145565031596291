import React from 'react';
import style from './top-full-bins-chart.module.scss';
import classNames from 'classnames';
import { AwaitingPickupChartReport } from '../../../redux/dashboard/types';
import { useSelector } from 'react-redux';
import { awaitingPickupChartDataSelector } from '../../../redux/dashboard/selectors';
import { Sensor } from '../../../api/contracts';
import { getStringShortenedAtLength } from '../../../utils/string';
import { thresholdSelector } from '../../../redux/account/selectors';

export const TopFullBinsChart: React.FC<{}> = () => {
  const awaitingPickupChartData: Array<AwaitingPickupChartReport> = useSelector(
    awaitingPickupChartDataSelector
  );

  const threshold: number = useSelector(thresholdSelector);

  const stringShortener = getStringShortenedAtLength(30);

  return (
    <div className={style.TopBinsChart}>
      {awaitingPickupChartData.map((item) => {
        const bin = item as Sensor;
        const title = stringShortener(bin.nickname || bin.sensor_id);
        const percentage = bin.fill_percentage || 0;
        const hasAlert = percentage >= threshold;
        const barChartPercentage = Math.min(percentage, 100);
        const percentageLabel = `${percentage}%`;
        return (
          <div
            key={bin.sensor_id}
            className={classNames(style.binRow, { [style.hasAlert]: hasAlert })}
          >
            <div className={style.binTitle}>{title}</div>
            <div className={style.binBarChart}>
              <div style={{ width: `${barChartPercentage}%` }} />
            </div>
            <div className={style.percentage}>{percentageLabel}</div>
          </div>
        );
      })}
    </div>
  );
};
