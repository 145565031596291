import React from "react";
import style from "./user-row.module.scss";
import {
  FlexibleRowCell,
  HighlightedCellGroup,
  IconRowCell,
  OptionsRowCell,
  Row,
  RowProps,
  StaticRowCell,
} from "../../controls/row/row";
import {
  expandedUserRoleOptions,
  User,
  UserAsyncDispatch,
  userStatusOptions,
} from "../../../redux/user/types";
import { Avatar } from "../../mui/Avatar";
import classNames from "classnames";
import { FlyOver } from "../../controls/fly-over/fly-over";
import { ReactComponent as EllipsisIcon } from "../../../assets/img/icons/icon.ellipsis.svg";
import { getFullName } from "../../../utils/string";
import { updateUserState } from "../../../redux/user/actions";
import { useDispatch } from "react-redux";
import { LidbotUser } from "../../../redux/auth/types";
import { useLoadingBarDispatch } from "../../../redux/loading-bar/effects";
import { LightTooltip } from "../../controls/light-tooltip/light-tooltip";

export interface UserRowProps extends RowProps {
  user: User;
  lidbotUser: LidbotUser | null;
  onSelect: () => void;
  onDelete: () => void;
  onEdit?: (user: User) => void;
}

export const UserRow: React.FC<UserRowProps> = (props) => {
  const { user, lidbotUser, onEdit } = props;
  const {
    given_name: fName,
    family_name: lName,
    email,
    user_role: role,
    user_status: status,
    enabled,
  } = user;

  const fullName = getFullName(fName, lName);
  const roleLabel =
    expandedUserRoleOptions.find((option) => option.value === role)?.label ||
    "";

  const statusLabel =
    userStatusOptions.find((option) => option.value === status)?.label || "";

  const userAsyncDispatch = useDispatch<UserAsyncDispatch>();
  const [incRC, decRC] = useLoadingBarDispatch();
  const selectUserOnClickHandler = (): void => {
    setTimeout(() => props.onSelect && props.onSelect(), 0);
  };
  const disableUserOnClickHandler = (): void => {
    incRC();
    userAsyncDispatch(updateUserState(user.email, "disable"))
      .then(() => {
        decRC();
        props.onDeselect && props.onDeselect();
      })
      .catch(() => decRC());
  };
  const enableUserOnClickHandler = (): void => {
    incRC();
    userAsyncDispatch(updateUserState(user.email, "enable"))
      .then(() => {
        decRC();
        props.onDeselect && props.onDeselect();
      })
      .catch(() => {
        decRC();
      });
  };
  const resendMailOnClickHandler = (): void => {
    incRC();
    userAsyncDispatch(updateUserState(user.email, "resend-invitation"))
      .then(() => {
        props.onDeselect && props.onDeselect();
        decRC();
      })
      .catch(() => {
        decRC();
      });
  };

  // Flyover
  const flyoverOptions = [
    { label: "edit", onClick: () => onEdit && onEdit(user) },
    { label: "disable", enabled: enabled, onClick: disableUserOnClickHandler },
    { label: "enable", enabled: !enabled, onClick: enableUserOnClickHandler },
    ...(status === "PENDING_INVITATION"
      ? [{ label: "resend confirm. mail", onClick: resendMailOnClickHandler }]
      : []),
  ];

  // Role Hierarchy
  const roleHierarchy =
    lidbotUser?.attributes.email === user.email
      ? "LIDBOT_SUPPORT"
      : user.user_role === "USER"
      ? "ADMIN"
      : user.user_role;

  const destructiveOption = {
    label: "delete",
    onClick: () => props.onDelete && props.onDelete(),
  };
  return (
    <Row
      key={user.email}
      className={style.UserRow}
      selected={props.selected}
      onDeselect={() => props.onDeselect && props.onDeselect()}
      {...user}
    >
      <IconRowCell className={style.iconCell}>
        <Avatar user={user} />
      </IconRowCell>
      <HighlightedCellGroup>
        <FlexibleRowCell className={style.nameCell}>{fullName}</FlexibleRowCell>
        <FlexibleRowCell className={style.emailCell}>{email}</FlexibleRowCell>
        <FlexibleRowCell className={style.roleCell}>
          {roleLabel}
        </FlexibleRowCell>
        <FlexibleRowCell className={style.sensorsCell}>
          <LightTooltip
            title={
              <>
                <p>{`This user has ${user.sensors?.length} sensors assigned.`}</p>
              </>
            }
            enterDelay={1000}
            enterNextDelay={200}
          >
            <div>{user.sensors?.length}</div>
          </LightTooltip>
        </FlexibleRowCell>
        <StaticRowCell
          className={classNames(style.statusCell, {
            [style.confirmed]: status === "CONFIRMED",
            [style.pending]: status === "PENDING_INVITATION",
          })}
        >
          {statusLabel}
        </StaticRowCell>
      </HighlightedCellGroup>

      <OptionsRowCell
        className={style.optionsCell}
        onClick={() => selectUserOnClickHandler()}
        flyover={
          <FlyOver
            className={style.flyover}
            options={flyoverOptions}
            destructiveOption={destructiveOption}
          />
        }
        roleHierarchy={roleHierarchy}
      >
        <EllipsisIcon />
      </OptionsRowCell>
    </Row>
  );
};
