import React, { ReactElement, ReactNode } from 'react';
import style from './reports-settings-general-modal.module.scss';
import classNames from 'classnames';
import { AccountReportTypeEnum } from '../../../redux/account/types';
import { Option } from '../../../redux/types';
import { Switch } from '../../controls/switch/switch';
import { Slider } from '../../controls/slider/slider';
import { ReactComponent as ReportsWasteAnalysisIcon } from '../../../assets/img/icons/icon.reports-waste-analysis.svg';
import { ReactComponent as ReportsBinsFullIcon } from '../../../assets/img/icons/icon.reports-bin-full.svg';
import { FormLabel } from '../../building-blocks/form-label/form-label';
import { LightTooltip } from '../../controls/light-tooltip/light-tooltip';
import { UserRoleType } from '../../../redux/user/types';
import { Access } from '../../controls/access/access';
import { Box, TextField } from '@material-ui/core';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';


export interface AccountReportType extends Option {
  value: AccountReportTypeEnum;
  icon?: ReactNode;
  label: string;
  restrictedTo: UserRoleType;
}

export const accountReportType: Array<AccountReportType> = [
  {
    value: AccountReportTypeEnum.BINS_READY_FOR_PICKUP,
    icon: <ReportsBinsFullIcon />,
    label: 'Pick-up',
    restrictedTo: 'USER'
  },
  {
    value: AccountReportTypeEnum.WASTE_VOLUME,
    icon: <ReportsWasteAnalysisIcon />,
    label: 'Waste',
    restrictedTo: 'LIDBOT_SUPPORT'
  }
];


export interface AccountReportSettingsModalProps {
  name: string;
  nameError: string
  type?: AccountReportTypeEnum;
  setName: (name: string) => void;
  binsReadyForPickupThreshold: number;
  setBinsReadyForPickupThreshold: (binsReadyForPickupThreshold: number) => void;
  allBins: boolean;
  setAllBins: (allBins: boolean) => void;
  setType: (type: AccountReportTypeEnum) => void;
}

export const ReportsSettingsGeneralModal: React.FC<AccountReportSettingsModalProps> = (props) => {
  const {
    setName,
    name,
    nameError,
    type,
    binsReadyForPickupThreshold,
    setBinsReadyForPickupThreshold,
    allBins,
    setType,
    setAllBins
  } = props;

  const getWasteTypeHelp = (type: AccountReportTypeEnum): ReactElement => {
    switch (type) {
      case AccountReportTypeEnum.BINS_READY_FOR_PICKUP:
        return (
          <Box>
            <h4>Bins ready-for-pick-up report</h4>
            <label>{'Bins ready for pick-up report collects all the bins matching the criteria selected in the right-hand panel.'}</label>
          </Box>
        );
      case AccountReportTypeEnum.WASTE_VOLUME:
        return (
          <Box>
            <h4>Waste analysis report</h4>
            <label>{'Aggregates generated waste volume from the previous week.'}</label>
          </Box>
        );
      default:
        return (
          <Box>
            <h4>This is generic report type</h4>
          </Box>
        );
    }
  };

  const getCollectAllBinsHelp = (): ReactElement => {
    return (
      <label>
        When selected, all the bins will be included in the final report, regardless of their fill level.
      </label>
    );
  };


  return (
    <Box height='100%' className={style.ReportSettingsGeneral}>
      <Box className={style.inputs}>
        <Box className={style.inputsColumn}>
          <Box mb={2}>
            <TextField
              type='text'
              value={name}
              label='Report name'
              required={true}
              variant='filled'
              fullWidth
              error={!!nameError}
              helperText={nameError ? nameError : ' '}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box className={style.typeSection}>
            <FormLabel required={true}>
              Report type
            </FormLabel>

            <Box className={style.typeGrid}>
              {accountReportType.map(typeItem => {
                return (
                  <Access
                    roleHierarchy={typeItem.restrictedTo}
                    key={typeItem.value}
                  >
                    <Box
                      className={classNames(style.typeIcon, { [style.selected]: typeItem.value == type })}
                      onClick={() => setType(typeItem.value)}
                    >
                      <LightTooltip
                        title={getWasteTypeHelp(typeItem.value)}
                        enterDelay={1000}
                        enterNextDelay={200}
                      >
                        <Box>
                          {
                            typeItem.icon
                          }
                          {
                            <FormLabel>{typeItem.label}</FormLabel>
                          }
                        </Box>
                      </LightTooltip>
                    </Box>
                  </Access>
                );
              })}
            </Box>
          </Box>
        </Box>
        {
          type === AccountReportTypeEnum.BINS_READY_FOR_PICKUP &&
          <Box className={classNames(style.inputsColumn, style.binsFull)}>
            <Box className={style.switch}>
              <Switch
                label={'Collect all bins?'}
                name='collect_all_bins'
                checked={allBins} onChange={() => setAllBins(!allBins)}
                help={getCollectAllBinsHelp()}
              />
            </Box>
            <Box className={classNames(style.slider, { [style.readonly]: allBins })}>
              <Box>
                <label className={style.primary}>Report Threshold</label>
              </Box>
              <Box>
                <label>When fill levels reach <b>{binsReadyForPickupThreshold}%</b> the bins will be included in the report.</label>
              </Box>

              <Box className={style.thresholdSettings}>
                {binsReadyForPickupThreshold !== null &&
                <Box className={style.thresholdSliderWrapper}>
                  <Box className={style.thresholdSlider}>
                    <Slider value={binsReadyForPickupThreshold} onChange={(t) => setBinsReadyForPickupThreshold(t)} format={(n) => `${n}%`} readonly={allBins} />
                  </Box>
                  <Box className={style.thresholdImage} />
                </Box>
                }
              </Box>
            </Box>
          </Box>
        }
        {
          type === AccountReportTypeEnum.WASTE_VOLUME &&
          <Box className={classNames(style.inputsColumn, style.wasteType)}>
            <Box>
              <label className={style.primary}>About waste reports</label>
            </Box>
            <Box>
              <label>
                It is important to know about the waste your company or campus is generating. This report collects the generated <b>volume of your waste streams</b> for you.
              </label>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  );
};
