import React, { useEffect } from 'react';

export interface ActiveDotProps {
    cx?: number;
    cy?: number;
    value?: number;
    onRender: (props: ActiveDotProps) => void;
    fill?: string;
    r?: number;
    stroke?: string;
    strokeWidth?: number;
}

export const ActiveDot: React.FC<ActiveDotProps> = (props) => {
    const {onRender, value, cx, cy, fill, r, stroke, strokeWidth } = props;

    useEffect(() => {
        onRender && onRender(props);
        // eslint-disable-next-line
    }, []);
    return value && cy ? (
        <g className='recharts-layer recharts-active-dot'>
            <circle
                className='recharts-dot'
                {...{ cx, cy, fill, r, stroke, strokeWidth }}
            />
        </g>
    ) : (null);
}
