import React from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./sensor-details-header.module.scss";
import classNames from "classnames";
import { DatePickerControl } from "../../controls/date-picker-control/date-picker-control";

import {
  setFilterOption,
  setFilterSearchQuery,
} from "../../../redux/sensor-detail/actions";
import {
  filterOptionSelector,
  filterSearchQuerySelector,
} from "../../../redux/sensor-detail/selectors";
import { DatePickerOption } from "../../controls/date-picker-control/utils";
import moment from "moment";
import { BatteryLevelDisplay } from "../../controls/battery-level-display/battery-level-display";
import { SignalStrengthDisplay } from "../../controls/signal-strength-display/signal-strength-display";
import { ReactComponent as BellIcon } from "../../../assets/img/icons/icon.bell.svg";
import { ReactComponent as BellCrossedIcon } from "../../../assets/img/icons/icon.bell-crossed.svg";
import { LightTooltip } from "../../controls/light-tooltip/light-tooltip";
import { User } from "../../../redux/user/types";
import { Box } from "@material-ui/core";
import { AvatarGroup } from "../../mui/AvatarGroup";

interface SensorDetailsHeaderProps {
  fillStatus: number;
  name: string;
  sensorId: string;
  binTypeName?: string;
  threshold?: number;
  lastReport?: number;
  battery?: number;
  signal?: number;
  muted?: boolean;
  users?: Array<User>;
}

/**
 * Displays the page header for the sensor details page.
 *
 * @param props
 */
export const SensorDetailsHeader: React.FC<SensorDetailsHeaderProps> = ({
  battery,
  signal,
  ...props
}) => {
  const dispatch = useDispatch();

  const displayName =
    props.name && props.name !== "" ? props.name : props.sensorId;

  const threshold = props.threshold || Infinity;

  const hasAlert = props.fillStatus >= threshold;
  const isConnected = props.lastReport !== undefined;

  /** DatepickerControl */
  const activeFilterOption = useSelector(filterOptionSelector);
  const activeFilterSearchQuery = useSelector(filterSearchQuerySelector);
  const selectedStartTimestamp =
    activeFilterSearchQuery?.["created_on.gte"] || null;
  const selectedEndTimestamp =
    activeFilterSearchQuery?.["created_on.lt"] || null;
  const filterOptionClickHandler = (filterOption: DatePickerOption): void => {
    dispatch(setFilterOption(filterOption));
  };
  const onDateRangeChangeHandler = (
    startTimestamp: number,
    endTimestamp: number
  ): void => {
    dispatch(
      setFilterSearchQuery({
        "created_on.gte": moment.unix(startTimestamp).startOf("day").unix(),
        "created_on.lt": moment.unix(endTimestamp).endOf("day").unix(),
      })
    );
  };

  return (
    <div
      className={classNames(style.SensorDetailsHeader, {
        [style.hasAlert]: hasAlert,
        [style.isConnected]: isConnected,
      })}
    >
      <div className={style.sensorDetails}>
        <div className={style.sensorData}>
          <div className={style.sensorFillLevel}>
            {props.fillStatus}
            <span className={style.sensorFillLevelUnit}>%</span>
          </div>
          <div className={style.sensorIdentity}>
            <div className={style.sensorName}>
              {displayName}
              <em>.</em>
            </div>
            <div className={style.sensorType}>
              <span className={style.sensorTypeName}>{props.binTypeName}</span>
            </div>
          </div>
        </div>
        <div className={style.chartFilters}>
          <DatePickerControl
            selectedDatePickerOption={activeFilterOption}
            selectedStartTimestamp={selectedStartTimestamp}
            selectedEndTimestamp={selectedEndTimestamp}
            onDatePickerChange={filterOptionClickHandler}
            onDateRangeChange={onDateRangeChangeHandler}
          />
        </div>
      </div>
      <Box
        display="flex"
        alignItems="center"
        className={style.sensorStateDetails}
      >
        {battery && (
          <div>
            Battery
            <BatteryLevelDisplay percentage={battery} />
          </div>
        )}
        {signal && (
          <div>
            Signal
            <SignalStrengthDisplay signal={signal} />
          </div>
        )}
        {props.muted && (
          <LightTooltip
            title={
              <>
                <p>{"Sensor is muted"}</p>
              </>
            }
            severity="error"
          >
            <div>
              Muted
              <BellCrossedIcon />
            </div>
          </LightTooltip>
        )}
        {!props.muted && (
          <LightTooltip
            title={
              <>
                <p>{"Notifications are enabled for this sensor."}</p>
              </>
            }
            severity="info"
          >
            <div>
              Unmuted
              <BellIcon />
            </div>
          </LightTooltip>
        )}
        {props.users && props.users.length > 0 && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box className={style.staffLabel}>
              Staff
              <em>.</em>
            </Box>
            <AvatarGroup users={props.users} showDetailsOnHover={true} />
          </Box>
        )}
      </Box>
    </div>
  );
};
