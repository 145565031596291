import { ChangeEvent, Dispatch, SetStateAction } from 'react';

/**
 * Numeric input
 * */

// Creates an event handler that filters text value for numbers and updates a useState setter
export const numericInputOnChangeHandlerWithSetter =
	(setter: Dispatch<SetStateAction<number>>) =>
	(e: ChangeEvent<HTMLInputElement>): void => {
		const textValue = e.target.value.replace(/\D/, '');
		const value = parseInt(textValue, 10) || 0;
		setter(value);
	};

// A companion function to the one above. We want the input to display the placeholder when the input is zero.
export const numericInputValueFilter = (n: number): number | string => {
	return n === 0 ? '' : n;
};
