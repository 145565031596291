import React, { FC } from 'react';
import { LightTooltip, toolTipSeverityTypes } from '../light-tooltip/light-tooltip';

const POOR_THRESHOLD = 9;
const GOOD_THRESHOLD = 20;
const VERY_GOOD_THRESHOLD = 28;

const COLOR_BLACK = 'black';
const COLOR_GRAY = '#E8E8E8'
const COLOR_YELLOW = '#FAB635';
const COLOR_RED = '#A74040';

export const SignalStrengthDisplay: FC<{
    signal: number;
}> = ({
    signal
}) => {
    let bar1Color;
    let bar2Color;
    let bar3Color = COLOR_GRAY;
    let bar4Color = COLOR_GRAY;
    let signalQuality;
    let toolTipSeverity: toolTipSeverityTypes = 'info'

    if(signal < POOR_THRESHOLD) {
        bar1Color = COLOR_RED
        bar2Color = COLOR_GRAY
        signalQuality = 'Poor'
        toolTipSeverity = 'error'
    } else if(signal < GOOD_THRESHOLD) {
        bar1Color = COLOR_YELLOW
        bar2Color = COLOR_YELLOW
        signalQuality = 'Good'
        toolTipSeverity = 'warning'
    } else if (signal < VERY_GOOD_THRESHOLD) {
        bar1Color = COLOR_BLACK;
        bar2Color = COLOR_BLACK;
        bar3Color = COLOR_BLACK;
        signalQuality = 'Very good'
    } else {
        bar1Color = COLOR_BLACK;
        bar2Color = COLOR_BLACK;
        bar3Color = COLOR_BLACK;
        bar4Color = COLOR_BLACK;
        signalQuality = 'Excellent'
    }

    return (
        <LightTooltip
            title={`Signal: ${signalQuality}`}
            severity={toolTipSeverity}
        >
            <svg width="15" height="12" viewBox="0 1 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 10 H1 C0.448 10 0 10.4477 0 11 V12 C0 12.5523 0.448 13 1 13 H2 C2.552 13 3 12.5523 3 12 V11 C3 10.4477 2.552 10 2 10Z" fill={bar1Color}/>
                <path d="M6 7 H5 C4.448 7 4 7.44772 4 8 V12 C4 12.5523 4.448 13 5 13 H6 C6.552 13 7 12.5523 7 12 V8 C7 7.44772 6.552 7 6 7Z" fill={bar2Color}/>
                <path d="M10 4 H9 C8.448 4 8 4.44772 8 5 V12 C8 12.5523 8.448 13 9 13 H10 C10.552 13 11 12.5523 11 12 V5 C11 4.44772 10.552 4 10 4Z" fill={bar3Color}/>
                <path d="M14 1 H13 C12.448 1 12 1.44772 12 2 V12 C12 12.5523 12.448 13 13 13 H14 C14.552 13 15 12.5523 15 12 V2 C15 1.44772 14.552 1 14 1Z" fill={bar4Color}/>
            </svg>
        </LightTooltip>
    )
}