import classNames from 'classnames';
import React, { ReactElement, ReactNode, ReactNodeArray, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EllipsisIcon } from '../../../assets/img/icons/icon.ellipsis.svg';
import { deleteAccountReport } from '../../../redux/account/actions';
import { reportsSelector } from '../../../redux/account/selectors';
import { AccountAsyncDispatch, AccountReport, AccountReportTypeEnum } from '../../../redux/account/types';
import { useClientRefreshCallback } from '../../../redux/auth/effects';
import { FlyOver, FlyOverOption } from '../../controls/fly-over/fly-over';
import { CellGroup, FlexibleRowCell, HeaderRowCell, HighlightedCellGroup, IconRowCell, OptionsRowCell, Row } from '../../controls/row/row';
import { ReactComponent as DeleteBinIcon } from './../../../assets/img/icons/icon.remove-report.svg';
import { ReportsSettingsModal } from './reports-settings-modal';
import style from './reports-settings.module.scss';
import { Access } from '../../controls/access/access';
import { ContentContainer } from '../../building-blocks/content-container/content-container';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { InterfaceFrame } from '../../building-blocks/interface-frame/interface-frame';
import { ReactComponent as ReportsIcon } from '../../../assets/img/icons/icon.reports.svg';
import { ReactComponent as ReportsWasteAnalysisIcon } from '../../../assets/img/icons/icon.reports-waste-analysis.svg';
import { ReactComponent as ReportsBinsFullIcon } from '../../../assets/img/icons/icon.reports-bin-full.svg';
import { LightTooltip } from '../../controls/light-tooltip/light-tooltip';
import { DataGrid } from '../../mui/DataGrid';
import _ from 'lodash'
import { Box, Button, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import { DeleteModal } from '../../controls/modal/deleteModal';
import { MuiButton } from '../../mui/MuiButton';

export const ReportSettings: React.FC = () => {
  const accountReports = useSelector(reportsSelector);
  const [selectedAccountReport, setSelectedAccountReport] =
    useState<AccountReport | null>(null);
  
  useClientRefreshCallback(() => {
    setSelectedAccountReport(null);
    setDisplayedModalType("none");
    setSettingsModalReport(null);
    setDeleteModalReport(null);
  });
  
  type ReportSettingsModalType = "reports-settings" | "delete-report";
  const [displayedModalType, setDisplayedModalType] =
    useState<ReportSettingsModalType | "none">("none");
  
  const [settingsModalReport, setSettingsModalReport] =
    useState<AccountReport | null>(null);
  const displaySettingsModalEventHandler = (
    accountReport: AccountReport | null
  ): void => {
    setSelectedAccountReport(null);
    setSettingsModalReport(accountReport);
    setDisplayedModalType("reports-settings");
  };
  const settingsModalNode: ReactNode = (
    <ReportsSettingsModal
      accountReport={settingsModalReport}
      onClose={() => setDisplayedModalType("none")}
    />
  );
  
  const [deleteModalReport, setDeleteModalReport] =
    useState<AccountReport | null>(null);
  const displayDeleteModalEventHandler = (report: AccountReport): void => {
    setSelectedAccountReport(null);
    setDeleteModalReport(report);
    setDisplayedModalType("delete-report");
  };
  
  const [incRC, decRC] = useLoadingBarDispatch();
  const accountAsyncDispatch = useDispatch<AccountAsyncDispatch>();
  const onDeleteHandler = (): void => {
    incRC();
    deleteModalReport &&
    accountAsyncDispatch(deleteAccountReport(deleteModalReport))
      .then(() => {
        decRC();
        setDisplayedModalType("none");
      })
      .catch(() => {
        decRC();
      });
  };
  const deleteModalNode: ReactNode = (
    <DeleteModal
      isOpen={true}
      objectClass="Report"
      objectName={deleteModalReport?.name}
      onDelete={onDeleteHandler}
      onClose={() => setDisplayedModalType("none")}
    />
  );
  
  const selectReportOnClickHandler = (accountReport: AccountReport) => () => {
    setTimeout(() => {
      setSelectedAccountReport(accountReport);
    }, 0);
  };
  
  
  const getReportIcon = (type: AccountReportTypeEnum): ReactElement => {
    switch (type) {
      case AccountReportTypeEnum.WASTE_VOLUME:
        return <ReportsWasteAnalysisIcon />;
      case AccountReportTypeEnum.BINS_READY_FOR_PICKUP:
        return <ReportsBinsFullIcon />;
      default:
        return <ReportsIcon />;
    }
  };
  
  const getOptions = (id: string): ReactElement => {
    const ar = _.find(accountReports, o => o.id === id)
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return (
      <>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <EllipsisIcon/>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => {
            handleClose()
            displaySettingsModalEventHandler(ar!)
          }}>
            <ListItemIcon>
              <EditOutlined fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Edit</Typography>
          </MenuItem>
          <MenuItem onClick={() => {
            handleClose()
            displayDeleteModalEventHandler(ar!)
          }}>
            <ListItemIcon>
              <DeleteOutline fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
          </MenuItem>
        </Menu>
      </>
    )
  }
  
  const accountReportRowNodes: ReactNodeArray = accountReports.map(
    (accountReport) => {
      const flyoverOptions: Array<FlyOverOption> = [
        {
          label: "Edit",
          onClick: () => displaySettingsModalEventHandler(accountReport),
        },
      ];
      
      const destructiveOption: FlyOverOption = {
        label: "Delete",
        onClick: () => displayDeleteModalEventHandler(accountReport),
      };
      
      const getWasteTypeHelp = (type: AccountReportTypeEnum): ReactElement => {
        switch (type) {
          case AccountReportTypeEnum.BINS_READY_FOR_PICKUP:
            return (
              <>
                <p>{"Scheduled Pick-up Reports"}</p>
              </>
            );
          case AccountReportTypeEnum.WASTE_VOLUME:
            return (
              <>
                <p>{"Waste report by volume."}</p>
              </>
            );
          default:
            return (
              <>
                <p>This is generic report type</p>
              </>
            );
        }
      };
      
      return (
        <Row
          key={accountReport.id}
          className={classNames(style.chartRow)}
          selected={selectedAccountReport?.id === accountReport.id}
          onDeselect={() => setSelectedAccountReport(null)}
        >
          <IconRowCell className={style.iconCell}>
            <LightTooltip
              title={getWasteTypeHelp(accountReport.type)}
              enterDelay={300}
              enterNextDelay={300}
              leaveDelay={300}
              placement="bottom-start"
            >
              <div>{getReportIcon(accountReport.type)}</div>
            </LightTooltip>
          </IconRowCell>
          <HighlightedCellGroup>
            <FlexibleRowCell className={style.nameCell}>
              {accountReport.name}
            </FlexibleRowCell>
          </HighlightedCellGroup>
          <OptionsRowCell
            className={style.optionsCell}
            onClick={selectReportOnClickHandler(accountReport)}
            flyover={
              <FlyOver
                options={flyoverOptions}
                destructiveOption={destructiveOption}
              />
            }
            roleHierarchy="ADMIN"
          >
            <EllipsisIcon />
          </OptionsRowCell>
        </Row>
      );
    }
  );
  
  const rows = accountReports.map((accountReport) => {
    return {
      id: accountReport.id,
      type: accountReport.type,
      name: accountReport.name,
    };
  });
  
  
  return (
    <InterfaceFrame>
      <Box marginBottom={4}>
        <Typography variant='h1'>Scheduled Pick-up Reports</Typography>
      </Box>
      <div className={style.ReportSettings}>
        {displayedModalType === "reports-settings" && settingsModalNode}
        {displayedModalType === "delete-report" && deleteModalNode}
        <ContentContainer className={style.reportChart} type="settings">
          <Row className={style.reportChartHeader} rowClassName="header">
            <CellGroup>
              <HeaderRowCell
                className={style.iconCell}
                rowCellClassName="flexible"
                hasBorder={true}
              >
                Type
              </HeaderRowCell>
              <HeaderRowCell
                className={style.nameCell}
                rowCellClassName="flexible"
                hasBorder={true}
              >
                Name
              </HeaderRowCell>
            </CellGroup>
            <HeaderRowCell
              className={style.optionsCell}
              rowCellClassName="options"
            />
          </Row>
          {accountReportRowNodes}
        </ContentContainer>
        <Access className={style.formButton} roleHierarchy="ADMIN">
          <MuiButton
            label="Add report"
            color="default"
            onClick={() => displaySettingsModalEventHandler(null)}
          />
        </Access>
      </div>
    </InterfaceFrame>
  );
};
