import { ApplicationState } from './../index';
import { LidbotUser } from './types';
import { UserRoleType } from '../user/types';

export const userSelector = (state: ApplicationState): LidbotUser | null =>
	state.auth.user;
export const roleSelector = (state: ApplicationState): UserRoleType | null =>
	state.auth.user?.attributes?.['custom:user_role'] || null;
export const clientIdSelector = (state: ApplicationState): string | null =>
	state.auth.user?.attributes?.['custom:client_id'] || null;
