import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import style from "./animated-list-container.module.scss";
import classNames from "classnames";
import {
  AnimatedSortedListContainerAnimationState,
  AnimatedSortedListContainerProps,
} from "./animated-list-container-types";
import { getAnimatedVisibleAndFadeOutElementsFunction } from "./animated-list-container-functions";

export const AnimatedListContainer: FC<AnimatedSortedListContainerProps> = ({
  id,
  className,
  children,
}: AnimatedSortedListContainerProps) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationState, setAnimationState] =
    useState<AnimatedSortedListContainerAnimationState>("done");

  // Variables for managing the state of the ui
  const [visibleContainerChildren, setVisibleContainerChildren] = useState<
    Array<ReactElement>
  >([]);
  const visibleContainerRef = useRef<HTMLDivElement>(null);
  const [hiddenContainerChildren, setHiddenContainerChildren] = useState<
    Array<ReactElement>
  >([]);
  const hiddenContainerRef = useRef<HTMLDivElement>(null);
  const [fadeOutContainerChildren, setFadeOutContainerChildren] = useState<
    Array<ReactElement>
  >([]);
  const fadeoutContainerRef = useRef<HTMLDivElement>(null);

  // Setup function that will add animation layers to the new ui elements
  const getAnimatedVisibleAndFadeOutElements =
    getAnimatedVisibleAndFadeOutElementsFunction(
      visibleContainerChildren,
      visibleContainerRef,
      hiddenContainerChildren,
      hiddenContainerRef,
      fadeoutContainerRef
    );

  // Reset the container if the id changes, used for changing between row and tile display
  useEffect(() => {
    setHiddenContainerChildren([]);
    setVisibleContainerChildren([]);
    setFadeOutContainerChildren([]);
  }, [id]);

  // Triggers next animation or saves latest changes if the component is currently animating
  const [nextWrappedChildren, setNextWrappedChildren] =
    useState<Array<ReactElement> | null>(null);
  useEffect(() => {
    const wrappedChildren = children.map((child) => (
      <div id={child.key as string} key={child.key as string}>
        {child}
      </div>
    ));
    if (animationState === "done") {
      setHiddenContainerChildren(wrappedChildren);
      setAnimationState("start");
    } else {
      setNextWrappedChildren(wrappedChildren);
    }
    // eslint-disable-next-line
  }, [children]);

  // Handles each of the steps in a animation lifecycle
  useEffect(() => {
    switch (animationState) {
      case "start":
        const isOrderSame =
          hiddenContainerChildren.length === visibleContainerChildren.length &&
          visibleContainerChildren.every(
            (child, i) => child.props.id === hiddenContainerChildren[i].props.id
          );
        if (isOrderSame) {
          setVisibleContainerChildren(hiddenContainerChildren);
          setAnimationState("done");
          break;
        }
        const [newVisibleContainerChildren, newFadeOutContainerChildren] =
          getAnimatedVisibleAndFadeOutElements();
        setVisibleContainerChildren(newVisibleContainerChildren);
        setFadeOutContainerChildren(newFadeOutContainerChildren);
        setIsAnimating(false);
        setAnimationState("animating");
        break;
      case "animating":
        setIsAnimating(true);
        setTimeout(() => setAnimationState("done"), 400);
        break;
      case "done":
        if (nextWrappedChildren === null) break;
        setHiddenContainerChildren(nextWrappedChildren);
        setAnimationState("start");
        setNextWrappedChildren(null);
        break;
    }
    // eslint-disable-next-line
  }, [animationState]);

  return (
    <div
      className={classNames(style.AnimatedSortedListContainer, {
        [style.animating]: isAnimating,
      })}
    >
      <div className={style.hiddenContainer}>
        <div className={className} ref={hiddenContainerRef}>
          {hiddenContainerChildren}
        </div>
      </div>
      <div className={style.fadeOutContainer} ref={fadeoutContainerRef}>
        {fadeOutContainerChildren}
      </div>
      <div className={style.visibleContainer}>
        <div className={className} ref={visibleContainerRef}>
          {visibleContainerChildren}
        </div>
      </div>
    </div>
  );
};
