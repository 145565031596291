import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { userSelector } from '../../../redux/auth/selectors';
import { Modal } from '../../controls/modal/modal';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { Box, TextField } from '@material-ui/core';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';

export interface PasswordModalProps {
    onModalClose: () => void;
}

export const PasswordModal: React.FC<PasswordModalProps> = (props) => {
    const user = useSelector(userSelector);
    const [currentPass, setCurrentPass] = useState<string>('');
    const [newPass, setNewPass] = useState<string>('');
    const [newPassRepeat, setNewPassRepeat] = useState<string>('');
    
    const resetButtonClickHandler = (): void => {
        props.onModalClose && props.onModalClose();
    };
    
    const modalCloseButtonClickHandler = (): void => {
        props.onModalClose && props.onModalClose();
    };
    
    const [incRC, decRC] = useLoadingBarDispatch();
    const formSubmitHandler = (): void => {
        incRC();
        Auth.changePassword(user, currentPass, newPass)
            .then(() => {
                props.onModalClose && props.onModalClose();
                notifySuccess('Password changed');
                decRC();
            })
            .catch((e) => {
                notifyError('Password not changed', notifyErrorMessage(e));
                decRC();
                console.error(e);
            });
    };
    
    return (
        <Modal title='Change password'
               isOpen={true}
               onClose={resetButtonClickHandler}
               content={(
                   <Box marginBottom={2}>
                       <Box marginBottom={2}>
                           <TextField
                               type='password'
                               label='Current password *'
                               value={currentPass}
                               fullWidth
                               variant='filled'
                               onChange={(e) => setCurrentPass(e.target.value)}
                           />
                       </Box>
        
                       <Box marginBottom={2}>
                           <TextField
                               type='password'
                               label='New password *'
                               value={newPass}
                               fullWidth
                               variant='filled'
                               onChange={(e) => setNewPass(e.target.value)}
                           />
                       </Box>
        
                       <Box marginBottom={0}>
                           <TextField
                               type='password'
                               label='Confirm new password *'
                               value={newPassRepeat}
                               fullWidth
                               variant='filled'
                               onChange={(e) => setNewPassRepeat(e.target.value)}
                           />
                       </Box>
                   </Box>

               )}
               actions={(
                   <LidbotButtonGroup onSecondaryClickHandler={resetButtonClickHandler} onPrimaryClickHandler={formSubmitHandler} />
               )}
        />
    );
};
