import React from "react";
import style from "./avg-fill-at-pickup-panel.module.scss";
import { StatsPanel } from "../../controls/stats-panel/stats-panel";
import { useSelector } from "react-redux";
import { avgPickupFillChartDataSelector } from "../../../redux/dashboard/selectors";
import { Trans } from "react-i18next";
import { StatsPanelStatus } from "../../controls/stats-panel-status/stats-panel-status";
import { AvgFillAtPickupChart } from "../../controls/avg-fill-at-pickup-chart/avg-fill-at-pickup-chart";
import { thresholdSelector } from "../../../redux/account/selectors";

export const AvgFillPanel: React.FC<{}> = () => {
  const threshold: number = useSelector(thresholdSelector);

  const panelTitle = (
    <span>
      <Trans i18nKey="dashboard.avgPickupFill.header1" />
    </span>
  );

  const response = useSelector(avgPickupFillChartDataSelector);

  return (
    <StatsPanel title={panelTitle}>
      <div className={style.AvgFillPanel}>
        <StatsPanelStatus
          value={response?.aggregations.stats.avg}
          unit="%"
          precision={0}
          alertThreshold={threshold}
        />
        <AvgFillAtPickupChart
          buckets={response?.aggregations.data?.buckets?.reverse().slice(0, 5)}
          precision={0}
          alertThreshold={threshold}
        />
      </div>
    </StatsPanel>
  );
};
