import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import style from './schedule-selector-time-cell.module.scss';
import { DayOfWeekOption, TimeOfDayOption } from '../../../utils/time';
import { ScheduleSelectorAction } from '../../controls/schedule-selector/types';
import { Box } from '@material-ui/core';

export interface ScheduleSelectorTimeCellProps {
  day: DayOfWeekOption;
  time: TimeOfDayOption;
  value: string;
  selected: boolean;
  onChange?: (action: ScheduleSelectorAction) => void;
  height?: number
}

export const ScheduleSelectorTimeCell: React.FC<ScheduleSelectorTimeCellProps> =
  (props: ScheduleSelectorTimeCellProps) => {
    const {
        day,
        time,
        value,
        selected,
        height = 50
    } = props;

    const addOnClickHandler = (): void => {
      const action: ScheduleSelectorAction = {
        type: "add",
        day: day.value,
        time: time.value,
      };
      !selected && props.onChange && props.onChange(action);
    };

    const deleteOnClickHandler = (e: MouseEvent<HTMLDivElement>): void => {
      e.stopPropagation();
      const action: ScheduleSelectorAction = {
        type: "delete",
        day: day.value,
        time: value,
      };
      props.onChange && props.onChange(action);
    };

    const timeLabel: string = value || time.label;

    return (
      <Box
          height={height}
        className={classNames(
          style.TimeCell,
          style[day.type],
          style[time.type],
          { [style.selected]: selected }
        )}
        onClick={addOnClickHandler}
      >
        <Box className={style.card}>
          <Box className={style.statusBar} />
          <Box className={style.content}>{timeLabel}</Box>
          <Box className={style.delete} onClick={deleteOnClickHandler}>
            &times;
          </Box>
        </Box>
      </Box>
    );
  };
