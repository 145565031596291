import React, { ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import style from './date-picker-control.module.scss';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { CustomRangePicker } from './custom-range-picker';
import { DatePickerOption, datePickerOptions } from './utils';
import { Optional } from '../../../redux/types';
import moment from 'moment';
import { ReactComponent as PenIcon } from '../../../assets/img/icons/icon.pen.svg';
import { notifyError } from '../../../utils/notify';

/**
 * Props for the sort controls component
 */
export interface DatePickerControlProps {
    /** The active sort option */
    selectedDatePickerOption: DatePickerOption;
    selectedStartTimestamp?: Optional<number>;
    selectedEndTimestamp?: Optional<number>;
    /** Optional click handler for a sort option */
    onDatePickerChange?: (filterOption: DatePickerOption) => void;
    onDateRangeChange?: (startTimestamp: number, endTimestamp: number) => void;
    onStartEndTimestampChange?: () => void;
}

/**
 * Displays sort controls.
 *
 * @param props
 */
export const DatePickerControl: React.FC<DatePickerControlProps> = ({ 
    selectedDatePickerOption,
    selectedStartTimestamp= null,
    selectedEndTimestamp= null,
    onDatePickerChange,
    onDateRangeChange
}) => {

    const onClickHandler = (option: DatePickerOption) => (event: MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        if (option.value === 'custom') {
            setIsCustomDatePickerOpen(true);
        } else {
            setIsCustomDatePickerOpen(false);
        }
        onDatePickerChange && onDatePickerChange(option);
    }

    /**
     * Duration Date Picker
     * */
    const [isCustomDatePickerOpen, setIsCustomDatePickerOpen] = useState(false);
    const onDateRangeChangeHandler = (startTimestamp: number, endTimestamp: number): void => {
        setIsCustomDatePickerOpen(false);
        onDateRangeChange && onDateRangeChange(startTimestamp, endTimestamp);
    }
    
    /**
     * Custom Duration Text Input
     * */

    // Check if the time duration text format is valid
    const dateFormatRegex = /^\d{0,2}\/\d{0,2}, ?\d{0,4} ?— ?\d{0,2}\/\d{0,2}, ?\d{0,4}$/;

    // Flag for if the text input should remain visible
    const [isCustomTextInputActive, setIsCustomTextInputActive] = useState(false);

    // Value for the text input
    const [customTextInputValue, setCustomTextInputValue] = useState<string>('');
    const customTextInputRef = useRef<HTMLInputElement>(null);
    const convertStartAndEndTimestampToText = (startTimestamp: number, endTimestamp: number): string => {
        const startDateText = moment.unix(startTimestamp).format('DD/MM, YYYY');
        const endDateText = moment.unix(endTimestamp).format('DD/MM, YYYY');
        return `${startDateText} — ${endDateText}`;
    }
    useEffect(() => {
        selectedStartTimestamp &&
        selectedEndTimestamp &&
        setCustomTextInputValue(convertStartAndEndTimestampToText(selectedStartTimestamp, selectedEndTimestamp));
    }, [selectedStartTimestamp, selectedEndTimestamp, setCustomTextInputValue]);

    // Track if the current text format duration is valid
    const [isCustomTextInputValueValid, setIsCustomTextInputValueValid] = useState(true);
    const [isCustomTextInputDateFormatsValid, setIsCustomTextInputDateFormatsValid] = useState(true);
    const [isCustomTextInputDurationValid, setIsCustomTextInputDurationValid] = useState(true);
    useEffect(() => {
        const [startDateText, endDateText] = customTextInputValue.split('—').map(text => text.trim().replace(/ /g, ''));
        const mStartDate = moment(startDateText, 'DD/MM,YYYY');
        const mEndDate = moment(endDateText, 'DD/MM,YYYY');
        const isStartDateValid = mStartDate.isValid();
        const isEndDateValid = mEndDate.isValid();
        const isStartDateBeforeEndDate = mStartDate.isSameOrBefore(mEndDate, 'day');
        setIsCustomTextInputValueValid(
            isStartDateValid &&
            isEndDateValid &&
            isStartDateBeforeEndDate &&
            /^\d{1,2}\/\d{1,2}, ?\d{4} ?— ?\d{1,2}\/\d{1,2}, ?\d{4}$/.test(customTextInputValue)
        );
        setIsCustomTextInputDateFormatsValid(isStartDateValid && isEndDateValid);
        setIsCustomTextInputDurationValid(isStartDateBeforeEndDate);
    }, [customTextInputValue]);

    /**
     * If we use onChange to alter the results, the state of the actual input and the react element will be out of
     * sync, which will lead to a rerender and we will lose the cursor position during the rerender. Instead, we will
     * use the keydown event, where we can still cancel the event, and we will predict the outcome of the change and
     * check that value against the date format regex.
     * */
    const customTextInputOnKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>): void => {
        const { key } = e;
        const isArrowKey = ['ArrowLeft', 'ArrowRight'].includes(key);
        const isOperatorKey = ['Delete', 'Backspace', 'Enter', ' '].includes(key);
        const isNumberKey = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key);

        // Guard to filter out unwanted keys
        if (!isArrowKey && !isOperatorKey && !isNumberKey) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!customTextInputRef.current) {
            e.preventDefault();
            e.stopPropagation();
        }

        const { value, selectionStart, selectionEnd } = customTextInputRef.current!;
        if (selectionStart === null || selectionEnd === null) {
            e.preventDefault();
            e.stopPropagation();
        }

        const hasSelection = selectionStart && selectionEnd && selectionEnd > selectionStart;

        // Store the predicted outcome of the keypress
        let predictedValue = value;

        if (isNumberKey) {
            predictedValue = `${value.slice(0, selectionStart!)}${key}${value.slice(selectionEnd!)}`;
        } else if (['Backspace', 'Delete'].includes(key) && hasSelection){
            predictedValue = `${value.slice(0, selectionStart!)}${value.slice(selectionEnd!)}`;
        } else if (key === 'Backspace'){
            const newSelectionStart = selectionStart === 0 ? 0 : selectionStart! - 1;
            predictedValue = `${value.slice(0, newSelectionStart)}${value.slice(selectionEnd!)}`;
        } else if (key === 'Delete'){
            const newSelectionEnd = selectionEnd === value.length - 1 ? value.length - 1 : selectionEnd! + 1;
            predictedValue = `${value.slice(0, selectionStart!)}${value.slice(newSelectionEnd!)}`;
        } else if (key === ' '){
            predictedValue = `${value.slice(0, selectionStart!)} ${value.slice(selectionEnd!)}`;
        } else if (key === 'Enter'){
            customTextInputRef.current!.blur();
        }

        // cancel event if predicted outcome does not fulfill the date duration text format
        if (!dateFormatRegex.test(predictedValue)){
            e.preventDefault();
            e.stopPropagation();
        }
    }

    // Submit the changes or throw an error
    const handleCustomTextInputOnBlur = (): void => {
        setIsCustomTextInputActive(false);
        const [startDateText, endDateText] = customTextInputValue.split('—').map(text => text.trim().replace(/ /g, ''));
        const startTimestamp = moment(startDateText, 'DD/MM,YYYY').unix();
        const endTimestamp = moment(endDateText, 'DD/MM,YYYY').unix();
        if (isCustomTextInputValueValid){
            onDateRangeChange && onDateRangeChange(startTimestamp, endTimestamp);
        } else {
            !isCustomTextInputDateFormatsValid && notifyError('Graph not updated', 'Invalid dates provided');
            !isCustomTextInputDurationValid && notifyError('Graph not updated', 'Start date must be the same or before end date');
            selectedStartTimestamp &&
                selectedEndTimestamp &&
                setCustomTextInputValue(convertStartAndEndTimestampToText(selectedStartTimestamp, selectedEndTimestamp));
        }
    };

    const customTextInputOnChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
        setCustomTextInputValue(e.target.value);
    }

    const isCustomDurationLabelVisible = selectedDatePickerOption.value === 'custom' && selectedStartTimestamp && selectedEndTimestamp;

    return (
        <div className={style.FilterControls}>
            { isCustomDatePickerOpen && <div className={style.backdrop} onClick={ () => setIsCustomDatePickerOpen(false)}/>}
            <div className={style.controls}>
                <ul>
                    {datePickerOptions.map((filterOption) => {
                        const isActive = selectedDatePickerOption.value === filterOption.value;

                        return (
                            <li
                                key={filterOption.value}
                                onClick={onClickHandler(filterOption)}
                                className={classNames({ [style.active]: isActive })}
                                title={filterOption.label}
                            >
                                {filterOption.i18nKey ? <Trans i18nKey={filterOption.i18nKey}/> : filterOption.label}
                                <em>.</em>
                            </li>
                        );
                    })}
                </ul>
                { isCustomDatePickerOpen && selectedStartTimestamp && selectedEndTimestamp && (
                    <CustomRangePicker
                        selectedStartTimestamp={selectedStartTimestamp}
                        selectedEndTimestamp={selectedEndTimestamp}
                        onChangeDateRange={onDateRangeChangeHandler}
                    />
                )}
                <div 
                    className={classNames(style.customDurationLabelWrapper, {
                        [style.visible]: isCustomDurationLabelVisible,
                        [style.editing]: isCustomTextInputActive,
                        [style.invalid]: !isCustomTextInputValueValid})
                    }
                >
                    <div className={style.errorMsg}>Invalid date</div>
                    <div
                        className={classNames(style.customDurationLabel,
                            {
                               
                            })
                        }
                    >
                        <div className={classNames(style.customDurationIcon)}>
                            { !isCustomTextInputActive && <PenIcon /> }
                        </div>
                        <div className={classNames(style.customDurationText)}>
                            <input
                                type='text'
                                ref={customTextInputRef}
                                value={customTextInputValue}
                                onBlur={handleCustomTextInputOnBlur}
                                onKeyDown={customTextInputOnKeyDownHandler}
                                onChange={customTextInputOnChangeHandler}
                                onFocus={() => setIsCustomTextInputActive(true)}
                            />
                            <div className={style.customLabels}>
                                <span>{selectedStartTimestamp && moment.unix(selectedStartTimestamp).format('DD/MM, YYYY')}</span>
                                <span>&nbsp;&mdash;&nbsp;</span>
                                <span>{selectedEndTimestamp && moment.unix(selectedEndTimestamp).format('DD/MM, YYYY')}</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};
