import React from 'react';
import { Fab } from '@material-ui/core';
import { LightTooltip } from '../controls/light-tooltip/light-tooltip';
import { ReactComponent as AddIcon } from '../../assets/img/icons/icon.add.svg';


export interface AddFabProps {
  onClick: () => void;
  title: string
}

export const AddFab: React.FC<AddFabProps> = (props: AddFabProps) => {
  const {
    onClick,
    title
  } = props
  
  return (
    <LightTooltip title={title}>
      <Fab color='secondary' size='small' onClick={() => onClick()}>
        <AddIcon />
      </Fab>
    </LightTooltip>
  );
}