import React, { MouseEvent, ReactNode } from 'react';
import style from './fly-over.module.scss';
import classNames from 'classnames';

export interface FlyOverOption {
    label: string;
    onClick: () => void;
    enabled?: boolean;
}

export interface FlyOverProps {
    className?: string;
    options?: Array<FlyOverOption>;
    destructiveOption?: FlyOverOption;
}

export const FlyOver: React.FC<FlyOverProps> = (props) => {
    const { className = null, options, destructiveOption } = props;

    const optionsList: ReactNode = options ?
        options.map(option => {
            const { label, onClick, enabled = true} = option;
            const onClickHandler = (e: MouseEvent<HTMLDivElement>): void => {
                e.stopPropagation();
                onClick && onClick();
            }
            return (
                <div
                    key={label}
                    className={classNames(style.option, { [style.inactive]: !enabled})}
                    onClick={onClickHandler}
                >
                    <div>{label}</div>
                </div>
            );
        }) :
        null;

    const dividerNode: ReactNode = options && destructiveOption ?
        <div className={style.divider} /> :
        null;

    const destructiveNodeOnClickHandler = (e: MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
        destructiveOption?.onClick && destructiveOption.onClick();
    }
    const destructiveNode: ReactNode = destructiveOption ?
        <div className={style.destructiveOption} onClick={destructiveNodeOnClickHandler}>
            <div>{ destructiveOption.label}</div>
        </div> :
        null;

    return (
        <div className={classNames(style.Flyover, className)}>
            { optionsList }
            { dividerNode }
            { destructiveNode }
        </div>
    );
}