import React, { MouseEvent, ReactNodeArray, useCallback, useEffect, useState } from 'react';
import style from './slider.module.scss';
import classNames from 'classnames';
import { roundNumberToIncrement } from '../../../utils/number';

export interface SliderProps {
  value: number;
  min?: number;
  max?: number;
  increment?: number;
  format?: (n: number) => string;
  onChange?: (value: number) => void;
  readonly?: boolean;
}

/**
 * Slider
 * NOTE: To improve performance we will call onChange only when the sliding finishes thus avoiding
 * rerenders on the parent view.
 * */
export const Slider: React.FC<SliderProps> = ({
                                                value,
                                                min = 0,
                                                max = 100,
                                                increment = 1,
                                                format,
                                                onChange,
                                                readonly = false
                                              }) => {

  /**
   * tempValue tracks the change in value while sliding so that we can send the final value to the parent componenent
   * when the slide gesture is finished.
   *
   * This useEffect here is a safety precaution in case value is changed from outside this component.
   * */
  const [tempValue, setTempValue] = useState<number>(value);
  useEffect(() => {
    setTempValue(value);
  }, [value, setTempValue]);

  /**
   * Track the height of the slider
   *
   * I think this is the best practice for obtaining the height through hooks. The issue is that a normal ref is not supposed
   * to hold a value that will trigger a rerender and throws a typescript warning when ref.current is listed as a useEffect dependency.
   * In this version we use an inline function to set the value when ref is assigned and we memoized it to prevent further calls
   * whenever the component is rerendered.
   * */
  const [sliderHeight, setSliderHeight] = useState<number>(0);
  const trackRef = useCallback((node: HTMLDivElement) => {
    node && setSliderHeight(node.getBoundingClientRect().height);
  }, [setSliderHeight]);


  /**
   * Set the position of the slider components
   * */
  const [top, setTop] = useState<number>(0);
  useEffect(() => {
    const height = ((tempValue - min) / (max - min) * sliderHeight);
    const newTop = sliderHeight - height;
    setTop(newTop);
  }, [tempValue, min, max, sliderHeight, setTop]);
  const topStyle = { top: `${top}px` };

  /**
   * Slider movement and tracking data
   * */
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [pageYStart, setPageYStart] = useState<number | null>(null);

  const knobOnMouseDownHandler = (e: MouseEvent<HTMLDivElement>): void => {
    const { pageY } = e.nativeEvent;
    setIsDragging(true);
    setPageYStart(pageY);
  };

  // Capture mousedown event on the portion of the bar that overlaps the knob
  const barOnMouseDownHandler = (e: MouseEvent<HTMLDivElement>): void => {
    const { offsetY, pageY } = e.nativeEvent;
    if (offsetY > 16) return;
    setIsDragging(true);
    setPageYStart(pageY);
  };

  const onMouseMoveHandler = (e: MouseEvent<HTMLElement>): void => {
    const { pageY } = e.nativeEvent;
    if (isDragging && pageYStart && pageY) {
      const diffPixels = pageYStart - pageY;
      const diffValue = (diffPixels / sliderHeight) * (max - min);
      const newValue = value + diffValue;
      const restrictedValue = newValue > max ? max : newValue < min ? min : newValue;
      const roundedValue = roundNumberToIncrement(restrictedValue, increment);
      setTempValue(roundedValue);
    }
  };

  const onMouseUpHandler = (): void => {
    setIsDragging(false);
    setPageYStart(null);
    onChange && value !== tempValue && onChange(tempValue);
  };

  /**
   * Markers
   * */
  const markerPercentages = [0.9, 0.7, 0.5, 0.3, 0.1];
  const markersList: ReactNodeArray = markerPercentages.map((pct, index) => {
    const markerValue = min + (max - min) * pct;
    const marked = tempValue >= markerValue;
    const markerTop = sliderHeight * (1 - pct);
    return (
      <div
        key={index}
        className={classNames(style.sliderMarker, { [style.marked]: marked })}
        style={{ transform: `translateY(${markerTop}px)` }}
      >
        <div className={classNames(style.sliderGradient, { [style.readonly]: readonly })} />
      </div>
    );
  });


  return (
    <div
      className={classNames(style.Slider, { [style.readonly]: readonly })}
      onMouseMove={onMouseMoveHandler}
      onMouseUp={onMouseUpHandler}
      onMouseLeave={onMouseUpHandler}
    >
      <div className={style.sliderWrapper}>
        <div className={style.sliderLabel}>{format ? format(max) : max}</div>
        <div className={style.sliderTrack} ref={trackRef}>
          <div className={style.sliderTrackBackground} />
          <div className={classNames(style.sliderKnobShadow, { [style.readonly]: readonly })} style={topStyle} onMouseDown={knobOnMouseDownHandler}>
            <div className={style.sliderValueLabel}>{format ? format(tempValue) : tempValue}</div>
          </div>
          <div className={classNames(style.sliderBar, { [style.readonly]: readonly })} style={topStyle} onMouseDown={barOnMouseDownHandler} />
          <div className={classNames(style.sliderKnob, { [style.readonly]: readonly })} style={topStyle} onMouseDown={knobOnMouseDownHandler} />
        </div>
        <div className={style.sliderLabel}>{format ? format(min) : min}</div>
      </div>
      <div className={style.sliderMarkers}>
        {markersList}
      </div>
    </div>
  );
};