import React, { ReactElement, useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { User, userStatusOptions } from '../../redux/user/types';
import { getInitials, getUserName } from '../../utils/string';
import { Avatar as MuiAvatar, Box } from '@material-ui/core';
import { LightTooltip } from '../controls/light-tooltip/light-tooltip';

export interface AvatarProps {
  user: User;
  showDetailsOnHover?: boolean;
  showInitials?: boolean;
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const { user, showDetailsOnHover = false, showInitials = true } = props;

  const initials = getInitials(user.given_name, user.family_name);
  const [avatar, setAvatar] = useState<string | undefined>(undefined);

  useEffect(() => {
    user.picture &&
      Storage.get(user.picture).then((url) => {
        setAvatar(url.toString());
      });
  }, []);

  const userName = getUserName(user.given_name, user.family_name);

  const getAvatar = (): ReactElement => {
    return (
      <Box>
        {showInitials && <MuiAvatar src={avatar}>{initials}</MuiAvatar>}
        {!showInitials && <MuiAvatar src={avatar} />}
      </Box>
    );
  };

  return showDetailsOnHover ? (
    <LightTooltip
      key={user.email}
      title={
        <>
          <h2>
            <Avatar user={user} />
          </h2>
          <h4>
            {`${userName ? `${userName} ` : ""}(${
              userStatusOptions.find(
                (option) => option.value === user.user_status
              )?.label || ""
            })`}
          </h4>
          <h5>{user.email}</h5>
        </>
      }
      severity={user.user_status === "CONFIRMED" ? "info" : "warning"}
    >
      {getAvatar()}
    </LightTooltip>
  ) : (
    getAvatar()
  );
};
