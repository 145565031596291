import React from 'react';
import { Box } from '@material-ui/core';
import { MuiButton } from './MuiButton';


export interface CancelSaveButtonsProps {
    onSecondaryClickHandler: () => void;
    onPrimaryClickHandler: () => void;
    secondaryLabel?: string;
    primaryLabel?: string;
    isPrimaryInProgress?: boolean;
    isPrimaryError?: boolean
}



export const LidbotButtonGroup: React.FC<CancelSaveButtonsProps> = (props: CancelSaveButtonsProps) => {
    const {
        secondaryLabel = 'cancel',
        primaryLabel = 'save',
        isPrimaryInProgress = false,
        isPrimaryError
    } = props
    
        return (
            <Box display='flex'>
                
                <Box
                    flexBasis="50%"
                    marginRight={1}
                >
                    <MuiButton
                        onClick={props.onSecondaryClickHandler}
                        label={secondaryLabel}
                        color="secondary"
                        variant="contained"
                        fullWidth
                    />
                </Box>
                
                <Box
                    flexBasis="50%"
                    marginLeft={1}
                >
                    <MuiButton
                        onClick={props.onPrimaryClickHandler}
                        label={primaryLabel}
                        color="primary"
                        fullWidth
                        inProgress={isPrimaryInProgress}
                        error={isPrimaryError}
                        autoFocus
                    />
                </Box>
                
            </Box>
        );
}