import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import style from './verify.module.scss';
import '../../../config/amplify';
import { appRoutes } from '../../../config/routes';
import { loadUser, loginUser } from '../../../redux/auth/actions';
import { AuthAsyncDispatch } from '../../../redux/auth/types';
import { ReactComponent as TickIcon } from '../../../assets/img/icons/icon.tick.svg';
import { ReactComponent as WelcomeIcon } from '../../../assets/img/icons/icon.welcome.svg';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';
import { Box, InputAdornment, Link, TextField, Typography } from '@material-ui/core';
import { MuiButton } from '../../mui/MuiButton';
import { MIN_PASSWORD_LENGTH } from '../../building-blocks/password-reset-form/password-reset-form';

interface VerifyUrlParams {
    email: string;
    password: string;
}

export const Verify: React.FC<{}> = () => {
    const authDispatch = useDispatch<AuthAsyncDispatch>();
    const { t } = useTranslation();
    const history = useHistory();
    const { email, password } = useParams<VerifyUrlParams>();
    
    const [newPassword, setNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState<string>();
    const [inProgress, setInProgress] = useState<boolean>(false);
    
    useEffect(() => {
        authDispatch(loadUser()).then(() =>
            history.push(appRoutes.dashboard.path as string)
        );
    }, [authDispatch, history]);
    
    useEffect(() => {
        if (newPassword) {
            setPasswordError('');
        }
    }, [newPassword]);
    
    const passwordInputChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setNewPassword(event.target.value);
    };
    
    const formSubmitHandler = (event?: React.FormEvent<HTMLFormElement>): void => {
        event && event.preventDefault();
        
        let validationFailed = false;
        
        if (!newPassword) {
            setPasswordError('Password required');
            validationFailed = true;
        }
        
        if (newPassword && newPassword.length < MIN_PASSWORD_LENGTH) {
            setPasswordError(`Please enter at least ${MIN_PASSWORD_LENGTH} characters.`);
            validationFailed = true;
        }
        
        if (validationFailed) {
            return;
        }
        
        setInProgress(true);
        
        authDispatch(loginUser(email, password, newPassword))
            .then(() => {
                history.push(appRoutes.dashboard.path as string);
                setInProgress(false);
                notifySuccess('Account verified');
            })
            .catch((e) => {
                setInProgress(false);
                notifyError('Error', notifyErrorMessage(e));
            });
    };
    
    return (
        <div className={style.Verify}>
            <div className={style.verifyPanel}>
                <div className={style.verifyFormWrapper}>
                    <div className={style.verifyForm}>
                        <div className={style.verifyPanelHeadline}>
                            <Trans i18nKey={'verifyPage.panelHeadline'} />
                        </div>
                        
                        <div className={style.verifyPanelSublineWrap}>
                            <WelcomeIcon />
                            <div className={style.verifyPanelSubline}>
                                {t('verifyPage.panelSubline')}
                                <em>.</em>
                            </div>
                        </div>
                        
                        <div className={style.verifyPanelText}>
                            <Trans i18nKey={'verifyPage.panelText'} />
                        </div>
                        
                        <form className={style.verifyForm} onSubmit={formSubmitHandler}>
                            <Box marginBottom={2}>
                                <TextField
                                    type='email'
                                    value={email}
                                    disabled
                                    variant='filled'
                                    fullWidth
                                    label='Email'
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <TickIcon />
                                        </InputAdornment>
                                    }}
                                >
                                    <TickIcon />
                                </TextField>
                            </Box>
                            <Box mb={2}>
                                <TextField
                                    type='password'
                                    value={newPassword}
                                    variant='filled'
                                    fullWidth
                                    error={!!passwordError}
                                    helperText={passwordError ? passwordError : ' '}
                                    label={t('verifyPage.password')}
                                    onChange={passwordInputChangeHandler}
                                />
                            </Box>
                            <Box marginBottom={2}>
                                <MuiButton onClick={formSubmitHandler} label={t('verifyPage.confirm')} fullWidth inProgress={inProgress} />
                            </Box>
                            <Box textAlign="right">
                                <Typography variant="caption" color="secondary">
                                    Already have an account? Go to
                                    <Link style={{
                                        marginLeft: 3
                                    }} color="primary" href="/login">login</Link>
                                </Typography>
                            </Box>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
