import React, { useState, useRef, useEffect } from "react";
import style from "./average-fill-chart.module.scss";
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
} from "recharts";
import { ChartTooltip } from "../../controls/chart-tooltip/chart-tooltip";
import { useSelector } from "react-redux";
import {
  avgFillChartDataSelector,
  avgFillChartSearchQuerySelector,
} from "../../../redux/dashboard/selectors";
import {
  ActiveDot,
  ActiveDotProps,
} from "../../controls/recharts-dot/active-dot";
import { Trans } from "react-i18next";
import { thresholdSelector } from "../../../redux/account/selectors";
import { Optional } from "../../../redux/types";
import { DateTime } from 'luxon';

const COLOR_GRID_STROKE = "#E7E7E7";
const COLOR_STROKEGRADIENT_0 = "#0EC797";
const COLOR_STROKEGRADIENT_40 = "#00C391";
const COLOR_STROKEGRADIENT_100 = "#17CA9B";
const COLOR_ACTIVE_DOT = "#007355";
const TOOLTIP_OFFSET_Y = -15;

export const AverageFillChart: React.FC = () => {
  const threshold = useSelector(thresholdSelector);
  const avgFillChartData = useSelector(avgFillChartDataSelector);

  // Ref to the tooltip itself. Required to calculate the position of the tooltip
  // eslint-disable-next-line
  const tooltip = useRef<any>(null);

  // Calculated tooltip coordinates
  const [tooltipX, setTooltipX] = useState<number>(0);
  const [tooltipY, setTooltipY] = useState<number>(0);

  // CSS Properties for hidden / active tooltip
  const hiddenTooltipWrapperStyle: React.CSSProperties = {
    opacity: 0,
    transition: "opacity 300ms ease-in-out",
  };
  const activeTooltipWrapperStyle: React.CSSProperties = {
    opacity: 1,
    transition: "opacity 300ms ease-in-out",
  };

  const [tooltipWrapperStyle, setTooltipWrapperStyle] = useState<{}>(
    hiddenTooltipWrapperStyle
  );

  /**
   * Mouse over handler for the activeDot of the area chart.
   * Displays the tooltip when an active dot has been hovered.
   * Calculates the position for the tooltip, so the tooltip is displayed above the data point.
   *
   * @param props
   */
  const showTooltip = (props: ActiveDotProps): void => {
    const { cx, cy } = props;

    setTooltipWrapperStyle(activeTooltipWrapperStyle);
    if (tooltip && tooltip.current && tooltip.current.wrapperNode && cx && cy) {
      const tooltipRect = tooltip.current.wrapperNode.getBoundingClientRect();
      setTooltipX(cx - tooltipRect.width / 2);
      setTooltipY(cy - tooltipRect.height + TOOLTIP_OFFSET_Y);
    }
  };

  /** X-Axis calculations */
  const searchQuery = useSelector(avgFillChartSearchQuerySelector);
  const startTimestamp = searchQuery?.["created_on.gte"] || null;
  const endTimestamp = searchQuery?.["created_on.lt"] || null;

  const [domainStart, setDomainStart] =
    useState<Optional<number>>(startTimestamp);
  const [domainEnd, setDomainEnd] = useState<Optional<number>>(endTimestamp);

  useEffect(() => {
    if (startTimestamp && endTimestamp) {
      setDomainStart(startTimestamp);
      setDomainEnd(endTimestamp);
    }
  }, [startTimestamp, endTimestamp]);

  const LineChartXAxisTickLabel: React.FC<any> = (props) => {
    const { width, height, x, y, payload } = props;

    if (payload?.value && Number.isInteger(payload.value) && avgFillChartData) {
      return (
          <text
              fontWeight={"normal"}
              fontSize={12}
              width={width}
              height={height}
              x={x}
              y={y}
              dy={16}
              textAnchor="middle"
              fill="#A0A0A0"
          >
            {DateTime.fromSeconds(payload.value).toFormat('LLLL dd')}
          </text>
      );
    }

    return null;
  };

  return domainStart && domainEnd ? (
    <div className={style.AverageFillChart}>
      <div className={style.chartTitle}>
        <Trans i18nKey="dashboard.averageFillChart.yAxisLabel" />
      </div>
      <ResponsiveContainer
        width="100%"
        height={300}
        className={style.chartContainer}
      >
        <LineChart
          width={800}
          height={400}
          data={avgFillChartData}
          margin={{ top: 10, bottom: 10, right: 3, left: 3 }}
        >
          <defs>
            <linearGradient
              id="strokegradient"
              x1="0"
              y1="0"
              x2="100%"
              y2="0"
              gradientUnits="userSpaceOnUse"
            >
              <stop
                offset="0%"
                stopColor={COLOR_STROKEGRADIENT_0}
                stopOpacity={0.3}
              />
              <stop
                offset="40%"
                stopColor={COLOR_STROKEGRADIENT_40}
                stopOpacity={1}
              />
              <stop
                offset="100%"
                stopColor={COLOR_STROKEGRADIENT_100}
                stopOpacity={0.3}
              />
            </linearGradient>
          </defs>
          <XAxis
              dataKey="created_on"
              tickLine={false}
              tick={<LineChartXAxisTickLabel />}
          />
          <YAxis
            dataKey="value"
            axisLine={false}
            tickLine={false}
            ticks={[0, 25, 50, 75, 100]}
            orientation="left"
            width={30}
          />
          <CartesianGrid
            stroke={COLOR_GRID_STROKE}
            strokeWidth={1}
            vertical={false}
          />
          <Line
            type="monotoneX"
            dataKey="value"
            stroke="url(#strokegradient)"
            strokeWidth={4}
            fillOpacity={1}
            activeDot={
              <ActiveDot onRender={showTooltip} fill={COLOR_ACTIVE_DOT} />
            }
            dot={false}
          />
          <Tooltip
            cursor={false}
            wrapperStyle={tooltipWrapperStyle}
            allowEscapeViewBox={{ x: true, y: true }}
            position={{ x: tooltipX, y: tooltipY }}
            isAnimationActive={false}
            content={
              <ChartTooltip
                dataKey="value"
                hideBattery={true}
                threshold={threshold}
              />
            }
            ref={tooltip}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  ) : null;
};
