import React from 'react';
import { Box } from '@material-ui/core';
import { Access } from '../controls/access/access';
import style from '../building-blocks/users-settings/users-settings.module.scss';
import { AddFab } from './AddFab';


export interface CancelSaveButtonsProps {
    addRowHandler: () => void;
    addRowTooltipTitle: string
}

export const LidbotListHeader: React.FC<CancelSaveButtonsProps> = (props: CancelSaveButtonsProps) => {
    const {
        addRowHandler,
        addRowTooltipTitle
    } = props
    
        return (
            <Box mb={4}>
                <Access className={style.AddUserButton} roleHierarchy="ADMIN">
                    <AddFab onClick={() => addRowHandler()} title={addRowTooltipTitle}/>
                </Access>
            </Box>
        );
}