import { SensorScheduleDay } from '../../../redux/account/types';
import moment from 'moment';

/**
 * The function is a sort function that sorts an array of SensorScheduleDays from Sunday to Saturday.
 * Primarily used to format data according to client api specs for account.sensor_schedule.week
 * */
export const sortSensorScheduleDays = (
	d1: SensorScheduleDay,
	d2: SensorScheduleDay,
): number => {
	return moment().day(d1.day).weekday() - moment().day(d2.day).weekday();
};

/**
 * This functino is a sort function that sorts and array of times from earliest to latest.
 *
 * */
export const sortSensorScheduleTimes = (t1: string, t2: string): number => {
	return moment(t1, 'HH:mm').valueOf() - moment(t2, 'HH:mm').valueOf();
};
