import {
	DeleteSensor,
	SensorListDisplayOption,
	SensorListOrderDirection,
	SensorListRequestParams,
	SensorListResponse,
	SensorOverviewActions,
	SensorOverviewAsyncActions,
	SensorOverviewAsyncDispatch,
	SensorOverviewTypes,
	SetDisplayOption,
	SetSensor,
	SetSensorOverviewDirection,
	SetSensors,
	SetSortOption,
} from './types';
import { API } from 'aws-amplify';
import { SensorOverviewSortOption } from '../../components/building-blocks/sensor-overview-header/sensor-overview-header';
import { DeepPartial, Optional, RequestOptions } from '../types';
import { Sensor } from '../../api/contracts';
import config from '../../config/config';

export const setSortOption = (
	sortOption: SensorOverviewSortOption,
): SetSortOption => {
	return {
		type: SensorOverviewTypes.SET_SORT_OPTION,
		payload: sortOption,
	};
};

export const setDisplayOption = (
	displayOption: SensorListDisplayOption,
): SetDisplayOption => {
	return {
		type: SensorOverviewTypes.SET_DISPLAY_OPTION,
		payload: displayOption,
	};
};

export const setSensorOverviewDirection = (
	direction: SensorListOrderDirection,
): SetSensorOverviewDirection => ({
	type: SensorOverviewTypes.SET_DIRECTION,
	payload: direction,
});

export const setSensors = (sensors: Array<Sensor>): SetSensors => {
	return {
		type: SensorOverviewTypes.SET_SENSORS,
		payload: sensors,
	};
};

export const setSensor = (sensor: Sensor): SetSensor => ({
	type: SensorOverviewTypes.SET_SENSOR,
	payload: sensor,
});

export const deleteSensor = (sensor: Sensor): DeleteSensor => ({
	type: SensorOverviewTypes.DELETE_SENSOR,
	payload: sensor,
});

/**
 * Loads the list of sensors.
 *
 * @param orderBy Property to order the records by.
 * @param direction Order direction
 * @param limit Limits the number of records to retrieve from the API
 * @param fromId Pagination ID of the last element of the previous set.
 * @param field by with to sort the sensors
 * @param value sort direction of the list of sensors
 */
export const loadSensors =
	(
		orderBy: string,
		direction: SensorListOrderDirection = 'desc',
		field: Optional<string> = null,
		value: Optional<string> = null,
		limit = 10000,
		fromId?: string,
	): SensorOverviewAsyncActions =>
	(
		dispatch: SensorOverviewAsyncDispatch,
	): Promise<SensorOverviewActions | void> => {
		const queryStringParameters: SensorListRequestParams = {
			limit: limit,
			sort_field: orderBy,
			sort_order: direction,
			...(field && value ? { field, value } : {}),
		};

		if (fromId) {
			queryStringParameters.starting_after = fromId;
		}

		return API.get(config.API.name, '/sensors', {
			queryStringParameters,
		}).then((response: SensorListResponse) => {
			dispatch(setSensors(response.data));
		});
	};

export const updateSensor =
	(id: string, data: DeepPartial<Sensor>): SensorOverviewAsyncActions =>
	(dispatch: SensorOverviewAsyncDispatch): Promise<void | SetSensor> => {
		const options: RequestOptions = {
			body: data,
		};
		return API.post(config.API.name, `/sensors/${id}`, options).then(
			(sensor: Sensor) => dispatch(setSensor(sensor)),
		);
	};
