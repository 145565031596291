/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import style from './sensor-chart.module.scss';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { SensorChartTooltip, SensorChartTooltipProps } from '../sensor-chart-tooltip/sensor-chart-tooltip';
import { SensorChartActiveDot } from '../sensor-chart-active-dot/sensor-chart-active-dot';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { filterSearchQuerySelector } from '../../../redux/sensor-detail/selectors';
import { SensorReport } from '../../../api/sensors-reports';
import { Optional } from '../../../redux/types';
import { MomentTimeUnit } from '../../../utils/momentjs';
import { DateTime } from 'luxon';

/**
 * Color configuration. Might be usefull to create an object and put it in a config file and iterate over the object.
 */
const COLOR_GRID_STROKE = '#E7E7E7';
const COLOR_FILLGRADIENT_START = '#00CD98';
const COLOR_FILLGRADIENT_END = '#ffffff';
const COLOR_STROKEGRADIENT_0 = '#0EC797';
const COLOR_STROKEGRADIENT_40 = '#00C391';
const COLOR_STROKEGRADIENT_100 = '#17CA9B';
const COLOR_ACTIVE_DOT = '#007355';

interface SensorChartProps {
    reports: Array<SensorReport>;
    threshold?: number;
    thresholdDirection?: 'gt' | 'lt';
    yAxisMaxValue?: number;
    yAxisIncrement?: number;
}

/**
 * Displays the chart for the details page that contains information about the fill level over time.
 *
 * TODO: Tooltip repositioning if outside of chart.
 *
 * @param props
 */
export const SensorChart: React.FC<SensorChartProps> = ({
    reports = [],
    threshold = Infinity,
    thresholdDirection= 'gt',
    yAxisMaxValue = 100,
    yAxisIncrement = 25
}) => {
    const [tooltipX, setTooltipX] = useState<number>(0);
    const [tooltipY, setTooltipY] = useState<number>(0);

    /**
     * recharts passes all the possible data points for a coordinate to the tooltip as an array in it's
     * payload property. Instead of filtering through this payload, I wanted to get more control of which payload
     * would be used by passing in the payload originating from active dot.
     *
     * Since recharts rerenders the tooltip on every mousemove over the chart, we won't see a performance impact from
     * using this design.
     * */

    const [tooltipProps, setTooltipProps] = useState<SensorChartTooltipProps>({ createdOn: 0, value: 0 });
    const onTooltipChangeHandler = (ttProps: SensorChartTooltipProps): void => {
        const { cx, cy, value, createdOn, battery } = ttProps;

        // Setting state variables only as needed so we don't overflow react's setter functions
        if (tooltipProps.value !== value ||
            tooltipProps.createdOn !== createdOn ||
            tooltipProps.battery !== battery
        ){
            setTooltipProps(ttProps);
        }
        (cx === 0 || cx) && cx !== tooltipX && setTooltipX(cx);
        (cy === 0 || cy) && cy !== tooltipY && setTooltipY(cy);
    }


    /**
     *  recharts has a bug where it occasionally hides a tick due to internal tick calculations. We will bypass this bug
     *  by creating the ticks by ourselves
     * */
    const filterSearchQuery = useSelector(filterSearchQuerySelector);
    const startTimestamp = filterSearchQuery?.['created_on.gte'] || null;
    const endTimestamp = filterSearchQuery?.['created_on.lt'] || null;

    const [domainStart, setDomainStart] = useState<Optional<number>>(startTimestamp);
    const [domainEnd, setDomainEnd] = useState<Optional<number>>(endTimestamp);

    const [ticks, setTicks] = useState<Array<number>>([]);
    const [tickFormatType, setTickFormatType] = useState<MomentTimeUnit>('day');
    const tickFormatterHandler = (val: number): string => {
        const mTick = DateTime.fromSeconds(val)
        switch (tickFormatType) {
            case 'day':
            case 'week':
                return mTick.toFormat('LLLL dd');
            case 'month':
            case 'quarter':
                return mTick.toFormat('LLLL');
            case 'year':
                return mTick.toFormat('yyyy');
        }
    };

    useEffect(() => {
        if (startTimestamp && endTimestamp){
            const mStart = moment.unix(startTimestamp);
            const mEnd = moment.unix(endTimestamp);

            const mTicks = [];
            const mDay = mStart.clone();
            if (mDay.isSame(mDay.clone().startOf('day'))) {
                mTicks.push(mDay.clone());
            }
            mDay.add(1, 'day').startOf('day');
            while (mDay.isBefore(mEnd)){
                mTicks.push(mDay.clone());
                mDay.add(1, 'day').startOf('day');
            }

            let mFilteredTicks = mTicks;
            let timeUnit: MomentTimeUnit = 'day';
            const mDuration = moment.duration(mEnd.diff(mStart));
            if (mDuration.years() >= 2){
                timeUnit = 'year';
            } else if (mDuration.years() >= 1){
                timeUnit = 'quarter';
            } else if (mDuration.months() > 1 ){
                timeUnit = 'month';
            } else if (mDuration.days() > 7){
                timeUnit = 'week';
            }
            mFilteredTicks = timeUnit === 'day' ? mTicks : mTicks.filter(t => t.isSame(t.clone().startOf(timeUnit)));

            const newTicks = mFilteredTicks.map(m => m.unix());

            setDomainStart(startTimestamp);
            setDomainEnd(endTimestamp);
            setTicks(newTicks);
            setTickFormatType(timeUnit);
        }
    }, [startTimestamp, endTimestamp]);

    const yAxisNumTicks = Math.floor(yAxisMaxValue / yAxisIncrement) + 1;
    const yAxisTicks = new Array(yAxisNumTicks).fill(0).map((t, i) => t + (i * yAxisIncrement));

    return  domainStart && domainEnd ? (
        <React.Fragment>
            <ResponsiveContainer width='100%' height={440} className={style.SensorChart}>
                <AreaChart width={800} height={400} data={reports} margin={{ top: 10, bottom: 10 }}>
                    <defs>
                        <linearGradient id='fillgradient' x1='0' y1='0' x2='0' y2='100%' gradientUnits='userSpaceOnUse'>
                            <stop offset='7.5%' stopColor={COLOR_FILLGRADIENT_START} stopOpacity={0.2} />
                            <stop offset='83%' stopColor={COLOR_FILLGRADIENT_END} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id='strokegradient' x1='0' y1='0' x2='100%' y2='0' gradientUnits="userSpaceOnUse" >
                            <stop offset='0%' stopColor={COLOR_STROKEGRADIENT_0} stopOpacity={0.3} />
                            <stop offset='40%' stopColor={COLOR_STROKEGRADIENT_40} stopOpacity={1} />
                            <stop offset='100%' stopColor={COLOR_STROKEGRADIENT_100} stopOpacity={0.3} />
                        </linearGradient>
                        <linearGradient id='modelstrokegradient' x1='0' y1='0' x2='100%' y2='0' gradientUnits="userSpaceOnUse">
                            <stop offset='0%' stopColor='#0EE7AE' stopOpacity={1} />
                            <stop offset='100%' stopColor='#003B2C' stopOpacity={1} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey='created_on'
                        type='number'
                        domain={[domainStart, domainEnd]}
                        tickFormatter={tickFormatterHandler}
                        tick={{ transform: 'translate(0, 8)' }}
                        ticks={ticks}
                        interval='preserveStartEnd'
                        padding={{ left: 20, right: 20 }}
                    />
                    <YAxis dataKey='value' axisLine={false} tickLine={false} ticks={yAxisTicks} tickCount={yAxisNumTicks} orientation='left' width={30} />
                    <CartesianGrid stroke={COLOR_GRID_STROKE} strokeWidth={1} vertical={false}/>
                    <Area
                        type='monotoneX'
                        dataKey='model'
                        stroke='url(#modelstrokegradient)'
                        strokeWidth={3}
                        strokeDasharray='10 20'
                        strokeLinejoin='round'
                        strokeLinecap='round'
                        fill='none'
                        connectNulls={true}
                        activeDot={false}
                        dot={false}
                    />
                    <Area
                        type='monotoneX'
                        dataKey='value'
                        stroke='url(#strokegradient)'
                        strokeWidth={4}
                        fillOpacity={1}
                        fill='url(#fillgradient)'
                        connectNulls={false}
                        activeDot={<SensorChartActiveDot onTooltipChange={onTooltipChangeHandler} fill={COLOR_ACTIVE_DOT} />}
                        dot={false}
                    />
                    <Tooltip
                        cursor={false}
                        allowEscapeViewBox={{ x: true, y: true }}
                        position={{ x: tooltipX, y: tooltipY }}
                        isAnimationActive={false}
                        content={<SensorChartTooltip {...tooltipProps} threshold={threshold} thresholdDirection={thresholdDirection}/>}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </React.Fragment>
    ) : <div style={{height: '440px'}}/>;
};
