import { Option, Optional } from '../redux/types';
import { DayOfWeekType } from '../redux/account/types';
import moment from 'moment';

export interface DayOfWeekOption extends Option {
	label: string;
	value: DayOfWeekType;
	type: 'weekend' | 'weekday';
}

/**
 * Creates an array of DayOfWeekOptions with localized formatted labels
 * and values accepted by client api.
 */
export const daysOfWeekOptions: Array<DayOfWeekOption> = (
	[
		'MONDAY',
		'TUESDAY',
		'WEDNESDAY',
		'THURSDAY',
		'FRIDAY',
		'SATURDAY',
		'SUNDAY',
	] as Array<DayOfWeekType>
).map((value) => ({
	label: moment().day(value).format('ddd'),
	value,
	type: ['SATURDAY', 'SUNDAY'].includes(value) ? 'weekend' : 'weekday',
}));

export interface TimeOfDayOption extends Option {
	label: string;
	value: string;
	type: 'start' | 'half';
}

export const timesInDayOptions: Array<TimeOfDayOption> = [...Array(24)].map(
	(_, idx) => {
		const isEven = idx % 2 === 0;
		const hour = idx;
		const fill = hour < 10 ? '0' : '';
		return {
			label: `${hour}:00`,
			value: `${fill}${hour}:00`,
			type: isEven ? 'start' : 'half',
		};
	},
);

export interface TimeUnitOption extends Option {
	label: string;
	value: 'days' | 'hours' | 'minutes' | 'seconds';
	multiplier: number;
}

export const minutesSinceMidnight = (time: string): number => {
	const mMidnight = moment('00:00', 'HH:mm');
	const mTime = moment(time, 'HH:mm');
	return mTime.diff(mMidnight, 'minutes');
};

export const getUnixTimeFromRange = (rangeFilter: string): Optional<number> => {
	switch (rangeFilter) {
		case '4w':
			return moment().subtract(4, 'weeks').startOf('day').unix();
		case '2w':
			return moment().subtract(2, 'weeks').startOf('day').unix();
		case '1w':
			return moment().subtract(1, 'week').startOf('day').unix();
		case '3d':
			return moment().subtract(3, 'days').startOf('day').unix();
		default:
			return null;
	}
};

export const getTickCountFromRange = (rangeFilter: string): number => {
	switch (rangeFilter) {
		case '4w':
			return 8;
		case '2w':
			return 8;
		case '1w':
			return 8;
		case '3d':
			return 4;
		default:
			return 8;
	}
};
