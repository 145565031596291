import { ApplicationState } from '../index';
import {
	AvgFillChartReport,
	AwaitingPickupChartReport,
	HistogramReport,
} from './types';
import { DatePickerOption } from '../../components/controls/date-picker-control/utils';
import {
	HistogramQuery,
	SearchResponse,
	WasteCollectionResponse,
} from '../../api/histogram';
import { Optional } from '../types';

export const avgFillSelector = (state: ApplicationState): number =>
	state.dashboard.dashboardStats.avgFill;
export const awaitingPickupSelector = (state: ApplicationState): number =>
	state.dashboard.dashboardStats.awaitingPickup;
export const binsTotalSelector = (state: ApplicationState): number =>
	state.dashboard.dashboardStats.binsTotal;
export const avgFillChartFilterSelector = (
	state: ApplicationState,
): DatePickerOption => state.dashboard.avgFillChartFilter;
export const avgFillChartSearchQuerySelector = (
	state: ApplicationState,
): Optional<Partial<HistogramQuery>> => state.dashboard.avgFillChartSearchQuery;
export const avgFillChartDataSelector = (
	state: ApplicationState,
): Array<AvgFillChartReport> => state.dashboard.avgFillChartData;
export const awaitingPickupChartDataSelector = (
	state: ApplicationState,
): Array<AwaitingPickupChartReport> => state.dashboard.awaitingPickupChartData;
export const todayMissedPickupCountSelector = (
	state: ApplicationState,
): number => state.dashboard.todayMissedPickupCount;
export const missedPickupChartDataSelector = (
	state: ApplicationState,
): Array<HistogramReport> => state.dashboard.missedPickupChartData;
export const totalBagsCountSelector = (state: ApplicationState): number =>
	state.dashboard.totalBagsCount;
export const avgPickupFillChartDataSelector = (
	state: ApplicationState,
): Optional<SearchResponse> => state.dashboard.avgPickupFillChartData;
export const wasteCollectionPanelDataSelector = (
	state: ApplicationState,
): Optional<WasteCollectionResponse> =>
	state.dashboard.wasteCollectionPanelData;
export const bagsChartDataSelector = (
	state: ApplicationState,
): Array<HistogramReport> => state.dashboard.bagsChartData;
