import React, { useEffect, useState } from 'react';
import style from './animated-option-label.module.scss';
import { Option, Optional } from '../../../redux/types';
import classNames from 'classnames';

export interface AnimatedOptionLabelProps {
    className?: Optional<string>;
    option: Option;
    direction?: 'up' | 'down';
}

export const AnimatedOptionLabel: React.FC<AnimatedOptionLabelProps> = ({
    className = null,
    direction = 'up',
    option
}) => {
    const [currentOption, setCurrentOption] = useState(option);
    const [previousOption, setPreviousOption] = useState(option);
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        if (option.value !== currentOption.value){
            setPreviousOption({...currentOption});
            setCurrentOption({...option});
            setIsTransitioning(true);
        }
    }, [option, currentOption, previousOption, setIsTransitioning]);

    useEffect(() => {
        isTransitioning && setTimeout(() => {
            setIsTransitioning(false);
        }, 500);
    }, [isTransitioning, setIsTransitioning, setPreviousOption]);

    const directionStyle = direction === 'down' ? style.down : style.up;

    return (
        <div className={classNames(style.AnimatedOptionLabel, directionStyle, {[style.transitioning]: isTransitioning}, className)}>
            <div className={style.previousLabel}><div>{ previousOption.label }</div></div>
            <div className={style.currentLabel}><div>{ currentOption.label }</div></div>
        </div>
    );
}