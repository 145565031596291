import React, { useEffect } from 'react';

/**
 * Custom hook that is listening to any clicks outside of the given referenced element and calls the passed eventHandler
 * @param targetElementRef The element to listen if clicked outside of.
 * @param type The event type
 * @param eventHandler The event handler that should be called.
 */
export const useDocumentEventListener = (
	targetElementRef: React.RefObject<HTMLElement>,
	type: string,
	eventHandler: (event: Event) => void,
): void => {
	/**
	 * The event handler function that is registered for the event on the document
	 *
	 * @param event The triggered event
	 */
	const documentEventHandler = (event: Event): void => {
		// Check if the clicked element is not the passed target element or one of its children
		if (
			targetElementRef.current &&
			!targetElementRef.current.contains(event.target as HTMLElement)
		) {
			// Call the outside event handler
			eventHandler(event);
		}
	};

	// We need the useEffect hook to have a defined targetElementRef
	useEffect(() => {
		// Listen to any events on the document using our previously defined documentEventHandler
		document.addEventListener(type, documentEventHandler);

		// Clean things up by removing the documentEventHandler
		return () => {
			document.removeEventListener(type, documentEventHandler);
		};
	});
};
