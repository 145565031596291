import classNames from 'classnames';
import React, { ReactElement, ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as UserIcon } from '../../../assets/img/icons/icon.profile-pic.svg';
import { useClientRefreshCallback } from '../../../redux/auth/effects';
import { Optional } from '../../../redux/types';
import { deleteUser } from '../../../redux/user/actions';
import { useUsersData } from '../../../redux/user/effects';
import { usersSelector } from '../../../redux/user/selectors';
import { User, UserAsyncDispatch } from '../../../redux/user/types';
import { UserSettingsModal } from '../add-user-modal/user-settings-modal';
import { ReactComponent as ProfilePicIcon } from './../../../assets/img/icons/icon.profile-pic.svg';
import { ReactComponent as BinIcon } from './../../../assets/img/icons/icon.bin.svg';
import { UserRow } from './user-row';
import { UsersSettingsDisplayedModal } from './users-settings-types';
import style from './users-settings.module.scss';
import { Access } from '../../controls/access/access';
import { AnimatedListContainer } from '../animated-list-container/animated-list-container';
import { userSelector } from '../../../redux/auth/selectors';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { LightTooltip } from '../../controls/light-tooltip/light-tooltip';
import { Box, Fab } from '@material-ui/core';
import { ListSkeleton } from '../../mui/skeletons/ListSkeleton';
import AddIcon from '@material-ui/icons/Add';
import { LidbotListHeader } from '../../mui/LidbotListHeader';
import { Modal } from '../../controls/modal/modal';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';
import { DeleteModal } from '../../controls/modal/deleteModal';

export const UsersSettings: React.FC<{}> = () => {
  const [incRC, decRC] = useLoadingBarDispatch();

  /** USERS DATA **/
  useUsersData();
  const users = useSelector(usersSelector);
  const [selectedUser, setSelectedUser] = useState<Optional<User>>(null);

  // Reset state data when client id is changed
  useClientRefreshCallback(() => {
    setDisplayedModal("none");
    setSelectedUser(null);
    setUserSettingsUser(null);
    setDeleteModalUser(null);
  });

  /** MODALS **/
  const [displayedModal, setDisplayedModal] =
    useState<UsersSettingsDisplayedModal>("none");
  const closeModal = (): void => {
    setDisplayedModal("none");
  };

  /**
   * USER SETTINGS MODAL
   **/
  const [userSettingsUser, setUserSettingsUser] = useState<User | null>(null);
  const displayUserSettingsModal = (user: User | null): void => {
    setUserSettingsUser(user);
    setSelectedUser(null);
    setDeleteModalUser(null);
    setDisplayedModal("user-settings");
  };

  /**
   * Delete Modal
   * */
  // Data and event handler
  const [deleteModalUser, setDeleteModalUser] = useState<User | null>(null);
  const handleOnDeleteUser = (user: User): void => {
    setSelectedUser(null);
    setDeleteModalUser(user);
    setDisplayedModal("delete-user");
  };

  // Redux delete user functions
  const userAsyncDispatch = useDispatch<UserAsyncDispatch>();
  const deleteModalOnDeleteHandler = (): void => {
    if (deleteModalUser) {
      incRC();
      userAsyncDispatch(deleteUser(deleteModalUser))
        .then(() => {
          decRC();
          setDisplayedModal("none");
        })
        .catch(() => decRC());
    }
  };

  // Delete modal node
  const deleteUserFullName = [
    deleteModalUser?.given_name,
    deleteModalUser?.family_name,
  ]
    .filter((s) => s && s.length > 0)
    .join(" ");
  const identifierName = deleteUserFullName
    ? deleteUserFullName
    : deleteModalUser?.email;
  const deleteModalIcon = (
    <Box className={style.deleteModalIconWrapper}>
      <UserIcon className={style.icon} />
      <Box className={style.symbol}>
        <Box />
      </Box>
    </Box>
  );
  
  const lidbotUser = useSelector(userSelector);
  const userList: Array<ReactElement> =
    users?.map((user) => (
      <UserRow
        key={user.email}
        user={user}
        lidbotUser={lidbotUser}
        selected={selectedUser?.email === user.email}
        onDeselect={() => setSelectedUser(null)}
        onSelect={() => setSelectedUser(user)}
        onDelete={() => handleOnDeleteUser(user)}
        onEdit={displayUserSettingsModal}
      />
    )) || [];

  return (
    <Box className={style.UsersSettings}>
      {displayedModal === "user-settings" && (
        <UserSettingsModal
          user={userSettingsUser}
          onModalClose={() => closeModal()}
        />
      )}
      {displayedModal === "delete-user" &&
        deleteModalUser &&
      <DeleteModal
          isOpen={true}
          onClose={closeModal}
          onDelete={deleteModalOnDeleteHandler}
          objectClass="User"
          objectName={deleteUserFullName}
      />
      }
      <Box>
        <LidbotListHeader addRowTooltipTitle="Add new user" addRowHandler={() => displayUserSettingsModal(null)}/>
        <Box className={style.UsersTableHeader}>
          <Box className={classNames(style.headerCell, style.profilePicCell)}>
            <ProfilePicIcon />
          </Box>
          <Box className={style.flexibleCells}>
            <Box className={classNames(style.headerCell, style.nameCell)}>
              Name
            </Box>
            <Box className={classNames(style.headerCell, style.emailCell)}>
              E-mail
            </Box>
            <Box className={classNames(style.headerCell, style.roleCell)}>
              <LightTooltip
                title={""}
                contentType="roles"
              >
                <Box>Role</Box>
              </LightTooltip>
            </Box>
            <Box className={classNames(style.headerCell, style.sensorsCell)}>
              <LightTooltip
                title={
                  <>
                    <h2>Responsible bins</h2>
                    <span>The number of bins the user is responsible for.</span>
                  </>
                }
              >
                <BinIcon />
              </LightTooltip>
            </Box>
          </Box>
          <Box className={classNames(style.headerCell, style.statusCell)}>
            <LightTooltip
              title={
                <>
                  <h2>{"User's Status:"}</h2>
                  <ul>
                    <li>
                      <h4>Confirmed</h4>
                      <span>
                        The users has confirmed their account by following the
                        instructions in the invitation email.
                      </span>
                    </li>
                    <li>
                      <h4>Pending invitation</h4>
                      <span>
                        The user was added to the system but has not yet
                        confirmed their account. Please note that users in this
                        state will still receive notifications and reports
                      </span>
                    </li>
                  </ul>
                </>
              }
            >
              <Box>Status</Box>
            </LightTooltip>
          </Box>
          <Box className={style.optionsCell} />
        </Box>
        {
          users ? (
              <AnimatedListContainer>{userList}</AnimatedListContainer>
          ) : (
            <ListSkeleton />
          )}
      </Box>
    </Box>
  );
};
