import React from 'react';
import style from './waste-collection-chart.module.scss';
import classNames from 'classnames';
import { WasteCollectionDateBucket, WasteCollectionTypeBucket } from '../../../api/histogram';
import { WasteAvatar } from '../waste-avatar/waste-avatar';
import _ from 'lodash';

export interface AvgFillAtPickupChartProps {
  buckets?: Array<WasteCollectionDateBucket>;
  precision: number;
  alertThreshold: number;
}

export const WasteCollectionChart: React.FC<AvgFillAtPickupChartProps> = (
  props: AvgFillAtPickupChartProps
) => {
  const first = _.first(props.buckets);
  const last = _.last(props.buckets);

  return (
    <div className={style.TopBinsChart}>
      {last &&
        _.map(
          last.waste_type_histogram.buckets,
          function (bucket: WasteCollectionTypeBucket) {
            let change;

            if (first) {
              const correspondingWasteTypeBucket = _.find(
                first.waste_type_histogram.buckets,
                (bucket) => bucket.key === bucket.key
              );

              if (correspondingWasteTypeBucket) {
                const diff =
                  bucket.waste_type_stats.sum -
                  correspondingWasteTypeBucket.waste_type_stats.sum;
                change = Math.floor(
                  (diff / correspondingWasteTypeBucket.waste_type_stats.sum) *
                    100
                );
              }
            }

            return (
              <div
                key={bucket.key}
                className={classNames(style.binRow, {
                  [style.hasAlert]: false,
                })}
              >
                <div className={style.volume}>
                  {bucket.waste_type_stats.sum > 0 &&
                    `${bucket.waste_type_stats.sum} l`}
                </div>
                <div className={style.wasteType}>
                  {bucket.key.toLocaleLowerCase()}
                </div>
                <div
                  className={classNames(style.change, {
                    [style.minus]: change && change < 0,
                    [style.plus]: change && change > 0,
                  })}
                >
                  {change && <span>{change}%</span>}
                </div>
                <div className={style.icon}>
                  <WasteAvatar size={48} type={bucket.key} darkMode={false} />
                </div>
              </div>
            );
          }
        )}
    </div>
  );
};
