import { useDispatch, useSelector } from 'react-redux';
import { clientIdSelector } from '../auth/selectors';
import { useEffect } from 'react';
import {
	loadAvgPickupFillChartData,
	loadBagsChartData,
	loadReportHistogram,
	loadSensorStatistics,
	loadWasteCollectionData,
} from './actions';
import {
	avgFillChartFilterSelector,
	avgFillChartSearchQuerySelector,
} from './selectors';
import { DashboardAsyncDispatch } from './types';
import { getSearchQueryForDatePickerOption } from '../../components/controls/date-picker-control/utils';
import { accountSelector } from '../account/selectors';
import { useLoadingBarDispatch } from '../loading-bar/effects';

export const useDashboardDataLoader = (): void => {
	// clientId will track when support changes clients and automatically refresh
	const clientId = useSelector(clientIdSelector);

	// Track when account has been loaded
	const account = useSelector(accountSelector);

	const avgFillChartFilter = useSelector(avgFillChartFilterSelector);
	const filterValue = avgFillChartFilter.value;

	const filterSearchQuery = useSelector(avgFillChartSearchQuerySelector);
	const startTimestamp = filterSearchQuery?.['created_on.gte'] || null;
	const endTimestamp = filterSearchQuery?.['created_on.lt'] || null;

	const dashboardAsyncDispatch = useDispatch<DashboardAsyncDispatch>();
	const [incRC, decRC] = useLoadingBarDispatch();

	/**
	 * Custom report queries
	 * **/
	useEffect(() => {
		if (filterValue === 'custom' && startTimestamp && endTimestamp) {
			const searchQuery = {
				'created_on.gte': startTimestamp,
				'created_on.lt': endTimestamp,
			};
			if (account) {
				incRC();
				dashboardAsyncDispatch(loadReportHistogram(searchQuery))
					.then(() => decRC())
					.catch(() => decRC());
			}
		}
		//eslint-disable-next-line
	}, [account, filterValue, startTimestamp, endTimestamp]);

	/** Preset report queries **/
	useEffect(() => {
		if (filterValue !== 'custom' && filterSearchQuery === null) {
			const searchQuery = getSearchQueryForDatePickerOption(filterValue);
			if (account && searchQuery) {
				incRC();
				dashboardAsyncDispatch(loadReportHistogram(searchQuery))
					.then(() => decRC())
					.catch(() => decRC());
			}
		}
		//eslint-disable-next-line
	}, [account, filterValue, filterSearchQuery]);

	useEffect(() => {
		if (clientId) {
			incRC();
			Promise.all([
				dashboardAsyncDispatch(loadSensorStatistics()),
				dashboardAsyncDispatch(loadBagsChartData()),
				dashboardAsyncDispatch(loadAvgPickupFillChartData()),
				dashboardAsyncDispatch(
					loadWasteCollectionData('week', 'now-2w/w', 'now/w'),
				),
			])
				.then(() => decRC())
				.catch(() => decRC());
		}
		//eslint-disable-next-line
	}, [clientId]);
};
