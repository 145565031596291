import React from 'react';
import style from './progress-indicator.module.scss';
import { ProgressIndicatorStep } from '../../controls/progress-indicator-step/progress-indicator-step';

export interface ProgressStep {
    label: string;
    hint: string;
}

export interface ProgressIndicatorProps {
    steps: Array<ProgressStep>;
    currentIndex: number;
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = (props) => {
    return (
        <div className={style.ProgressIndicator}>
            {props.steps.map((step: ProgressStep, index: number) => {
                const badge = index + 1;
                if (badge < props.currentIndex) {
                    return <ProgressIndicatorStep badge={badge} key={index} {...step} done />
                }

                if (badge === props.currentIndex) {
                    return <ProgressIndicatorStep badge={badge} key={index} {...step} active />
                }

                return <ProgressIndicatorStep badge={badge} key={index} {...step}/>
            })}
        </div>
    );
}
