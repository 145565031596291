import React from 'react';
import style from './floating-panel.module.scss';
import classNames from 'classnames';

export interface FloatingPanelProps {
    /** The children for the floating panel */
    children: React.ReactNode,
    /** Weather to collapse the padding or not */
    collapsed?: boolean;
}

/**
 * The panel that floats in the middle of the screen on public accessible pages.
 *
 * @param props
 */
export const FloatingPanel: React.FC<FloatingPanelProps> = (props) => {
    const {children, collapsed = false} = props;
    return (
        <div className={classNames(style.FloatingPanel, {[style.collapsed]: collapsed})}>
            {children && children}
        </div>
    );
}
