import React from 'react';
import style from './radio.module.scss';
import { Option } from '../../../redux/types';
import { FormLabel } from '../../building-blocks/form-label/form-label';
import { RadioInput, RadioValueType } from '../radio-input/radio-input';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { contentTypes } from '../light-tooltip/light-tooltip';
import { Help } from '../help/help';
import { Box } from '@material-ui/core';

export interface RadioOption extends Option {
  label: string;
  value: RadioValueType;
}

export interface RadioProps {
  label?: string;
  required?: boolean;
  value: RadioValueType;
  name: string;
  options: Array<RadioOption>;
  onChange: (option: RadioOption) => void;
  readonly?: boolean;
  helpChildren?: NonNullable<React.ReactNode>
  helpContentType?: contentTypes;
}

export const Radio: React.FC<RadioProps> = (props: RadioProps) => {

  const readonly = props.readonly || false;

  const onChangeHandler = (value: RadioValueType): void => {
    const selectedOption = props.options.find(option => option.value === value);
    selectedOption && props.onChange(selectedOption);
  };

  const optionsStyle = style[`options-${props.options.length}`];

  return (
    <div className={classNames(style.Radio, optionsStyle, { [style.readonly]: readonly })}>
      <Box
        display='flex'
        alignItems='center'
      >
        {
          props.label &&
          <Box>
            <FormLabel for={props.name} required={true}>
              {props.label}
            </FormLabel>
          </Box>
        }
        {
          props.helpContentType &&
          <Box>
            <Help
              contentType={props.helpContentType}
            >
              <div />
            </Help>
          </Box>
        }
      </Box>
      <div className={style.radioOptions}>
        {
          props.options.map((option) => (
            <div key={option.value} className={style.radioOption}>
              <label>
                <RadioInput
                  value={option.value}
                  name={props.name}
                  checked={option.value === props.value}
                  onChange={onChangeHandler} />
                {option.i18nKey ? <Trans i18nKey={option.i18nKey} /> : option.label}
              </label>
            </div>
          ))
        }
      </div>
    </div>
  );
};