export const getInitials = (firstName?: string, lastName?: string): string =>
	[firstName, lastName]
		.filter((s) => s && s.length > 0)
		.map((s) => s?.charAt(0))
		.join('');

export const getFullName = (firstName?: string, lastName?: string): string =>
	[firstName, lastName].filter((s) => s && s.length > 0).join(' ');

export const getCamelCase = (s: string): string =>
	s
		.replace(/[^a-zA-Z0-9]+(.)/g, (_, char) => char.toUpperCase())
		.replace(/[^a-zA-Z0-9]/g, '');

export const getStringShortenedAtLength =
	(n: number) =>
	(s: string): string => {
		return s.length <= n ? s : s.slice(0, n - 3) + '...';
	};

export const copyStringToClipboard = (s: string): boolean => {
	try {
		const tempInput = document.createElement('input');
		tempInput.value = s;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand('copy');
		document.body.removeChild(tempInput);
		return true;
	} catch {
		return false;
	}
};

export const getUserName = (
	givenName?: string,
	familyName?: string,
): string => {
	if (givenName && familyName) {
		return `${givenName} ${familyName}`;
	}

	if (givenName && !familyName) {
		return givenName;
	}

	if (!givenName && familyName) {
		return familyName;
	}

	return '';
};

export const convertHexToRGBA = (hexCode: string, opacity: number) => {
	let hex = hexCode.replace('#', '');

	if (hex.length === 3) {
		hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
	}

	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const validateEmail = (email: string) => {
	const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regexp.test(email);
}
