import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { ReactComponent as ExpandIcon } from './../../assets/img/icons/icon.expand.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      paddingLeft: '12px !important'
    },
    option: {
      fontSize: 13,
      padding: '16px 24px',
      borderBottom: '1px solid',
      borderImageSource: 'linear-gradient(90deg ,transparent,hsla(0,0%,84.7%,.2) 24px,#d8d8d8 50%,hsla(0,0%,84.7%,.2) calc(100% - 24px),transparent)',
      borderImageSlice: 1,
      '&:last-child': {
        borderBottom: 'none'
      }
    }
  }));

export const StyledAutocomplete: React.FC<AutocompleteProps<any, any, any, any>> = (props) => {
  const classes = useStyles();
  
  return (
    <Autocomplete
      options={props.options}
      value={props.value}
      classes={{
        option: classes.option,
        input: classes.input,
        inputRoot: classes.input,
      }}
      getOptionLabel={(option) => props.getOptionLabel ? props.getOptionLabel(option) : ''}
      onChange={(e, value, reason, details) => props.onChange && props.onChange(e, value, reason, details)}
      style={props.style}
      renderInput={props.renderInput}
      popupIcon={<ExpandMoreIcon/>}
    />
  );
};