import React, { useState } from 'react';
import style from './sensor-row.module.scss';
import classNames from 'classnames';
import { FlexibleRowCell, HighlightedCellGroup, OptionsRowCell, Row, StaticRowCell } from '../../controls/row/row';
import { ReactComponent as PinIcon } from '../../../assets/img/icons/icon.pin.svg';
import { ReactComponent as BellCrossedIcon } from '../../../assets/img/icons/icon.bell-crossed.svg';
import { ReactComponent as BellIcon } from '../../../assets/img/icons/icon.bell.svg';
import { Location, SensorState } from '../../../api/contracts';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { thresholdSelector } from '../../../redux/account/selectors';
import { useAccess } from '../../../hooks/access';
import { BatteryLevelDisplay } from '../../controls/battery-level-display/battery-level-display';
import { SignalStrengthDisplay } from '../../controls/signal-strength-display/signal-strength-display';
import { DateTime } from 'luxon';
import { LightTooltip } from '../../controls/light-tooltip/light-tooltip';

const GOOD_SIGNAL_THRESHOLD = 20;
export interface SensorRowProps {
    sensorId: string;
    isPinned?: boolean;
    fillPercentage?: number;
    nextReport?: number;
    type?: string;
    name?: string;
    location?: Location;
    lastReport?: number | null;
    battery?: number;
    signal?: number;
    muted?: boolean;
    state: SensorState;
    onClick?: () => void;
    onPinClick?: (isPinned: boolean) => void;
}
export const SensorRow: React.FC<SensorRowProps> = ({
    sensorId,
    name = '',
    isPinned = false,
    fillPercentage,
    nextReport,
    type= '',
    lastReport,
    battery,
    signal,
    muted,
    state,
    onClick,
    onPinClick
}) => {
    let isNotResponding = false
    const NOT_REPOSNDING_THRESHOLD = 24

    if(lastReport) {
        const date1 = DateTime.fromSeconds(lastReport)
        const date2 = DateTime.now()

        const diff = date1.diff(date2, ["hours"])

        if(diff.hours < -NOT_REPOSNDING_THRESHOLD) {
            isNotResponding = true
        }
    }

    const nameLabel = name || sensorId;

    const threshold = useSelector(thresholdSelector);
    const isOverThreshold = threshold && fillPercentage && fillPercentage >= threshold;

    const timestampToHumanizedDuration = (nextReport?:number): string => {
        if (!nextReport) return '';
        const mNow = moment();
        const mReport = moment(nextReport * 1000);
        const duration = moment.duration(mReport.diff(mNow));
        return duration.humanize(true);
    }

    const hasPinningAccess = useAccess('ADMIN');
    const [pinState, setPinState] = useState<boolean>(isPinned);
    const onTogglePinStateHandler = (): void => {
        if (hasPinningAccess) {
            setPinState(!pinState);
            onPinClick && onPinClick(!pinState);
        }
    }

    return (
        <Row
            className={
                classNames(style.SensorRow, style.row,
                    {[style.alert]: isOverThreshold,
                        [style.notResponding]: isNotResponding,
                        [style.isPinned]: pinState, [style.muted]: muted})}
            onClick={() => onClick && onClick()}
        >
            {/*<IconRowCell className={style.iconCell}>*/}
            {/*    { state === SensorState.CONNECTED && <div className={style.dot}/>}*/}
            {/*</IconRowCell>*/}
            <HighlightedCellGroup className={style.highlightGroup}>
                {/*<div className={style.strip}/>*/}
                <StaticRowCell className={style.fillPctCell}>
                    { fillPercentage && <span>{fillPercentage}%</span>}
                </StaticRowCell>
                <StaticRowCell className={style.nextReportCell}>
                    { timestampToHumanizedDuration(nextReport)}
                </StaticRowCell>
                <FlexibleRowCell className={style.typeCell}>
                    { type}
                </FlexibleRowCell>
                <FlexibleRowCell className={style.nameCell}>
                    { nameLabel }<em>.</em>
                </FlexibleRowCell>
                <StaticRowCell className={style.lastReportCell}>
                    {
                        lastReport &&
                        timestampToHumanizedDuration(lastReport)
                    }
                </StaticRowCell>
                <StaticRowCell className={style.batteryCell}>
                    {
                        (battery) &&
                        <BatteryLevelDisplay percentage={battery} />
                    }
                    {
                        (signal) &&
                        <SignalStrengthDisplay signal={signal}/>
                    }
                    {
                        muted &&
                        <LightTooltip
                            title={
                                <>
                                    <p>{"Sensor is muted"}</p>
                                </>
                            }
                            severity="error"
                        >
                            <BellCrossedIcon />
                        </LightTooltip>
                    }
                    {
                        !muted &&
                        <LightTooltip
                            title="Notifications are enabled for this sensor."
                            severity="error"
                        >
                            <BellIcon />
                        </LightTooltip>
                    }
                </StaticRowCell>
            </HighlightedCellGroup>
            <OptionsRowCell className={style.optionsCell} onClick={onTogglePinStateHandler} roleHierarchy='ADMIN'>
                <LightTooltip
                    title={""}
                    contentType="pin"
                    enterDelay={1000}
                    enterNextDelay={200}
                >
                    <PinIcon/>
                </LightTooltip>
            </OptionsRowCell>
        </Row>
    )
}