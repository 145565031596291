import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import React, { ReactNodeArray, useEffect, useState } from 'react';
import style from './api-keys.module.scss';
import { Modal } from '../../controls/modal/modal';
import { API } from 'aws-amplify';
import { FormLabel } from '../../building-blocks/form-label/form-label';
import { DeepPartial, Optional, RequestOptions } from '../../../redux/types';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';
import { APIKey } from './api-keys-types';
import { copyStringToClipboard } from '../../../utils/string';
import { CellGroup, FlexibleRowCell, HeaderRowCell, HighlightedCellGroup, OptionsRowCell, Row, StaticRowCell } from '../../controls/row/row';
import { FlyOver, FlyOverOption } from '../../controls/fly-over/fly-over';
import { ReactComponent as EllipsisIcon } from '../../../assets/img/icons/icon.ellipsis.svg';
import moment from 'moment';
import { useClientRefreshCallback } from '../../../redux/auth/effects';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import config from '../../../config/config';
import { Box, TextField, Tooltip, Typography } from '@material-ui/core';
import { ListSkeleton } from '../../mui/skeletons/ListSkeleton';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../../redux/account/selectors';
import { LidbotListHeader } from '../../mui/LidbotListHeader';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';
import { LightTooltip } from '../../controls/light-tooltip/light-tooltip';
import { DeleteModal } from '../../controls/modal/deleteModal';


export const ApiKeys: React.FC = () => {
  const [incRC, decRC] = useLoadingBarDispatch();
  
  const account = useSelector(accountSelector);
  
  useClientRefreshCallback(() => {
    setSelectedApiKey(null);
    setSettingModalApiKey(null);
    setDeleteModalApiKey(null);
    setApiKeys(null);
  });
  
  const [apiKeys, setApiKeys] = useState<Optional<Array<APIKey>>>(null);
  useEffect(() => {
    incRC();
    API.get(config.API.name, '/keys', {})
      .then(({ data }) => {
        decRC();
        setApiKeys(data as Array<APIKey>);
      })
      .catch((e) => {
        decRC();
        notifyError('Failed to get api key list', notifyErrorMessage(e));
      });
  }, [account]);
  
  const [selectedApiKey, setSelectedApiKey] = useState<Optional<APIKey>>(null);
  
  const setSelectedApiKeyPostRender = (apiKey: APIKey) => () => {
    setTimeout(() => {
      setSelectedApiKey(apiKey);
    }, 0);
  };
  
  const onCreateApiKey = (key: DeepPartial<APIKey>): void => {
    
    const options: RequestOptions = { body: key };
    
    incRC();
    
    API.post(config.API.name, `/keys/${key.key_id ? key.key_id : ''}`, options)
      .then((apiKey: APIKey) => {
        const _apiKeys = apiKeys?.map((k) =>
          k.key_id === apiKey.key_id ? apiKey : k
        ) || [apiKey];
        setApiKeys([..._apiKeys, apiKey]);
        setSettingModalApiKey(apiKey)
        notifySuccess('Api key saved');
        decRC();
      })
      .catch((e) => {
        notifyError('Api key not saved', notifyErrorMessage(e));
        decRC();
      });
  };
  
  const onDeleteApiKey = (): void => {
    const apiKeyId = deleteModalApiKey?.key_id || null;
    if (apiKeyId) {
      incRC();
      API.del(config.API.name, `/keys/${apiKeyId}`, {})
        .then(() => {
          setApiKeys(apiKeys?.filter((k) => k.key_id !== apiKeyId) || []);
          setDeleteModalApiKey(null);
          setDisplayedModalType('none');
          notifySuccess('Api key deleted');
          decRC();
        })
        .catch((e) => {
          notifyError('Api key not deleted', notifyErrorMessage(e));
          decRC();
        });
    }
  };
  
  const [displayedModalType, setDisplayedModalType] =
    useState<'none' | 'settings' | 'delete'>('none');
  const onCloseModal = (): void => setDisplayedModalType('none');
  
  // Settings
  const [settingModalApiKey, setSettingModalApiKey] =
    useState<Optional<APIKey>>(null);
  const displaySettingModalForApiKey = (apiKey: Optional<APIKey>) => () => {
    setSelectedApiKey(null);
    setSettingModalApiKey(apiKey);
    setDisplayedModalType('settings');
  };
  
  // Delete
  const [deleteModalApiKey, setDeleteModalApiKey] =
    useState<Optional<APIKey>>(null);
  const displayDeleteModalForApiKey = (apiKey: APIKey) => () => {
    setSelectedApiKey(null);
    setDeleteModalApiKey(apiKey);
    setDisplayedModalType('delete');
  };
  
  // Copy
  const onCopyApiKeyHandler = (apiKey: APIKey) => () => {
    const success =
      apiKey.plain_api_key && copyStringToClipboard(apiKey.plain_api_key);
    if (success) notifySuccess('Api key copied');
    else notifyError('Api key not copied');
  };
  
  const apiKeyRows: ReactNodeArray =
    apiKeys?.map((apiKey) => {
      const flyOverOptions: Array<FlyOverOption> = [
        {
          label: 'Edit',
          onClick: displaySettingModalForApiKey(apiKey)
        }
      ];
      
      const destructiveOption: FlyOverOption = {
        label: 'Delete',
        onClick: displayDeleteModalForApiKey(apiKey)
      };
      
      return (
        <Row
          key={apiKey.key_id}
          className={style.row}
          selected={selectedApiKey?.key_id === apiKey.key_id}
          onDeselect={() => setSelectedApiKey(null)}
        >
          <HighlightedCellGroup className={style.highlightGroup}>
            <FlexibleRowCell className={style.nameCell}>
              {apiKey.key_name}
            </FlexibleRowCell>
            <StaticRowCell className={style.createdCell}>
              {moment.unix(apiKey.created_on).format('YYYY/MM/DD')}
            </StaticRowCell>
            <StaticRowCell className={style.lastUsedCell}>
              {apiKey.last_used &&
              moment.unix(apiKey.last_used).format('YYYY/MM/DD')}
            </StaticRowCell>
            <FlexibleRowCell className={style.apiKeyCell}>
              <Box className={style.apiKeyLabel}>{apiKey.plain_api_key}</Box>
              <Box className={style.copyAPIButton}>
                <Box onClick={onCopyApiKeyHandler(apiKey)}>
                  <LightTooltip title="Copy">
                    <FileCopyOutlinedIcon/>
                  </LightTooltip>
                </Box>
              </Box>
            </FlexibleRowCell>
          </HighlightedCellGroup>
          <OptionsRowCell
            className={style.optionsCell}
            onClick={setSelectedApiKeyPostRender(apiKey)}
            flyover={
              <FlyOver
                options={flyOverOptions}
                destructiveOption={destructiveOption}
              />
            }
            roleHierarchy='DEVELOPER'
          >
            <EllipsisIcon />
          </OptionsRowCell>
        </Row>
      );
    }) || [];
  
  return (
    <Box className={style.ApiKeys}>
      {displayedModalType === 'settings' && (
        <APIKeySettingsModal
          apiKey={settingModalApiKey}
          onCreateApiKey={(key) => onCreateApiKey(key)}
          onCloseModal={onCloseModal}
        />
      )}
      <LidbotListHeader addRowHandler={displaySettingModalForApiKey(null)} addRowTooltipTitle='Add new API key' />
      {displayedModalType === 'delete' &&
      <DeleteModal
          isOpen={true}
          onClose={onCloseModal}
          onDelete={onDeleteApiKey}
          objectClass="API key"
          objectName={deleteModalApiKey?.key_name}
      />
      }
      <Row className={style.header} rowClassName='header'>
        <CellGroup>
          <HeaderRowCell
            className={style.nameCell}
            rowCellClassName='flexible'
            hasBorder={true}
          >
            Name
          </HeaderRowCell>
          <HeaderRowCell
            className={style.createdCell}
            rowCellClassName='static'
            hasBorder={true}
          >
            Created on
          </HeaderRowCell>
          <HeaderRowCell
            className={style.lastUsedCell}
            rowCellClassName='static'
            hasBorder={true}
          >
            Last used
          </HeaderRowCell>
          <HeaderRowCell
            className={style.apiKeyCell}
            rowCellClassName='flexible'
          >
            API Key
          </HeaderRowCell>
        </CellGroup>
        <HeaderRowCell
          className={style.optionsCell}
          rowCellClassName='options'
        />
      </Row>
      {
        apiKeys ? (
          apiKeyRows
        ) : (
          <ListSkeleton hideAvatar={true} />
        )
      }
    </Box>
  );
};


interface APIKeySettingsModalProps {
  apiKey: Optional<APIKey>;
  onCreateApiKey?: (apiKey: DeepPartial<APIKey>) => void;
  onCloseModal?: () => void;
}


const APIKeySettingsModal: React.FC<APIKeySettingsModalProps> = ({
                                                                   apiKey,
                                                                   onCreateApiKey,
                                                                   onCloseModal
                                                                 }) => {
  const actionType = apiKey ? 'Update' : 'New';
  
  const [name, setName] = useState<string>(apiKey?.key_name || '');
  const [nameError, setNameError] = useState<string>('');
  const apiKeyText = apiKey?.plain_api_key || '';
  
  const onClickCopy = (): void => {
    const success = apiKeyText && copyStringToClipboard(apiKeyText);
    if (success) notifySuccess('Api key copied');
    else notifyError('Api key copy failed');
  };
  
  useEffect(() => {
    setNameError('')
  }, [name])
  
  const validate = () : boolean => {
    let validationFailed = false
    
    if(!name) {
      setNameError('API Key name required.')
      validationFailed = true
    }
    
    return !validationFailed
  }
  
  return (
    <Modal
      isOpen={true}
      onClose={() => onCloseModal && onCloseModal()}
      title={`${actionType} API Key`}
      maxWidth='xs'
      content={(
        <Box className={style.ApiKeys}>
          <Box marginBottom={1}>
            <TextField
              type='text'
              label='Name'
              value={name}
              variant='filled'
              error={!!nameError}
              helperText={nameError ? nameError : ' '}
              fullWidth
              onChange={(s) => setName(s.target.value)}
            />
          </Box>
          <Box>
            <FormLabel>API Key</FormLabel>
            <Box className={style.apiKeyStatusDisplay}>
              <Box className={style.apiKeyInput}>
                {apiKeyText}
                {apiKeyText && (
                  <Box
                    className={style.copyAPIButton}
                    onClick={() => onClickCopy()}
                  >
                    <LightTooltip title="Copy">
                      <FileCopyOutlinedIcon/>
                    </LightTooltip>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={style.warningMessage}>
              <Box mb={2}>
                <Typography variant='caption' color='secondary'>
                  Your API keys carry many privileges, so be sure to keep them
                  secure!{' '}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='caption' color='secondary'>
                  Do not share your secret API keys in publicly accessible areas
                  such as GitHub, client-side code, and so forth.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='caption' color='secondary'>
                  Authentication to the API is performed via HTTP Basic Auth. Provide your API key as the basic auth username value. You do not need to provide a password.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      actions={(
        <Box className={style.buttonContainer}>
          <LidbotButtonGroup
            onSecondaryClickHandler={() => onCloseModal && onCloseModal()}
            onPrimaryClickHandler={() => {
              if(validate()) {
                onCreateApiKey && onCreateApiKey({ key_name: name });
              }
            }} primaryLabel="Create" />
        </Box>
      )}
    />
  );
};
