import React, { FC } from 'react';
import { LightTooltip, toolTipSeverityTypes } from '../light-tooltip/light-tooltip';

const WARNING_LEVEL = 75;
const ALERT_LEVEL = 50;

const COLOR_BLACK = 'black';
const COLOR_GRAY = '#E8E8E8'
const COLOR_YELLOW = '#FAB635';
const COLOR_RED = '#A74040';

export const BatteryLevelDisplay: FC<{
    percentage: number;
}> = ({
    percentage
}) => {
    let batteryOutlineFillColor = COLOR_BLACK
    let batteryBarFillColor = COLOR_BLACK
    let toolTipSeverity: toolTipSeverityTypes = 'info'

    const twentyPercentToBarRadius = (pct: number): number => pct > 13 ? 4 : pct > 6 ? 3 : pct > 0 ? 2 : 1;
    const batteryBarRadiuses: number[] = [0,20,40,60,80].map((height) => Math.max(0, percentage! - height)).map(twentyPercentToBarRadius);

    if(percentage < ALERT_LEVEL) {
        batteryOutlineFillColor = COLOR_RED
        batteryBarFillColor = COLOR_RED
        toolTipSeverity = 'error'
    } else if(percentage <= WARNING_LEVEL) {
        batteryOutlineFillColor = COLOR_YELLOW
        batteryBarFillColor = COLOR_YELLOW
        toolTipSeverity = 'warning'
    }

    const batteryBarPath = (index: number, radius: number): string => {
        const fX = (start: number, index: number): number => index * 4 + start;
        const fY = (position: 'top'|'bottom',  radius: number, difference: number): number => position == 'top' ? 6 - radius + difference : 6 + radius - difference;

        const p1 = `M${fX(4, index)} ${fY('top', radius, 0)}`;
        const p2 = `H${fX(3, index)}`;
        const p3 = `C${fX(2.44772, index)} ${fY('top', radius, 0)} ${fX(2, index)} ${fY('top', radius, 0.55229)} ${fX(2, index)} ${fY('top', radius, 1)}`;
        const p4 = `V${fY('bottom', radius, 1)}`;
        const p5 = `C${fX(2, index)} ${fY('bottom', radius, 0.44772)} ${fX(2.44772, index)} ${fY('bottom', radius, 0)} ${fX(3, index)} ${fY('bottom', radius, 0)}`;
        const p6 = `H${fX(4, index)}`;
        const p7 = `C${fX(4.55228, index)} ${fY('bottom', radius, 0)} ${fX(5, index)} ${fY('bottom', radius, 0.44772)} ${fX(5, index)} ${fY('bottom', radius, 1)}`;
        const p8 = `V${fY('top', radius, 1)}`;
        const p9 = `C${fX(5, index)} ${fY('top', radius, 0.55228)} ${fX(4.55228, index)} ${fY('top', radius, 0)} ${fX(4, index)} ${fY('top', radius, 0)}Z`;

        return [p1, p2, p3, p4, p5, p6, p7, p8, p9].join(' ');
    }

    return (
        <LightTooltip
            title={`Battery: ${percentage}%`}
            severity={toolTipSeverity}
        >
            <svg width="22" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* eslint-disable-next-line max-len */}
                <path fillRule="evenodd" clipRule="evenodd" d="M25 2.98742C25.5523 2.98742 26 3.43514 26 3.98742V7.96226C26 8.51455 25.5523 8.96226 25 8.96226H24V2.98742H25ZM23 1.9917V9.95816C23 9.95953 23 9.96089 23 9.96226V11C23 11.5523 22.5523 12 22 12H1C0.447715 12 0 11.5523 0 11V1C0 0.447715 0.447715 0 1 0H22C22.5523 0 23 0.447716 23 1V1.98742C23 1.98885 23 1.99027 23 1.9917ZM21 10.9539C21.5523 10.9539 22 10.5062 22 9.95388V1.99581C22 1.44352 21.5523 0.995807 21 0.995807H2C1.44772 0.995807 1 1.44352 1 1.99581V9.95388C1 10.5062 1.44772 10.9539 2 10.9539H21Z" fill={batteryOutlineFillColor}/>
                { batteryBarRadiuses.map((radius, i) => {
                    return (<path key={i} d={batteryBarPath(i, radius)} fill={ radius !== 1 ? batteryBarFillColor : COLOR_GRAY}/>)
                })}
            </svg>
        </LightTooltip>
    )
}