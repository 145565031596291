import React from 'react';
import style from './progress-indicator-step.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export interface ProgressIndicatorStepProps {
    /** The number to display on the badge */
    badge: string | number;
    /** Label for the step */
    label: string;
    /** Useful hint */
    hint: string;
    /** Display as current step */
    active?: boolean;
    /** Display as finished step */
    done?: boolean;
}

/**
 * Step that is displayed with the progress indicator. Main usage in the password reset page.
 *
 * @param props
 */
export const ProgressIndicatorStep: React.FC<ProgressIndicatorStepProps> = (props) => {
    const { t } = useTranslation();
    const { active = false, done = false } = props;

    return (
        <div className={classNames(style.ProgressIndicatorStep, {[style.active]: active, [style.done]: done})}>
            <span className={style.badge}>
                {props.badge}
            </span>

            <div className={style.label}>
                <span className={style.name}>
                    {t(props.label)}
                </span>
                <span className={style.hint}>
                    {t(props.hint)}
                </span>
            </div>
        </div>
    );
}
