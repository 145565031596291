import { ConfigActions, ConfigActionTypes, ConfigState } from './types';

const initialConfigState: ConfigState = {
	clientAccount: null,
};

export const configReducer = (
	state: ConfigState = initialConfigState,
	action: ConfigActions,
): ConfigState => {
	switch (action.type) {
		case ConfigActionTypes.SET_CLIENT_ACCOUNT: {
			return {
				...state,
				clientAccount: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};
