import { ScheduleSelectorAction } from './types';
import { SensorScheduleDay } from '../../../redux/account/types';
import { sortSensorScheduleDays, sortSensorScheduleTimes } from './utils';

/**
 * This function applies the changes to the week data object and formats it according to the specs
 * of the client api.
 *
 * Uses actions that come from the time cells. Currently the only possible action types are 'add'
 * and 'delete', but 'move' and 'update' are possible new actions that may be added in the future.
 *
 * The function is also responsible for sorting the sensor schedule day data from Sunday to Saturday
 * as well as removing any days which do not have any times data.
 * */
export const weekReducer = (
	week: Array<SensorScheduleDay>,
	action: ScheduleSelectorAction,
): Array<SensorScheduleDay> => {
	const { day } = action;
	const currentDay = week.find((d) => d.day === day) || {
		day: day,
		times: [],
	};
	const newTimes = timesReducer(currentDay.times, action);
	const newDay = { ...currentDay, ...{ times: newTimes } };
	const newWeek = [
		...week.filter((d) => d.day !== day),
		...(newDay.times.length > 0 ? [newDay] : []),
	].sort(sortSensorScheduleDays);
	return newWeek;
};

/**
 * This function is responsible for adding and deleting times from the sensor schedule times array.
 *
 * It is also responsible for sorting the times per the specs of the client api.
 * */
export const timesReducer = (
	times: Array<string> = [],
	action: ScheduleSelectorAction,
): Array<string> => {
	const { type, time } = action;
	switch (type) {
		case 'add': {
			return [...times, time].sort(sortSensorScheduleTimes);
		}
		case 'delete': {
			return times.filter((t) => t !== time);
		}
	}
};
