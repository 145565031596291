import React, { useState } from 'react';
import style from './date-picker-control.module.scss';
import { Calendar, CalendarDay } from '../calendar/calendar';
import { Optional } from '../../../redux/types';
import { ReactComponent as SubtractIcon } from '../../../assets/img/icons/icon.subtract.svg';
import { ReactComponent as PenIcon } from '../../../assets/img/icons/icon.pen.svg';
import moment from 'moment';
import classNames from 'classnames';
import { Fader } from '../../building-blocks/fader/fader';
import { MuiButton } from '../../mui/MuiButton';

export interface CustomRangePickerProps {
    selectedStartTimestamp: number;
    selectedEndTimestamp: number;
    onChangeDateRange?: (startTimestamp:number, endTimestamp:number) => void;
}

export const CustomRangePicker: React.FC<CustomRangePickerProps> = ({
    selectedStartTimestamp,
    selectedEndTimestamp,
    onChangeDateRange
}) => {

    // We want the month number relative to this year. So for example, Jan would be 0, but January from a year before would be -12
    const numYears = moment().diff(moment.unix(selectedStartTimestamp), 'years');
    const [rootCalendarMonth, setRootCalendarMonth] = useState<number>(moment.unix(selectedStartTimestamp).month() - (numYears * 12));

    /** Hover Date */
    const [startTimestamp, setStartTimestamp] = useState<number>(selectedStartTimestamp);
    const [endTimestamp, setEndTimestamp] = useState<number>(selectedEndTimestamp);

    const [adjustState, setAdjustState] = useState<'start'|'end'>('start');
    const [hoverDate, setHoverDate] = useState<Optional<CalendarDay>>(null);
    const adjustedStartTimestamp = adjustState === 'start' ? hoverDate?.value || startTimestamp : startTimestamp;
    const adjustedEndTimestamp = adjustState === 'end' ? hoverDate?.value || endTimestamp : endTimestamp;
    const adjustMaxTimestamp = adjustState === 'start' ? endTimestamp : moment().unix();
    const adjustMinTimestamp = adjustState === 'end' ? startTimestamp : null;

    const onClickDateHandler = (date: CalendarDay): void => {
        if (adjustState === 'start') {
            setStartTimestamp(date.value);
            setAdjustState('end');
        } else {
            setEndTimestamp(date.value);
            setAdjustState('start');
        }
    };

    /** Status Labels */
    const onClickSubtractHandler = (type: 'start' | 'end') => () => {
        setAdjustState(type);
    }

    return (
        <div className={style.customDurationPicker}>
            <div className={style.calendarContainer}>
                <Calendar
                    displayedMonth={rootCalendarMonth}
                    selectedStartTimestamp={startTimestamp}
                    adjustedStartTimestamp={adjustedStartTimestamp}
                    selectedEndTimestamp={endTimestamp}
                    adjustedEndTimestamp={adjustedEndTimestamp}
                    minTimestamp={adjustMinTimestamp}
                    maxTimestamp={adjustMaxTimestamp}
                    onClickPreviousMonth={() => setRootCalendarMonth(rootCalendarMonth - 1)}
                    onMouseEnterDate={(date) => setHoverDate(date)}
                    onMouseLeaveGrid={() => setHoverDate(null)}
                    onClickDate={onClickDateHandler}
                    highlightedHeader={true}
                />
                <div className={style.separator}/>
                <Calendar
                    displayedMonth={rootCalendarMonth + 1}
                    selectedStartTimestamp={startTimestamp}
                    adjustedStartTimestamp={adjustedStartTimestamp}
                    selectedEndTimestamp={endTimestamp}
                    adjustedEndTimestamp={adjustedEndTimestamp}
                    minTimestamp={adjustMinTimestamp}
                    maxTimestamp={adjustMaxTimestamp}
                    onClickNextMonth={() => setRootCalendarMonth(rootCalendarMonth + 1)}
                    onMouseEnterDate={(date) => setHoverDate(date)}
                    onMouseLeaveGrid={() => setHoverDate(null)}
                    onClickDate={onClickDateHandler}
                />
            </div>
            <div className={style.durationPickerControls}>
                <div className={style.durationStatus}>
                    <Fader className={style.iconWrapper} selectedIndex={adjustState === 'start' ? 0 : 1}>
                        <div className={style.statusIcon}><PenIcon/></div>
                        <div onClick={onClickSubtractHandler('start')}><SubtractIcon/></div>
                    </Fader>
                    <div className={classNames(style.statusLabel, {[style.active]: adjustState === 'start'})}>
                        {moment.unix(adjustedStartTimestamp).format('MMMM DD, YYYY')}
                    </div>
                    <div className={style.dash}>&ndash;</div>
                    <Fader className={style.iconWrapper} selectedIndex={adjustState === 'end' ? 0 : 1}>
                        <div className={style.statusIcon}><PenIcon/></div>
                        <div onClick={onClickSubtractHandler('end')}><SubtractIcon/></div>
                    </Fader>
                    <div className={classNames(style.statusLabel, {[style.active]: adjustState === 'end'})}>
                        {moment.unix(adjustedEndTimestamp).format('MMMM DD, YYYY')}
                    </div>
                </div>
                <MuiButton
                    onClick={() => onChangeDateRange && onChangeDateRange(startTimestamp, endTimestamp)}
                    label="Apply"
                />
            </div>
        </div>
    );
}