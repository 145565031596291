import { Auth, Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { ReactComponent as ArrowUpIcon } from '../../../assets/img/icons/icon.arrow-up.svg';
import { loadUser } from '../../../redux/auth/actions';
import { userSelector } from '../../../redux/auth/selectors';
import { AuthAsyncDispatch } from '../../../redux/auth/types';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';
import { getInitials } from '../../../utils/string';
import { Optional } from '../../../redux/types';
import style from './user-settings.module.scss';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { Avatar, Box, Grid, Link, Paper, TextField, Typography } from '@material-ui/core';
import { PasswordModal } from '../password-modal/password-modal';
import { InterfaceFrame } from '../interface-frame/interface-frame';
import { MuiButton } from '../../mui/MuiButton';

export const UserProfile: React.FC<{}> = () => {
    const authDispatch = useDispatch<AuthAsyncDispatch>();
    const user = useSelector(userSelector);
    const [incRC, decRC] = useLoadingBarDispatch();
    const [inProgress, setInProgress] = useState<boolean>(false);
    
    const initials = getInitials(
        user?.attributes.given_name,
        user?.attributes.family_name
    );
    
    const [email, setEmail] = useState<string | undefined>(user?.attributes.email);
    const [pwModalActive, setPwModalActive] = useState<boolean>(false);
    
    const passwordChangeLinkClickHandler = (): void => {
        setPwModalActive(true);
    };
    
    const picture: Optional<string> = user?.attributes.picture || null;
    const [avatar, setAvatar] = useState<string>();
    useEffect(() => {
        picture && Storage.get(picture).then((url) => setAvatar(url.toString()));
    }, [picture, setAvatar]);
    
    const uploadUserAvatarButtonClickHandler = (
        event: React.FormEvent<HTMLInputElement>
    ): void => {
        const file = event.currentTarget.files && event.currentTarget.files.item(0);
        
        if (file) {
            incRC();
            Storage.put(uuid(), file, {
                contentType: file.type
            })
                .then((data: any) => {
                    if (user && user.attributes.picture) {
                        Storage.remove(user.attributes.picture)
                            .then(() => decRC())
                            .catch((error) => {
                                notifyError('Avatar not updated', notifyErrorMessage(error));
                                console.error(error);
                            });
                    }
                    
                    if ((data as { key: string }).key) {
                        const key = (data as { key: string }).key;
                        const userAttributes = {
                            picture: key
                        };
                        
                        incRC();
                        Auth.updateUserAttributes(user, userAttributes)
                            .then(() => authDispatch(loadUser(true)))
                            .then(() => {
                                notifySuccess('Avatar updated');
                                decRC();
                            })
                            .catch((e) => {
                                decRC();
                                notifyError('Avatar not updated', notifyErrorMessage(e));
                                console.error(e);
                            });
                    }
                    decRC();
                })
                .catch((error) => {
                    decRC();
                    notifyError('Avatar not updated', notifyErrorMessage(error));
                    console.error(error);
                });
        }
    };
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    useEffect(() => {
        setFirstName(user?.attributes?.given_name || '');
        setLastName(user?.attributes?.family_name || '');
    }, [user]);
    
    const userFormSubmitHandler = (event?: React.FormEvent<HTMLButtonElement>): void => {
        event && event.preventDefault();
        
        const userAttributes = {
            given_name: firstName,
            family_name: lastName
        };
        
        setInProgress(true);
        
        incRC();
        Auth.updateUserAttributes(user, userAttributes)
            .then(() => {
                notifySuccess('User profile updated');
                incRC();
                authDispatch(loadUser(true))
                    .then(() => {
                        decRC();
                        setInProgress(false);
                    })
                    .catch(() => {
                        decRC();
                        setInProgress(false);
                    });
                decRC();
            })
            .catch((error) => {
                decRC();
                setInProgress(false);
                notifyError('User details not updated', notifyErrorMessage(error));
                console.error(error);
            });
    };
    
    return (
        <InterfaceFrame>
            <Box marginBottom={4}>
                <Typography variant='h1'>Profile</Typography>
            </Box>
            <Box className={style.UserSettings}>
                <Grid container xs={12} spacing={4}>
                    <Grid item xs={12}>
                        <Paper elevation={1} className={style.profilePictureContainer}>
                            <Avatar style={{ width: 100, height: 100 }} src={avatar}>{initials}</Avatar>
                            
                            <label className={style.profilePictureUpload}>
                                <input
                                    type='file'
                                    accept='image/*'
                                    onChange={uploadUserAvatarButtonClickHandler}
                                />
                                <ArrowUpIcon />
                            </label>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type='text'
                            label='First name'
                            variant='filled'
                            style={{ width: 300 }}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type='text'
                            label='Last name'
                            variant='filled'
                            style={{ width: 300 }}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type='email'
                            label='E-mail'
                            style={{ width: 300 }}
                            variant='filled'
                            value={email}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <Link color="secondary" href='#' onClick={passwordChangeLinkClickHandler}>
                                Change password
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
                
                <Box>
                    {pwModalActive && (
                        <PasswordModal onModalClose={() => setPwModalActive(false)} />
                    )}
                </Box>
                <Box marginTop={6}>
                    <MuiButton onClick={() => userFormSubmitHandler()} inProgress={inProgress} />
                </Box>
            </Box>
        </InterfaceFrame>
    );
};
