import React, { ReactElement } from 'react';
import style from './stats-panel.module.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as EnlargeIcon } from '../../../assets/img/icons/icon.enlarge.svg';
import { Help } from '../help/help';
import { LightTooltip } from '../light-tooltip/light-tooltip';
import classNames from 'classnames';

export interface StatsPanelProps {
    title: React.ReactNode;
    children: React.ReactNode;
    to?: string;
    help?: ReactElement | string;
    size?: StatsPanelSizeTypes
}

export type StatsPanelSizeTypes = 'small' | 'wide'

export const StatsPanel: React.FC<StatsPanelProps> = (props) => {
    const sizeClassName = props.size ? style[props.size] : null;

    return (
        <div className={classNames(style.StatsPanel, sizeClassName)}>
            <div className={style.panelInner}>
                <div className={style.panelHeader}>
                    <span className={style.title}>
                        {props.title}
                        <em>.</em>
                    </span>
                    { props.to &&
                        <LightTooltip
                            title={
                                <>
                                    <p>{`Go to Sensors Overview page`}</p>
                                </>
                            }
                            enterDelay={1000}
                            enterNextDelay={200}
                        >
                            <Link to={props.to}>
                                <EnlargeIcon />
                            </Link>
                        </LightTooltip>
                    }
                    {
                        props.help &&
                        <Help>
                            {props.help}
                        </Help>
                    }
                </div>
                {props.children}
            </div>
        </div>
    );
}
