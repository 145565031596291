import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { SensorOverviewSortOption } from "../../components/building-blocks/sensor-overview-header/sensor-overview-header";
import { Sensor } from "../../api/contracts";
import { ActionWithPayload } from "../types";

export type SensorListDisplayOption = "tiles" | "list" | "mini_tiles";
export type SensorListSortOptions =
  | "sensor_id"
  | "nickname"
  | "fill_percentage";
export type SensorListOrderDirection = "asc" | "desc";

/**
 * A sensors that is returned when requesting the sensor list
 */
export interface SensorListSensor {
  /** The sensor ID */
  sensor_id: string;
  /** The id of the customer to whom the sensor belongs */
  customer_id: string;
  /** The signal strength */
  signal_strength: string;
  /** The current battery level in percent */
  battery: number;
  /** The current fill level in percent */
  fill_percentage: number;
}

export interface SensorListRequestParams {
  /** Number of records to retreive */
  limit: number;
  /** Property to order the records, including direction */
  // order_by: string;
  sort_order: string;
  sort_field: string;
  /** ID of the last element for pagination */
  starting_after?: string;
}

/**
 * Interface for the API response of the /sensors endpoint.
 * Could be handled by a generic interface in the future.
 */
export interface SensorListResponse {
  /** Which kind of object does the response handle? */
  object: string;
  /** The url */
  url: string;
  /** Wether there are more records available */
  has_more: boolean;
  /** The data */
  data: Array<Sensor>;
  /** The id of the last element for pagination purposes */
  last_element: string;
}

export interface SensorOverviewState {
  /** The enabled sort option */
  sortOption: SensorOverviewSortOption;
  /** Display option - either list or tile view */
  displayOption: SensorListDisplayOption;
  /** The list of sensors */
  direction: SensorListOrderDirection;
  sensors?: Array<Sensor>;
}

export enum SensorOverviewTypes {
  SET_SORT_OPTION = "@@sensor_overview/SET_SORT_OPTION",
  SET_DISPLAY_OPTION = "@@sensor_overview/SET_DISPLAY_OPTION",
  SET_DIRECTION = "@@sensor_overview/SET_DIRECTION",
  SET_SENSORS = "@@sensor_overview/SET_SENSORS",
  SET_SENSOR = "@@sensor_overview/SET_SENSOR",
  DELETE_SENSOR = "@@sensor_overview/DELETE_SENSOR",
}

export interface SetSortOption {
  type: SensorOverviewTypes.SET_SORT_OPTION;
  payload: SensorOverviewSortOption;
}

export interface SetDisplayOption {
  type: SensorOverviewTypes.SET_DISPLAY_OPTION;
  payload: SensorListDisplayOption;
}

export interface SetSensorOverviewDirection {
  type: SensorOverviewTypes.SET_DIRECTION;
  payload: SensorListOrderDirection;
}

export interface SetSensors {
  type: SensorOverviewTypes.SET_SENSORS;
  payload: Array<Sensor>;
}

export interface SetSensor {
  type: SensorOverviewTypes.SET_SENSOR;
  payload: Sensor;
}

export type DeleteSensor = ActionWithPayload<
  SensorOverviewTypes.DELETE_SENSOR,
  Sensor
>;

export type SensorOverviewActions =
  | SetSortOption
  | SetDisplayOption
  | SetSensorOverviewDirection
  | SetSensors
  | SetSensor
  | DeleteSensor;
export type SensorOverviewAsyncActions = ThunkAction<
  Promise<void | SensorOverviewActions>,
  {},
  {},
  AnyAction
>;
export type SensorOverviewAsyncDispatch = ThunkDispatch<{}, {}, AnyAction>;
