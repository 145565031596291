import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from './protected-route';
import { appRoutes } from '../../config/routes';
import { RoleProtectedRoute } from './role-protected-route';

/**
 * The application router component.
 * Generates the route child components for the router from the routes configuration file.
 *
 * @constructor
 */
export const AppRouter: React.FC = () => {
  const routeComponents: Array<React.ReactNode> = [];

  // Build the route components from the configuration file
  for (const routeKey in appRoutes) {
    if (appRoutes.hasOwnProperty(routeKey)) {
      const route = appRoutes[routeKey];

      if (route.roleHierarchy) {
        routeComponents.push(<RoleProtectedRoute roleHierarchy={route.roleHierarchy} {...route} key={routeKey} />);
      } else if (route.protected) {
        // Protected routes
        routeComponents.push(<ProtectedRoute {...route} key={routeKey} />);
      } else {
        routeComponents.push(<Route {...route} key={routeKey} />);
      }
    }
  }

  return (
    <BrowserRouter>
      <Switch>
        {routeComponents}
      </Switch>
    </BrowserRouter>
  );
};
