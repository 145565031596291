import React, { ReactText } from 'react';
import { cssTransition, toast, ToastOptions } from "react-toastify";
import {
  NotificationToast,
  NotificationType,
} from "./../components/controls/notification-toast/notification-toast";

export interface Notification {
  title: string;
  message?: string;
}

/**
 * Notifications
 *
 * In general, I've put the notify functions in the redux actions files. The exception are for async methods dealing with
 * Account and Auth since there are a variety of purposes for these calls.
 *
 * **/

export const notifySuccess = (
  title: string,
  message?: string,
  options?: ToastOptions
): ReactText => {
  return notify(NotificationType.success, { title, message }, options);
};

export const notifyWarning = (
  title: string,
  message?: string,
  options?: ToastOptions
): ReactText => {
  return notify(NotificationType.warning, { title, message }, options);
};

export const notifyError = (
  title: string,
  message?: string,
  options?: ToastOptions
): ReactText => {
  return notify(NotificationType.error, { title, message }, options);
};

const Slide = cssTransition({
  enter: "Toastify__slideIn",
  exit: "Toastify__slideOut",
});

export const notify = (
  type: NotificationType,
  notification: Notification,
  options?: ToastOptions
): ReactText => {
  return toast(<NotificationToast type={type} {...notification} />, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const notifyErrorMessage = (e: any): string | undefined => {
  const message =
    e?.response?.data?.message ||
    e?.response?.data?.body?.message ||
    e?.message ||
    undefined;

  if (typeof message === "string") return message;
  else return undefined;
};
