import React from 'react';
import classNames from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';
import style from './sidebar-link.module.scss';
import { Trans } from 'react-i18next';

/**
 * Properties for the SidebarLink component.
 */
export interface SidebarLinkProps {
    /** The label for the SidebarLink element. Will be displayed when active. */
    label: string;
    /** The route for the SidebarLink */
    i18nKey?: string;
    to: string;
    /** A react component to render as icon */
    icon: React.ReactNode;
    /** Disable the link */
    disabled?: boolean;
    /** Only set the element as active, when the current route matches exactly to the supplied route */
    onlyActiveWhenExact?: boolean;
    /** Only show if the user is suppport **/
    supportProtected?: boolean;
}

/**
 * Displays a link element with an icon. Also displays a label when active.
 * This component is basically a wrapper for the Link component from react-router-dom.
 *
 * @constructor
 */
export const SidebarLink: React.FC<SidebarLinkProps> = ({ onlyActiveWhenExact = false, disabled = false, ...props }) => {
    const isActive = useRouteMatch({
        path: props.to,
        exact: onlyActiveWhenExact
    });

    return (
        <Link to={props.to} className={classNames(style.SidebarLink, { [style.active]: isActive, [style.disabled]: disabled })}>
            {props.icon}
            <span className={style.label}>{props.i18nKey ? <Trans i18nKey={props.i18nKey} /> : props.label}</span>
        </Link>
    );
};
