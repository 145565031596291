const envSettings = window as any;

export default class Config {
	static Auth = {
		identityPoolId: envSettings.REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
		region: envSettings.REACT_APP_AMPLIFY_AUTH_API_REGION,
		userPoolId: envSettings.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
		userPoolWebClientId:
			envSettings.REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
	};
	static Storage = {
		bucket: envSettings.REACT_APP_AMPLIFY_S3_BUCKET,
		region: envSettings.REACT_APP_AMPLIFY_S3_API_REGION,
	};
	static API = {
		name: envSettings.REACT_APP_AMPLIFY_API_NAME,
		endpoint: envSettings.REACT_APP_AMPLIFY_API_URL,
	};
	static MAPBOX = {
		REACT_APP_MAPBOX_ACCESS_KEY: envSettings.REACT_APP_MAPBOX_ACCESS_KEY,
		REACT_APP_MAPBOX_STYLE_URL: envSettings.REACT_APP_MAPBOX_STYLE_URL,
	};
}
