import classNames from 'classnames';
import React from 'react';
import style from './stats-panel-status.module.scss';

export interface StatsPanelStatusProps {
    value?: number;
    unit?: string;
    precision?: number;
    alertThreshold?: number;
}

export const StatsPanelStatus: React.FC<StatsPanelStatusProps> = (props) => {
    let value;
    let hasAlert = false;
    let green = false;
    let valueLabel

    if (props.value) {
        if (props.precision !== undefined) {
            value = props.value.toFixed(props.precision)
        } else {
            value = props.value
        }

        valueLabel = `${value}${(props.unit) ? props.unit : ''}`;

        if (props.alertThreshold) {
            if (value < props.alertThreshold) {
                hasAlert = true;
            } else {
                green = true;
            }
        }
    }

    return (
        <div className={classNames(style.StatsPanelStatus, { [style.hasAlert]: hasAlert, [style.green]: green})}>
            <span className={style.statusValue}>{valueLabel}</span>
        </div>
    );
}
