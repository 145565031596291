import { AuthActions, AuthActionTypes, AuthState } from './types';

const initialAuthState: AuthState = {
	user: null,
};

export const authReducer = (
	state: AuthState = initialAuthState,
	action: AuthActions,
): AuthState => {
	switch (action.type) {
		case AuthActionTypes.SET_USER: {
			return {
				...state,
				user: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};
