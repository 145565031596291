import React from 'react';
//import style from './missed-pickup-chart.module.scss';
import { Bar, BarChart, ResponsiveContainer, XAxis } from 'recharts';
import moment from 'moment';

const COLOR_STROKEGRADIENT_ALERT_0 = '#DA6464';
const COLOR_STROKEGRADIENT_ALERT_100 = '#A74040';

const COLOR_STROKEGRADIENT_0 = '#A7CFC5';
const COLOR_STROKEGRADIENT_100 = '#003B2C';

export interface WeekDataChartProps {
    barDataKey?: string;
    xAxisDataKey?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Array<any>;
}

export const WeekDataChart: React.FC<WeekDataChartProps> = (props) => {
    const {barDataKey = 'value', xAxisDataKey = 'datetime'} = props;

    const weekDayTickFormatter = (timestamp: number): string => {
        return moment(timestamp * 1000).format('dd').substr(0, 1);
    };

    return (
        <ResponsiveContainer width='100%' height={120}>
            <BarChart data={props.data}>
                <defs>
                    <linearGradient id='missedPickUpChartAlertGradient' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='0%' stopColor={COLOR_STROKEGRADIENT_ALERT_0} stopOpacity={1} />
                        <stop offset='100%' stopColor={COLOR_STROKEGRADIENT_ALERT_100} stopOpacity={1} />
                    </linearGradient>
                    <linearGradient id='missedPickUpChartDefaultGradient' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='0%' stopColor={COLOR_STROKEGRADIENT_0} stopOpacity={1} />
                        <stop offset='100%' stopColor={COLOR_STROKEGRADIENT_100} stopOpacity={1} />
                    </linearGradient>
                </defs>
                <XAxis dataKey={xAxisDataKey} axisLine={false} tickLine={false} tickFormatter={weekDayTickFormatter} tick={{ fill: '#757575' }} height={15}/>
                <Bar dataKey={barDataKey} barSize={4} fill='url(#missedPickUpChartDefaultGradient)' radius={[2, 2, 2, 2]} />
            </BarChart>
        </ResponsiveContainer>
    );
};
