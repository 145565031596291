import React from 'react';
import classNames from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';
import style from './settings-navigation-link.module.scss';
import { Trans } from 'react-i18next';
import { UserRoleType } from '../../../redux/user/types';

export interface SettingsNavigationLinkProps {
  to: string;
  label: string;
  i18nKey?: string;
  onlyActiveWhenExact?: boolean;
  roleHierarchy?: UserRoleType;
}

export const SettingsNavigationLink: React.FC<SettingsNavigationLinkProps> = (
  props: SettingsNavigationLinkProps
) => {
  const isActive = useRouteMatch({
    path: props.to,
    exact: props.onlyActiveWhenExact,
  });

  return (
    <Link
      to={props.to}
      className={classNames(style.SettingsNavigationLink, {
        [style.active]: isActive,
      })}
    >
      {props.i18nKey ? <Trans i18nKey={props.i18nKey} /> : props.label}
      <em>.</em>
    </Link>
  );
};
