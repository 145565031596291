import React from 'react';
import style from './waste-label.module.scss';
import classNames from 'classnames';
import { WasteType } from '../../../redux/account/types';
import { useWasteColorPalette } from '../../controls/waste-avatar/waste-avatar-utils';

export interface WasteLabelProps {
    type: WasteType;
    className?: string;
    hasColor?: boolean;
}

export const WasteLabel: React.FC<WasteLabelProps> = props => {
    const { type, className, hasColor = true } = props;

    const wasteColorPalette = useWasteColorPalette();
    const color = wasteColorPalette[type];

    return (
        <div className={classNames(className, style.WasteLabel, { [style[color]]: hasColor}  )}>{props.children}</div>
    );
}