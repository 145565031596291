import React, { ReactElement } from 'react';
import style from './location.module.scss';
import { InterfaceFrame } from '../../building-blocks/interface-frame/interface-frame';
import { MapBox } from '../../controls/map-box/map-box';
import { MapBoxProps } from '../../controls/map-box/types';
import { getBoundsOfSensors } from '../../../utils/map';
import { useSensorOverviewData } from '../../../redux/sensor-overview/effects';
import { useSelector } from 'react-redux';
import { sensorsSelector } from '../../../redux/sensor-overview/selectors';
import { ContentContainer } from '../../building-blocks/content-container/content-container';
import { Box, Typography } from '@material-ui/core';

export const Location: React.FC<{}> = () => {
  useSensorOverviewData(null, null);
  const sensors = useSelector(sensorsSelector);

  
  const getMapBoxProps = (): ReactElement | undefined => {
    if(sensors && sensors.length > 0) {
      const sensorsWithLocation = sensors.filter((s) => s.location);
    
      const bounds = getBoundsOfSensors(sensorsWithLocation);
    
      const mapBoxProps: MapBoxProps = {
        bounds,
        sensors: sensorsWithLocation,
      };
  
      return (
          <MapBox {...mapBoxProps} />
      )
    }
  }

  return (
    <InterfaceFrame>
      <ContentContainer className={style.Location}>
        <Box marginBottom={2}>
          <Typography variant="h1">Locations</Typography>
        </Box>
        <div className={style.mapContainer}>
          {
            getMapBoxProps()
          }
        </div>
      </ContentContainer>
    </InterfaceFrame>
  );
};
