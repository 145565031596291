import React, { ReactElement, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';
import { Modal } from './modal';


export interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  objectClass: string;
  objectName?: string;
  icon?: ReactElement;
}

export const DeleteModal: React.FC<DeleteModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    onDelete,
    objectClass,
    objectName,
    icon
  } = props;
  
  const [inProgress, setInProgress] = useState<boolean>(false);
  
  const onDeleteHandler = (): void => {
    setInProgress(true);
    onDelete()
  };
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Delete ${objectClass}`}
      maxWidth='xs'
      content={(
        <>
          <Box display="flex" alignItems="center">
            {
              icon &&
              <Box mr={2}>
                {icon}
              </Box>
            }
            <Box>
              <Typography variant='caption' color='textPrimary'>
                You are about to delete the {objectClass} <b>{objectName}</b>. This action can’t be undone and is permanent. Please confirm your choice, or cancel and return to the page.
              </Typography>
            </Box>
          </Box>
        </>
      )}
      actions={(
        <LidbotButtonGroup
          onSecondaryClickHandler={onClose}
          onPrimaryClickHandler={() => onDeleteHandler()}
          isPrimaryError={true}
          primaryLabel='Delete'
          isPrimaryInProgress={inProgress}
        />
      )}
    />
  );
};
