import { useEffect } from 'react';
import { loadSensors } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { SensorOverviewAsyncDispatch } from './types';
import { Optional } from '../types';
import {
	sensorOverviewDirectionSelector,
	sortOptionSelector,
} from './selectors';
import { clientIdSelector } from '../auth/selectors';
import { useLoadingBarDispatch } from '../loading-bar/effects';

export const useSensorOverviewData = (
	field: Optional<string>,
	value: Optional<string>,
): void => {
	const clientId = useSelector(clientIdSelector);
	const activeSortOption = useSelector(sortOptionSelector);
	const activeSortDirection = useSelector(sensorOverviewDirectionSelector);

	const [incRC, decRC] = useLoadingBarDispatch();
	const sensorOverviewAsyncDispatch =
		useDispatch<SensorOverviewAsyncDispatch>();
	useEffect(() => {
		if (clientId) {
			incRC();
			sensorOverviewAsyncDispatch(
				loadSensors(
					activeSortOption.key,
					activeSortDirection,
					field,
					value,
				),
			)
				.then(() => decRC())
				.catch(() => decRC());
		}
		//eslint-disable-next-line
	}, [clientId, activeSortOption, field, value, activeSortDirection]);
};
