/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from 'aws-amplify';
import {
	SensorDetailActions,
	SensorDetailAsyncActions,
	SensorDetailAsyncDispatch,
	SensorDetailTypes,
	SetFilterOption,
	SetFilterSearchQuery,
	SetReportData,
} from './types';
import moment from 'moment';
import { Optional, RequestOptions } from '../types';
import { HistogramQuery, SearchResponse } from '../../api/histogram';
import { SensorReport } from '../../api/sensors-reports';
import { DatePickerOption } from '../../components/controls/date-picker-control/utils';
import config from '../../config/config';

export const setFilterOption = (
	filterOption: DatePickerOption,
): SetFilterOption => {
	return {
		type: SensorDetailTypes.SET_FILTER_OPTION,
		payload: filterOption,
	};
};

export const setFilterSearchQuery = (
	payload: Optional<Partial<HistogramQuery>>,
): SetFilterSearchQuery => ({
	type: SensorDetailTypes.SET_FILTER_SEARCH_QUERY,
	payload,
});

export const setReportData = (
	reportData: Array<SensorReport>,
): SetReportData => {
	return {
		type: SensorDetailTypes.SET_REPORT_DATA,
		payload: reportData,
	};
};

export const loadHistogramData =
	(
		sensorId: string,
		searchQuery: Partial<HistogramQuery>,
	): SensorDetailAsyncActions =>
	(
		dispatch: SensorDetailAsyncDispatch,
	): Promise<SensorDetailActions | void> => {
		if (Object.keys(searchQuery).length === 0)
			return Promise.reject('Empty search query');

		const query: HistogramQuery = {
			sensor_id: sensorId,
			calendar_interval: 'day',
			'moving_avg.model': 'simple',
			'created_on.lt': moment().unix(),
			sort_field: 'created_on',
			sort_order: 'desc',
			limit: 10000,
			...searchQuery,
		};

		const options: RequestOptions = {
			queryStringParameters: query,
		};

		return API.get(
			config.API.name,
			'/search/reports/date-histogram',
			options,
		)
			.then((response: SearchResponse) => {
				const reports: Array<SensorReport> =
					response.hits?.reverse().map((r) => ({
						...r,
						value: r.fill_percentage,
					})) || [];

				if (reports.length === 0) {
					const endTime =
						searchQuery['created_on.lt'] || moment().unix();
					const startTime =
						searchQuery['created_on.gte'] ||
						moment.unix(endTime).subtract(7, 'days').unix();
					dispatch(
						setFilterSearchQuery({
							'created_on.lt': endTime,
							'created_on.gte': startTime,
						}),
					);
					dispatch(setReportData([]));
					return;
				}

				// Handle 'all' preset that doesn't have start date
				const updatedSearchQuery = {
					...searchQuery,
					'created_on.gte':
						searchQuery['created_on.gte'] ||
						moment
							.unix(reports[0].created_on)
							.startOf('day')
							.unix(),
				};
				dispatch(setFilterSearchQuery(updatedSearchQuery));
				dispatch(setReportData(reports));
			})
			.catch((e) => {
				dispatch(setReportData([]));
				throw e;
			});
	};
