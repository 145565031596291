import { API } from "aws-amplify";
import {
  AddUser,
  CreateUserProps,
  RemoveUser,
  SetUser,
  SetUserAPICalled,
  SetUserAPILoaded,
  SetUsers,
  User,
  UserActionType,
  UserAsyncAction,
  UserAsyncDispatch,
  UserUpdateState,
} from "./types";
import { DeepPartial, Optional, RequestOptions } from "../types";
import {
  notifyError,
  notifyErrorMessage,
  notifySuccess,
} from "../../utils/notify";
import config from "../../config/config";

export const setUsers = (users: Optional<Array<User>>): SetUsers => {
  return {
    type: UserActionType.SET_USERS,
    payload: users,
  };
};

export const addUser = (user: User): AddUser => {
  return {
    type: UserActionType.ADD_USER,
    payload: user,
  };
};

export const removeUser = (user: User): RemoveUser => {
  return {
    type: UserActionType.REMOVE_USER,
    payload: user,
  };
};

export const setUser = (user: User): SetUser => {
  return {
    type: UserActionType.SET_USER,
    payload: user,
  };
};

export const setUserAPICalled = (payload: boolean): SetUserAPICalled => ({
  type: UserActionType.SET_API_CALLED,
  payload,
});
export const setUserAPILoaded = (payload: boolean): SetUserAPILoaded => ({
  type: UserActionType.SET_API_LOADED,
  payload,
});

export const loadUsersData =
  (options: RequestOptions = {}): UserAsyncAction =>
  (dispatch: UserAsyncDispatch): Promise<void> => {
    return API.get(config.API.name, "/users", options)
      .then((response) => {
        dispatch(setUsers(response.data as Array<User>));
      })
      .catch((e) => {
        dispatch(setUsers([] as Array<User>));
        throw e;
      });
  };

export const createUser =
  (user: CreateUserProps): UserAsyncAction =>
  (dispatch: UserAsyncDispatch): Promise<void | AddUser> => {
    const payload = {
      body: user,
    };

    return API.post(config.API.name, "/users", payload)
      .then((response) => {
        dispatch(addUser(response as User));
        notifySuccess("User created");
      })
      .catch((e) => {
        notifyError("User not created", notifyErrorMessage(e));
        console.error(e.response);
      });
  };

export const updateUser =
  (user: DeepPartial<User>): UserAsyncAction =>
  (dispatch: UserAsyncDispatch): Promise<void | SetUser> => {
    const email = user.email;
    if (!email) {
      notifyError("User not updated", "Could not verify email");
    }

    const apiPath = `/users/${email}`;
    const options = {
      body: user,
    };
    return API.post(config.API.name, apiPath, options)
      .then((response) => {
        dispatch(setUser(response as User));
        notifySuccess("User updated");
      })
      .catch((e) => {
        notifyError("User not updated", notifyErrorMessage(e));
      });
  };

export const updateUserState =
  (
    email: string,
    type: UserUpdateState,
    options: RequestOptions = {}
  ): UserAsyncAction =>
  (dispatch: UserAsyncDispatch): Promise<void | SetUser> => {
    const apiPath = `/users/${email}/${type}`;
    const getActionStringFromUserUpdateType = (
      type: UserUpdateState,
      success: boolean
    ): string => {
      const adverbStr = success ? "" : "not ";
      if (type === "enable" || type === "disable")
        return `User ${adverbStr}${type}d`;
      else return `Confirmation ${adverbStr}mail sent`;
    };
    return API.post(config.API.name, apiPath, options)
      .then((response) => {
        dispatch(setUser(response as User));
        notifySuccess(getActionStringFromUserUpdateType(type, true));
      })
      .catch((e) => {
        notifyError(
          getActionStringFromUserUpdateType(type, false),
          notifyErrorMessage(e)
        );
        console.error(e);
      });
  };

export const deleteUser =
  (user: User): UserAsyncAction =>
  (dispatch: UserAsyncDispatch): Promise<void | RemoveUser> => {
    const { email } = user;

    /* Can avoid this if we make email a required property */
    if (!email) return Promise.reject("user does not have email property");

    const apiPath = `/users/${email}`;

    return API.del(config.API.name, apiPath, {})
      .then(() => {
        dispatch(removeUser(user));
        notifySuccess("User deleted");
      })
      .catch((e) => {
        notifyError("User not deleted", notifyErrorMessage(e));
        console.error(e);
      });
  };
