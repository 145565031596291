import { UserRoleType } from '../redux/user/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { roleSelector } from '../redux/auth/selectors';
import userRoleHierarchy from '../config/userRoleHierarchy';

/** Can increase performance by saving value of roles so that the effect is not rerun
 * everytime the interface rerenders. But I don't think it's neccesary right now since
 * it's not a very heavy operation */
export const useAccess = (roleHierarchy: UserRoleType): boolean => {
	const role = useSelector(roleSelector);
	const [hasAccess, setHasAccess] = useState(false);
	useEffect(() => {
		const value: boolean =
			role !== null && userRoleHierarchy[roleHierarchy].includes(role);
		setHasAccess(value);
	}, [role, roleHierarchy]);
	return hasAccess;
};
