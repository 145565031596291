import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.scss';
import { Map, Marker } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './map-box.css';
import { convertSensorDataToMarkerWithThreshold, convertSensorDataToPopupWithThreshold, useMapInitialization } from './utils';
import { MapBoxProps } from './types';
import { useSelector } from 'react-redux';
import { thresholdSelector } from '../../../redux/account/selectors';


export const MapBox: React.FC<MapBoxProps> = (props) => {
  const { sensors } = props;

  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map | null>(null);

  const threshold = useSelector(thresholdSelector);

  // Initialization
  useMapInitialization(props, mapRef, setMap);

  /**
   * NOTE: The mapbox Map object does not have a reference to the Marker objects, we must
   * manage them on our own. Be careful with what you do with the markers array since its
   * the only way we can access them again.
   **/
  const markers = useRef<Array<Marker>>([]);
  const convertSensorDataToMarker = convertSensorDataToMarkerWithThreshold(threshold);
  const convertSensorDataToPopup = convertSensorDataToPopupWithThreshold(threshold);
  useEffect(() => {
    if (sensors && map) {
      markers.current.forEach((m) => {
        m.remove();
      });
      markers.current = sensors.filter(s => s.location).map(s => {
        const marker = convertSensorDataToMarker(s).addTo(map);
        const popup = convertSensorDataToPopup(s).addTo(map);
        marker.setPopup(popup);
        return marker;
      });
    }
  }, [sensors, map, convertSensorDataToMarker, convertSensorDataToPopup, threshold]);

  return (
    <div
      ref={mapRef}
      className={style.MapBox}
    />);
};