import { useDispatch } from 'react-redux';
import { decrementReferenceCount, incrementReferenceCount } from './actions';

export const useLoadingBarDispatch = (): [() => void, () => void] => {
	const loadingBarDispatch = useDispatch();
	const increment = (): void => {
		loadingBarDispatch(incrementReferenceCount());
	};
	const decrement = (): void => {
		loadingBarDispatch(decrementReferenceCount());
	};
	return [increment, decrement];
};
