import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';
import style from './request-password-reset-form.module.scss';
import { Box, Link, TextField, Typography } from '@material-ui/core';
import { MuiButton } from '../../mui/MuiButton';
import { validateEmail } from '../../../utils/string';

interface RequestPasswordResetFormProps {
    onSubmit?: (email: string) => void;
    displayResent?: boolean;
}

export const RequestPasswordResetForm: React.FC<RequestPasswordResetFormProps> = (props) => {
    const { t } = useTranslation();
    const { displayResent = false } = props;

    const textDomain = displayResent ? 'resentForm' : 'requestForm'
    
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string>();
    
    const [inProgress, setInProgress] = useState<boolean>(false);
    
    useEffect(() => {
        if(email) {
            setEmailError('')
        }
    }, [email])
    
    const formSubmitHandler = (): void => {
        let validationFailed = false
    
        if (!email) {
            setEmailError('Email is required');
            validationFailed = true
        }
    
        if(email && !validateEmail(email)) {
            setEmailError('Email incorrect');
            validationFailed = true
        }
    
        if(validationFailed) {
            return
        }
    
        setInProgress(true)
        
        Auth.forgotPassword(email)
            .then(() => {
                notifySuccess('Email sent', 'We\'ve sent you an email with instructions how to update your password.');
                setInProgress(false)
                props.onSubmit && props.onSubmit(email);
            })
            .catch(e => {
                setInProgress(false)
                notifyError('Something went wrong', notifyErrorMessage(e));
                console.error(e);
            });
    }

    return (
        <div className={style.RequestPasswordResetForm}>
            <div className={style.headline}>
                <Trans i18nKey={`passwordReset.${textDomain}.headline`} />
            </div>

            <div className={style.subline}>{t(`passwordReset.${textDomain}.subline`)}</div>

            <div className={style.text}>
                <Trans i18nKey={`passwordReset.${textDomain}.text`} values={{email}} />
            </div>

            <Box marginBottom={2}>
                <TextField
                    type='email'
                    value={email}
                    label={t('passwordReset.formLabels.email')}
                    onChange={e => setEmail(e.target.value)}
                    variant='filled'
                    error={!!emailError}
                    helperText={emailError ? emailError : ' '}
                    fullWidth
                />
            </Box>
            <Box marginBottom={2}>
                <MuiButton onClick={formSubmitHandler} label="send link" fullWidth inProgress={inProgress}/>
            </Box>
            <Box textAlign="right">
                <Typography variant="caption" color="secondary">
                    Go back to Smartbin
                    <Link style={{
                        marginLeft: 3
                    }} color="primary" href="/login">login</Link>
                </Typography>
            </Box>
        </div>
    );
}
