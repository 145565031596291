import React, { useEffect, useState } from 'react';
import { Customer, RequestOptions } from '../../../redux/types';
import { Modal } from '../../controls/modal/modal';
import { getTimezones, TimezoneOption } from '../../../utils/locale';
import { Box, TextField } from '@material-ui/core';
import { StyledAutocomplete } from '../../mui/StyledAutocomplete';
import _ from 'lodash';
import { timezoneSelector } from '../../../redux/account/selectors';
import { useSelector } from 'react-redux';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';
import { API } from 'aws-amplify';
import config from '../../../config/config';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';

const timezones = getTimezones();

export interface CustomerSettingsModalProps {
    customer?: Customer;
    onClose: () => void;
    onCreate: (customer: Customer) => void;
}

export const CustomerModal: React.FC<CustomerSettingsModalProps> = props => {
    const { customer, onClose, onCreate } = props;
    
    const title = customer ? 'Update an account' : 'Add a new account';
    
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [accountName, setAccountName] = useState<string | undefined>(customer?.account_name);
    const [accountNameError, setAccountNameError] = useState<string>();
    
    let currentTimezone;
    
    if (customer?.locale?.timezone) {
        currentTimezone = _.find(timezones, tz => tz.value === customer?.locale?.timezone);
    } else {
        const currentAccountTimezone = useSelector(timezoneSelector);
        currentTimezone = _.find(timezones, tz => tz.value === currentAccountTimezone);
    }
    
    const [timezone, setTimezone] = useState<TimezoneOption | undefined>(currentTimezone);
    const [timeZoneError, setTimeZoneError] = useState<string>();
    
    useEffect(() => {
        if (accountName) {
            setAccountNameError(undefined);
        }
        
        if (timezone) {
            setTimeZoneError(undefined);
        }
    }, [timezone, accountName]);
    
    const onSubmit = (): void => {
        let hasValidationErrors = false;
        
        if (!accountName) {
            setAccountNameError('Account name is required.');
            hasValidationErrors = true;
        }
        
        if (!timezone) {
            setTimeZoneError('Time zone is required.');
            hasValidationErrors = true;
        }
        
        if (hasValidationErrors) {
            return;
        }
        
        setInProgress(true);
        
        const options: RequestOptions = {
            body: {
                'account_name': accountName,
                locale: {
                    language: 'en-US',
                    timezone: timezone!.value as string
                }
            }
        };
        
        API.post(config.API.name, `/customers/${customer?.customer_id ? customer.customer_id : ''}`, options)
            .then((c: Customer) => {
                setInProgress(false);
                notifySuccess('Customer created');
                onCreate(c);
            })
            .catch((e) => {
                notifyError('Customer not created', notifyErrorMessage(e));
                console.error(e.message);
            });
    };
    
    return (
        <Modal
            isOpen={true}
            onClose={onClose}
            title={title}
            content={(
                <Box>
                    <Box marginBottom={2}>
                        <TextField
                            variant='filled'
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value || '')}
                            label='Account Name'
                            required
                            fullWidth
                            error={!!accountNameError}
                            helperText={accountNameError ? accountNameError : ' '}
                        />
                    </Box>
                    <Box>
                        <StyledAutocomplete
                            options={getTimezones()}
                            value={timezone}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, option) => setTimezone(option)}
                            fullWidth
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label='Select time zone'
                                    variant='filled'
                                    margin='normal'
                                    error={!!timeZoneError}
                                    helperText={timeZoneError ? timeZoneError : ' '}
                                />
                            }
                        />
                    </Box>
                </Box>
            )}
            actions={(
                <LidbotButtonGroup onSecondaryClickHandler={onClose} onPrimaryClickHandler={onSubmit} isPrimaryInProgress={inProgress}/>
            )}
        />
    );
};