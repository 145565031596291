import { UserRoleType } from '../redux/user/types';

type UserRoleHierarchy = {
	[R in UserRoleType]: Array<UserRoleType>;
};

const userRoleHierarchy: UserRoleHierarchy = {
	USER: ['USER', 'ADMIN', 'DEVELOPER', 'ACCOUNT_OWNER', 'LIDBOT_SUPPORT'],
	ADMIN: ['ADMIN', 'DEVELOPER', 'ACCOUNT_OWNER', 'LIDBOT_SUPPORT'],
	DEVELOPER: ['DEVELOPER', 'ACCOUNT_OWNER', 'LIDBOT_SUPPORT'],
	ACCOUNT_OWNER: ['ACCOUNT_OWNER', 'LIDBOT_SUPPORT'],
	LIDBOT_SUPPORT: ['LIDBOT_SUPPORT'],
};

export default userRoleHierarchy;
