import React, { ReactElement } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

export interface ModalProps {
    title: string;
    content?: ReactElement,
    actions?: ReactElement
    isOpen?: boolean;
    onClose: () => void;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

export const Modal: React.FC<ModalProps> = (props) => {
    const {
        isOpen = false,
        title,
        content,
        actions,
        onClose,
        maxWidth
    } = props;
    
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby={title}
            scroll="paper"
            maxWidth={maxWidth}
        >
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            {
                content &&
                <DialogContent>
                    {content}
                </DialogContent>
            }
            {
                actions &&
                <DialogActions>
                    {actions}
                </DialogActions>
            }
        </Dialog>
    );
};
