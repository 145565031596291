import LaunchSharpIcon from '@material-ui/icons/LaunchSharp';
import { ReactComponent as EnlargeIcon } from '../../../assets/img/icons/icon.enlarge.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addEvent, updateAccount, updateEvent } from '../../../redux/account/actions';
import { accountSelector, eventSelectorOfType } from '../../../redux/account/selectors';
import { AccountAPIPost, AccountAsyncDispatch, Event, SensorSchedule } from '../../../redux/account/types';
import { DeepPartial } from '../../../redux/types';
import { Slider } from '../../controls/slider/slider';
import style from './sensors-settings.module.scss';
import { useAccess } from '../../../hooks/access';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { Box, Button, createStyles, Link, makeStyles, Step, StepLabel, Stepper, Theme, Typography } from '@material-ui/core';
import { MuiButton } from '../../mui/MuiButton';
import { Schedule } from '../sensor-schedule/schedule';
import { notifyError, notifySuccess } from '../../../utils/notify';
import { useClientRefreshCallback } from '../../../redux/auth/effects';
import { MuiStepper } from '../../mui/MuiStepper';

const CONTENT_HEIGHT = 440

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        content: {
            height: '500px'
        },
        button: {
            marginRight: theme.spacing(1)
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    })
);

export const SensorsSettings: React.FC = () => {
    const [incRC, decRC] = useLoadingBarDispatch();
    
    const account = useSelector(accountSelector);
    const binFullEvent = useSelector(eventSelectorOfType('Notifications_BinFull'));
    
    const access = useAccess('ADMIN');
    const readonly = !access;
    
    const [threshold, setThreshold] = useState<number>(binFullEvent?.condition?.value || 75);
    
    const accountAsyncDispatch = useDispatch<AccountAsyncDispatch>();
    
    // sensor schedule state
    const [sensorSchedule, setSensorSchedule] = useState<SensorSchedule>({
        type: 'WEEKLY',
        rate: 60,
        week: []
    })
    const [scheduleError, setScheduleError] = useState<string>();
    const [rate, setRate] = useState<number>(sensorSchedule.rate || 0)
    
    const [pickUpSchedule, setPickUpSchedule] = useState<SensorSchedule>({
        type: 'WEEKLY',
        week: []
    })
    
    useEffect(() => {
        if(binFullEvent) {
            setThreshold(binFullEvent?.condition?.value || 0);
        }
        
        if (account?.sensor_schedule) {
            setSensorSchedule(account.sensor_schedule)
            setRate(account.sensor_schedule.rate ? account.sensor_schedule.rate / 60 : 0)
        }
        
    }, [account, binFullEvent])
    
    useEffect(() => {
        setScheduleError('')
    }, [sensorSchedule])
    
    
    const onSaveThreshold = (onComplete: () => void): void => {
        if (binFullEvent) {
            const event: DeepPartial<Event> = {
                condition: { value: threshold, operator: 'GT' }
            };
            incRC();
            accountAsyncDispatch(updateEvent('Notifications_BinFull', event))
                .then(() => {
                    decRC();
                    onComplete()
                })
                .catch(() => {
                    decRC();
                });
        } else {
            const event: Event = {
                type: 'Notifications_BinFull',
                enabled: true,
                condition: {
                    value: threshold,
                    operator: 'GT'
                }
            };
            
            incRC();
            accountAsyncDispatch(addEvent('Notifications_BinFull', event))
                .then(() => {
                    decRC();
                })
                .catch(() => {
                    decRC();
                });
        }
    };
    
    return (
        <Box className={style.SensorsSettings}>
            <MuiStepper
              contentHeight={CONTENT_HEIGHT}
              steps={[
                  {
                      label: 'Schedule',
                      content: (
                        <Schedule
                          height={CONTENT_HEIGHT}
                          schedule={sensorSchedule}
                          setSchedule={setSensorSchedule}
                          scheduleError={scheduleError}
                          rate={rate}
                          setRate={setRate}
                        />
                      ),
                      onComplete: (handler => {
                          let validationFailed = false;
    
                          if (sensorSchedule.type === "RATE") {
                              if(!rate) {
                                  setScheduleError('Rate required for interval schedule type.')
                                  validationFailed = true
                              } else {
                                  if(rate && rate < 5) {
                                      setScheduleError('Rate schedule must be at least 5 minutes')
                                      validationFailed = true
                                  } else if (rate > (24 * 60 * 7)) {
                                      setScheduleError(`Rate schedule can not exceed 7 days (${24*7} hours)`);
                                      validationFailed = true
                                  }
            
                              }
                          } else {
                              if (!sensorSchedule.week || sensorSchedule.week.length === 0) {
                                  setScheduleError("At least one time selection is required for fixed schedule");
                                  validationFailed = true
                              }
                          }
    
                          if(validationFailed) {
                              handler(false)
                              return
                          }
    
                          const body: AccountAPIPost = {
                              sensor_schedule: {
                                  ...sensorSchedule,
                                  rate: rate * 60
                              },
                          };
    
                          incRC();
                          accountAsyncDispatch(updateAccount(body))
                            .then(() => {
                                decRC();
                                handler(true)
                                notifySuccess("Sensor schedule updated");
                            })
                            .catch((e) => {
                                decRC();
                                handler(false)
                                notifyError("Sensor schedule not updated");
                                console.error(e);
                            });
                      })
                  },
                  {
                      label: 'Threshold',
                      content: (
                        <Box height={533} className={style.inputsWrapper}>
                            <Box marginBottom={4} width={350} className={style.thresholdSettings}>
                                <Box mb={2}>
                                    <Typography variant="h3">Bin full settings</Typography>
                                </Box>
                                <Typography variant="caption" color="secondary">When your bins get filled at <b>{threshold}%</b> or more your team will be
                                    notified to pick them up (Staff members will <b>only</b> be notified about the bins they are <b>responsible</b> for).
                                </Typography>
                                {threshold !== null &&
                                <Box className={style.thresholdSliderWrapper}>
                                    <Box className={style.thresholdSlider}>
                                        <Slider value={threshold} onChange={(t) => setThreshold(t)} format={(n) => `${n}%`} readonly={readonly} />
                                    </Box>
                                    <Box className={style.thresholdImage} />
                                </Box>
                                }
                            </Box>
                        </Box>
                      ),
                      onComplete: (handler => {
                          onSaveThreshold(() => {
                              handler(true);
                          })
                      })
                  },
                  // {
                  //     disabled: true,
                  //     label: 'Pick-up report',
                  //     content: (
                  //       <Box display="flex">
                  //           <Box minWidth={200} flexBasis={200} mr={2}>
                  //               <Box mb={2}>
                  //                   <Typography variant="h4">Pick-up Schedule</Typography>
                  //               </Box>
                  //               <Box mb={1}>
                  //                   <Typography variant="caption" color="secondary">
                  //                       Configure the times when a pick-up report will be delivered to your staff.
                  //                   </Typography>
                  //               </Box>
                  //               <Box mb={1}>
                  //                   <Typography variant="caption" color="secondary">
                  //                       Each staff will receive an email with a list of bins that are full (based on the threshold value configured in the previous step.
                  //                   </Typography>
                  //               </Box>
                  //               <Box mb={1}>
                  //                   <Typography variant="caption" color="secondary">
                  //                       Please note that the list includes only the bins that a particular staff member is responsible for.
                  //                   </Typography>
                  //               </Box>
                  //               <Box mb={1}>
                  //                   <Typography variant="caption" color="secondary">
                  //                       You can configure more pick-up reports in the reports section
                  //                       <Link target="_blank" style={{paddingLeft: '3px'}} href="/reports">
                  //                           reports section
                  //                           {/*<LaunchSharpIcon fontSize="small"/>*/}
                  //                       </Link>
                  //                       .
                  //                   </Typography>
                  //               </Box>
                  //           </Box>
                  //           <Box flexGrow={1}>
                  //               <Schedule
                  //                 height={CONTENT_HEIGHT}
                  //                 schedule={pickUpSchedule}
                  //                 setSchedule={setPickUpSchedule}
                  //                 scheduleError={scheduleError}
                  //                 onlyFixed={true}
                  //               />
                  //           </Box>
                  //       </Box>
                  //     )
                  // }
              ]}
            />
        </Box>
    );
};
