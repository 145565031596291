import { Action } from "redux";
import { LidbotUserAttributes } from "./../auth/types";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ActionWithPayload, Option, Optional } from "../types";
import { UserSensor } from "../../api/contracts";

export type UserRoleType =
  | "USER"
  | "ADMIN"
  | "DEVELOPER"
  | "ACCOUNT_OWNER"
  | "LIDBOT_SUPPORT";

export interface UserRoleOption extends Option {
  label: string;
  value: UserRoleType;
}

export const basicUserRoleOptions: Array<UserRoleOption> = [
  {
    label: "user",
    value: "USER",
    i18nKey: "constants.basicUserRoleOptions.user",
  },
  {
    label: "admin",
    value: "ADMIN",
    i18nKey: "constants.basicUserRoleOptions.admin",
  },
  {
    label: "developer",
    value: "DEVELOPER",
    i18nKey: "constants.basicUserRoleOptions.developer",
  },
  {
    label: "owner",
    value: "ACCOUNT_OWNER",
    i18nKey: "constants.basicUserRoleOptions.owner",
  },
];

export const expandedUserRoleOptions: Array<UserRoleOption> = [
  ...basicUserRoleOptions,
  {
    label: "support",
    value: "LIDBOT_SUPPORT",
    i18nKey: "constants.expandedUserRoleOptions.support",
  },
];

export type UserStatusType =
  | "CONFIRMED"
  | "PENDING_INVITATION"
  | "PASSWORD_RESET_REQUIRED";

export interface StatusOption extends Option {
  label: string;
  value: UserStatusType;
}

export const userStatusOptions: Array<StatusOption> = [
  { label: "confirmed", value: "CONFIRMED" },
  { label: "pending invitation", value: "PENDING_INVITATION" },
  { label: "password reset required", value: "PASSWORD_RESET_REQUIRED" },
];

export type UserUpdateState = "enable" | "disable" | "resend-invitation";

export interface User extends LidbotUserAttributes {
  user_role: UserRoleType;
  user_status: UserStatusType;
  enabled: boolean;
  language?: string;
  timezone?: string;
  sensors?: Array<UserSensor>;
}

export interface UsersDataState {
  users: Optional<Array<User>>;
  apiCalled: boolean;
  apiLoaded: boolean;
}

export enum UserActionType {
  SET_USERS = "@@user/SET_USERS",
  ADD_USER = "@@user/ADD_USER",
  REMOVE_USER = "@@user/REMOVE_USER",
  SET_USER = "@@user/SET_USER",
  SET_API_CALLED = "@@user/SET_API_CALLED",
  SET_API_LOADED = "@@user/SET_API_LOADED",
}

export interface SetUsers extends Action {
  type: UserActionType.SET_USERS;
  payload: Optional<Array<User>>;
}

export interface AddUser extends Action {
  type: UserActionType.ADD_USER;
  payload: User;
}

export interface RemoveUser extends Action {
  type: UserActionType.REMOVE_USER;
  payload: User;
}

export interface SetUser extends Action {
  type: UserActionType.SET_USER;
  payload: User;
}

export type SetUserAPICalled = ActionWithPayload<
  UserActionType.SET_API_CALLED,
  boolean
>;
export type SetUserAPILoaded = ActionWithPayload<
  UserActionType.SET_API_LOADED,
  boolean
>;

export type UserAction =
  | SetUsers
  | AddUser
  | RemoveUser
  | SetUser
  | SetUserAPICalled
  | SetUserAPILoaded;
export type UserAsyncAction = ThunkAction<
  Promise<void | UserAction>,
  unknown,
  unknown,
  UserAction
>;
export type UserAsyncDispatch = ThunkDispatch<{}, {}, UserAction>;

export interface CreateUserProps {
  email: string;
  user_role: UserRoleType;
  given_name?: string;
  family_name?: string;
  language?: string;
  timezone?: string;
  sensors: Array<UserSensor>;
}
