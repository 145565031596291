import React from 'react';
import style from './waste-chart-tootltip.module.scss';
import { DateTime } from 'luxon';
import { convertHexToRGBA } from '../../../utils/string';

export const WasteChartTooltip: React.FC<any> = (props) => {
    if (props.payload && props.payload.length > 0) {
        const data = props.payload[0];

        if (props.active && data) {
            return (
                <div className={style.WasteChartTooltip}>
                    <div style={{
                        background: `linear-gradient(135deg, ${convertHexToRGBA(props.color, 100)} 0%, ${convertHexToRGBA(props.color, 30)} 100%)`
                    }} className={style.statusBar} />
                    <div className={style.content}>
                        <div>
                            <span style={{
                                color: props.color
                            }} className={style.value}>{Math.round(data.payload[props.wasteType].stats.count)}</span>
                            <span className={style.unit}> bag(s)</span>
                        </div>
                        <div className={style.divider}>
                            <div className={style.left}/>
                            <div className={style.right}/>
                        </div>
                        <div>
                            <span>Date:</span>
                            <span>{DateTime.fromISO(data.payload.key_as_string).toLocaleString()}</span>
                        </div>
                    </div>
                </div>
            );
        }
    }
    return null;
};
