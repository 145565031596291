import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Modal } from '../../controls/modal/modal';
import { Customer, Optional } from '../../../redux/types';
import { Sensor } from '../../../api/contracts';
import { useDispatch, useSelector } from 'react-redux';
import { binTypesSelector } from '../../../redux/account/selectors';
import { useTranslation } from 'react-i18next';
import { clientIdSelector } from '../../../redux/auth/selectors';
import { useHistory } from 'react-router-dom';
import { notifyError, notifyErrorMessage, notifySuccess } from '../../../utils/notify';
import { deleteSensor } from '../../../redux/sensor-overview/actions';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import config from '../../../config/config';
import { Switch } from '../../controls/switch/switch';
import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import { StyledAutocomplete } from '../../mui/StyledAutocomplete';
import { BinType } from '../../../redux/account/types';
import { Access } from '../../controls/access/access';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';
import { Help } from '../../controls/help/help';
import { useAccess } from '../../../hooks/access';

export interface SensorSettingsModalProps {
    sensor: Sensor;
    setSensor: Dispatch<SetStateAction<Sensor | null>>;
    onModalClose: () => void;
}

export const SensorSettingsModal: React.FC<SensorSettingsModalProps> = (
    props
) => {
    const { sensor } = props;
    const { sensor_id: sensorId } = sensor;
    const customerId = useSelector(clientIdSelector);
    const [nickname, setNickname] = useState<string>(sensor.nickname || '');
    const [ICCID, setICCID] = useState<string | undefined>(sensor.ICCID || undefined);
    const [ICCIDError, setICCIDError] = useState<string>();
    
    const [muted, setMuted] = useState(sensor?.muted || false);
    
    const muteSensorClickHandler = (muted: boolean): void => {
        setMuted(muted);
    };
    
    const [incRC, decRC] = useLoadingBarDispatch();
    
    const access = useAccess('LIDBOT_SUPPORT');
    
    const [selectedClientOption, setSelectedClientOption] = useState<Customer | null>(null);
    const [clientOptions, setClientOptions] = useState<Array<Customer>>([]);
    
    useEffect(() => {
        API.get(config.API.name, '/customers', {})
            .then((response) => {
                const customers: Array<Customer> = response.data;
                setClientOptions(customers);
                setSelectedClientOption(customers.find((option) => option.customer_id === customerId) || null);
            })
            .catch((e) => {
                console.error(e);
            });
    }, [access]);
    
    const binTypes = useSelector(binTypesSelector);
    const initialBinType = binTypes.find((binType) => binType.bin_type_id === sensor.bin_type?.bin_type_id);
    const [selectedBinType, setSelectedBinType] = useState<Optional<BinType | undefined>>(initialBinType);
    
    const cancelHandler = (): void => {
        props.onModalClose && props.onModalClose();
    };
    
    const modalCloseButtonClickHandler = (): void => {
        props.onModalClose && props.onModalClose();
    };
    
    const history = useHistory();
    const dispatch = useDispatch();
    
    const saveHandler = (): void => {
        let validationFailed = false;
        
        if (access) {
            if (!selectedClientOption) {
                !selectedClientOption && notifyError('Customer is required');
                return;
            }
        } else {
            if (!selectedBinType) {
                notifyError('Bin model is required');
                return;
            }
        }
        
        if (ICCID && (ICCID.length < 18 || ICCID.length > 22)) {
            setICCIDError('Sim card number\'s length must be between 18 and 22 digits.');
            validationFailed = true;
        }
        
        if (validationFailed) {
            return;
        }
        
        const options: any = {
            body: {
                nickname,
                muted,
                ICCID
            }
        };
        
        if (!ICCID) {
            options.body.ICCID = null;
        }
        
        if (selectedBinType) {
            options.body.bin_type = selectedBinType.bin_type_id;
        }
        
        if (selectedClientOption) {
            options.body.customer_id = selectedClientOption.customer_id;
        }
        
        const hasNewClientId = access && sensor.customer_id !== selectedClientOption?.customer_id;
        
        incRC();
        API.post(config.API.name, `/sensors/${sensorId}`, options)
            .then((response: Sensor) => {
                decRC();
                if (hasNewClientId) {
                    dispatch(deleteSensor(sensor));
                    notifySuccess('Sensor transferred');
                    hasNewClientId && history.goBack();
                } else {
                    props.setSensor && props.setSensor(response);
                    props.onModalClose && props.onModalClose();
                    notifySuccess('Sensor settings saved');
                }
            })
            .catch((e) => {
                decRC();
                notifyError('Sensor settings not saved', notifyErrorMessage(e));
                console.error(e);
            });
    };
    
    const { t } = useTranslation();
    const headerTitle = t('sensorDetails.sensorSettingsModal.header');
    
    const getMutedHelp = (): ReactElement => {
        return (
            <>
                <h4>{'Muted sensors don\'t:'}</h4>
                <ul>
                    <li>generate events and notifications</li>
                    <li>appear in reports</li>
                    <li>appear in dashboard</li>
                </ul>
                <h4>{'Muted sensors do:'}</h4>
                <ul>
                    <li>collect data from physical devices installed inside bins</li>
                    <li>{'display that data in sensor details reports\' graph'}</li>
                    <li>{'appear on the "Sensor overview" page.'}</li>
                </ul>
            </>
        );
    };
    
    return (
        <Modal
            isOpen={true}
            onClose={cancelHandler}
            title={headerTitle}
            content={(
                <Box>
                    <Box>
                        <Box marginBottom={3}>
                            <TextField
                                type='text'
                                label={'Sensor\'s Name'}
                                value={nickname}
                                variant='filled'
                                fullWidth
                                onChange={(e) => setNickname(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'><Help><p>Give the sensor a unique name that will help your stuff to easily identify it.</p></Help></InputAdornment>
                                }}
                            />
                        </Box>
                        <Box marginBottom={2}>
                            <Switch
                                label='Mute Sensor'
                                name='mute_sensor'
                                checked={muted}
                                onChange={() => muteSensorClickHandler(!muted)}
                                help={getMutedHelp()}
                            />
                        </Box>
                        <Box>
                            <StyledAutocomplete
                                options={binTypes}
                                value={selectedBinType}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, option) => setSelectedBinType(option)}
                                fullWidth
                                renderInput={(params) => <TextField {...params} label='Bin Model' type='text' size='medium' variant='filled' margin='normal' />}
                            />
                        </Box>
                    </Box>
                    <Access roleHierarchy='LIDBOT_SUPPORT'>
                        <Box marginTop={4}>
                            <Box borderBottom={1} marginBottom={2} paddingBottom={1} borderColor='secondary.main'>
                                <Typography variant='caption' color='secondary'>Customer support options</Typography>
                            </Box>
                            <Box>
                                <Box marginBottom={3}>
                                    {
                                        <StyledAutocomplete
                                            options={clientOptions}
                                            value={selectedClientOption}
                                            getOptionLabel={(option: Customer) => option.account_name}
                                            onChange={(e, option) => setSelectedClientOption(option)}
                                            fullWidth
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label='Account'
                                                    type='text'
                                                    size='medium'
                                                    variant='filled'
                                                    margin='normal'
                                                />}
                                        />
                                    }
                                </Box>
                                <Box>
                                    <TextField
                                        type='text'
                                        variant='filled'
                                        label='Sim card number (ICCID)'
                                        value={ICCID}
                                        fullWidth
                                        error={ICCIDError ? true : false}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'><Help><p>ICCID stands for Integrated Circuit Card Identification Number. It’s a unique 18-22 digit code that includes a SIM card’s country, home network, and identification
                                                number. You’ll usually find an ICCID printed on the back of a SIM card, but sometimes it’s included in the packaging materials instead.</p></Help></InputAdornment>
                                        }}
                                        onChange={(e) => setICCID(e.target.value)}
                                        helperText={ICCIDError ? ICCIDError : 'Enter 18-22 digit sim card number'}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Access>
                </Box>
            )}
            actions={(
                <LidbotButtonGroup
                    onSecondaryClickHandler={cancelHandler}
                    onPrimaryClickHandler={saveHandler}
                />
            )}
        />
    );
};
