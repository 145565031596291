import React from 'react';
import { PublicPageFrame } from '../../building-blocks/public-page-frame/public-page-frame';
import { FloatingPanel } from '../../controls/floating-panel/floating-panel';

/**
 * A view for an unmatched route.
 * Display a "404" error message.
 *
 * @constructor
 */
export const NotFound: React.FC<{}> = () => {
  return (
    <PublicPageFrame>
      <FloatingPanel>
        <h1>
          Sorry<em>.</em>
        </h1>
        <p>The page you are looking for does not exist!</p>
      </FloatingPanel>
    </PublicPageFrame>
  );
};
