import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import style from './login.module.scss';
import '../../../config/amplify';
import { appRoutes } from '../../../config/routes';
import { loadUser, loginUser } from '../../../redux/auth/actions';
import { AuthAsyncDispatch } from '../../../redux/auth/types';
import { PublicPageFrame } from '../../building-blocks/public-page-frame/public-page-frame';
import { FloatingPanel } from '../../controls/floating-panel/floating-panel';
import { notifyError, notifyErrorMessage } from '../../../utils/notify';
import { Box, Link, TextField, Typography } from '@material-ui/core';
import { MuiButton } from '../../mui/MuiButton';
import { validateEmail } from '../../../utils/string';

export const Login: React.FC<{}> = () => {
    const authDispatch = useDispatch<AuthAsyncDispatch>();
    const { t } = useTranslation();
    const history = useHistory();
    
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState('');
    
    const [emailError, setEmailError] = useState<string>();
    const [passwordError, setPasswordError] = useState<string>();
    
    const [inProgress, setInProgress] = useState<boolean>(false);
    
    useEffect(() => {
        authDispatch(loadUser()).then(() =>
            history.push(appRoutes.dashboard.path as string)
        );
    }, [authDispatch, history]);
    
    const emailInputChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setEmail(event.target.value);
    };
    
    const passwordInputChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setPassword(event.target.value);
    };
    
    const formSubmitHandler = (): void => {
        let validationFailed = false
    
        if (!email) {
            setEmailError('Email is required');
            validationFailed = true
        }
    
        if(email && !validateEmail(email)) {
            setEmailError('Email incorrect');
            validationFailed = true
        }
        
        if(!password) {
            setPasswordError('Password required')
            validationFailed = true
        }
        
        if(validationFailed) {
            return
        }
        
        setInProgress(true)
        
        authDispatch(loginUser(email, password, ''))
            .then(() => {
                setInProgress(false)
                history.push(appRoutes.dashboard.path as string);
            })
            .catch((e) => {
                setInProgress(false)
                notifyError('Log in not successful', notifyErrorMessage(e));
                console.error(e);
            });
    };
    
    useEffect(() => {
        if(email) {
            setEmailError('')
        }
        
        if(password) {
            setPasswordError('')
        }
    }, [email, password])
    
    
    return (
        <PublicPageFrame>
            <FloatingPanel collapsed>
                <div className={style.Login}>
                    <div className={style.loginForm}>
                        <div className={style.loginPanelHeadline}>
                            <Trans i18nKey='loginPage.panelHeadline' />
                        </div>
                        
                        <div className={style.loginPanelSubline}>
                            {t('loginPage.panelSubline')}
                            <em>.</em>
                        </div>
                        
                        <div className={style.loginPanelText}>
                            <Trans i18nKey='loginPage.panelText' />
                        </div>
                        
                        <Box
                            marginBottom={4}
                            className={style.loginForm}>
                            <Box marginBottom={2}>
                                <TextField
                                    type='email'
                                    value={email}
                                    fullWidth
                                    variant='filled'
                                    label={t('loginPage.email')}
                                    error={!!emailError}
                                    helperText={emailError ? emailError : ' '}
                                    onChange={emailInputChangeHandler}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    type='password'
                                    value={password}
                                    fullWidth
                                    variant='filled'
                                    label={t('loginPage.password')}
                                    onChange={passwordInputChangeHandler}
                                    error={!!passwordError}
                                    helperText={passwordError ? passwordError : ' '}
                                />
                            </Box>
                            <Box textAlign="right">
                                <Typography variant="caption" color="secondary">
                                    <Link color="secondary" href='/reset-password'>
                                        {t('loginPage.passwordForgotten')}
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <MuiButton onClick={formSubmitHandler} fullWidth label="Sign in" inProgress={inProgress}/>
                        </Box>
                    </div>
                </div>
            </FloatingPanel>
        </PublicPageFrame>
    );
};
