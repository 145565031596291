import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';
import mapboxgl, { EventData, LngLatLike, Map, MapboxOptions, Marker, Popup, PopupOptions } from 'mapbox-gl';
import { MapBoxProps } from './types';
import { Sensor } from '../../../api/contracts';
import { notifyError } from '../../../utils/notify';

import config from '../../../config/config';

export const useMapInitialization = (
	props: MapBoxProps,
	mapRef: RefObject<HTMLDivElement>,
	setMap: Dispatch<SetStateAction<Map | null>>,
): void => {
	const accessToken = config.MAPBOX.REACT_APP_MAPBOX_ACCESS_KEY;
	const style = config.MAPBOX.REACT_APP_MAPBOX_STYLE_URL;

	useEffect(() => {
		if (accessToken && style && mapRef.current) {
			mapboxgl.accessToken = accessToken!;

			const options: MapboxOptions = {
				...props,
				...{
					container: mapRef.current,
					style,
					attributionControl: false,
				},
			};
			const map: Map = new mapboxgl.Map(options);

			const nav = new mapboxgl.NavigationControl({ showCompass: false });
			map.addControl(nav, 'bottom-right');

			const geoLocate = new mapboxgl.GeolocateControl({
				showAccuracyCircle: false,
			});
			map.addControl(geoLocate, 'bottom-right');

			map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');

			map.on('load', () => {
				setMap(map);
				map.resize();
			});

			map.on('error', (e: ErrorEvent & EventData) => {
				notifyError(e.message);
			});

			return () => {
				// This will remove all events
				map.remove();
			};
		}
	}, [accessToken, mapRef, setMap, props, style]);
};

export const convertSensorDataToMarkerWithThreshold =
	(threshold: number) =>
	(sensor: Sensor): Marker => {
		const fillPercentage = sensor.fill_percentage || 0;
		const { latitude, longitude } = sensor.location!.coordinates;
		const classStatus = fillPercentage >= threshold ? ' hasAlert' : '';

		const el = document.createElement('div');
		el.className = 'lidbot-bin-marker' + classStatus;
		el.innerHTML = '<div class="dot"></div>';

		return new mapboxgl.Marker(el).setLngLat([
			longitude,
			latitude,
		] as LngLatLike);
	};

export const convertSensorDataToPopupWithThreshold =
	(threshold: number) =>
	(sensor: Sensor): Popup => {
		const title = sensor.nickname || sensor.sensor_id;
		const fillPercentage = sensor.fill_percentage || 0;
		const classStatus = fillPercentage >= threshold ? ' hasAlert' : '';

		const popupOptions: PopupOptions = {
			closeButton: false,
			closeOnClick: false,
			closeOnMove: false,
			className: 'lidbot-bin-popup' + classStatus,
			anchor: 'bottom',
			offset: 10,
		};

		const html = `
        <a class="LidbotPopup" href="/sensors/${sensor.sensor_id}">
            <div class="statusBar"></div>
            <div class="fillPercentage">${fillPercentage}%</div>
            <div class="info">
                <div class="title">${title}</div>
            </div>
        </a>`;

		const popup = new Popup(popupOptions).setHTML(html);

		return popup;
	};
