import moment from 'moment';
import 'moment-timezone';
import { Optional } from '../redux/types';

export type MomentTimeUnit = 'year' | 'quarter' | 'month' | 'week' | 'day';

export const configMomentJS = (
	timezone: Optional<string> = null,
	language: Optional<string> = null,
): void => {
	timezone && moment.tz.setDefault(timezone);
	language &&
		moment.updateLocale(language, {
			week: {
				dow: 1,
			},
		});
};
