import {
	LoadingBarAction,
	LoadingBarActionType,
	LoadingBarState,
} from './types';

const initialLoadingBarState: LoadingBarState = {
	referenceCount: 0,
};

export const loadingBarReducer = (
	state: LoadingBarState = initialLoadingBarState,
	action: LoadingBarAction,
): LoadingBarState => {
	switch (action.type) {
		case LoadingBarActionType.INCREMENT_REFERENCE_COUNT: {
			return {
				referenceCount: state.referenceCount + 1,
			};
		}
		case LoadingBarActionType.DECREMENT_REFERENCE_COUNT: {
			return {
				referenceCount: state.referenceCount - 1,
			};
		}
		default: {
			return {
				referenceCount: state.referenceCount,
			};
		}
	}
};
