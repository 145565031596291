import React from 'react';
import { User } from '../../redux/user/types';
import { default as MuiAvatarGroup } from '@material-ui/lab/AvatarGroup';
import { Avatar } from './Avatar';
import { withStyles } from '@material-ui/core';

export interface AvatarProps {
  users: Array<User>;
  max?: number;
  showDetailsOnHover?: boolean;
}

const StyledMuiAvatarGroup = withStyles(() => ({
  root: {
    "& > div": {
      marginLeft: "-15px",
      transition: '300ms ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.15)'
      },
      "&:first-child": {
        marginLeft: 0,
      },
    },
  },
}))(MuiAvatarGroup);

export const AvatarGroup: React.FC<AvatarProps> = (props) => {
  const { users, max = 10000, showDetailsOnHover = false } = props;

  return (
    <StyledMuiAvatarGroup max={max}>
      {users.map((user) => {
        return (
          <Avatar
            key={user.email}
            user={user}
            showDetailsOnHover={showDetailsOnHover}
          />
        );
      })}
    </StyledMuiAvatarGroup>
  );
};
