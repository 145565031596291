import {
	DecrementReferenceCount,
	IncrementReferenceCount,
	LoadingBarActionType,
} from './types';

export const incrementReferenceCount = (): IncrementReferenceCount => ({
	type: LoadingBarActionType.INCREMENT_REFERENCE_COUNT,
	payload: null,
});
export const decrementReferenceCount = (): DecrementReferenceCount => ({
	type: LoadingBarActionType.DECREMENT_REFERENCE_COUNT,
	payload: null,
});
