import React from "react";
import { SensorChartTooltipProps } from "../sensor-chart-tooltip/sensor-chart-tooltip";
import { SensorReport } from "../../../api/sensors-reports";

export interface SensorChartActiveDotProps {
  cx?: number;
  cy?: number;
  fill?: string;
  r?: number;
  stroke?: string;
  strokeWidth?: number;
  payload?: SensorReport;
  onTooltipChange?: (payload: SensorChartTooltipProps) => void;
}

export const SensorChartActiveDot: React.FC<SensorChartActiveDotProps> = (
  props: SensorChartActiveDotProps
) => {
  const { onTooltipChange, payload, cx, cy, fill, r, stroke, strokeWidth } =
    props;

  onTooltipChange &&
    onTooltipChange({
      cx,
      cy,
      createdOn: payload?.created_on,
      value: payload?.value,
      battery: payload?.battery,
    });

  return payload && (payload.value === 0 || payload.value) && cy ? (
    <g className="recharts-layer recharts-active-dot">
      <circle
        className="recharts-dot"
        {...{ cx, cy, fill, r, stroke, strokeWidth }}
      />
    </g>
  ) : null;
};
