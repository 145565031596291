import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createBinType, updateBinType } from '../../../redux/account/actions';
import { AccountAsyncDispatch, BinType, WasteType } from '../../../redux/account/types';
import { Option } from '../../../redux/types';
import { numericInputOnChangeHandlerWithSetter, numericInputValueFilter } from '../../../utils/input';
import { WasteLabel } from '../../building-blocks/waste-label/waste-label';
import { Modal } from '../../controls/modal/modal';
import { WasteAvatar } from '../../controls/waste-avatar/waste-avatar';
import { useWasteColorPalette } from '../../controls/waste-avatar/waste-avatar-utils';
import style from './bin-type-settings-modal.module.scss';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import { LidbotButtonGroup } from '../../mui/LidbotButtonGroup';
import _ from 'lodash'


export interface BinTypeIcon extends Option {
  value: WasteType,
}

export const binTypeIcons: Array<BinTypeIcon> = [
  {
    value: WasteType.RECYCLABLE,
    label: 'recycling'
  },
  {
    value: WasteType.GLASS,
    label: 'glass'
  },
  {
    value: WasteType.PLASTIC,
    label: 'plastic'
  },
  {
    value: WasteType.METALS,
    label: 'aluminium'
  },
  {
    value: WasteType.PAPER,
    label: 'paper'
  },
  {
    value: WasteType.MIXED,
    label: 'general'
  },
  {
    value: WasteType.ORGANIC,
    label: 'organic'
  },
  {
    value: WasteType.CLOTHING,
    label: 'clothing'
  }
];


export interface BinTypeSettingsModalProps {
  binType: BinType | null;
  onClose?: () => void;
}


export const BinTypeSettingsModal: React.FC<BinTypeSettingsModalProps> = (props) => {
  const { binType, onClose } = props;
  const [incRC, decRC] = useLoadingBarDispatch();
  
  const title = binType ? 'Update a bin model' : 'Add a new bin model';
  
  const onCloseHandler = (): void => {
    onClose && onClose();
  };
  
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [selectedBinTypeIcons, setSelectedBinTypeIcons] = useState<BinTypeIcon[]>(binType ? [{
    value: binType.waste_type!,
    label: 'recycling'
  }] : []);
  
  const setSelectedBinTypeIconOnClick = (icon: BinTypeIcon): void => {
    const selectedIcon = selectedBinTypeIcons.find(i => icon.value === i.value)
    
    if(selectedIcon) {
      setSelectedBinTypeIcons(_.without(selectedBinTypeIcons, selectedIcon));
    } else {
      setSelectedBinTypeIcons([icon]);
    }
  };
  
  const [name, setName] = useState<string>(binType?.name || '');
  const [nameError, setNameError] = useState<string>();
  const [volumeError, setVolumeError] = useState<string>();
  const [heightError, setHeightError] = useState<string>();
  
  const [volume, setVolumeValue] = useState<number>(binType?.volume || 0);
  const volumeInputOnChangeHandler = numericInputOnChangeHandlerWithSetter(setVolumeValue);
  
  const [height, setMaxDistanceValue] = useState<number>(binType?.max_distance || 0);
  const maxDistanceOnChangeHandler = numericInputOnChangeHandlerWithSetter(setMaxDistanceValue);
  
  const accountAsyncDispatch = useDispatch<AccountAsyncDispatch>();
  
  useEffect(() => {
    if (name) {
      setNameError(undefined);
    }
    
    if (height) {
      setHeightError(undefined);
    }
    
    if (volume) {
      setVolumeError(undefined);
    }
    
  }, [name, height, volume]);
  
  
  const onSubmitHandler = (): void => {
    let validationFailed = false;
    
    if (height > 4000) {
      setHeightError('Bin height can not exceed 4 metres (4000 millimeters)');
      validationFailed = true;
    }
    
    if (height <= 0) {
      setHeightError('Bin height must be at least 1 millimeter');
      validationFailed = true;
    }
    
    if (name === '') {
      setNameError('Name required');
      validationFailed = true;
    }
    
    if (volume < 1) {
      setVolumeError('Volume must be at least 1 litre.');
      validationFailed = true;
    }
    
    if (validationFailed) {
      return;
    }
    
    setInProgress(true);
    
    if (binType) {
      const bt: BinType = {
        'bin_type_id': binType.bin_type_id,
        name,
        'min_distance': 0,
        'max_distance': height,
        'volume': volume,
        'waste_type': selectedBinTypeIcons[0].value,
        'waste_streams': selectedBinTypeIcons.map(item => {
          return {
            waste_type: item.value,
            volume: volume,
            height: height,
            accuracy_adjustment: null
          }
        })
      };
      
      incRC();
      accountAsyncDispatch(updateBinType(bt))
        .then(() => {
          decRC();
          setInProgress(false);
          onCloseHandler();
        })
        .catch(() => {
          setInProgress(false);
          decRC();
        });
    } else {
      const bt: Omit<BinType, 'bin_type_id'> = {
        name,
        'min_distance': 0,
        'max_distance': height,
        'volume': volume,
        'waste_type': selectedBinTypeIcons[0].value,
        'waste_streams': selectedBinTypeIcons.map(item => {
          return {
            waste_type: item.value,
            volume: volume,
            height: height,
            accuracy_adjustment: null
          }
        })
      };
      
      incRC();
      accountAsyncDispatch(createBinType(bt))
        .then(() => {
          decRC();
          setInProgress(false);
          onCloseHandler();
        })
        .catch(() => decRC());
    }
  };
  
  const wasteColorPalette = useWasteColorPalette();
  
  return (
    <Modal
      isOpen={true}
      onClose={onCloseHandler}
      title={title}
      content={(
        <Box>
          <Box marginBottom={0}>
            <Box>
              <Box marginBottom={2}>
                <TextField
                  variant='filled'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label='Name'
                  required
                  fullWidth
                  error={nameError ? true : false}
                  helperText={nameError ? nameError : ' '}
                />
              </Box>
              <Box marginBottom={6}>
                <Box marginBottom={2}>
                  <Typography color='secondary'>Waste type</Typography>
                </Box>
                <Box
                  className={style.binTypeIcons}
                >
                  {
                    binTypeIcons.map(icon => {
                      const selected = selectedBinTypeIcons.find(i => icon.value === i.value)
                      const isIconSelected = selected ? true : false;
                      const color = wasteColorPalette[icon.value];
                      return (
                        <Box
                          style={{
                              cursor: 'pointer'
                          }}
                          key={icon.value}
                          className={classNames(style.binTypeIconWrapper, style[color], { [style.selected]: isIconSelected })}
                          onClick={() => setSelectedBinTypeIconOnClick(icon)}
                        >
                          <WasteAvatar
                            size={80}
                            type={icon.value}
                            selected={isIconSelected}
                            darkMode={false}
                          />
                          <WasteLabel
                            className={classNames(style.binTypeIconLabel, { [style.selected]: isIconSelected })}
                            type={icon.value} hasColor={isIconSelected}>
                            {icon.label}
                          </WasteLabel>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
              <Box display='flex'>
                <Box
                  flexBasis='50%'
                  marginRight={1}
                >
                  <TextField
                    variant='filled'
                    value={numericInputValueFilter(height)}
                    onChange={maxDistanceOnChangeHandler}
                    label='Height'
                    required
                    fullWidth
                    error={heightError ? true : false}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>{`( ${height < 1000 ? `${(height / 10)} cm` : `${(height / 1000)} m`} )`}</InputAdornment>
                    }}
                    helperText={heightError ? heightError : 'Enter the distance in millimeters (mm) between the sensor and the bottom of the bin.'}
                  />
                </Box>
                <Box
                  flexBasis='50%'
                  marginLeft={1}
                >
                  <TextField
                    variant='filled'
                    value={numericInputValueFilter(volume)}
                    onChange={volumeInputOnChangeHandler}
                    label='Volume'
                    fullWidth
                    error={volumeError ? true : false}
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>(l)</InputAdornment>
                    }}
                    helperText={volumeError ? volumeError : 'Enter volume of a single waste stream.'}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      actions={(
        <LidbotButtonGroup onSecondaryClickHandler={onCloseHandler} onPrimaryClickHandler={onSubmitHandler} isPrimaryInProgress={inProgress} />
      )}
    />
  );
};
