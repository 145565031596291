import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuthData } from '../../redux/auth/effects';
import { useAccountData } from '../../redux/account/effects';

/**
 * Private route. Makes sure the user is authenticated when trying to access this route.
 * Tries to get the current authenticated user from
 *
 * @param props Properties for the route.
 * @constructor
 */
export const ProtectedRoute: React.FC<RouteProps> = (props) => {
  // Ensures that user has user data and loads it
  useAuthData();

  // Ensures that the account data is in sync with user data
  useAccountData();

  // Actually display the route
  return <Route {...props} />;
};
