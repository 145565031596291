import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import React from 'react';
import style from './sidebar.module.scss';
import { SidebarLink, SidebarLinkProps } from '../../controls/sidebar-link/sidebar-link';

import { ReactComponent as TilesIcon } from '../../../assets/img/icons/icon.tiles.svg';
import { ReactComponent as SensorIcon } from '../../../assets/img/icons/icon.sensor.svg';
import { ReactComponent as BinIcon } from '../../../assets/img/icons/icon.bin.svg';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { ReactComponent as LocationIcon } from '../../../assets/img/icons/icon.location.svg';
import { ReactComponent as GearIcon } from '../../../assets/img/icons/icon.gear.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/img/icons/icon.reports.svg';

import { ReactComponent as ActiveGradient } from '../../../assets/img/sidebar-active-gradient.svg';
import { useSelector } from 'react-redux';
import { roleSelector } from '../../../redux/auth/selectors';
import { mapEnabledSelector } from '../../../redux/account/selectors';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { blackMainColor } from '../../mui/themes';

export interface SidebarMenuLinks {
  [key: string]: SidebarLinkProps;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        fill: blackMainColor
      }
    }));

const sidebarSections: Array<SidebarMenuLinks> = [
  {
    overview: {
      label: "Dashboard",
      i18nKey: "sidebar.overview",
      to: "/",
      icon: <TilesIcon />,
      onlyActiveWhenExact: true,
    },
    // bins: {
    //   label: "Bins",
    //   to: "/bins",
    //   icon: <DeleteOutlineOutlinedIcon />,
    // },
    sensors: {
      label: "Sensors",
      to: "/sensors",
      icon: <SensorIcon />,
    },
    reports: {
      label: "Reports",
      to: "/reports",
      icon: <ReportsIcon/>
    },
    location: {
      label: "Location",
      i18nKey: "sidebar.location",
      to: "/location",
      icon: <LocationIcon />,
    },
  },
  {
    settings: {
      label: "Settings",
      i18nKey: "sidebar.settings",
      to: "/settings",
      icon: <GearIcon />,
    },
    admin: {
      label: "Customers",
      to: "/customers",
      supportProtected: true,
      icon: <PeopleOutlineIcon/>,
    },
  },
];

export const Sidebar: React.FC<{}> = () => {
  const sidebarMenuItems: Array<React.ReactNode> = [];
  const userRole = useSelector(roleSelector);
  const mapEnabled = useSelector(mapEnabledSelector);

  sidebarSections.forEach((sidebarLinkSection, index) => {
    for (const menuItemKey in sidebarLinkSection) {
      if (sidebarLinkSection.hasOwnProperty(menuItemKey)) {
        const linkProps = sidebarLinkSection[menuItemKey];

        if (menuItemKey === "location" && !mapEnabled) continue;
        if (linkProps.supportProtected && userRole !== "LIDBOT_SUPPORT")
          continue;

        sidebarMenuItems.push(<SidebarLink {...linkProps} key={menuItemKey} />);
      }
    }

    if (index < sidebarSections.length - 1) {
      sidebarMenuItems.push(<hr key={"divider-" + index + 1} />);
    }
  });

  return (
    <div className={style.Sidebar}>
      {sidebarMenuItems}
      <ActiveGradient />
    </div>
  );
};
