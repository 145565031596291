import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { CognitoUser } from "amazon-cognito-identity-js";
import { UserRoleType } from "../user/types";
import { UserSensor } from "../../api/contracts";

export interface LidbotUserAttributes {
  given_name?: string;
  family_name?: string;
  ["custom:client_id"]: string;
  ["custom:user_role"]: UserRoleType;
  ["custom:language"]: string;
  ["custom:timezone"]: string;
  ["custom:sensors"]: Array<UserSensor>; // list of sensors assigned to this user
  email: string;
  picture?: string;
}

export interface LidbotUser extends CognitoUser {
  attributes: LidbotUserAttributes;
}

export interface AuthState {
  user: LidbotUser | null;
}

export enum AuthActionTypes {
  SET_USER = "@@auth/SET_USER",
}

export interface SetUser {
  type: AuthActionTypes.SET_USER;
  payload: LidbotUser | null;
}

export type AuthActions = SetUser;
export type AuthAsyncActions = ThunkAction<
  Promise<void | AuthActions>,
  {},
  {},
  Action
>;
export type AuthAsyncDispatch = ThunkDispatch<{}, {}, Action>;
