import { SensorListDisplayOption, SensorListOrderDirection } from './types';
import { ApplicationState } from './../index';
import { SensorOverviewSortOption } from '../../components/building-blocks/sensor-overview-header/sensor-overview-header';
import { Sensor } from '../../api/contracts';

export const sortOptionSelector = (
	state: ApplicationState,
): SensorOverviewSortOption => state.sensorOverview.sortOption;
export const displayOptionSelector = (
	state: ApplicationState,
): SensorListDisplayOption => state.sensorOverview.displayOption;
export const sensorOverviewDirectionSelector = (
	state: ApplicationState,
): SensorListOrderDirection => state.sensorOverview.direction;
export const sensorsSelector = (state: ApplicationState): Array<Sensor> | undefined => state.sensorOverview.sensors
