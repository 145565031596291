import { configReducer } from './config/reducer';
import { ConfigState } from './config/types';
import {
	applyMiddleware,
	combineReducers,
	compose,
	createStore,
	Middleware,
	Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { SensorDetailState } from './sensor-detail/types';
import { sensorDetailReducer } from './sensor-detail/reducer';
import { sensorOverviewReducer } from './sensor-overview/reducer';
import { SensorOverviewState } from './sensor-overview/types';
import { AuthState } from './auth/types';
import { authReducer } from './auth/reducer';
import { UsersDataState } from './user/types';
import { usersDataReducer } from './user/reducer';
import { DashboardState } from './dashboard/types';
import { dashboardReducer } from './dashboard/reducer';
import { AccountState } from './account/types';
import { accountReducer } from './account/reducer';
import { LoadingBarState } from './loading-bar/types';
import { loadingBarReducer } from './loading-bar/reducers';
import { notificationReducer } from './notifications/reducer';
import { NotificationState } from './notifications/types';

export interface ApplicationState {
	auth: AuthState;
	dashboard: DashboardState;
	sensorOverview: SensorOverviewState;
	notification: NotificationState;
	sensorDetail: SensorDetailState;
	config: ConfigState;
	usersData: UsersDataState;
	account: AccountState;
	loadingBar: LoadingBarState;
}

const rootReducer = combineReducers<ApplicationState>({
	auth: authReducer,
	dashboard: dashboardReducer,
	sensorOverview: sensorOverviewReducer,
	notification: notificationReducer,
	sensorDetail: sensorDetailReducer,
	config: configReducer,
	usersData: usersDataReducer,
	account: accountReducer,
	loadingBar: loadingBarReducer,
});

/**
 * Set up the redux store.
 * Applies middleware to redux and sets up the initial state.
 *
 * @param initialState
 */
export default function configureStore(initialState: ApplicationState): Store {
	// Apply the middleware
	const middleware: Middleware[] = [thunk];
	const middlewareEnhancer = applyMiddleware(...middleware);

	// Add the dev tools if we're in dev environment only
	if (process.env.NODE_ENV === 'development') {
		return createStore(
			rootReducer,
			initialState,
			composeWithDevTools(middlewareEnhancer),
		);
	}

	// Create the redux store
	return createStore(rootReducer, initialState, compose(middlewareEnhancer));
}
