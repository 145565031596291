import React from 'react';
import { ProtectedRoute } from './protected-route';
import { settingsRoutes } from '../../config/routes';
import { Route, Switch } from 'react-router-dom';
import { RoleProtectedRoute } from './role-protected-route';

/**
 * Router for the settings view with the tabbed interface.
 */
export const SettingsRouter: React.FC<{}> = () => {
  const routeComponents: Array<React.ReactNode> = [];

  for (const routeKey in settingsRoutes) {
    if (settingsRoutes.hasOwnProperty(routeKey)) {
      const route = settingsRoutes[routeKey];

      if (route.roleHierarchy) {
        routeComponents.push(
          <RoleProtectedRoute
            roleHierarchy={route.roleHierarchy}
            {...route}
            key={routeKey}
          />
        );
      } else if (route.protected) {
        routeComponents.push(<ProtectedRoute {...route} key={routeKey} />);
      } else {
        const compo = <Route {...route} key={routeKey} />;
        routeComponents.push(compo);
      }
    }
  }

  return <Switch>{routeComponents}</Switch>;
};
