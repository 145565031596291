import React from 'react';
import style from './public-page-frame.module.scss';

export interface PublicPageFrameProps {
    /** The contents of the page frame */
    children: React.ReactNode;
}

/**
 * The page frame for public pages like the login page, password reset and account verification.
 *
 * @param props
 */
export const PublicPageFrame: React.FC<PublicPageFrameProps> = (props) => {
    return (
        <div className={style.PublicPageFrame}>
            {props.children && props.children}
        </div>
    )
}
