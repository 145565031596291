import { useEffect } from 'react';
import { AccountAsyncDispatch } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { loadAccount } from './actions';
import { customerIdSelector } from './selectors';
import { clientIdSelector } from '../auth/selectors';

export const useAccountData = (): void => {
	const clientId = useSelector(clientIdSelector);
	const customerId = useSelector(customerIdSelector);
	const accountDispatch = useDispatch<AccountAsyncDispatch>();
	useEffect(() => {
		clientId && clientId !== customerId && accountDispatch(loadAccount());
	}, [clientId, customerId, accountDispatch]);
};
