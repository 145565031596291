import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { AppRouter } from "./components/router/app-router";
import { Slide, ToastContainer } from "react-toastify";
import configureStore, { ApplicationState } from "./redux";
import { LoadingBar } from "./components/building-blocks/loading-bar/loading-bar";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "./components/mui/themes";

const initialState = {} as ApplicationState;
const store = configureStore(initialState);

const App: React.FC<{}> = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={<h1>Loading...</h1>}>
          <LoadingBar />
          <AppRouter />
          <ToastContainer
            transition={Slide}
            position="top-right"
            style={{ top: "44px", right: "44px" }}
            autoClose={2500}
            hideProgressBar
            closeButton={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            bodyStyle={{
              padding: 0,
            }}
          />
        </Suspense>
      </Provider>
    </MuiThemeProvider>
  );
};

export default App;
