import React from "react";
import style from "./waste-collection-panel.module.scss";
import { StatsPanel } from "../../controls/stats-panel/stats-panel";
import { useSelector } from "react-redux";
import { wasteCollectionPanelDataSelector } from "../../../redux/dashboard/selectors";
import { Trans } from "react-i18next";
import { thresholdSelector } from "../../../redux/account/selectors";
import { WasteCollectionChart } from "../../controls/waste-collection-chart/waste-collection-chart";
import { FormLabel } from "../form-label/form-label";

export const WasteCollectionPanel: React.FC<{}> = () => {
  const threshold: number = useSelector(thresholdSelector);

  const panelTitle = (
    <span>
      <Trans i18nKey="dashboard.wasteCollectionPanel.header" />
    </span>
  );

  const response = useSelector(wasteCollectionPanelDataSelector);

  return (
    <StatsPanel
      title={panelTitle}
      help="Waste Type Analysis widget contains waste data from the previous week."
    >
      <div className={style.WasteCollectionPanel}>
        {response?.waste_date_histogram.buckets &&
          response?.waste_date_histogram.buckets.length > 0 && (
            <WasteCollectionChart
              buckets={response?.waste_date_histogram.buckets}
              precision={0}
              alertThreshold={threshold}
            />
          )}
        {!response?.waste_date_histogram.buckets ||
          (response?.waste_date_histogram.buckets.length == 0 && (
            <FormLabel capitalize={false}>
              Nothing to show here yet. The data will be available next week
            </FormLabel>
          ))}
      </div>
    </StatsPanel>
  );
};
