import classNames from 'classnames';
import React, { ReactNode, ReactNodeArray, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EllipsisIcon } from '../../../assets/img/icons/icon.ellipsis.svg';
import { deleteBinType } from '../../../redux/account/actions';
import { binTypesSelector } from '../../../redux/account/selectors';
import { AccountAsyncDispatch, BinType } from '../../../redux/account/types';
import { useClientRefreshCallback } from '../../../redux/auth/effects';
import { WasteLabel } from '../../building-blocks/waste-label/waste-label';
import { FlyOver, FlyOverOption } from '../../controls/fly-over/fly-over';
import { CellGroup, FlexibleRowCell, HeaderRowCell, HighlightedCellGroup, IconRowCell, OptionsRowCell, Row } from '../../controls/row/row';
import { WasteAvatar } from '../../controls/waste-avatar/waste-avatar';
import { ReactComponent as BinIcon } from './../../../assets/img/icons/icon.bin.svg';
import { binTypeIcons, BinTypeSettingsModal } from './bin-type-settings-modal';
import style from './bin-type-settings.module.scss';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { Box } from '@material-ui/core';
import { LidbotListHeader } from '../../mui/LidbotListHeader';
import { DeleteModal } from '../../controls/modal/deleteModal';


export const BinTypeSettings: React.FC = () => {
  const binTypes = useSelector(binTypesSelector);
  const [selectedBinType, setSelectedBinType] = useState<BinType | null>(null);

  useClientRefreshCallback(() => {
    setSelectedBinType(null);
    setDisplayedModalType('none');
    setSettingsModalBinType(null);
    setDeleteModalBinType(null);
  });


  type BinTypeSettingsModalType = 'bin-type-settings' | 'delete-bin-type';
  const [displayedModalType, setDisplayedModalType] = useState<BinTypeSettingsModalType | 'none'>('none');

  const [settingsModalBinType, setSettingsModalBinType] = useState<BinType | null>(null);
  const displaySettingsModalEventHandler = (binType: BinType | null): void => {
    setSelectedBinType(null);
    setSettingsModalBinType(binType);
    setDisplayedModalType('bin-type-settings');
  };
  
  const settingsModalNode: ReactNode = (
    <BinTypeSettingsModal
      binType={settingsModalBinType}
      onClose={() => setDisplayedModalType('none')}
    />
  );

  const [deleteModalBinType, setDeleteModalBinType] = useState<BinType | null>(null);
  const displayDeleteModalEventHandler = (binType: BinType): void => {
    setSelectedBinType(null);
    setDeleteModalBinType(binType);
    setDisplayedModalType('delete-bin-type');
  };

  const [incRC, decRC] = useLoadingBarDispatch();
  const accountAsyncDispatch = useDispatch<AccountAsyncDispatch>();
  const onDeleteHandler = (): void => {
    incRC();
    deleteModalBinType && accountAsyncDispatch(deleteBinType(deleteModalBinType))
      .then(() => {
        decRC();
        setDisplayedModalType('none');
      })
      .catch(() => {
        decRC();
      });
  };

  const binTypeChartHeader: ReactNode = (
    <Row className={style.binChartHeader} rowClassName='header'>
      <HeaderRowCell className={style.avatar} rowCellClassName='icon' hasBorder={true}>
        Waste type
      </HeaderRowCell>
      <CellGroup>
        <HeaderRowCell className={style.nameCell} rowCellClassName='flexible' hasBorder={true}>Name</HeaderRowCell>
        <HeaderRowCell className={style.volumeCell} rowCellClassName='flexible' hasBorder={true}>Volume (l)</HeaderRowCell>
        <HeaderRowCell className={style.maxDistanceCell}>Bin Height (mm)</HeaderRowCell>
      </CellGroup>
      <HeaderRowCell className={style.optionsCell} rowCellClassName='options' />
    </Row>
  );


  const selectBinTypeOnClickHandler = (binType: BinType) => () => {
    setTimeout(() => {
      setSelectedBinType(binType);
    }, 0);
  };

  const binTypeRowNodes: ReactNodeArray = binTypes.map(binType => {
    const flyoverOptions: Array<FlyOverOption> = [
      {
        label: 'Edit',
        onClick: () => displaySettingsModalEventHandler(binType)
      }
    ];

    const destructiveOption: FlyOverOption = {
      label: 'Delete',
      onClick: () => displayDeleteModalEventHandler(binType)
    };

    return (
      <Row
        key={binType.bin_type_id}
        className={classNames(style.binChartRow)}
        selected={selectedBinType?.bin_type_id === binType.bin_type_id}
        onDeselect={() => setSelectedBinType(null)}>
        <IconRowCell className={style.avatar}>
          {binType.waste_type &&
          <WasteAvatar
              key={binType.waste_type}
              size={48}
              type={binType.waste_type}
              displayIcon={true}
          />
          }
        </IconRowCell>
        <HighlightedCellGroup>
          <FlexibleRowCell className={style.nameCell}>
            <Box>
              <Box className={style.nameLabel}>{binType.name}</Box>
            </Box>
          </FlexibleRowCell>
          <FlexibleRowCell className={style.volumeCell}>{binType.volume}</FlexibleRowCell>
          <FlexibleRowCell className={style.maxDistanceCell}>{binType.max_distance}</FlexibleRowCell>
        </HighlightedCellGroup>
        <OptionsRowCell
          className={style.optionsCell}
          onClick={selectBinTypeOnClickHandler(binType)}
          flyover={<FlyOver options={flyoverOptions} destructiveOption={destructiveOption} />}
          roleHierarchy='ADMIN'
        >
          <EllipsisIcon />
        </OptionsRowCell>
      </Row>
    );
  });

  return (
    <Box className={style.BinTypeSettings}>
      {displayedModalType === 'bin-type-settings' && settingsModalNode}
      {displayedModalType === 'delete-bin-type' &&
      <DeleteModal
          isOpen={true}
          onClose={() => setDisplayedModalType('none')}
          objectClass="bin model"
          objectName={deleteModalBinType?.name}
          onDelete={onDeleteHandler}
      />
      }
      <LidbotListHeader addRowTooltipTitle="Add new bin model" addRowHandler={() => displaySettingsModalEventHandler(null)}/>
      <Box marginBottom={6} className={style.binChart}>
        <Box className={style.binChartHeader}>
          {binTypeChartHeader}
        </Box>
        {binTypeRowNodes}
      </Box>
    </Box>
  );
};
