import { useEffect } from 'react';
import { loadNotifications } from './actions';
import { useDispatch } from 'react-redux';
import { useLoadingBarDispatch } from '../loading-bar/effects';
import { NotificationAsyncDispatch } from './types';

export const useNotificationData = (): void => {
	const [incRC, decRC] = useLoadingBarDispatch();
	const NotificationAsyncDispatch = useDispatch<NotificationAsyncDispatch>();

	const sortField = 'created_on';

	useEffect(() => {
		incRC();
		NotificationAsyncDispatch(loadNotifications(sortField, 'desc'))
			.then(() => decRC())
			.catch(() => decRC());
	}, []);
};
