import React from "react";
import style from "./chart-tootltip.module.scss";
import classNames from "classnames";
import { TooltipProps } from "recharts";
import moment from "moment";

export interface SensorChartTooltipProps extends TooltipProps<any, any> {
  cx?: number;
  cy?: number;
  createdOn?: number;
  value?: number;
  threshold?: number;
  thresholdDirection?: "gt" | "lt";
  battery?: number;
}

export const SensorChartTooltip: React.FC<SensorChartTooltipProps> = ({
  createdOn,
  value,
  threshold = Infinity,
  thresholdDirection = "gt",
  active,
}) => {
  if ((value === 0 || value) && createdOn && active) {
    const hasAlert =
      thresholdDirection === "gt"
        ? value >= threshold
        : value <= threshold && value !== Infinity;
    return (
      <div
        className={classNames(style.ChartTooltip, {
          [style.hasAlert]: hasAlert,
        })}
      >
        <div className={style.statusBar} />
        <div className={style.sensorTooltipContent}>
          <span className={style.sensorFillStatus}>{Math.round(value)} %</span>
          <div className={style.sensorDetails}>
            <span className={style.sensorName}>
              {moment.unix(createdOn).format("MM/DD/YYYY, hh:mm A")}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
