import React from 'react';
import style from './avg-fill-at-pickup-chart.module.scss';
import classNames from 'classnames';
import { Bucket } from '../../../api/histogram';
import moment from 'moment';
import { Trans } from 'react-i18next';

export interface AvgFillAtPickupChartProps {
    buckets?: Array<Bucket>;
    precision: number;
    alertThreshold: number;
}

export const AvgFillAtPickupChart: React.FC<AvgFillAtPickupChartProps> = (props) => {

    return (
        <div className={style.TopBinsChart}>
            {props.buckets?.map((bucket, index)=>{
                const thisMonth = (
                    <Trans i18nKey='dashboard.avgPickupFill.thisMonth'/>
                );

                return (
                    <div key={bucket.key} className={classNames(style.binRow, { [style.hasAlert]: bucket.stats.avg < props.alertThreshold})}>
                        <div className={style.binTitle}>{(index === 0) ? thisMonth : moment(bucket.key_as_string).format('MMMM')}</div>
                        <div className={style.binBarChart}>
                            <div style={{width: `${bucket.stats.avg}%`}}/>
                        </div>
                        {
                            bucket.stats.avg &&
                            <div className={style.percentage}>{bucket.stats.avg.toFixed(props.precision)}%</div>
                        }
                    </div>
                );
            })}
        </div>
    );
};
