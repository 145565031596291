import React, { ReactElement } from 'react';
import { Avatar, Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';

export interface UsersSkeletonProps {
    size?: number;
    hideAvatar?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: 10
        }
    }));

export const ListSkeleton: React.FC<UsersSkeletonProps> = (props) => {
    const classes = useStyles()
    
    const {
        size = 3,
        hideAvatar = false
    } = props;
    
    const elements: Array<ReactElement> = _.times(size, function(index: number) {
        return (
            <Box marginBottom={2} key={index}>
                <Box display="flex" alignItems="center">
                    {
                        !hideAvatar &&
                        <Box marginRight={1}>
                            <Skeleton variant="circle">
                                <Avatar />
                            </Skeleton>
                        </Box>
                    }
                    <Box width="100%">
                        <Skeleton classes={{
                            root: classes.root
                        }} variant="rect" height={30}/>
                    </Box>
                </Box>
            </Box>
        )
    })
    
    return (
        <Box>
            {
                elements
            }
        </Box>
    )
}