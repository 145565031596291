import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Optional } from "../types";
import { Sensor } from "../../api/contracts";
import { API } from "aws-amplify";
import { loadHistogramData, setFilterSearchQuery } from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { filterOptionSelector, filterSearchQuerySelector } from "./selectors";
import { SensorDetailAsyncDispatch } from "./types";
import { getSearchQueryForDatePickerOption } from "../../components/controls/date-picker-control/utils";
import { accountSelector } from "../account/selectors";
import { useLoadingBarDispatch } from "../loading-bar/effects";
import config from "../../config/config";

export const useSensorData = (
  sensorId: Optional<string>
): [Optional<Sensor>, Dispatch<SetStateAction<Sensor | null>>] => {
  const [sensor, setSensor] = useState<Optional<Sensor>>(null);
  useEffect(() => {
    sensorId &&
      API.get(config.API.name, `/sensors/${sensorId}`, {})
        .then((response: Sensor) => {
          setSensor(response);
        })
        .catch((e) => console.error(e));
  }, [sensorId, setSensor]);
  return [sensor, setSensor];
};

export const useSensorReportsData = (sensorId: Optional<string>): void => {
  const account = useSelector(accountSelector);

  const activeFilterOption = useSelector(filterOptionSelector);
  const activeFilterOptionValue = activeFilterOption.value;

  const filterSearchQuery = useSelector(filterSearchQuerySelector);
  const startTimestamp = filterSearchQuery?.["created_on.gte"] || null;
  const endTimestamp = filterSearchQuery?.["created_on.lt"] || null;

  const sensorDetailDispatch = useDispatch<SensorDetailAsyncDispatch>();
  const [incRC, decRC] = useLoadingBarDispatch();

  const dispatch = useDispatch();
  useEffect(() => {
    sensorId &&
      activeFilterOptionValue !== "custom" &&
      dispatch(setFilterSearchQuery(null));
  }, [sensorId, activeFilterOptionValue, dispatch]);

  /**
   *  For preselected time ranges, we will only search when searchquery is null. It will be set after the query is finished.
   *  For custom ranges, we will search whenever the values of the search query changes.
   *
   *  We breakdown filterSearchQuery into start and end timestamp variables so that for 'custom' date ranges
   *  the effect will only trigger when those values change, versus everytime the object gets changed.
   **/
  useEffect(() => {
    if (
      sensorId &&
      account &&
      activeFilterOptionValue !== "custom" &&
      filterSearchQuery === null
    ) {
      const searchQuery = getSearchQueryForDatePickerOption(
        activeFilterOptionValue
      );
      if (searchQuery) {
        incRC();
        sensorDetailDispatch(loadHistogramData(sensorId, searchQuery))
          .then(() => decRC())
          .catch(() => decRC());
      }
    }
    //eslint-disable-next-line
  }, [account, sensorId, activeFilterOptionValue, filterSearchQuery]);

  useEffect(() => {
    if (
      sensorId &&
      account &&
      activeFilterOptionValue === "custom" &&
      startTimestamp &&
      endTimestamp
    ) {
      const searchQuery = {
        ...(startTimestamp ? { "created_on.gte": startTimestamp } : {}),
        ...(endTimestamp ? { "created_on.lt": endTimestamp } : {}),
      };
      incRC();
      sensorDetailDispatch(loadHistogramData(sensorId, searchQuery))
        .then(() => decRC())
        .catch(() => decRC());
    }
    //eslint-disable-next-line
  }, [
    account,
    sensorId,
    activeFilterOptionValue,
    startTimestamp,
    endTimestamp,
  ]);
};
