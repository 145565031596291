import React, { ReactElement } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import { SensorListDisplayOption } from '../../../redux/sensor-overview/types';

export interface UsersSkeletonProps {
    size?: number,
    sensorListDisplayOption: SensorListDisplayOption
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: 10
        }
    }));

export const SensorTilesSkeleton: React.FC<UsersSkeletonProps> = (props) => {
    const classes = useStyles();
    
    let width: number | string;
    let height: number;
    
    const {
        size = 9,
        sensorListDisplayOption
    } = props;
    
    if (sensorListDisplayOption) {
        switch (sensorListDisplayOption) {
            case 'tiles':
                width = 214;
                height = 181;
                break;
            case 'mini_tiles':
                width = 214;
                height = 68;
                break;
            case 'list':
                width = '100%';
                height = 40;
                break;
        }
    }
    
    const elements: Array<ReactElement> = _.times(size, function(index: number) {
        return (
            <Box key={index}>
                <Skeleton classes={{
                    root: classes.root
                }} variant='rect' width={width} height={height} />
            </Box>
        );
    });
    
    return (
        <Box
            display='grid'
            gridTemplateColumns="repeat(auto-fill, minmax(214px, 214px))"
            gridColumnGap={28}
            gridAutoRows="1fr"
            gridRowGap={29}
            margin="0 -12px"
        >
            {
                elements
            }
        </Box>
    );
};