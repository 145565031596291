import React from 'react';
import style from './full-bins-chart.module.scss';
import { RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';

interface FullBinsChartProps {
    totalBins: number;
    awaitingPickup: number;
}

const COLOR_STROKEGRADIENT_0 = '#A7CFC5';
const COLOR_STROKEGRADIENT_100 = '#003B2C';

export const FullBinsChart: React.FC<FullBinsChartProps> = (props) => {

    const binData = [
        {
            name: 'Total bins',
            value: props.totalBins,
            fill: '#ffffff',
            background: false
        },
        {
            name: 'Full bins',
            value: props.awaitingPickup,
            fill: 'url(#strokegradientFullBins)'
        }
    ];

    return (
        <div className={style.FullBinsChart}>
            <div className={style.fullBinsChartValue}>{ props.awaitingPickup }</div>
            <ResponsiveContainer height={100}>
                <RadialBarChart height={100} width={100} data={binData} startAngle={90} endAngle={450} innerRadius={25} outerRadius={50}>
                    <defs>
                        <linearGradient id='strokegradientFullBins' x1='0' y1='0' x2='1' y2='0'>
                            <stop offset='0%' stopColor={COLOR_STROKEGRADIENT_100} stopOpacity={1} />
                            <stop offset='100%' stopColor={COLOR_STROKEGRADIENT_0} stopOpacity={1} />
                        </linearGradient>
                    </defs>
                    <RadialBar dataKey='value' background={true} isAnimationActive={false} cornerRadius={5} />
                </RadialBarChart>
            </ResponsiveContainer>
        </div>
    );
}
