import DoneIcon from '@material-ui/icons/Done';
import React, { useEffect, useState } from 'react';
import { Subscriber } from '../../../redux/account/types';
import { useSelector } from 'react-redux';
import { usersSelector } from '../../../redux/user/selectors';
import { clone } from 'ramda';
import { Optional } from '../../../redux/types';
import { User } from '../../../redux/user/types';
import { useUsersData } from '../../../redux/user/effects';
import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { Avatar } from '../../mui/Avatar';
import { useClientRefreshCallback } from '../../../redux/auth/effects';


export interface RowCellProps {
  className?: string;
  rowCellClassName?: string;
  onClick?: () => void;
  user: User;
}

const defaultSubscriber = {
  'email': '',
  'email_enabled': true,
  'phone_enabled': false,
  'phone_number': '',
  'in_app_enabled': true,
  'given_name': ''
};

export interface AccountReportStaffSettingsModalProps {
  subscribers: Array<Subscriber>;
  setSubscribers: (subscribers: Array<Subscriber>) => void;
}

export const ReportsSettingsStaffModal: React.FC<AccountReportStaffSettingsModalProps> = (props) => {
  const {
    subscribers,
    setSubscribers,
  } = props;
  
  useClientRefreshCallback(() => {
    setSortedUsers([])
  })

  const users = useSelector(usersSelector);
  useUsersData();

  const [sortedUsers, setSortedUsers] = useState<Optional<Array<User>>>(null);

  useEffect(() => {
    if (users && sortedUsers === null) {
      const su = clone(users).sort((u1, u2) => {
        const v1 = subscribers.some(s => s.email === u1.email) ? 1 : 0;
        const v2 = subscribers.some(s => s.email === u2.email) ? 1 : 0;
        return v2 - v1;
      });
      setSortedUsers(su);
    }
  }, [users, subscribers, sortedUsers, setSortedUsers]);

  const onClickSubscriberHandler = (user: User) => () => {
    const email = user.email;
    const givenName = user.given_name;
    const subs = subscribers || [];
    const isSelected: boolean = subs.some(s => s.email === email);

    const newSubs = [
      ...subs.filter(s => s.email !== email),
      ...(isSelected ? [] : [{
        ...defaultSubscriber,
        email,
        givenName
      }])
    ];

    setSubscribers(newSubs);
  };

  return (
    <Box>
      <Box>
        <Box>
          <Box mb={4}>
            <Box mb={1}>
              <Typography variant="h4">Add your staff members</Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="secondary">
                A staff member (if selected) will <b>only</b> receive information about the bins he or she is responsible for.
              </Typography>
            </Box>
          </Box>
          {sortedUsers &&
          <Box>
            <Grid container spacing={2} xs={12}>
              {sortedUsers.map((user: User) => {
                const { given_name: fName, family_name: lName, email } = user;
                const fullName = (fName || lName) ? [fName, lName].join(' ') : email;
                const checked = subscribers && subscribers.some(s => s.email === email);
                return (
                    <Grid key={user.email} item xs={6}>
                      <Chip
                          avatar={<Avatar user={user} />}
                          label={fullName}
                          clickable
                          size="medium"
                          variant="default"
                          color="default"
                          onClick={onClickSubscriberHandler(user)}
                          onDelete={onClickSubscriberHandler(user)}
                          deleteIcon={
                            checked ? (
                                <DoneIcon />
                            ) : (
                                <></>
                            )
                          }
                      />
                    </Grid>
                );
              })}
            </Grid>
          </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};
