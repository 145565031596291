import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DeepPartial } from '../../../redux/types';
import { createUser, updateUser } from '../../../redux/user/actions';
import { CreateUserProps, User, UserAsyncDispatch, UserRoleType } from '../../../redux/user/types';
import { Modal } from '../../controls/modal/modal';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { UserSettingsDetails } from './user-settings-details';
import style from './user-settings-modal.module.scss';
import { UserSettingsSensors } from './user-settings-sensors';
import classNames from 'classnames';
import { UserSensor } from '../../../api/contracts';
import { Help } from '../../controls/help/help';
import { Box } from '@material-ui/core';

export interface UserSettingsModalProps {
  user: User | null;
  onModalClose: () => void;
}

export const UserSettingsModal: React.FC<UserSettingsModalProps> = ({
  user,
  onModalClose,
}: UserSettingsModalProps) => {
  const modalAction: "add" | "edit" = user != null ? "edit" : "add";

  const [givenName, setGivenName] = useState<string>(user?.given_name || "");
  const [familyName, setFamilyName] = useState<string>(user?.family_name || "");
  const [email, setEmail] = useState<string>(user?.email || "");
  const [emailError, setEmailError] = useState<string>();
  const [givenNameError, setGivenNameError] = useState<string>();
  const [userRole, setUserRole] = useState<UserRoleType>(
    user?.user_role || "USER"
  );
  const [selectedSensors, setSelectedSensors] = useState<Array<UserSensor>>(
    user?.sensors || []
  );

  const modalCloseButtonClickHandler = (): void => {
    onModalClose && onModalClose();
  };

  const [incRC, decRC] = useLoadingBarDispatch();

  const userAsyncDispatch = useDispatch<UserAsyncDispatch>();
  const addUserDispatch = (): void => {
    const user: CreateUserProps = {
      email: email,
      user_role: userRole,
      ...(givenName && { given_name: givenName }),
      ...(familyName && { family_name: familyName }),
      sensors: selectedSensors,
    };

    incRC();
    userAsyncDispatch(createUser(user))
      .then(() => {
        decRC();
        onModalClose && onModalClose();
      })
      .catch(() => {
        decRC();
      });
  };
  const editUserDispatch = (): void => {
    const user: DeepPartial<User> = {
      email: email,
      user_role: userRole,
      ...(givenName && { given_name: givenName }),
      ...(familyName && { family_name: familyName }),
      sensors: selectedSensors,
    };
    incRC();
    userAsyncDispatch(updateUser(user))
      .then(() => {
        decRC();
        onModalClose && onModalClose();
      })
      .catch(() => {
        decRC();
      });
  };
  
  const formSubmitHandler = (): void => {
    modalAction === "add" && addUserDispatch();
    modalAction === "edit" && editUserDispatch();
  };

  const { t } = useTranslation();
  const modalTitle =
    modalAction === "add"
      ? t("settings.usersPage.addUserModal.title")
      : "Edit a user";

  type StepType = "details" | "sensors";
  const [step, setStep] = useState<StepType>("details");

  return (
    <Modal
        isOpen={true}
        maxWidth="md"
        onClose={modalCloseButtonClickHandler}
      title={modalTitle}
      content={(
          <Box className={style.UserSettingsModal}>
            <Box className={style.steps}>
              <ul>
                <li
                    className={classNames({ [style.selected]: step === "details" })}
                    onClick={() => setStep("details")}
                >
                  User Details
                </li>
                <li
                    className={classNames({ [style.selected]: step === "sensors" })}
                    onClick={() => setStep("sensors")}
                >
                  Responsible Bins
                </li>
              </ul>
              <Help>
                <h1>{`Add the bins the user is responsible for.`}</h1>
                <ul>
                  <li>
                <span>{`This means that ${
                    givenName ? givenName : "the user"
                } will only receive notifications from those bins.`}</span>
                  </li>
                  <li>
                    {`The scheduled pick-up report will only include bins that ${
                        givenName ? givenName : "the user"
                    } is responsible for.`}
                  </li>
                </ul>
              </Help>
            </Box>
            {step === "details" && (
                <UserSettingsDetails
                    givenName={givenName}
                    setGivenName={setGivenName}
                    givenNameError={givenNameError}
                    setGivenNameError={setGivenNameError}
                    familyName={familyName}
                    setFamilyName={setFamilyName}
                    email={email}
                    setEmail={setEmail}
                    emailError={emailError}
                    setEmailError={setEmailError}
                    userRole={userRole}
                    setUserRole={setUserRole}
                    onSubmit={() => setStep('sensors')}
                    modalAction={modalAction}
                    onClose={modalCloseButtonClickHandler}
                />
            )}
            {step === "sensors" && (
                <UserSettingsSensors
                    onSubmit={formSubmitHandler}
                    onClose={modalCloseButtonClickHandler}
                    modalAction={modalAction}
                    selectedSensors={selectedSensors}
                    setSelectedSensors={setSelectedSensors}
                />
            )}
          </Box>
      )}
    />
  );
};
