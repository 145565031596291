import Amplify, { Auth } from 'aws-amplify';

import config from './config';

const AWS_CONFIG = {
	Auth: {
		identityPoolId: config.Auth.identityPoolId,
		region: config.Auth.region,
		userPoolId: config.Auth.userPoolId,
		userPoolWebClientId: config.Auth.userPoolWebClientId,
	},
	Storage: {
		AWSS3: {
			bucket: config.Storage.bucket,
			region: config.Storage.region,
		},
	},
	API: {
		endpoints: [
			{
				name: config.API.name,
				endpoint: config.API.endpoint,
				custom_header: async () => {
					return {
						Authorization: `Bearer ${(await Auth.currentSession())
							.getIdToken()
							.getJwtToken()}`,
					};
				},
			},
		],
	},
};

Amplify.configure(AWS_CONFIG);
