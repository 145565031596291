import {
	NotificationActions,
	NotificationState,
	NotificationTypes,
} from './types';

const initialNotificationState: NotificationState = {
	notifications: [],
};

export const notificationReducer = (
	state: NotificationState = initialNotificationState,
	action: NotificationActions,
): NotificationState => {
	switch (action.type) {
		case NotificationTypes.SET_NOTIFICATIONS: {
			return {
				...state,
				notifications: action.payload,
			};
		}
		case NotificationTypes.DELETE_NOTIFICATION: {
			return {
				...state,
				notifications: state.notifications.filter(
					(notification) =>
						notification.notification_id !==
						action.payload.notification_id,
				),
			};
		}

		default: {
			return state;
		}
	}
};
