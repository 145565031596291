import { Action } from "redux";
import { ConditionOperator } from "../config/types";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ActionWithPayload, Optional } from "../types";

/**
 * Client API Types
 * */

export type EventType =
  | "Notifications_BinChanged"
  | "Notifications_BinFull"
  | "Notifications_MissedPickup"
  | "Notifications_BinEmpty"
  | "Notifications_LowBatteryLevel";

export interface EventCondition {
  value?: number;
  operator?: ConditionOperator;
}

export interface Subscriber {
  email: string;
  email_enabled?: boolean;
  phone_enabled?: boolean;
  phone_number?: string;
  in_app_enabled?: boolean;
  given_name?: string;
}

export interface Notification {
  enabled?: boolean;
  subscribers?: Array<Subscriber>;
}

export interface ScheduleOverride {
  schedule?: number;
  cycles?: number;
}

export interface Event {
  type: EventType;
  enabled?: boolean;
  condition?: EventCondition;
  notifications?: Notification;
  schedule_override?: ScheduleOverride;
}

export type SensorScheduleType = "RATE" | "WEEKLY";

export type DayOfWeekType =
  | "SUNDAY"
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY";

export interface SensorScheduleDay {
  day: DayOfWeekType;
  times?: Array<string>;
}

export interface SensorSchedule {
  type: SensorScheduleType;
  week?: Array<SensorScheduleDay>;
  rate?: number; // in seconds
}

export interface Locale {
  timezone?: string;
  language?: string;
}

export interface WasteStreamRequest {
  waste_type: WasteType
  height?: number | null;
  volume?: number | null;
  accuracy_adjustment?: number | null;
}

export interface WasteStreamResponse {
  waste_type: WasteType
  height: number | null;
  volume: number | null;
  accuracy_adjustment: number | null;
}

export interface BinType {
  bin_type_id: string;
  name: string;
  min_distance: number;
  max_distance: number;
  waste_type?: WasteType;
  volume?: number;
  waste_streams: WasteStreamResponse[]
}

export interface AccountReport {
  id: string;
  enabled: boolean;
  type: AccountReportTypeEnum;
  name: string;
  binsReadyForPickupThreshold?: number;
  allBins?: boolean;
  schedule?: SensorSchedule;
  subscribers?: Array<Subscriber>;
}

export enum AccountReportTypeEnum {
  BINS_READY_FOR_PICKUP = "BINS_READY_FOR_PICKUP",
  WASTE_VOLUME = "WASTE_VOLUME",
}

export enum WasteType {
  GLASS = "GLASS",
  PLASTIC = "PLASTIC",
  RECYCLABLE = "RECYCLABLE",
  METALS = "METALS",
  MIXED = "MIXED",
  PAPER = "PAPER",
  CLOTHING = "CLOTHING",
  ORGANIC = 'ORGANIC'
}

export enum ChartType {
  BAR = "BAR",
  PIE = "PIE",
  LINE = "LINE",
}

export interface Account {
  customer_id: string;
  account_name?: string;
  events?: Array<Event>;
  locale?: Locale;
  bin_types?: Array<BinType>;
  reports?: Array<AccountReport>;
  sensor_schedule?: SensorSchedule;
  options?: { map_enabled?: boolean };
}

export type AccountAPIPost = Omit<Account, "customer_id">;

/**
 * Redux Types
 * */

export type AccountState = {
  data: Optional<Account>;
  apiCalled: boolean;
  apiLoaded: boolean;
};

// ACTION TYPES

export enum AccountActionType {
  SET_ACCOUNT = "@@account/SET_ACCOUNT",
  UPDATE_BIN_TYPE = "@@account/UPDATE_BIN_TYPE",
  UPDATE_ACCOUNT_REPORT = "@@account/UPDATE_ACCOUNT_REPORT",
  ADD_BIN_TYPE = "@@account/ADD_BIN_TYPE",
  ADD_ACCOUNT_REPORT = "@@account/ADD_ACCOUNT_REPORT",
  DELETE_BIN_TYPE = "@@account/DELETE_BIN_TYPE",
  DELETE_ACCOUNT_REPORT = "@@account/DELETE_ACCOUNT_REPORT",
  SET_API_CALLED = "@@account/SET_API_CALLED",
  SET_API_LOADED = "@@account/SET_API_LOADED",
  SET_EVENT = "@@account/SET_EVENT",
  ADD_EVENT = "@@account/ADD_EVENT",
  ADD_SUBSCRIBER = "@@account/ADD_SUBSCRIBER",
  DELETE_SUBSCRIBER = "@@account/DELETE_SUBSCRIBER",
  SET_CONDITION_VALUE = "@@account/SET_CONDITION_VALUE",
}

export interface SetAccount extends Action {
  type: AccountActionType.SET_ACCOUNT;
  payload: Account;
}

export type AddBinType = ActionWithPayload<
  AccountActionType.ADD_BIN_TYPE,
  BinType
>;
export type AddAccountReport = ActionWithPayload<
  AccountActionType.ADD_ACCOUNT_REPORT,
  AccountReport
>;
export type UpdateBinType = ActionWithPayload<
  AccountActionType.UPDATE_BIN_TYPE,
  BinType
>;
export type UpdateAccountReport = ActionWithPayload<
  AccountActionType.UPDATE_ACCOUNT_REPORT,
  AccountReport
>;
export type DeleteBinType = ActionWithPayload<
  AccountActionType.DELETE_BIN_TYPE,
  BinType
>;
export type DeleteAccountReport = ActionWithPayload<
  AccountActionType.DELETE_ACCOUNT_REPORT,
  AccountReport
>;

export type SetAccountAPICalled = ActionWithPayload<
  AccountActionType.SET_API_CALLED,
  boolean
>;
export type SetAccountAPILoaded = ActionWithPayload<
  AccountActionType.SET_API_LOADED,
  boolean
>;
export type SetEvent = ActionWithPayload<AccountActionType.SET_EVENT, Event>;
export type AddEvent = ActionWithPayload<AccountActionType.ADD_EVENT, Event>;

// Event Actions Types
interface EventTypeAction<E, T extends string, P>
  extends ActionWithPayload<T, P> {
  eventType: E;
}

export type AddSubscriber = EventTypeAction<
  EventType,
  AccountActionType.ADD_SUBSCRIBER,
  Subscriber
>;
export type DeleteSubscriber = EventTypeAction<
  EventType,
  AccountActionType.DELETE_SUBSCRIBER,
  Subscriber
>;
export type SetConditionValue = EventTypeAction<
  EventType,
  AccountActionType.SET_CONDITION_VALUE,
  number
>;

// Dispatch Types
export type AccountAction =
  | SetAccount
  | SetAccountAPICalled
  | SetAccountAPILoaded
  | SetEvent
  | AddEvent
  | AddSubscriber
  | DeleteSubscriber
  | SetConditionValue
  | AddBinType
  | UpdateBinType
  | UpdateAccountReport
  | DeleteBinType
  | DeleteAccountReport
  | AddAccountReport;
export type AccountAsyncAction = ThunkAction<
  Promise<void | AccountAction | Account>,
  {},
  {},
  AccountAction
>;
export type AccountAsyncDispatch = ThunkDispatch<{}, {}, AccountAction>;
