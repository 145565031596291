import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

/** Possible operators for the conditions within an event */
export type ConditionOperator = "NE" | "EQ" | "LE" | "GE" | "GT" | "LT";

/**
 * Describes a configuration for a condition.
 * There is no field that tells to which property of an object it has to be applied.
 */
export interface NotificationCondition {
  /** The value that must be compared */
  value: number;
  /** The operator to compare with */
  operator: ConditionOperator;
}

/**
 * Describes a notification event
 */
export interface NotificationEvent {
  /** Type of notification. Maybe replace with its own type definition later */
  type: string;
  /** Whether the notification is enabled or not */
  enabled: boolean;
  /** When to trigger the notification */
  condition: NotificationCondition;
}

/**
 * Describes the Account object from the API.
 */
export interface ClientAccount {
  /** First name */
  given_name: string;
  /** Last name */
  family_name: string;
  /** Email address */
  email: string;
  /** Language */
  language: string;
  /** Customer ID */
  customer_id: string;
  /** Created date as unix timestamp */
  created_on: number;
  /** Updated date as unix timestamp */
  updated_on: number;
  /** Array of configured events */
  events: Array<NotificationEvent>;
  /** Role for the user */
  user_role: string;
}

export interface ConfigState {
  clientAccount: ClientAccount | null;
}

export enum ConfigActionTypes {
  SET_CLIENT_ACCOUNT = "@@config/SET_CLIENT_ACCOUNT",
}

export interface SetClientAccount {
  type: ConfigActionTypes.SET_CLIENT_ACCOUNT;
  payload: ClientAccount | null;
}

export type ConfigActions = SetClientAccount;
export type ConfigAsyncActions = ThunkAction<
  Promise<void | ConfigActions>,
  {},
  {},
  AnyAction
>;
export type ConfigAsyncDispatch = ThunkDispatch<{}, {}, AnyAction>;
