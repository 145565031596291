import { Sensor } from '../api/contracts';
import { LngLatBoundsLike, LngLatLike } from 'mapbox-gl';

export const getCenterAndZoomOfSensors = (
	sensors: Array<Sensor>,
): { center: LngLatLike; zoom: number } => {
	const { longitudes, latitudes } = sensors.reduce(
		(lls, s) => {
			if (s.location) {
				const { latitude, longitude } = s.location!.coordinates;
				lls.latitudes.push(latitude);
				lls.longitudes.push(longitude);
			}
			return lls;
		},
		{ latitudes: [], longitudes: [] } as LngsLats,
	);

	if (latitudes.length === 0 || longitudes.length === 0)
		return { center: [0, 0], zoom: 0 };

	const maxLatitude = Math.max(...latitudes);
	const minLatitude = Math.min(...latitudes);
	const deltaLatitude = Math.abs(maxLatitude - minLatitude);
	const maxLongitude = Math.max(...longitudes);
	const minLongitude = Math.min(...longitudes);
	const deltaLongitude = Math.abs(maxLongitude - minLongitude);

	const centerLatitude = (maxLatitude + minLatitude) / 2;
	const centerLongitude = (maxLongitude + minLongitude) / 2;
	const center = [centerLongitude, centerLatitude] as LngLatLike;

	const delta = Math.max(deltaLatitude, deltaLongitude);
	const zoom = Math.round(Math.log(360 / delta) / Math.LN2);

	return { center, zoom };
};

export const getBoundsOfSensors = (
	sensors: Array<Sensor>,
): LngLatBoundsLike => {
	const { longitudes, latitudes } = sensors.reduce(
		(lls, s) => {
			if (s.location) {
				const { latitude, longitude } = s.location!.coordinates;
				lls.latitudes.push(latitude);
				lls.longitudes.push(longitude);
			}
			return lls;
		},
		{ latitudes: [], longitudes: [] } as LngsLats,
	);

	if (latitudes.length === 0 || longitudes.length === 0) return [0, 0, 0, 0];

	const mapPadding = 1.4;

	const maxLatitude = Math.max(...latitudes);
	const minLatitude = Math.min(...latitudes);
	const deltaLatitude =
		(Math.abs(maxLatitude - minLatitude) * mapPadding) / 2;
	const maxLongitude = Math.max(...longitudes);
	const minLongitude = Math.min(...longitudes);
	const deltaLongitude =
		(Math.abs(maxLongitude - minLongitude) * mapPadding) / 2;
	const centerLatitude = (maxLatitude + minLatitude) / 2;
	const centerLongitude = (maxLongitude + minLongitude) / 2;

	// west, south, east , north
	return [
		centerLongitude - deltaLongitude,
		centerLatitude - deltaLatitude,
		centerLongitude + deltaLongitude,
		centerLatitude + deltaLatitude,
	];
};

interface LngsLats {
	latitudes: Array<number>;
	longitudes: Array<number>;
}
