import React, { ReactElement } from 'react';
import style from './switch.module.scss';
import classNames from 'classnames';
import { FormLabel } from '../../building-blocks/form-label/form-label';
import { Help } from '../help/help';

export interface SwitchProps {
  name: string;
  checked: boolean;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  onChange?: () => void;
  help?: ReactElement;
}

export const Switch: React.FC<SwitchProps> = ({
                                                name,
                                                checked,
                                                required = false,
                                                label,
                                                disabled = false,
                                                onChange,
                                                help
                                              }) => {
  const stateClassName = disabled ? style.disabled : checked ? style.checked : null;
  return (
    <div className={classNames(style.Switch, stateClassName)}>
      {
        label &&
        <FormLabel for={name} required={required}>
          {label}
          {
            help &&
            <div className={style.help}>
              <Help>
                {help}
              </Help>
            </div>
          }
        </FormLabel>
      }
      <input id={name} name={name} value={name} type='checkbox' checked={checked} onChange={() => onChange && onChange()} />
      <div className={style.background} onClick={() => onChange && onChange()}>
        <div className={style.gradientBackground} />
        <div className={style.toggle} />
      </div>
    </div>
  );
};