import React from 'react';
import style from './sensor-overview.module.scss';
import { InterfaceFrame } from '../../building-blocks/interface-frame/interface-frame';
import { SensorList } from '../../building-blocks/sensor-list/sensor-list';
import { SensorOverviewHeader } from '../../building-blocks/sensor-overview-header/sensor-overview-header';
import { useLocation } from 'react-router-dom';
import querystring from 'querystring';
import { Optional } from '../../../redux/types';
import { useSensorOverviewData } from '../../../redux/sensor-overview/effects';

export const SensorOverview: React.FC<{}> = () => {
  const location = useLocation();
  const searchQuery = location.search.replace(/^\?/, "");
  const query = querystring.parse(searchQuery);
  const field: Optional<string> = (query.field as string) || null;
  const value: Optional<string> = (query.value as string) || null;
  useSensorOverviewData(field, value);

  return (
    <InterfaceFrame>
      <div className={style.SensorOverview}>
        <SensorOverviewHeader />
        <SensorList />
      </div>
    </InterfaceFrame>
  );
};
