import React from 'react';
import { Sidebar } from '../sidebar/sidebar';
import { InterfaceHeader } from '../interface-header/interface-header';
import style from './interface-frame.module.scss';

interface DashboardFrameProps {
    children?: React.ReactNode;
}

export const InterfaceFrame: React.FC<DashboardFrameProps> = (props) => {
    return (
        <div className={style.DashboardView}>
            <InterfaceHeader />
            <div className={style.dashboardMainStage}>
                <Sidebar />
                <div className={style.dashboardContent}>{props.children && props.children}</div>
            </div>
        </div>
    );
};
