import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientIdSelector, userSelector } from './selectors';
import { AuthAsyncDispatch } from './types';
import { loadUser } from './actions';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { appRoutes } from '../../config/routes';
import { customerIdSelector } from '../account/selectors';

export const useAuthData = (): void => {
	const history = useHistory();
	// Make sure, we have an authenticated user.
	// If that is not the case, redirect back to the login screen.
	Auth.currentSession().catch(() =>
		history.push(appRoutes.login.path as string),
	);

	const user = useSelector(userSelector);
	const authDispatch = useDispatch<AuthAsyncDispatch>();
	useEffect(() => {
		if (!user) authDispatch(loadUser());
	}, [user, authDispatch]);
};

/**
 * I make sure that account and auth are in sync before I call the refresh callback in case
 * any of the data is dependent on account data.
 * **/
export const useClientRefreshCallback = (callBack?: () => void): void => {
	const clientId = useSelector(clientIdSelector);
	const customerId = useSelector(customerIdSelector);
	const [notInSync, setNotInSync] = useState(false);
	useEffect(() => {
		if (clientId && customerId && clientId !== customerId)
			setNotInSync(true);
		else if (
			clientId &&
			customerId &&
			callBack &&
			notInSync &&
			clientId === customerId
		) {
			setNotInSync(false);
			callBack();
		}
	}, [clientId, customerId, callBack, notInSync, setNotInSync]);
};
