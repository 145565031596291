import { API } from "aws-amplify";
import { OrderDirection } from "../types";
import config from "../../config/config";
import {
  DeleteNotification,
  NotificationActions,
  NotificationAsyncActions,
  NotificationAsyncDispatch,
  NotificationListRequestParams,
  NotificationListResponse,
  NotificationListSortOptions,
  NotificationResponse,
  NotificationTypes,
  SetNotifications,
} from "./types";
import {
  notifyError,
  notifyErrorMessage,
  notifySuccess,
} from "../../utils/notify";

export const deleteNotificationAction = (
  notificationResponse: NotificationResponse
): DeleteNotification => ({
  type: NotificationTypes.DELETE_NOTIFICATION,
  payload: notificationResponse,
});

export const loadNotifications =
  (
    sortField: NotificationListSortOptions,
    sortOrder: OrderDirection = "desc",
    limit = 100
  ): NotificationAsyncActions =>
  (
    dispatch: NotificationAsyncDispatch
  ): Promise<NotificationActions | void> => {
    const queryStringParameters: NotificationListRequestParams = {
      limit: limit,
      sort_field: sortField,
      sort_order: sortOrder,
    };

    return API.get(config.API.name, "/notifications", {
      queryStringParameters,
    }).then((response: NotificationListResponse) => {
      dispatch(setNotifications(response.data));
    });
  };

export const setNotifications = (
  notifications: Array<NotificationResponse>
): SetNotifications => {
  return {
    type: NotificationTypes.SET_NOTIFICATIONS,
    payload: notifications,
  };
};

export const deleteNotification =
  (notificationResponse: NotificationResponse): NotificationAsyncActions =>
  (
    dispatch: NotificationAsyncDispatch
  ): Promise<void | NotificationActions> => {
    return API.del(
      config.API.name,
      `/notifications/${notificationResponse.notification_id}`,
      {}
    )
      .then((notificationResponse: NotificationResponse) => {
        dispatch(deleteNotificationAction(notificationResponse));
        notifySuccess("Notification deleted");
      })
      .catch((e) => {
        notifyError("Notification not deleted", notifyErrorMessage(e));
        console.error(e);
      });
  };
