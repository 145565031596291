import React from 'react';
import style from './schedule-selector.module.scss';
import classNames from 'classnames';
import { daysOfWeekOptions, minutesSinceMidnight, timesInDayOptions } from '../../../utils/time';
import { ScheduleSelectorTimeCell } from '../../building-blocks/schedule-selector-time-cell/schedule-selector-time-cell';
import { SensorScheduleDay } from '../../../redux/account/types';
import { ScheduleSelectorAction } from './types';
import { weekReducer } from './reducer';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { timezoneSelector } from '../../../redux/account/selectors';
import moment from 'moment-timezone';

export interface ScheduleSelectorProps {
    week: Array<SensorScheduleDay>;
    onChange?: (value: Array<SensorScheduleDay>) => void;
    readonly?: boolean;
    cellHeight?: number;
    height?: number | string;
    error?: boolean
}

export const ScheduleSelector: React.FC<ScheduleSelectorProps> = (props) => {
    const {
        week,
        readonly = false,
        cellHeight = 50,
        height = '100%',
        error
    } = props;

    const timeCellOnChangeHandler = (action: ScheduleSelectorAction): void => {
        const newWeek = weekReducer(week, action);
        props.onChange && props.onChange(newWeek);
    }
    
    const timezone = useSelector(timezoneSelector);

    return (
        <Box className={classNames(style.ScheduleSelector, {[style.readonly]: readonly, [style.error]: error})}>
            <Box display="flex" pr="10px" pb={1} className={style.header}>
                <Box textAlign="center" minWidth={50}>
                    <Typography variant="subtitle2" color="secondary">
                        {`GMT${moment.tz(timezone).format("Z").split(':')[0]}`}
                    </Typography>
                </Box>
                <Box display="flex" flexBasis="100%">
                    {
                        daysOfWeekOptions.map(d => (
                            <Box textAlign="center" key={'header_' + d.value} className={classNames(style.day, style[d.type])}>
                                <Typography variant="h3" color="textPrimary">{d.label}</Typography>
                            </Box>
                        ))
                    }
                </Box>
            </Box>
            <Box height={height} pr="10px" className={style.contentWrapper}>
                <Box className={style.content}>
                    <Box
                        className={style.timeline}>
                        <Box>
                            {
                                timesInDayOptions.map((time, idx)=> {
                                    return (
                                        <Box
                                            textAlign="center"
                                            width={50}
                                            height={cellHeight}
                                            key={`time_${time.value}`} className={style.time}
                                        >
                                            {
                                                time.value !== '00:00' &&
                                                <Typography variant="subtitle2" color="textPrimary">{ time.value }</Typography>
                                            }
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                    {
                        daysOfWeekOptions.map(day => {
                            const scheduleDay: SensorScheduleDay | undefined = week.find(scheduleDay => scheduleDay.day === day.value);
        
                            return (
                                <Box key={`day_${day.value}`} className={style.day}>
                                    {   timesInDayOptions.map(time => {
                                        const minTime = minutesSinceMidnight(time.value);
                                        const maxTime = minTime + 60;
                                        const scheduledTime = scheduleDay?.times?.find(scheduleTime => {
                                            const scheduledMinutesSinceMidnight = minutesSinceMidnight(scheduleTime)
                                            return scheduledMinutesSinceMidnight >= minTime && scheduledMinutesSinceMidnight < maxTime;
                                        }) || '';
                    
                                        const isScheduled = scheduledTime !== '';
                    
                                        return (
                                            <ScheduleSelectorTimeCell
                                                key={`cell_${day.value}${time.value}`}
                                                day={day}
                                                time={time}
                                                value={scheduledTime}
                                                selected={isScheduled}
                                                onChange={timeCellOnChangeHandler}
                                                height={cellHeight}
                                            />
                                        );
                                    })}
                                </Box>
                            );
                        })
                    }
                </Box>
            </Box>
        </Box>
    )
}