import {
  ActionWithPayload,
  ListRequestParams,
  ListResponse,
  Response,
} from "../types";
import { EventType } from "../account/types";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

export type NotificationListSortOptions = "created_on" | "updated_on" | "email";
export type DeleteNotification = ActionWithPayload<
  NotificationTypes.DELETE_NOTIFICATION,
  NotificationResponse
>;

export interface NotificationListRequestParams extends ListRequestParams {
  sort_field: NotificationListSortOptions;
}

export interface NotificationListResponse extends ListResponse {
  data: Array<NotificationResponse>;
}

export interface NotificationResponse extends Response {
  notification_id: string;
  customer_id: string;
  sensor_id?: string;
  sensor_fill_percentage?: number;
  sensor_nickname?: string;
  event_type: EventType;
  email: string;
  read: boolean;
  archived: boolean;
}

export interface SetNotifications {
  type: NotificationTypes.SET_NOTIFICATIONS;
  payload: Array<NotificationResponse>;
}

export enum NotificationTypes {
  SET_NOTIFICATIONS = "@@notification/SET_NOTIFICATIONS",
  DELETE_NOTIFICATION = "@@notification/DELETE_NOTIFICATION",
}

export type NotificationActions = SetNotifications | DeleteNotification;
export type NotificationAsyncActions = ThunkAction<
  Promise<void | NotificationActions>,
  unknown,
  unknown,
  AnyAction
>;
export type NotificationAsyncDispatch = ThunkDispatch<
  {},
  {},
  NotificationActions
>;

export interface NotificationState {
  notifications: Array<NotificationResponse>;
}
