import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ActionWithPayload, Optional } from "../types";
import { DatePickerOption } from "../../components/controls/date-picker-control/utils";
import {
  HistogramQuery,
  SearchResponse,
  WasteCollectionResponse,
} from "../../api/histogram";

export interface DashboardStats {
  avgFill: number;
  awaitingPickup: number;
  binsTotal: number;
}

export interface AvgFillChartReport {
  created_on: number;
  value: number | null;
}

export interface AwaitingPickupChartReport {
  nickname: string;
  fill_percentage: number;
}

export interface HistogramReport {
  datetime: number;
  value: number;
}

export interface DashboardState {
  dashboardStats: DashboardStats;
  avgFillChartData: Array<AvgFillChartReport>;
  avgFillChartFilter: DatePickerOption;
  avgFillChartSearchQuery: Optional<Partial<HistogramQuery>>;
  awaitingPickupChartData: Array<AwaitingPickupChartReport>;
  todayMissedPickupCount: number;
  missedPickupChartData: Array<HistogramReport>;
  totalBagsCount: number;
  avgPickupFillChartData: Optional<SearchResponse>;
  wasteCollectionPanelData: Optional<WasteCollectionResponse>;
  bagsChartData: Array<HistogramReport>;
}

export enum DashboardTypes {
  SET_DASHBOARD_STATS = "@@dashboard/SET_DASHBOARD_STATS",
  SET_AVG_FILL_CHART_FILTER = "@@dashboard/SET_AVG_FILL_CHART_FILTER",
  SET_AVG_FILL_CHART_SEARCH_QUERY = "@@dashboard/SET_AVG_FILL_CHART_SEARCH_QUERY",
  SET_AVG_FILL_CHART_DATA = "@@dashboard/SET_AVG_FILL_CHART_DATA",
  SET_AWAITING_PICKUP_CHART_DATA = "@@dashboard/SET_AWAITING_PICKUP_CHART_DATA",
  SET_TODAY_MISSED_PICKUP_COUNT = "@@dashboard/SET_TODAY_MISSED_PICKUP_COUNT",
  SET_MISSED_PICKUP_CHART_DATA = "@@dashboard/SET_MISSED_PICKUP_CHART_DATA",
  SET_TOTAL_BAGS_COUNT = "@@dashboard/SET_TOTAL_BAGS_COUNT",
  SET_AVG_PICKUP_FILL = "@@dashboard/SET_AVG_PICKUP_FILL",
  SET_AVG_PICKUP_FILL_CHART_DATA = "@@dashboard/SET_AVG_PICKUP_FILL_CHART_DATA",
  SET_WASTE_COLLECTION_DATA = "@@dashboard/SET_WASTE_COLLECTION_DATA",
  SET_BAGS_CHART_DATA = "@@dashboard/SET_BAGS_CHART_DATA",
}

export interface SetDashboardStats extends Action {
  type: DashboardTypes.SET_DASHBOARD_STATS;
  payload: DashboardStats;
}

export interface SetAvgFillChartFilter extends Action {
  type: DashboardTypes.SET_AVG_FILL_CHART_FILTER;
  payload: DatePickerOption;
}

export type SetAvgFillChartSearchQuery = ActionWithPayload<
  DashboardTypes.SET_AVG_FILL_CHART_SEARCH_QUERY,
  Optional<Partial<HistogramQuery>>
>;

export interface SetAvgFillChartData extends Action {
  type: DashboardTypes.SET_AVG_FILL_CHART_DATA;
  payload: Array<AvgFillChartReport>;
}

export interface SetAwaitingPickupChartData extends Action {
  type: DashboardTypes.SET_AWAITING_PICKUP_CHART_DATA;
  payload: Array<AwaitingPickupChartReport>;
}

export interface SetTodayMissedPickupCount extends Action {
  type: DashboardTypes.SET_TODAY_MISSED_PICKUP_COUNT;
  payload: number;
}

export interface SetMissedPickupChartData extends Action {
  type: DashboardTypes.SET_MISSED_PICKUP_CHART_DATA;
  payload: Array<HistogramReport>;
}

export interface SetTotalBagsCount extends Action {
  type: DashboardTypes.SET_TOTAL_BAGS_COUNT;
  payload: number;
}

export interface SetAvgPickupFillChartData extends Action {
  type: DashboardTypes.SET_AVG_PICKUP_FILL_CHART_DATA;
  payload: SearchResponse;
}

export interface SetWasteCollectionData extends Action {
  type: DashboardTypes.SET_WASTE_COLLECTION_DATA;
  payload: WasteCollectionResponse;
}

export interface SetBagsChartData extends Action {
  type: DashboardTypes.SET_BAGS_CHART_DATA;
  payload: Array<HistogramReport>;
}

export type DashboardAction =
  | SetDashboardStats
  | SetAvgFillChartFilter
  | SetAvgFillChartSearchQuery
  | SetAvgFillChartData
  | SetAwaitingPickupChartData
  | SetTodayMissedPickupCount
  | SetMissedPickupChartData
  | SetTotalBagsCount
  | SetBagsChartData
  | SetAvgPickupFillChartData
  | SetWasteCollectionData;

export type DashboardAsyncAction = ThunkAction<
  Promise<void | DashboardAction>,
  {},
  {},
  DashboardAction
>;
export type DashboardAsyncDispatch = ThunkDispatch<{}, {}, DashboardAction>;
