import React from 'react';
import style from './radio-input.module.scss';
import classNames from 'classnames';

export type RadioValueType = string | number;

export interface RadioInputProps {
    value: RadioValueType;
    name: string;
    checked: boolean;
    onChange: (value: RadioValueType) => void;
}

export const RadioInput: React.FC<RadioInputProps> = (props)=>{
    const { value, name, checked, onChange } = props;
    return (
        <div className={style.RadioInput}>
            <input
                type='radio'
                value={value}
                name={name}
                checked={checked}
                onChange={()=>{ onChange(value) }}
            />
            <div className={classNames(style.RadioButton, { [style.checked]: checked })}>
                <div className={style.border}>
                    <div className={style.dot} />
                </div>
            </div>
        </div>
    );
}
